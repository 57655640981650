import { useState, useEffect, useContext  } from 'react';

import {SettingsContext} from '../components/userSettingsContext';
import { DataGridPro, GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid-pro';
import {TransformDataForGrid}  from "../components/helpers";
import { Alert, AlertTitle, Button,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
   IconButton, Tooltip} from '@mui/material';
import {LionLoaderGradient} from "../commonComponents/generalComponents";
import {LionListbox} from "../commonComponents/inputFields";
  
import axios from 'axios';
import { Icon as Iconify } from '@iconify/react';

import { CcTitle, CcCreateNewButton } from '../commonComponents/styledTexts';
import { ccColorDarkTeal } from '../components/helpersStyles';
import { InputText } from '../commonComponents/inputFields';

import { object as yupObject, string, } from 'yup';
import { getErrorListFromYupError, getErrorMessage } from '../components/helperErrors';


export default function PageAdminUsers() {
    /*
    props:

    NO props 
    */
    const {settings, setSettings} = useContext(SettingsContext);
    const apiRef = useGridApiRef();
    const [logins, setLogins] = useState([]);
    const [addUser, setAddUser]  = useState(false);
    const [editUser, setEditUser]  = useState(false); //flag to check if we are in edit mode
    const [editUserData, setEditUserData]  = useState(); //contains the user datarow from the grid
    const [newUserUsername, setNewUserUsername]  = useState('');
    const [newUserEmail, setNewUserEmail]  = useState('');
    const [newUserType, setNewUserType]  = useState('SuperUser');
    const [newUserActivationCode, setNewUserActivationCode]  = useState('');

    const [showLoader, setShowLoader] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState('');
    const [showAlreadyExistsMsg, setShowAlreadyExistsMsg] = useState(false);

    const [loginNameExistsMsg, setLoginNameExistsMsg] = useState(null)
    const [firstname, setFirstname] = useState("")
    const [surname, setSurname] = useState("")
    const [activeErrorList, setActiveErrorList] = useState([]);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState({action: '', id:'', title:'', desc:''});
  
    const loginTypes = [{id: 'Admin', name: 'Administrator'},
                        {id: 'SuperUser', name: 'Super User'},
                        {id: 'Designer', name: 'Designer'},
                        {id: 'ReadOnly', name: 'Read Only'},]

    useEffect(() => {
        getLogins()
    }, [])

    const getLogins = async () => {
        try {  
          const url = `${process.env.REACT_APP_LION_API_URL}/Settings/logins`;
          const response = await axios.get(url);
          const data = response.data;
        setLogins(TransformDataForGrid(data, 'loginId'));

        } catch (error) {
          console.log(error);
        }
      }
    const deleteLogin = (loginId) => {
      // we open the confirmation dialog
      const dialogNewData = {action: 'delete', id:loginId, title:'Delete Login', desc:'Are you sure you want to delete this login?'};
      setDialogData(dialogNewData);
      setDialogOpen(true);
    }
    const deleteLoginCall = async (loginId) => {

      try {  
        const url = `${process.env.REACT_APP_LION_API_URL}/User/DeleteLogin/${loginId}`;
        const response = await axios.get(url);
        const data = response.data;

      } catch (error) {
        console.log(error);
      }
    }

    const columns = [
      { field: 'loginName', headerName: 'Name', width: 200 },
      { field: 'loginType', headerName: 'Type', width: 200 },
      { field: 'email', headerName: 'Email', width: 200 },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params) => [
          //<GridActionsCellItem icon={<Iconify icon='material-symbols:edit' width={20} />} onClick={() => {setAddUser(true); setEditUser(true); setEditUserData(params.row);}} label="Edit" disabled={params.row.loginType === 'Admin'} />,
          <GridActionsCellItem icon={<Iconify icon='material-symbols:delete-outline' width={20} />} onClick={() => {deleteLogin(params.row.id)}} label="Delete" disabled={params.row.loginType === 'Admin'}  />,
        ]
      }
      
    ];
    
    const submitAddUser = async() => {
      const objToValidate = createUserFormDataObject()
      const isValidForm = validateUserForm(objToValidate);
      if (!isValidForm) return;
  
      setShowLoader(true)
      setShowAlreadyExistsMsg(false)
      try {
        const url = `${process.env.REACT_APP_LION_API_URL}/User/CreateLogin`;

        const data = {loginName: newUserUsername, email: newUserEmail, firstname: firstname, surname: surname, loginType: newUserType}

        const response = await axios.post(url, data);

        if (response.data.success) {
          setShowSuccess(true)
          setNewUserActivationCode(response.data.result)
        }
        else {
          if (response.data.errorCode === 1) {
            setShowAlreadyExistsMsg(true)
          }
          else {
            setShowError(true)
            setShowErrorMsg(response.data.errorMessage)    
          }
        }

      } catch (error) {
        setShowError(true)
        setShowErrorMsg('Something went wrong, try again later')
      }
      finally {
        setShowLoader(false);

      }
    }
    const submitEditUser = async() => {
      setShowLoader(true)
      setShowAlreadyExistsMsg(false)
      try {
        const url = `${process.env.REACT_APP_LION_API_URL}/User/EditLogin`;

        const data = {...editUserData, loginId: editUserData.id};

        const response = await axios.post(url, data);

        if (response.data.success) {
          setShowSuccess(true)
          setNewUserActivationCode(response.data.result)
        }
        else {
          if (response.data.errorCode === 1) {
            setShowAlreadyExistsMsg(true)
          }
          else {
            setShowError(true)
            setShowErrorMsg(response.data.errorMessage)    
          }
        }

      } catch (error) {
        setShowError(true)
        setShowErrorMsg('Something went wrong, try again later')
      }
      finally {
        setShowLoader(false);

      }
    }

    const handleNweUserUsernameChange = (newValue) => {
      const regex = /^[a-z0-9]+$/i;
      const isAlphanumeric = regex.test(newValue);
      if (!isAlphanumeric && newValue !== '') return;

      const objToValidate = createUserFormDataObject()
      objToValidate.loginName = newValue;

      if (activeErrorList.length > 0) validateUserForm(objToValidate)

        if (newValue.length >= 6) {
          checkLoginExists(newValue)
      }

      setNewUserUsername(newValue)
    }
    const editLoginType = (newValue) => {
      const newEditUserData = {...editUserData, loginType: newValue}
      console.log(newEditUserData)
      setEditUserData(newEditUserData)
    }
    const closeAddUserForm = () => {
      setShowError(false)
      setShowErrorMsg('')
      setShowLoader(false)
      setShowSuccess(false)
      setShowAlreadyExistsMsg(false)
      setNewUserActivationCode('')

      setAddUser(false)
      setEditUser(false)

      getLogins()
    }
      
    const handleDialogClose = (action) => {
      if (action === 'delete') {
          deleteLoginCall(dialogData.id);
    
        apiRef.current.updateRows([{ id: dialogData.id, _action: 'delete' }]);      
      }
    
      setDialogData({action: '', id:'', title:'', desc:''});
      setDialogOpen(false);
    }

    const checkLoginExists = async (login) => {
        try {
            const url = `${process.env.REACT_APP_LION_API_URL}/email/login/exists/${login}`;
            const response = await axios.get(url);
            if (response.data === true) setLoginNameExistsMsg("This login already exists")
            else setLoginNameExistsMsg(null)
    
        return response.data;
                    
        } catch (error) {
            console.log(error);
        }    
    }
      
    const userFormValidationSchema = yupObject({
      email: string().required("Required field"),
      firstname: string().required("Required field"),
      surname: string().required("Required field"),
      loginName: string().required("Required field").min(6, "Must be at least 6 characters long"),
    });
    const createUserFormDataObject = () => {
      return {loginName: newUserUsername, email: newUserEmail, firstname: firstname, surname: surname}
    }
    const validateUserForm = (formData) => {
      let result = false;
      try {
        userFormValidationSchema.validateSync(formData, {abortEarly: false});
        setActiveErrorList([])
        result = true;
      }
      catch (e) {
        const errorList = getErrorListFromYupError(e)
        console.log(errorList)
        setActiveErrorList(errorList)
        result = false;
      }

      return result;
    }

    const handleFirstnameChange = (newName) => {
      const objToValidate = createUserFormDataObject()
      objToValidate.firstname = newName
      if (activeErrorList.length > 0) validateUserForm(objToValidate)

        setFirstname(newName)
    }
    const handleSurnameChange = (newName) => {
      const objToValidate = createUserFormDataObject()
      objToValidate.surname = newName
      if (activeErrorList.length > 0) validateUserForm(objToValidate)

        setSurname(newName)
    }
    const handleOpenAddUserForm = () => {
      setActiveErrorList([])
      setFirstname("")
      setSurname("")
      setNewUserEmail("")
      setNewUserUsername("")
      
      setAddUser(true)
    }

    return <div>
      <CcTitle>Teammate User Management</CcTitle>
      <div className='mt-2 text-ccColorDarkTeal'>Welcome to the User Management area of your account. Here, administrators can easily add, manage and remove new teammates in your business account.</div>
      {!addUser && !editUser &&
                <div className='flex flex-col gap-2'>
                {logins.length > 0 && settings.accountSettings !== undefined && <div>
                                <div className='flex flex-row items-center gap-4 mt-2'>
                                            <div className=' text-base text-ccColorDarkTeal'>You currently have {logins.length} active teammates out of your allotted {settings.accountSettings.maxLogins}.</div>
                                            {/*<button type="button" class="text-white bg-lionGradient2Via hover:bg-lionGradient2From font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Get more logins</button>*/}
                                    </div>
                                </div>     
                }
                {logins.length > 0 && settings.accountSettings !== undefined && <div>
                                        {logins.length < settings.accountSettings.maxLogins &&
                                          <CcCreateNewButton onClick={handleOpenAddUserForm} >
                                          add new teammate
                                          </CcCreateNewButton>
                                        }
                                <div>
                                <DataGridPro rows={logins} columns={columns}
                                            apiRef={apiRef}
                                />
                                </div>
                                <div className='text-ccColorDarkTeal mt-2'>If you’d like to add more teammates to your existing subscription, please <a className='text-ccColorSecondaryPink' href='mailto:support@codechemistry.io?subject=I%27d%20like%20to%20add%20more%20teammates&body=Please increase'>email support.</a></div>

                    </div>
                }
        </div>
      }
      {addUser &&  <div className=' rounded shadow-lg shadow-lionGradient2Via p-1'>
        <div className='flex flex-row-reverse'>
         <IconButton variant="contained" sx={{color: ccColorDarkTeal}} onClick={() => closeAddUserForm()}  >
            <Iconify icon="material-symbols:cancel-outline" width="48px" />
        </IconButton>
        </div>
        {!showLoader && !showSuccess && !showError && !editUser &&
                <div className='flex flex-col gap-2'>

                <div className=" container max-w-sm mx-auto">

              <div className="sm:mx-auto sm:w-full sm:max-w-sm mb-5">
                  <InputText displayName="Firstname" 
                        value={firstname}
                        onChange={handleFirstnameChange}
                        error={getErrorMessage(activeErrorList, 'firstname')}
                  />
              </div>
              <div className="sm:mx-auto sm:w-full sm:max-w-sm mb-5">
                  <InputText displayName="Surname" 
                        value={surname}
                        onChange={handleSurnameChange}
                        error={getErrorMessage(activeErrorList, 'surname')}
                  />
              </div>

                  <div className="sm:mx-auto sm:w-full sm:max-w-sm mb-5">
                      <InputText displayName="Username" 
                          value={newUserUsername}
                          onChange={handleNweUserUsernameChange}
                          error={loginNameExistsMsg ?? getErrorMessage(activeErrorList, 'loginName')}
                          />
                  </div>

                  <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-5">
                      <InputText displayName="Email" 
                            value={newUserEmail}
                            onChange={setNewUserEmail}
                            error={getErrorMessage(activeErrorList, 'email')}
                            />
                  </div>
                  {/* 
                  <div className="mb-5">
                    <label for="loginType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Login Type</label>
                    <LionListbox 
                          items={loginTypes}
                          selectedItemId={newUserType}
                          onChange={(newValue) => {setNewUserType(newValue.id)}}
                    />
                  </div>
                  */}
                </div>

                <div className='flex flex-row-reverse'>
                  <button type="button" class="text-white bg-ccColorDarkTeal hover:bg-ccColorTeal font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    onClick={submitAddUser}
                  >Add user</button>
                </div>
              </div>
        }
        {!showLoader && !showSuccess && !showError && editUser &&
                <div className='flex flex-col gap-2'>

                <div className=" container max-w-sm mx-auto">
                  <div className="mb-5">
                    <label for="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                    <input type="username" id="username" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                          value={editUserData.loginName}
                          disabled
                    />
                  </div>
                  <div className="mb-5">
                    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                    <input type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          value={editUserData.email}
                          disabled
                    />
                  </div>
                  <div className="mb-5">
                    <label for="loginType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Login Type</label>
                    <LionListbox 
                          items={loginTypes}
                          selectedItemId={editUserData.loginType}
                          onChange={(newValue) => {editLoginType(newValue.id)}}
                    />
                  </div>
                </div>

                <div className='flex flex-row-reverse'>
                  <button type="button" class="text-white bg-lionGradient2Via hover:bg-lionGradient2From font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    onClick={submitEditUser}
                  >Submit</button>
                  <button type="button" class="text-white bg-lionGradient2Via hover:bg-lionGradient2From font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    onClick={closeAddUserForm}
                  >Cancel</button>
                </div>
              </div>
        }
      {showLoader && <div className=" container max-w-sm mx-auto flex flex-row justify-center mb-8">
                         <LionLoaderGradient /> 
                      </div>
      }
      {showSuccess && <div className=" container max-w-sm mx-auto flex flex-col justify-center mb-8">
                        <Alert severity="success">
                          <AlertTitle>Success</AlertTitle>
                          <div className='flex flex-col gap-2'>
                            <div>{editUser ? "Login updated" : "New login created"}</div>
                          </div>
                        </Alert>                      
                      </div>
      }
      {showError && <div className=" container max-w-sm mx-auto flex flex-col justify-center mb-8">
                        <Alert severity="error">
                          <AlertTitle>Error</AlertTitle>
                          {showErrorMsg}
                        </Alert>                      
                      </div>
      }
      {(showSuccess || showError) && <div className='flex flex-row-reverse'>
                          <button type="button" class="text-white bg-ccColorDarkTeal hover:bg-ccColorTeal font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            onClick={() => closeAddUserForm()}
                          >Close</button>
                        </div>
      }

      </div>
      }
      <Dialog onClose={() => handleDialogClose('abort')} open={dialogOpen}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description">
      <DialogTitle id="dialog-title">{dialogData.title}</DialogTitle>
      <DialogContent>
          <DialogContentText id="dialog-description">
            {dialogData.desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose('abort')}>Cancel</Button>
          <Button onClick={() => handleDialogClose('delete')} autoFocus>
            Confirm
          </Button>
        </DialogActions>

</Dialog>

</div>
}