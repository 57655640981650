
import { useEffect, useState, useCallback, Fragment } from "react"
import axios from "axios";

import {Menu, MenuItem, ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';

 import { Listbox, Combobox, Switch, Transition } from '@headlessui/react'

import {ArrowUpTrayIcon, ExclamationCircleIcon, CheckIcon, ChevronUpDownIcon, ChevronDownIcon, CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { Icon as Iconify } from '@iconify/react';
import {lionIcons}  from '../components/helpersIcons';
import {RandomId}  from '../components/helpers';
import {LionProgressBar} from './generalComponents';
import {ParseSvg, SegmentsToSvg} from '../components/helpersSvg';

import { CcActionButton } from "./styledTexts";

const FileInfoCard = (props) => {
/*
props
fileInfo: a File object as described in https://developer.mozilla.org/en-US/docs/Web/API/File

onFileRemoved:
*/
const handleFileRemoved = () => {
if (props.onFileRemoved !== undefined) props.onFileRemoved();
}

useEffect(() => {
  //console.log(props.fileInfo)
}, [props.fileInfo])

  return (<>
    {props.fileInfo.fromServer && <div className={"rounded-md bg-green-50 pl-2 pr-2 pt-2 pb-1"}>
    <div className="flex flex-col gap-2 items-center">
      <div className="h-24">
        <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={props.fileInfo.url} alt="" />
      </div>
      <div className="flex">
        <div className="flex-shrink-0">
            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className={"text-sm font-medium text-green-800"}>{props.fileInfo.name}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={"inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"}
              onClick={handleFileRemoved}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
        }
  {!props.fileInfo.fromServer &&
  <div className={"rounded-md bg-ccColorOrange p-2 h-10 max-w-96"}>
    <div className="flex">
      <div className="flex-shrink-0">
          <ArrowUpTrayIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <p className={"text-sm font-medium text-white"}>{props.fileInfo.name}</p>
      </div>
      <div className="ml-auto pl-3">
        <div className="-mx-1.5 -my-1.5">
          <button
            type="button"
            className={"inline-flex rounded-md bg-ccColorOrange p-1.5 text-white hover:bg-ccColorYellow focus:outline-none"}
            onClick={handleFileRemoved}
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
</div>
}
</>
  )
}

const ErrorMsgCard = (props) => {

  return <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
  <span class="block sm:inline pr-6">{props.message}</span>
  <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
    <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
  </span>
</div>
}

export const ImageUploader = (props) => {
  /*
  props:
  uploadedFiles: a mapping filenameFromUser <--> uidFilename (same onject returned by the onChange event)
  endpoint: to specify a different url to upload to

  onChange: returns the file names that have been uploaded
  */
  const id = RandomId(20);
  const [files, setFiles] = useState([]) //can either contain objects from FormData or objects returned by the server
  const [errorMsg, setErrorMsg] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  useEffect(() => {
    if (props.uploadedFiles !== undefined) {
      console.log(props.uploadedFiles)

      const uploadedFilenames = Object.keys(props.uploadedFiles);

      const newFiles = uploadedFilenames.map(f => {return {name: f,  fromServer: true, url: props.uploadedFiles[f]}})
      setFiles(newFiles);
    }
    
  }, [props.uploadedFiles])

  const handleFileChange = (e) => {
    const numFiles = e.target.files.length;
    const result = [];
    for (let i=0; i<numFiles; i +=1) {
      result.push(e.target.files.item(i));
    }
    setFiles(result);
    setErrorMsg(null);
    setUploading(false)
    handleFormSubmit(e);
    }

const handleFormSubmit = async (e) => {
  e.preventDefault();
  setUploading(true)
  setUploadProgress(0)
  const formId = `singleImageForm${id}`
      try {
      let url = `${process.env.REACT_APP_LION_PUBLIC_API_URL}/saas/uploadImage`;
      if (props.endpoint) url = `${process.env.REACT_APP_LION_API_URL}/${props.endpoint}`;
      const xhr = new XMLHttpRequest();
      xhr.addEventListener('loadend', () => {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          if (data.success && props.onChange !== undefined) {setErrorMsg(null); props.onChange(data);}
          else if (!data.success) {setErrorMsg(data.errorMessage); setFiles([]) }
        } 
      });

      xhr.upload.addEventListener('progress', event => {
        setUploadProgress(event.loaded / event.total)
        if (event.loaded / event.total === 1) {
          //we fadeout the progress bar once it reached 100%
          var fadeTarget = document.getElementById("upload-progress-bar");
          fadeTarget.style.opacity = 1;
          var fadeEffect = setInterval(function () {
              if (fadeTarget.style.opacity > 0.9) {
                fadeTarget.style.opacity -= 0.02;
              }
              else if (fadeTarget.style.opacity > 0) {
                  fadeTarget.style.opacity -= 0.1;
              } else {
                  clearInterval(fadeEffect);
                  setUploading(false)
              }
          }, 200);
        }
      });

      xhr.open("post", url);
      xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem("token"))

      xhr.send(new FormData(document.getElementById(formId)));

      /*
      const response = await fetch(url, { method: "POST",
                               body: new FormData(document.getElementById(formId)), });
  
      const data = await response.json();

      if (data.success && props.onChange !== undefined) {setErrorMsg(null); props.onChange(data.fileMappings);}
      else if (!data.success) {setErrorMsg(data.errorMessage); setFiles([]) }
      */
    } catch (error) {
      setErrorMsg(error);
      setFiles([]);
      //console.log(error);
    }

}
    
const handleRemoveFile = (fileInfo) => {
  const filename = fileInfo.name;
  console.log(filename)
  const index = files.findIndex(f => f.name === filename);
  if (index !== -1) {
    const newFiles = [...files];
    const dataFromServer = newFiles[0].fromServer;

    newFiles.splice(index, 1);
    setFiles(newFiles);
    //if (dataFromServer && props.onChange !== undefined) props.onChange(newFiles);
    console.log(fileInfo)
    removeFileOnServer(fileInfo.url)
  }
}

const removeFileOnServer = async (fname) => {

  try {
    if (props.removeEndpoint) {
      const url = `${process.env.REACT_APP_LION_API_URL}/${props.removeEndpoint}?fname=${encodeURIComponent(fname)}`;

      axios.get(url);
    }
    

  } catch (error) {
  }
}

  return <div>
  <form method="post" id={`singleImageForm${id}`} action={`${process.env.REACT_APP_LION_PUBLIC_API_URL}/saas/uploadImage`} enctype="multipart/form-data">
    <div className="flex flex-col gap-2">
      <input id={`singleImageInputFile${id}`} name="files" type="file" multiple style={{display: 'none'}} onChange={handleFileChange} />
      {props.extraMessage && <div className="text-ccColorDarkTeal text-sm">{props.extraMessage}</div>}
      <div className="flex flex-row gap-1">
      <CcActionButton className="max-h-10" type="button" onClick={(e) => {e.preventDefault(); document.getElementById(`singleImageInputFile${id}`)?.click()}} >Choose an image</CcActionButton>
      {
        files.map(f => <FileInfoCard key={f.name} fileInfo={f}
                                  onFileRemoved={() => handleRemoveFile(f)}
                  />)
        
      }
      {errorMsg && <ErrorMsgCard message={errorMsg} />

      }
      </div>

      {uploading && <div id="upload-progress-bar" className="w-72"><LionProgressBar value={uploadProgress} /></div>}
      <div className="flex flex-row">
      {files.length > 0 && false && !files[0].fromServer &&
          <CcActionButton type="button"
          className=" max-h-10"
          //disabled={files.length === 0}
          onClick={handleFormSubmit}
        >Upload</CcActionButton>    
      }
      </div>
    </div>
  </form>
</div>

}
export const FileSelector = (props) => {
  //only select 1 file without uploading it
  /*
  props:
    text: the text of the button, if undefined it defaults to "Choose an image"
    resultAsArrayBuffer: if true it returns the file as an ArrayBuffer instead of a dataURL
    
    onChange: returns the dataURLs of the selected files
  */
  const id = RandomId(20);
  const [files, setFiles] = useState([]) //can either contain objects from FormData or objects returned by the server
  const [imgDataUrl, setImgDataUrl] = useState('')
  const [startImageB64, setStartImageB64] = useState(undefined) //use to store a base64 img that can be passed down during an edit
 
  useEffect(() => {
    if (props.base64) setStartImageB64(props.base64)
  }, [props.base64])

  const handleFileChange = async(e) => {
    setStartImageB64(undefined); //clear any passed data

    let dataURL = await toDataURL(e.target.files.item(0))
    const files = []
    files.push(e.target.files.item(0));
    setFiles(files);
    setImgDataUrl(dataURL)
    if (props.resultAsArrayBuffer) dataURL = await e.target.files.item(0).arrayBuffer()

    if (props.onChange) props.onChange(dataURL)
  }

  const toDataURL = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
  });

const handleRemoveFile = (filename) => {
  //there is only 1 file so this method can be simplified
  setFiles([]);
  setImgDataUrl('');
  setStartImageB64('')
  if (props.onChange) props.onChange('')

/*
  const index = files.findIndex(f => f.name === filename);
  if (index !== -1) {
    const newFiles = [...files];
    const dataFromServer = newFiles[0].fromServer;

    newFiles.splice(index, 1);
    setFiles(newFiles);
    setImgDataUrl('');
    if (props.onChange) props.onChange('')
  }
*/
}
const handleClearFile = () => {
  setFiles([]);
  setImgDataUrl('');
  setStartImageB64('')
  if (props.onChange) props.onChange('')
}

  return <div>
    <div className="flex flex-col">
      <input id={`singleImageInputFile${id}`} name="files" type="file" style={{display: 'none'}} onChange={handleFileChange} />
      <div className="flex flex-row gap-x-4">
      <CcActionButton className="max-h-10" type="button" onClick={(e) => {e.preventDefault(); document.getElementById(`singleImageInputFile${id}`)?.click()}} >{props.text ? props.text : "Choose an image"}</CcActionButton>
      {
        files.map(f =><div className="max-h-10"><FileInfoCard key={f.name} fileInfo={f}
                                  onFileRemoved={() => handleRemoveFile(f.name)}
                  /></div>)
        
      }
      {/*imgDataUrl !== '' && <div className="w-[250px] h-[250px]">
        <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={imgDataUrl} />
      </div>*/
      }
      {startImageB64 && <div className="relative w-[250px] h-[250px]">
        <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={`data:image;base64,${startImageB64}`} />
        <button type="button" onClick={handleClearFile}
               className="absolute -top-3 -right-3"><Iconify icon={lionIcons.Icon_Actions_Cancel} width={26} /></button>

      </div>
      }
      </div>
    </div>
</div>

}
export const MultipleFileSelector = (props) => {
  //only select 1 file without uploading it
  /*
  props:
    text: the text of the button, if undefined it defaults to "Choose an image"
    resultAsArrayBuffer: if true it returns the file as an ArrayBuffer instead of a dataURL
    
    onChange: returns the dataURLs of the selected files
  */
  const id = RandomId(20);
  const [files, setFiles] = useState([]) //can either contain objects from FormData or objects returned by the server
  const [imgDataUrl, setImgDataUrl] = useState('')
  const [startImageB64, setStartImageB64] = useState(undefined) //use to store a base64 img that can be passed down during an edit
 
  useEffect(() => {
    if (props.base64) setStartImageB64(props.base64)
  }, [props.base64])

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  const handleFileChange = async(e) => {

    const result = [];

    for (let i = 0; i < e.target.files.length; i += 1) {
      const currentFile = e.target.files.item(i)
      const dataArrayBuffer = await currentFile.arrayBuffer()
      const dataB64 = await toBase64(currentFile)
      result.push({fName: currentFile.name, data: dataArrayBuffer, dataB64: dataB64})
    }

    if (props.onChange) props.onChange(result)
  }



  return <div className={`${props.iconButton ? "w-full h-full" : ""}`}>
    <div className="flex flex-col w-full h-full">
      <input id={`singleImageInputFile${id}`} name="files" type="file" style={{display: 'none'}} onChange={handleFileChange} multiple />
      <div className="flex flex-row w-full h-full">
        {props.iconButton ? <div className="w-full h-full flex items-center justify-center" onClick={(e) => {e.preventDefault(); document.getElementById(`singleImageInputFile${id}`)?.click()}} >{props.iconButton}</div>
          :
          <CcActionButton type="button" onClick={(e) => {e.preventDefault(); document.getElementById(`singleImageInputFile${id}`)?.click()}} >{props.text ? props.text : "Choose an image"}</CcActionButton>
        }

      </div>
    </div>
</div>

}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Toggle = (props) => {
  /*
  props:
  checked:
  displayName:

  onChange:
  */
  const [enabled, setEnabled] = useState(false)
  const handleChange = (_enabled) => {
    setEnabled(_enabled)
    if (props.onChange !== undefined) props.onChange(_enabled)
  }
useEffect(() => {
if (props.checked) setEnabled(props.checked)
}, [props.checked])

  return ( <Switch.Group>
    <div className="flex items-center font-poppins">
     <Switch
      checked={enabled}
      onChange={handleChange}
      className={classNames(
        enabled ? ' bg-ccColorSecondaryPink' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-ccColorSecondaryPink focus:ring-offset-2'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      >
        <span
          className={classNames(
            enabled ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
          )}
          aria-hidden="true"
        >
          <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
          className={classNames(
            enabled ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
          )}
          aria-hidden="true"
        >
          <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
    </Switch>
    <Switch.Label className={() => enabled ? "ml-2 cursor-pointer" : "ml-2 cursor-pointer text-gray-400"} >{props.displayName}</Switch.Label>
    </div>
    </Switch.Group>
  )
}


export const InputText = (props) => {

    //const [isError, setIsError] = useState(false)
    const [inputValue, setInputValue] = useState('')
  
    /*
    props
    displayName
    value: to set the inital value
    error: the error message

    onChange:
    onKeyDown:
    */
   useEffect(() => {
    const v = props.value === undefined ? '' : props.value;
    setInputValue(v);
   }, [props.value])
  
   const handleInputValueChange = (e) => {
    setInputValue(e.target.value);
    if (props.onChange !== undefined) props.onChange(e.target.value);
   }
   const handleKeyDown = (e) => {
    if (props.onKeyDown) props.onKeyDown(e)
   }
  
    return (
      <div>
        <div>
          <label  className="block text-sm font-poppins font-medium leading-6 text-gray-900">
          {props.displayName}
          </label>
          <div className="mt-0 relative">
          <input
            className={(props.error === undefined || props.error.length === 0) ? "block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-ccColorDarkTeal placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-ccColorTeal sm:text-sm sm:leading-6" 
            : "block w-full rounded-md border-0 py-1.5 px-1.5 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6 outline-none"}
            onChange={handleInputValueChange}
            onKeyDown={handleKeyDown}
            value={inputValue}
            // placeholder="you@example.com"
          />
          {(props.error && props.error.length > 0) &&
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          }
          </div>
          {(props.error && props.error.length > 0) &&
                  <p className=" text-sm text-red-600" id="email-error">
                  {props.error}
                </p>
          }
        </div>
      </div>  
    )
  
  }
export const InputTextPassword = (props) => {

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')

  /*
  props
  displayName
  value: to set the inital value
  error: the error message

  onChange:
  onKeyDown:
  */
  useEffect(() => {
  const v = props.value === undefined ? '' : props.value;
  setInputValue(v);
  }, [props.value])

  const handleInputValueChange = (e) => {
  setInputValue(e.target.value);
  if (props.onChange !== undefined) props.onChange(e.target.value);
  }
  const handleKeyDown = (e) => {
  if (props.onKeyDown) props.onKeyDown(e)
  }
  const handleSwapVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  return (
    <div>
      <div>
        <label  className="block text-sm font-poppins font-medium leading-6 text-gray-900">
        {props.displayName}
        </label>
        <div className="mt-0 relative">
        <input
          type={isPasswordVisible ? "text" : "password"}
          name={props.name}
          className={(props.error === undefined || props.error.length === 0) ? "block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-ccColorDarkTeal placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-ccColorTeal sm:text-sm sm:leading-6" 
          : "block w-full rounded-md border-0 py-1.5 px-1.5 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6 outline-none"}
          onChange={handleInputValueChange}
          onKeyDown={handleKeyDown}
          value={inputValue}
          // placeholder="you@example.com"
        />
        {!(props.error && props.error.length > 0) &&
        <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
          onClick={handleSwapVisibility}
        >
          {!isPasswordVisible && <Iconify icon="fa6-regular:eye" className="h-5 w-5 text-gray-400" aria-hidden="true" />}
          {isPasswordVisible && <Iconify icon="fa6-regular:eye-slash" className="h-5 w-5 text-gray-400" aria-hidden="true" />}
        </div>
        }
        {(props.error && props.error.length > 0) &&
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3" >
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        }
        </div>
        {(props.error && props.error.length > 0) &&
                <p className=" text-sm text-red-600" id="email-error">
                {props.error}
              </p>
        }
      </div>
    </div>  
  )

}
  
export const InputURL = (props) => {
  const [inputValue, setInputValue] = useState('')
  const [hasFocus, setHasFocus] = useState(false);
  const [prefix, setPrefix] = useState('https://')

  /*
  props
  displayName
  value: to set the inital value
  error: the error message

  onChange:
  onKeyDown:
  */
  useEffect(() => {
    let v = props.value === undefined ? '' : props.value;
    //console.log(v)
    let prefix = ''
    if (v.startsWith('http://')) prefix = 'http://';
    if (v.startsWith('https://')) prefix = 'https://';

    if (prefix.length > 0) {
      v = v.substring(prefix.length)
      setInputValue(v)
      setPrefix(prefix)
    }
    
    setInputValue(v);
  }, [props.value])

  const handleInputValueChange = (e) => {
    let v = e.target.value;
    let pref = ''
    if (v.startsWith('http://')) pref = 'http://';
    if (v.startsWith('https://')) pref = 'https://';

    if (pref.length > 0) {
      v = v.substring(pref.length)
      setPrefix(pref)
    }

    //console.log(v)
    setInputValue(v);
    //if (props.onChange !== undefined) props.onChange(e.target.value);
    if (props.onChange !== undefined) {
      if (v === '') props.onChange('');
      else props.onChange(`${prefix}${v}`);
    }
  }
  const handleKeyDown = (e) => {
    if (props.onKeyDown) props.onKeyDown(e)
  }

  const handleOnFocusIn = () => {
    setHasFocus(true)
  }
  const handleOnFocusOut = () => {
    setHasFocus(false)
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelectPrefix = (newPrefix) => {
    setAnchorEl(null);

    //newPrefix could be a backdrop event, in that case we do nothing 
    if (typeof newPrefix !== 'string') return;

    setPrefix(newPrefix)
    if (props.onChange !== undefined) {
      if (inputValue === '') props.onChange('');
      else props.onChange(`${newPrefix}${inputValue}`);
    }

  };

  return <> <div className={(props.error === undefined || props.error.length === 0) ? 
                                            (hasFocus ? "flex relative block w-full rounded-md border-0 text-gray-900 shadow-sm ring-inset hover:ring-ccColorDarkTeal placeholder:text-gray-400 outline-none ring-2 ring-ccColorTeal sm:text-sm sm:leading-6" : "flex relative block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-ccColorDarkTeal placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" )
              : "flex relative block w-full rounded-md border-0  pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6 outline-none"}
            >
              <div className=" w-full flex items-center mt-0">
              <span onClick={handleOpenMenu} style={{height: hasFocus ? 'calc(100% - 4px)' : 'calc(100% - 2px)', marginLeft: hasFocus ? '2px' : '1px', width: hasFocus ? '55px' : '56px', paddingLeft: hasFocus ? '0px' : '1px'}} className=" cursor-pointer pr-1 rounded-s-md select-none flex items-center text-gray-900 bg-ccColorLightTeal sm:text-sm"><div className=" pl-1">{prefix}</div></span>
              <input
                type="text"
                onChange={handleInputValueChange}
                onKeyDown={handleKeyDown}
                onFocus={handleOnFocusIn}
                onBlur={handleOnFocusOut}
                value={inputValue}
                className="block w-full flex-1 border-0 bg-transparent py-1.5 px-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 outline-none sm:text-sm sm:leading-6"
                placeholder="www.example.com"
              />
              {(props.error && props.error.length > 0) &&
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
              }

              </div>
            </div>
          {(props.error && props.error.length > 0) &&
            <p className=" text-sm text-red-600" id="email-error">
            {props.error}
          </p>
    }

    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleSelectPrefix}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleSelectPrefix('https://')}>https://</MenuItem>
        <MenuItem onClick={() => handleSelectPrefix('http://')}>http://</MenuItem>
    </Menu>
</>
}
  
  
 export const InputNumber = (props) => {
      /*
    props:
    displayName
    value: to set the inital value
    disabled:
    min:
    max:
    incr:

    onChange: => value
    */

    const [theValue, setTheValue] = useState(100);
  
    useEffect(() => {
      const v = props.value === undefined ? '0' : props.value;
      let vInt = parseInt(v)
      if (props.min && vInt < props.min) vInt = props.min
      if (props.max && vInt > props.max) vInt = props.max
      setTheValue(vInt);
      
     }, [props.value])
  
     
  const handleIncrement = (incr) => {
    if (incr > 0 && props.max !== undefined) {
      if (theValue + incr > props.max) return;
    }
    if (incr < 0 && props.min !== undefined) {
      if (theValue + incr < props.min) return;
    }
    setTheValue(theValue + incr)
    if (props.onChange !== undefined) props.onChange(theValue + incr);
  }
  function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }
  const handleInputValueChange = (e) => {
    if (isNumeric(e.target.value)) {
      setTheValue(parseInt(e.target.value))
      if (props.onChange !== undefined) props.onChange(parseInt(e.target.value));
    }
  }
  
    return <>
                <label htmlFor="email" className={props.disabled ? `block text-sm font-medium leading-6 text-gray-400` : `block text-sm font-medium leading-6 text-gray-900`}>
          {props.displayName}
          </label>
    <div className="bg-white border border-gray-200 rounded-lg dark:bg-slate-700 dark:border-gray-700" >
  
    <div className="w-full flex justify-between items-center gap-x-1">
      <div className="grow py-2 px-3">
        <input className={props.disabled ? `w-full p-0 bg-transparent border-0 text-gray-400 focus:ring-0 dark:text-white` : `w-full p-0 bg-transparent border-0 outline-none text-gray-800 focus:ring-inset focus:ring-0 focus:ring-transparent dark:text-white`}
                type="text" value={theValue}
                disabled={props.disabled}
                style={{cursor: props.disabled ? "not-allowed" : "default"}}
                onChange={handleInputValueChange} />
      </div>

      <div className="flex items-center -gap-y-px divide-x divide-gray-200 border-s border-gray-200 dark:divide-gray-700 dark:border-gray-700">
        <button style={{cursor: props.disabled ? "not-allowed" : "pointer"}}
                disabled={props.disabled}
        onClick={() => handleIncrement(props.incr ? props.incr * -1 : -1)} type="button" className="w-10 h-10 inline-flex justify-center items-center gap-x-2 text-sm font-medium last:rounded-e-lg bg-white text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" >
          <svg className="flex-shrink-0 w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14"/></svg>
        </button>
        <button style={{cursor: props.disabled ? "not-allowed" : "pointer"}}
                disabled={props.disabled}
        onClick={() => handleIncrement(props.incr ? props.incr : 1)} type="button" className="w-10 h-10 inline-flex justify-center items-center gap-x-2 text-sm font-medium last:rounded-e-lg bg-white text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" >
          <svg className="flex-shrink-0 w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14"/><path d="M12 5v14"/></svg>
        </button>
      </div>
    </div>
  </div>
  </>
  }

export const LionListbox = (props) => {

  /*
  props
  items: [{id: 'id', name: 'name'}]
  selectedItemId: 'id'
  noDataJSX: (optional) a component to render when there are no data
  zIndex: we need to pass the right z-index when there are multiple Listboxes on the page due to a bug

  onChange:
  */
    const _items = [
      { id: 1, name: '' },
    ]
    const [items, setItems] = useState(_items);
    const [selected, setSelected] = useState(items[0])

    useEffect(() => {
      if (props.items !== undefined) {
        setItems(props.items);
        if (props.selectedItemId !== undefined) {
          const match = props.items.find(i => i.id === props.selectedItemId);
          if (match !== undefined) setSelected(match);
        }
        else if (props.items.length > 0) setSelected(props.items[0])
      }
    }, [props.items]);


    const handleItemChange = (item) => {
      setSelected(item)
      if (props.onChange !== undefined) props.onChange(item);
    }
    
    return ( <>
      {props.noDataJSX !== undefined && 
          (props.items === undefined || props.items.length === 0) ? <>{props.noDataJSX}</>
:
<div className="max-w-72">
<Listbox value={selected} onChange={handleItemChange}>
  <div className="relative" style={{zIndex: props.zIndex === undefined ? 'auto' : props.zIndex}}>
    <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
      <span className="block truncate">{selected.name}</span>
      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <ChevronUpDownIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </span>
    </Listbox.Button>
    <Transition
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >          
  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
        {items.map((person, personIdx) => (
          <Listbox.Option
            key={personIdx}
            className={({ active }) =>
              `z-[9999] relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                active ? 'bg-ccColorLightTeal text-ccColorDarkTeal' : 'text-gray-900'
              }`
            }
            value={person}
          >
            {({ selected }) => (
              <>
                <span
                  className={`block truncate ${
                    selected ? 'font-medium' : 'font-normal'
                  }`}
                >
                  {person.name}
                </span>
                {selected ? (
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-ccColorDarkTeal">
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                ) : null}
              </>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Transition>
  </div>
</Listbox>
</div>

}
  
      </>
    ) 
}

export const LionColorListbox = (props) => {

  /*
  props
  items: [{id: 'id', name: 'name'}]
  selectedItemId: 'id'
  noDataJSX: (optional) a component to render when there are no data
  zIndex: we need to pass the right z-index when there are multiple Listboxes on the page due to a bug

  onChange:
  */
    const _items = [
      { id: 1, name: '' },
    ]
    const [items, setItems] = useState(_items);
    const [selected, setSelected] = useState(items[0])

    useEffect(() => {
      if (props.items !== undefined) {
        setItems(props.items);
        if (props.selectedItemId !== undefined) {
          const match = props.items.find(i => i.id === props.selectedItemId);
          if (match !== undefined) setSelected(match);
        }
        else if (props.items.length > 0) setSelected(props.items[0])
      }
    }, [props.items]);


    const handleItemChange = (item) => {
      setSelected(item)
      if (props.onChange !== undefined) props.onChange(item);
    }
    
    return ( <>
      {props.noDataJSX !== undefined && 
          (props.items === undefined || props.items.length === 0) ? <>{props.noDataJSX}</>
:
<div className=" w-full">
<Listbox value={selected} onChange={handleItemChange}>
  <div className="relative mt-1" style={{zIndex: props.zIndex === undefined ? 'auto' : props.zIndex}}>
    <Listbox.Button style={{backgroundColor: selected.id}} className="relative w-full cursor-pointer rounded-lg py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
      <span className="block truncate">{selected.name}</span>
      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <ChevronUpDownIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </span>
    </Listbox.Button>
    <Transition
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >          
  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
        {items.map((person, personIdx) => (
          <Listbox.Option
            key={personIdx}
            style={{backgroundColor: person.id}}
            className={({ active }) =>
              `z-[9999] relative cursor-default select-none py-2 pl-10 pr-4 ${
                active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
              }`
            }
            value={person}
          >
            {({ selected }) => (
              <>
                <span
                  className={`block truncate ${
                    selected ? 'font-medium' : 'font-normal'
                  }`}
                >
                  {person.name}
                </span>
                {selected ? (
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                ) : null}
              </>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Transition>
  </div>
</Listbox>
</div>

}
  
      </>
    ) 
}

export const FontSelector = (props) => {
  const [currentFont, setCurrentFont] = useState('');

  const [currentFontStyles, setCurrentFontStyles] = useState([]); // contains styles that need to be converted in a format for fabricjs
  const [currentFontDecorations, setCurrentFontDecorations] = useState([]); // contains styles that need to be converted in a format for fabricjs
  const [availableFonts, setAvailableFonts] = useState([]);


  useEffect(() => {
    if (props.currentFont.style === null) props.currentFont.style = 'normal'
    setCurrentFont(props.currentFont)
    const newStyles = [];
    if (props.currentFont.style.includes('italic')) newStyles.push('italic');
    if (props.currentFont.style.includes('bold')) newStyles.push('bold');
    if (props.currentFont.textDecoration !== '') setCurrentFontDecorations([props.currentFont.textDecoration]);
    else setCurrentFontDecorations([]);

    setCurrentFontStyles(newStyles);
  }, [props.currentFont])

  useEffect(() => {
    getAvailableFonts();
  }, [])

  const getAvailableFonts = useCallback(async () => {
    try {
      const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Templates/fonts`;
      const response = await fetch(url, { method: "GET", });
  
      const data = await response.json();

      const fontData = data.map((f) => {return {id: f, name: f}});
      setAvailableFonts(fontData);
    } catch (error) {
      console.log(error);
    }
  }
  ,[])


  const handleChangeFont = (propName, propValue) => {
    const newFont = {...currentFont, [propName]: propValue}
    setCurrentFont(newFont);
    sendChanges(newFont)
  }

  const handleFontStyles = (e, styles) => {
    setCurrentFontStyles(styles);
    sendChanges(undefined, styles)
  }
  const handleFontTextDecoration = (e, decoration) => {
    setCurrentFontDecorations(decoration);
    sendChanges(undefined, undefined, decoration)
  }
  const sendChanges = (font, styles, decors) => {
    if (font === undefined) font = currentFont;
    if (styles === undefined) styles = currentFontStyles;
    if (decors === undefined) decors = currentFontDecorations;

    let fStyle = 'normal';
    if (styles.length === 1) fStyle = styles[0];
    if (styles.length === 2) fStyle = 'italic bold';

    props.onChange({fontName: font.fontName, size: font.size, style: fStyle, align: font.align, textDecoration: decors[0]});
  };


  return <>
          <div className=" p-2 border border-gray-200 shadow-md">
          <div className="flex flex-row items-center gap-2">
            <LionListbox 
                items={availableFonts} 
                selectedItemId={currentFont.fontName} 
                onChange={(item) => handleChangeFont('fontName', item.id)}
                />
            <Typography>Size:</Typography>
            <div className=" w-40">
              <InputNumber value={currentFont.size}
                          onChange={(val) => handleChangeFont('size', val)}
                      />
            </div>

            <ToggleButtonGroup 
                  value={currentFontStyles}
                  onChange={handleFontStyles}

                  aria-label="text formatting"
                >
                <ToggleButton value="bold" aria-label="bold" sx={{p: '7px'}}>
                  <Iconify icon={lionIcons.Icon_bold} width={22}  />
                </ToggleButton>
                <ToggleButton value="italic" aria-label="italic" sx={{p: '7px'}} >
                  <Iconify icon={lionIcons.Icon_italics} width={22} />
                </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup 
                  value={currentFontDecorations}
                  onChange={handleFontTextDecoration}
                  aria-label="text formatting"
                >
                <ToggleButton value="underline" aria-label="underlined" sx={{p: '7px'}}>
                  <Iconify icon={lionIcons.Icon_underlined} width={22} />
                </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup 
                  value={currentFont.align}
                  exclusive
                  onChange={(e, style) => handleChangeFont('align', style)}
                  aria-label="text alignment"
            >
              <ToggleButton value="left" aria-label="left aligned" sx={{p: '7px'}}>
                <Iconify icon={lionIcons.Icon_TextAlignLeft} width={22}  />
              </ToggleButton>
              <ToggleButton value="center" aria-label="centered" sx={{p: '7px'}}>
                <Iconify icon={lionIcons.Icon_TextAlignCenter} width={22} />
              </ToggleButton>
              <ToggleButton value="right" aria-label="right aligned" sx={{p: '7px'}}>
                <Iconify icon={lionIcons.Icon_TextAlignRight} width={22} />
              </ToggleButton>
            </ToggleButtonGroup>

          </div>

        </div>

  </>
}

export const ArcSimpleEdit = (props) => {
/* props:

data: the svg path

onChange: return the new svg path
*/
  const [segments, setSegments] = useState([]);

  useEffect(() => {
    if (props.data !== undefined) {
      // we only receive paths with an "M" segment and an "a" segment
      const newSegments = ParseSvg(props.data);
      setSegments(newSegments);
    }
  }, [props.data])

  const handleChangeArcPart = (partName, incr) => {
    const newSegments = [...segments];

    newSegments[1][partName] = parseInt(newSegments[1][partName]) + incr;

    const svgPath = SegmentsToSvg(newSegments);
    if (props.onChange !== undefined) props.onChange(svgPath);
    
    if (props.blocks !== undefined) {
      for (let i = 0; i<props.blocks.length; i += 1) {
        props.blocks[i].setAttrs({data: svgPath});
      }

    }
    
  }

  return <><div>
          <button type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              onClick={() => handleChangeArcPart('ry', 5)}
              >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g transform="rotate(0 12 12)"><g fill="none"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092"/><path fill="currentColor" d="M13.06 3.283a1.5 1.5 0 0 0-2.12 0L5.281 8.939a1.5 1.5 0 0 0 2.122 2.122L10.5 7.965V19.5a1.5 1.5 0 0 0 3 0V7.965l3.096 3.096a1.5 1.5 0 1 0 2.122-2.122L13.06 3.283Z"/></g></g></svg>
          </button>
          <button type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              onClick={() => handleChangeArcPart('ry', -5)}
              >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g transform="rotate(180 12 12)"><g fill="none"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092"/><path fill="currentColor" d="M13.06 3.283a1.5 1.5 0 0 0-2.12 0L5.281 8.939a1.5 1.5 0 0 0 2.122 2.122L10.5 7.965V19.5a1.5 1.5 0 0 0 3 0V7.965l3.096 3.096a1.5 1.5 0 1 0 2.122-2.122L13.06 3.283Z"/></g></g></svg>
          </button>
          <button type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              onClick={() => handleChangeArcPart('rx', -5)}
              >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g transform="rotate(-90 12 12)"><g fill="none"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092"/><path fill="currentColor" d="M13.06 3.283a1.5 1.5 0 0 0-2.12 0L5.281 8.939a1.5 1.5 0 0 0 2.122 2.122L10.5 7.965V19.5a1.5 1.5 0 0 0 3 0V7.965l3.096 3.096a1.5 1.5 0 1 0 2.122-2.122L13.06 3.283Z"/></g></g></svg>
          </button>
          <button type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              onClick={() => handleChangeArcPart('rx', 5)}
              >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g transform="rotate(90 12 12)"><g fill="none"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092"/><path fill="currentColor" d="M13.06 3.283a1.5 1.5 0 0 0-2.12 0L5.281 8.939a1.5 1.5 0 0 0 2.122 2.122L10.5 7.965V19.5a1.5 1.5 0 0 0 3 0V7.965l3.096 3.096a1.5 1.5 0 1 0 2.122-2.122L13.06 3.283Z"/></g></g></svg>
          </button>

    </div></>
}

export const AnimationSettingsEdit = (props) => {
  const [duration, setDuration] = useState(3000);
  const [isLoop, setIsLoop] = useState(false);
  const [pauseBetweenLoops, setPauseBetweenLoops] = useState(3000);

  /*
  props:
  displayName:

  settings: {"isLoop": true, "duration":3000, "pauseBetweenLoops":2000}
  */

  useEffect(() => {
    if (props.settings !== undefined) {
      setDuration(props.settings.duration);
      setIsLoop(props.settings.isLoop);
      if (props.settings.pauseBetweenLoops !== undefined) setPauseBetweenLoops(props.settings.pauseBetweenLoops);
    }
  }, [props.settings]);

  const handleDurationChange = (d) => {
    setDuration(d);
    if (props.onChange !== undefined) props.onChange({"isLoop": isLoop, "duration":d, "pauseBetweenLoops":pauseBetweenLoops})
  }
  const handleLoopChange = (loop) => {
    setIsLoop(loop);
    if (props.onChange !== undefined) props.onChange({"isLoop": loop, "duration":duration, "pauseBetweenLoops":pauseBetweenLoops})
  }
  const handlePauseBetweenLoopsChange = (pause) => {
    setPauseBetweenLoops(pause);
    if (props.onChange !== undefined) props.onChange({"isLoop": isLoop, "duration":duration, "pauseBetweenLoops":pause})
  }

  return <div className=" flex flex-col gap-4">
    <div>{props.displayName}</div>

      <div className=" max-w-[200px]"><InputNumber displayName='Duration'
                                                   value={duration}
                                                   onChange={handleDurationChange}
                                                    /></div>
      <div className=" flex flex-row gap-4 items-center">
        <Toggle displayName="Loop"
                checked={isLoop}
                onChange={handleLoopChange}
        />
        {isLoop && <div className=" max-w-[200px]">
          <InputNumber displayName='Pause between loops'
                       value={pauseBetweenLoops} 
                       onChange={handlePauseBetweenLoopsChange}
          /></div>
        }
      </div>
  </div>

}


// LionCombo IS NOT USED YET!! We use LionListbox most of the times
export const LionCombo = (props) => {

    /*
  props
  items: [{id: 'id', name: 'name'}]
  selectedItemId: 'id'
  zIndex: we need to pass the right z-index when there are multiple Listboxes on the page due to a bug

  onChange:
  */


  const _items = [
    { id: 1, name: '' },
  ]

  const [items, setItems] = useState(_items)
  const [selected, setSelected] = useState(_items[0])
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (props.items !== undefined) {
      setItems(props.items);
      if (props.selectedItemId !== undefined) {

        const match = props.items.find(i => i.id === props.selectedItemId);
        if (match !== undefined) setSelected(match);
      }
      else if (props.items.length > 0) setSelected(props.items[0])
    }
  }, [props.items]);


  const filteredItems =
    query === ''
      ? items
      : items.filter((person) =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  const handleItemChange = (item) => {
    setSelected(item)
    if (props.onChange !== undefined) props.onChange(item);
  }

  return (
    <div className="top-16 w-72">
      <Combobox value={selected} onChange={handleItemChange}>
        <div className="relative mt-1" style={{zIndex: props.zIndex === undefined ? 'auto' : props.zIndex}} >
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              displayValue={(person) => person.name}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {filteredItems.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredItems.map((person) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-teal-600 text-white' : 'text-gray-900'
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {person.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}

export const CcButton = (props) => {
/* props: 

onClick: 
*/
const handleClick = (e) => {
  if (props.onClick) props.onClick(e)
}

  return <button type="button"
  onClick={handleClick} 
  className=" bg-ccColorDarkTeal text-white
   hover:bg-ccColorTeal  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
  >{props.children}</button>
}

export const CcNumberInput = (props) => {

  return <>
    <label for="quantity-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Choose quantity:</label>
    <div class="relative flex items-center max-w-[8rem]">
        <button type="button" id="decrement-button" data-input-counter-decrement="quantity-input" class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
            <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16"/>
            </svg>
        </button>
        <input type="text" id="quantity-input" data-input-counter aria-describedby="helper-text-explanation" class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="999" required />
        <button type="button" id="increment-button" data-input-counter-increment="quantity-input" class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
            <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16"/>
            </svg>
        </button>
    </div>
  </>
}