import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {VotesElementManager} from "../workflows/votesWorkflow"
import {LionLoaderGradient} from '../commonComponents/generalComponents'
import {SnippetCodeViewer} from '../commonComponents/snippetGeneric'

import { getErrorListFromYupError, getErrorMessage, snippetDataValidationSchemaBase } from '../components/helperErrors'
import { CcActionButton } from "../commonComponents/styledTexts";
import { VotesHrefManager } from "../Wizards/votesWizard";

export default function PageMoodMeterFromTemplate() {
    const [snippetData, setSnippetData] = useState({});
    const [voteHrefs, setVoteHrefs] = useState([])

    const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [numQuestions, setNumQuestions] = useState(1);

    const [activeErrorList, setActiveErrorList] = useState([]);

    let params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params.templateId) getTemplateInfo(params.templateId)
    }, [params.templateId])

    const handleClose = () => {
        navigate('/ph/moodmeter')
    }
    const handleElementChange = (snippetInfo) => {
        setSnippetData(snippetInfo)
        try {
            snippetDataValidationSchemaBase.validateSync(snippetInfo, {abortEarly: false});
            setActiveErrorList([])
            }
            catch (e) {
            const errorList = getErrorListFromYupError(e)
            setActiveErrorList(errorList)
            }
    
      }
      
    const getTemplateInfo = async (templateId) => {
        try {
            const url = `${process.env.REACT_APP_LION_API_URL}/Templates/info/${templateId}`;
            let response = await axios.get(url);

            //console.log(response.data)
            setNumQuestions(response.data.templates.length);

                    
        } catch (error) {
            console.log(error);
        }
    }
  
const submitDataSnippet = async () => {
    try {
        snippetDataValidationSchemaBase.validateSync(snippetData, {abortEarly: false});
        setActiveErrorList([])
        }
        catch (e) {
        const errorList = getErrorListFromYupError(e)
        setActiveErrorList(errorList)
        console.log(errorList)
        return false;
        }
    
    setShowLoader(true);
    setSuccessHtmlMsg('');
    
    try {
        let url = `${process.env.REACT_APP_LION_API_URL}/Snippets/create/7`;
        
        const dataToPost = {...snippetData, multipleHrefs: voteHrefs} // added voteHrefs to this call
        const response = await axios.post(url, dataToPost);
    
        setSuccessHtmlMsg(response.data);
        setShowSuccessMsg(true);
    } catch (error) {
        setShowSuccessMsg(false);
        setShowErrorMsg(true);
        console.log(error);
    }
    setShowLoader(false);
    setSubmitted(true)
    return true;
    }
const handleHrefsChange = (hrefs) => {
    //console.log(hrefs)
    setVoteHrefs(hrefs)
    }

    return <div>
        {!submitted && !showLoader && <>
        <VotesElementManager
            onChange={handleElementChange}
            templateId={params.templateId}
            validationErrorsList={activeErrorList}
        />
        <div className='flex flex-col items-center mt-4'>
          <div className='w-1/2'>
            <VotesHrefManager
              num={numQuestions}
              hrefName="Mood Meter option"
              hrefs={voteHrefs}
              onHrefsChange={handleHrefsChange}
            />
          </div>
        </div>
        </>
        }
        <div className='flex justify-center mt-20 mb-5'>
            {showLoader && <LionLoaderGradient /> }
            {showSuccessMsg && <SnippetCodeViewer forVote numSnippets={numQuestions} html={successHtmlMsg} />}      
        </div>        

        <div className='flex flex-col gap-4 justify-center items-center'>
            {!submitted && !showLoader &&  <div className="w-1/2 flex flex-row-reverse">
                <CcActionButton type="button" onClick={submitDataSnippet} >Submit</CcActionButton>
                </div>
            }
            {submitted &&  <div className="w-1/2 flex flex-row-reverse">
                <CcActionButton type="button" onClick={handleClose} >Close</CcActionButton>
                </div>
            }
        </div>
    </div>
}
