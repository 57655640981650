
import { useState, useEffect, } from 'react';
import { IconButton, Snackbar} from '@mui/material';
import { Icon as Iconify } from '@iconify/react';
import { CcActionButton } from './styledTexts';
import { lionIcons } from '../components/helpersIcons';


export const SnippetCodeViewer = (props) => {
    /*
    props:
    numSnippets:
    html:
    forVote: when true we generate html with "question=" instead of "snippet="
  
    onChange:
    */
    const [indexes, setIndexes] = useState([]);
    const [htmlImg, setHtmlImg] = useState('');
    const [htmlHref, setHtmlHref] = useState('');
    const [snackBarClipboardOpen, setSnackBarClipboardOpen] = useState(false);

    useEffect(() => {
        if (props.numSnippets !== undefined) {
            const inds = [];
            for (let i = 0; i < props.numSnippets; i+=1) {
                inds.push(i+1);
            }
            setIndexes(inds)
        }
    }, []);

    useEffect(() => {
        if (props.html === undefined || props.html === null || props.html === '') return;

        let regex = "src=\"(.*?)\""
        let matches = props.html.match(regex)

        // console.log(matches[1])
        setHtmlImg(matches[1]);

        regex = "href=\"(.*?)\""
        matches = props.html.match(regex)
        // console.log(matches[1])
        setHtmlHref(matches[1]);

    }, [props.html]);

    const copyHtmlCodeToClipboard = (code) => {
        setSnackBarClipboardOpen(true);
        navigator.clipboard.writeText(code);
        }

    const copyAllToClipboard = () => {
        //console.log('entrato')
        let result = ''
        indexes.forEach(i => {
            let snippetNum = `element=${i}`;
            let imgSrc = htmlImg.replace('element=1', snippetNum);
            let href = htmlHref.replace('element=1', snippetNum);
            if (props.forVote) {
                snippetNum = `question=${i}`;
                imgSrc = htmlImg.replace('question=1', snippetNum);
                href = htmlHref.replace('question=1', snippetNum);    
            }

            result += `Image  ${i}\n`;
            result += `${imgSrc}\n`;
            result += `URL  ${i}\n`;
            result += `${href}\n\n`;
        })

        setSnackBarClipboardOpen(true);
        navigator.clipboard.writeText(result);
        }

    const handleCloseSnackBarClipboard = () => {
        setSnackBarClipboardOpen(false);
        }

    const SnackBarAction = (
        <>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackBarClipboard}
        >
            <Iconify icon={lionIcons.Icon_Actions_Cancel} />
        </IconButton>
        </>
    );
    

    return <div className=" w-1/2">
            {indexes.map(i => {
                    let snippetNum = `element=${i}`;
                    let imgSrc = htmlImg.replace('element=1', snippetNum);
                    let href = htmlHref.replace('element=1', snippetNum);
                    if (props.forVote) {
                        snippetNum = `question=${i}`;
                        imgSrc = htmlImg.replace('question=1', snippetNum);
                        href = htmlHref.replace('question=1', snippetNum);    
                    }
        
                    return <div key={i} className=' flex flex-col'>
                            <label htmlFor="snippetName" className=" mt-6 text-sm font-medium leading-6 text-gray-900">
                            Href
                            </label>
                            <div className="mt-0 flex flex-row">
                                <input
                                    type="text"
                                    name="first-name"
                                    id="snippetName"
                                    autoComplete="given-name"
                                    value={href}
                                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                <div>
                                    <IconButton color="inherit" onClick={() => copyHtmlCodeToClipboard(href)} >
                                    <Iconify icon='heroicons-outline:clipboard-copy' width={38} />
                                    </IconButton>
                                </div>
                            </div>
                            <label htmlFor="snippetName" className=" mt-3 text-sm font-medium leading-6 text-gray-900">
                            Image Src
                            </label>
                            <div className="mt-0 flex flex-row">
                                <input
                                    type="text"
                                    name="first-name"
                                    id="snippetName"
                                    autoComplete="given-name"
                                    value={imgSrc}
                                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                <div>
                                    <IconButton color="inherit" onClick={() => copyHtmlCodeToClipboard(imgSrc)} >
                                    <Iconify icon='heroicons-outline:clipboard-copy' width={38} />
                                    </IconButton>
                                </div>
                            </div>

                </div>
            })}

            <div className=' mt-4 flex flex-row justify-end'>
                <CcActionButton onClick={copyAllToClipboard}>Copy all</CcActionButton>
            </div>

            <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarClipboardOpen}
        autoHideDuration={1500}
        onClose={handleCloseSnackBarClipboard}
        message="Copied to clipboard"
        action={SnackBarAction}
      />

            </div>

    
  }