import { useState, useEffect  } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {LionLoader} from './generalComponents';
import axios from 'axios';

Highcharts.setOptions({
    colors: [
        '#357e86', '#fbbc3d', '#6dbfe8', '#ea8c44', '#9bcccd', '#a274a3',
        '#17425d', '#ff8095', '#55a68c', '#e6b543'
    ]
});

export const LineChartOpens = (props) => {

    //const [theNumber, setTheNumber] = useState(0);
    const [showLoader, setShowLoader] = useState(true);

    const options = {
        chart: {
            type: 'spline'
        },
        title: {
          text: 'Images by day',
          style: {
            color: '#17425d',
            //fontWeight: 'bold'
            },
        },
        series: [{
            name: 'Opens',
          data: [[10, 1], [11, 3], [12, 2]]
        }],
        yAxis: {
            gridLineWidth: 0,
            title: {text: undefined}
        },
        xAxis: {
            type: 'datetime',
        }
      }
      const [chartOptions, setChartOptions] = useState(options)

    useEffect(() => {
        getStats()
    }, [props.filter])

    const getStats = async () => {
        try {
            //const url = `${process.env.REACT_APP_LION_API_URL}/Stats/opensByDay/thisTerm`
            const url = `${process.env.REACT_APP_LION_API_URL}/Stats/opens`

            const response = await axios.post(url, props.filter);
            const data = response.data;

            const seriesData = [];
            data.forEach(dataPoint => {
                // the date is always in the yyyy-MM-dd format
                const dateParts = dataPoint.date.split('-');
                seriesData.push([Date.UTC(dateParts[0], Number(dateParts[1]) -1, Number(dateParts[2])), dataPoint.impressions])
            })
            const newOptions = {...chartOptions}
            newOptions.series[0].data = seriesData;
            setChartOptions(newOptions);

            //setTheNumber(data);
                    
        } catch (error) {
            console.log(error);
        }
        finally {setShowLoader(false);}

        }

      
    return <div className='border rounded-md shadow-md min-h-[400px]'>
        {
            showLoader ? <div><LionLoader /></div> : 
            <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
        />

        }
    </div>
} 

export const PieChartOpens = (props) => {

    const [theNumber, setTheNumber] = useState(0);
    const [showLoader, setShowLoader] = useState(true);

    const options = {
        chart: {
            type: 'pie'
        },
        title: {
          text: 'Opens by OS Family'
        },
        series: [{
            dataLabels: {
                enabled: true,
                /*
                formatter: function () { return `<div>
                                                <img src='${this.point.iconURL}' />
                                                ${this.point.name}
                                                </div>
                                                ` }
                */
            },
            name: 'Opens',
          data: []
        }],
        yAxis: {
            gridLineWidth: 0,
            title: {text: undefined}
        },
        xAxis: {
            type: 'datetime',
        },
        
        plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          
        legend: {
            symbolPadding: 0,
            symbolWidth: 0,
            symbolHeight: 0,
            maxHeight: 200,
            squareSymbol: false,
            useHTML: true,
            labelFormatter: function() {
                    return `
                        <span style="display:flex; place-items: center; justify-content: center; gap: 5px; maxWidth: 220px;">
                            <div class="w-[18px] h-[12px] rounded-full" style="background-color: ${this.color}"></div>
                            <img src="${this.iconURL}" width="48" />
                            <div style="width:200px">${this.name}</div>	
                        </span>
                    `;
            }
          },   
        }
      const [chartOptions, setChartOptions] = useState(options)

    useEffect(() => {
        getStats()
    }, [])

    const getStats = async () => {
        try {
            //const url = `api/Stats/opensByOsFamily/thisTerm`;
            const url = `${process.env.REACT_APP_LION_API_URL}/Stats/opensByOsFamily/thisTerm`

            const response = await axios.get(url);
            const data = response.data;

            const seriesData = [];
            data.forEach(dataPoint => {
                seriesData.push({name: dataPoint.name,
                                y: dataPoint.impressions,
                                iconURL: dataPoint.iconUrl,
                            })
            })
            // console.log(seriesData)
            const newOptions = {...chartOptions}
            newOptions.series[0].data = seriesData;
            setChartOptions(newOptions);

            setTheNumber(data);
                    
        } catch (error) {
            console.log(error);
        }  
        finally {setShowLoader(false);}

        }

      
    return <div className='border rounded-md shadow-md min-w-[400px] max-w-[500px]'>
        {
            showLoader ? <div><LionLoader /></div> 
            : 
            <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
        />
            
        }
    </div>
} 

export const PieChartDeviceType = (props) => {

    const [theNumber, setTheNumber] = useState(0);
    const [showLoader, setShowLoader] = useState(true);

    const options = {
        chart: {
            type: 'pie'
        },
        title: {
          text: 'Opens by Device Type'
        },
        series: [{
            dataLabels: {
                enabled: true,
                /*
                formatter: function () { return `<div>
                                                <img src='${this.point.iconURL}' />
                                                ${this.point.name}
                                                </div>
                                                ` }
                */
            },
            name: 'Opens',
          data: []
        }],
        yAxis: {
            gridLineWidth: 0,
            title: {text: undefined}
        },
        xAxis: {
            type: 'datetime',
        },
        
        plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          
        }
      const [chartOptions, setChartOptions] = useState(options)

    useEffect(() => {
        getStats()
    }, [])

    const getStats = async () => {
        try {
            // const url = `api/Stats/opensByDeviceType/thisTerm`;
            const url = `${process.env.REACT_APP_LION_API_URL}/Stats/opensByDeviceType/thisTerm`

            const response = await axios.get(url);
            const data = response.data;

            const seriesData = [];
            data.forEach(dataPoint => {
                seriesData.push({name: dataPoint.name,
                                y: dataPoint.impressions,
                                iconURL: dataPoint.iconUrl,
                            })
            })
            // console.log(seriesData)
            const newOptions = {...chartOptions}
            newOptions.series[0].data = seriesData;
            setChartOptions(newOptions);

            setTheNumber(data);
                    
        } catch (error) {
            console.log(error);
        }  
        finally {setShowLoader(false);}

        }

      
    return <div className='border rounded-md shadow-md min-w-[400px] max-w-[500px]'>
        {
            showLoader ? <div><LionLoader /></div> 
            : 
            <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
        />
            
        }
    </div>
}

export const PieChartMobileVsNonMobile = (props) => {

    const [theNumber, setTheNumber] = useState(0);
    const [showLoader, setShowLoader] = useState(true);

    const options = {
        chart: {
            type: 'pie'
        },
        title: {
          text: 'Mobile vs Non-mobile'
        },
        series: [{
            dataLabels: {
                enabled: true,
                /*
                formatter: function () { return `<div>
                                                <img src='${this.point.iconURL}' />
                                                ${this.point.name}
                                                </div>
                                                ` }
                */
            },
            name: 'Opens',
          data: []
        }],
        yAxis: {
            gridLineWidth: 0,
            title: {text: undefined}
        },
        xAxis: {
            type: 'datetime',
        },
        
        plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          
        }
      const [chartOptions, setChartOptions] = useState(options)

    useEffect(() => {
        getStats()
    }, [])

    const getStats = async () => {
        try {
            // const url = `api/Stats/opensMobileVsNonMobile/thisTerm`;
            const url = `${process.env.REACT_APP_LION_API_URL}/Stats/opensMobileVsNonMobile/thisTerm`

            const response = await axios.get(url);
            const data = response.data;

            const seriesData = [];
            data.forEach(dataPoint => {
                seriesData.push({name: dataPoint.name,
                                y: dataPoint.impressions,
                                iconURL: dataPoint.iconUrl,
                            })
            })
            // console.log(seriesData)
            const newOptions = {...chartOptions}
            newOptions.series[0].data = seriesData;
            setChartOptions(newOptions);

            setTheNumber(data);
                    
        } catch (error) {
            console.log(error);
        }  
        finally {setShowLoader(false);}

        }

      
    return <div className='border rounded-md shadow-md min-w-[400px] max-w-[500px]'>
        {
            showLoader ? <div><LionLoader /></div> 
            : 
            <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
        />
            
        }
    </div>
} 

export const PieChartBrowser = (props) => {

    const [theNumber, setTheNumber] = useState(0);
    const [showLoader, setShowLoader] = useState(true);

    const options = {
        chart: {
            type: 'pie'
        },
        title: {
          text: 'Opens by Browsers'
        },
        series: [{
            size: '45%',
            dataLabels: {
                enabled: true,
                color: '#ffffff',
            distance: '-50%'
            },
            name: 'Opens',
          data: []
        },
         {data: [],
            size: '80%',
            innerSize: '60%',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}:</b> <span style="opacity: 0.5">{y}%</span>',
                }   
        }],
        yAxis: {
            gridLineWidth: 0,
            title: {text: undefined}
        },
        xAxis: {
            type: 'datetime',
        },
        
        plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },
              showInLegend: false,
              shadow: false,
            center: ['50%', '50%']
            }
          },
          
        }
      const [chartOptions, setChartOptions] = useState(options)

    useEffect(() => {
        getStats()
    }, [])

    const getStats = async () => {
        const colors = Highcharts.getOptions().colors;

        try {
            // const url = `api/Stats/opensByBrowser/thisTerm`;
            const url = `${process.env.REACT_APP_LION_API_URL}/Stats/opensByBrowser/thisTerm`

            const response = await axios.get(url);
            const data = response.data;

            const seriesData = [];
            const subSeriesData = [];
            data.forEach((dataPoint, index) => {
                seriesData.push({name: dataPoint.name,
                                y: dataPoint.impressions,
                                color: colors[index],
                                categories: dataPoint.subStats.map(ss => ss.name),
                                data: dataPoint.subStats.map(ss => ss.impressions),
                            });
                const brightness = 0.2 - (index / data.length) / 5;
                dataPoint.subStats.forEach(sStat => {
                    subSeriesData.push({
                        name: sStat.name,
                        y: sStat.impressions,
                        color: Highcharts.color(colors[index]).brighten(brightness).get(),
                    });
                });
            })
            const newOptions = {...chartOptions}
            newOptions.series[0].data = seriesData;
            newOptions.series[1].data = subSeriesData;
            setChartOptions(newOptions);

            setTheNumber(data);
                    
        } catch (error) {
            console.log(error);
        }  
        finally {setShowLoader(false);}

        }

      
    return <div className='border rounded-md shadow-md min-w-[650px] max-w-[650px]'>
        {
            showLoader ? <div><LionLoader /></div> 
            : 
            <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
        />
            
        }
    </div>
} 
