import { useNavigate } from 'react-router-dom';

import {LionStepper, StepperNavButtons} from '../commonComponents/stepper'
import {LionCombo, LionListbox} from  '../commonComponents/inputFields'
import {TemplateGalleryViewer} from '../commonComponents/templateGallery'
import {LionLoaderGradient} from '../commonComponents/generalComponents'
import { Icon as Iconify } from '@iconify/react';
import { IconButton } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useEffect, useState } from 'react';
import {SnippetCodeViewer} from '../commonComponents/snippetGeneric'
import { GetFilterDefFromMetadata}  from '../components/helpers';


import { useParams } from "react-router-dom";
import axios from 'axios';

import {GeneratorElementManager} from "../workflows/generatorWorkflow";

import { CcStepper2 } from '../commonComponents/stepper';
import { getErrorListFromYupError, getErrorMessage, snippetDataValidationSchemaGenerator } from '../components/helperErrors'

// ----------------------------------------------------------------------



export default function PageGeneratorFromContentSource(props) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState({templateId: undefined});
  const [templateDicJson, setTemplateDicJson] = useState({}); //{templateId: {templateJson}} - we keep a dic with template json, it gets updated at step 1
  const [snippetData, setSnippetData] = useState(undefined)

  const [availableFilters, setAvailableFilters] = useState([]);
  const [availableFieldsForOrderBy, setAvailableFieldsForOrderBy] = useState([]);
  const [availableFieldsForHref, setAvailableFieldsForHref] = useState([]);
  const [templateDataConnectionMetadata, setTemplateDataConnectionMetadata] = useState({}); // dictionary {templateId: {dataTypeInMetadata: true, filters: filters}}
  const [templateMetadata, setTemplateMetadata] = useState({});

  const [workflowNumSnippetsToCreate, setWorkflowNumSnippetsToCreate] = useState(1);


  const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [activeErrorList, setActiveErrorList] = useState([]);

  let params = useParams();
  const navigate = useNavigate();


  useEffect(() => {
      //console.log(params)
    if (params.dataConnectionId !== undefined) {
      getDataConnectionInfo(params.dataConnectionId)
      const newSnippetData = {...snippetData, dataConnectionId: params.dataConnectionId}
      console.log(newSnippetData)
      setSnippetData(newSnippetData)
    }
  }, [params])

  const getSnippetInfo = async (snippetId) => {
    try {  
      const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/${snippetId}`;
      const response = await axios.get(url);
      const data = response.data;

      // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Snippets/info/${snippetId}`;
    //const response = await fetch(url, { method: "GET", });    
    // const data = await response.json();

    const newTemplate = {...selectedTemplate}
    newTemplate.templateId = data.templateId;
    setSelectedTemplate(newTemplate);
    //setSnippetName(data.snippetName)
    //setSnippetDesc(data.description)
    //setSelectedTimerDate(data.timerDate)
    //setSelectedTimeZoneId(data.timerDateTimezoneIANA);
    //setTimerSettings(JSON.parse(data.timerSetting));
      setSnippetData(data);

    } catch (error) {
      console.log(error);
    }
  }
  const getTemplateJson = async (templateId) => {
    try {  
      const url = `${process.env.REACT_APP_LION_API_URL}/Templates/info/${templateId}`;
      const response = await axios.get(url);
      const data = response.data;

    //const newTemplateDic = {...templateDicJson, [templateId]: data}
    //setTemplateDicJson(newTemplateDic);

    setTemplateMetadata(data)
  } catch (error) {
      console.log(error);
    }
  }

  const getDataConnectionInfo = async (dataConnectionId) => {
    try {
            const url = `${process.env.REACT_APP_LION_API_URL}/Feeds/info/${dataConnectionId}`;
            const response = await axios.get(url);
        
            const dataTypeInMetadata = response.data.feedType === 'CATALOG';
            const filters = GetFilterDefFromMetadata(JSON.parse(response.data.metadata), dataTypeInMetadata);
            setAvailableFilters(filters);
            setAvailableFieldsForOrderBy(filters.map(f => ({fieldName: f.conditionName, dataType: f.dataType})))
            setAvailableFieldsForHref(filters.map(f => (f.conditionName)))
    
            const newTemplateDataConnectionMetadata = {...templateDataConnectionMetadata, [dataConnectionId]:{'dataTypeInMetadata': dataTypeInMetadata, 'filters': filters }}
            setTemplateDataConnectionMetadata(newTemplateDataConnectionMetadata);
            // console.log(newTemplateDataConnectionMetadata)
        
    } catch (error) {
        console.log(error);
    }
    }



  const handleNavChange = async(navInfo) => {
    if (navInfo.actionType === 'Navigation'){
      setCurrentStepIndex(navInfo.nextIndex);
      if (navInfo.nextIndex === 1) {
        const currentTemplateIds = Object.keys(templateDicJson);
        if (!currentTemplateIds.includes(selectedTemplate.templateId.toString())) {
          getTemplateJson(selectedTemplate.templateId);
          setSnippetData({...snippetData, templateId: selectedTemplate.templateId})
        }
        //else setTemplateHasTimeLabels(templateContainsTimeLabels(templateDicJson[selectedTemplate.templateId]))
      }
    }
    else if (navInfo.actionType === 'Submit') {
      const isValid = await submitData();
      if (isValid) setCurrentStepIndex(navInfo.nextIndex);
    }
  }

  const handleSelectedTemplateChange = (tInfo) => {
    if (tInfo !== undefined) setSelectedTemplate(tInfo)
    const newSnippetData = {...snippetData, templateId: tInfo.templateId}
    
    setSnippetData(newSnippetData)
  }
  const submitData = async () => {
    try {
      snippetDataValidationSchemaGenerator.validateSync(snippetData, {abortEarly: false});
      setActiveErrorList([])
    }
    catch (e) {
      const errorList = getErrorListFromYupError(e)
      setActiveErrorList(errorList)
      console.log(errorList)
      return false;
    }

    setShowLoader(true);
    setSuccessHtmlMsg('');
  
    try {
        let url = `${process.env.REACT_APP_LION_API_URL}/Snippets/create/1`;
        const dataToPost = {...snippetData, fadeInFunction: JSON.stringify(snippetData.fadeInFunction)}

        //if (params.snippetId !== null) url = `${process.env.REACT_APP_LION_API_URL}/Snippets/update/9/${params.snippetId}`;
    
        const response = await axios.post(url, dataToPost);
  
        setSuccessHtmlMsg(response.data);
        setShowSuccessMsg(true);
    } catch (error) {
      setShowSuccessMsg(false);
      setShowErrorMsg(true);
      console.log(error);
    }
    setShowLoader(false);
    setSubmitted(true);
    return true;
  }

  const templateContainsTimeLabels = (templateJson) => {
    let result = false;
    const blocks = templateJson.model.children[0].children;

    blocks.forEach(block => {
      if (block.className === "Text" && block.attrs.id.startsWith('timerLabel')) result = true;
    })
    return result;
  }

  const handleElementChange = (snippetInfo) => {
    //console.log(snippetInfo)
    setSnippetData(snippetInfo)
    try {
      snippetDataValidationSchemaGenerator.validateSync(snippetInfo, {abortEarly: false});
      setActiveErrorList([])
    }
    catch (e) {
      const errorList = getErrorListFromYupError(e)
      setActiveErrorList(errorList)
    }

  }
  const handleClose = () => {
    navigate("/ph/generator")
  }

 return (
  <>
    <div className='border border-gray-300 rounded-md shadow-md p-1'>
    <div className='flex flex-row-reverse'>
      <IconButton variant="contained" sx={{color: '#d1d5db'}} >
        <Iconify icon="material-symbols-light:cancel-outline" width="44px" color='#d1d5db' />
      </IconButton>

    </div>
    <CcStepper2
      steps={[{ name: 'Template selection', href: '#' },
              { name: 'Configure Element', href: '#' },
              { name: 'Finish', href: '#' },
          ]}
      currentStepIndex={currentStepIndex}
    />

      {currentStepIndex === 0 && <div className=' max-h-[450px] overflow-auto mb-2'>
        <TemplateGalleryViewer productTypeId={1}
        dataConId={params.dataConnectionId}
        onChange={handleSelectedTemplateChange}
        selectedTemplateId={selectedTemplate.templateId}
        />
      </div>
      }

    {currentStepIndex === 1 && 
        <GeneratorElementManager
        templateId={snippetData.templateId}
        dataConnectionId={params.dataConnectionId}
        snippetInfo={snippetData}
        availableFilters={availableFilters}
        availableFieldsForOrderBy={availableFieldsForOrderBy}
        availableFieldsForHref={availableFieldsForHref}
        templateMetadata={templateMetadata}
        onChange={handleElementChange}
        onNumSnippetsChange={(num) => setWorkflowNumSnippetsToCreate(num)}
        validationErrorsList={activeErrorList}
        />

      
    }

    {currentStepIndex === 2 && <div className='flex justify-center'>
      {!showLoader && !showSuccessMsg && !showErrorMsg && <div>Confirm</div> }
      {showLoader && <LionLoaderGradient /> }
      {showSuccessMsg && <SnippetCodeViewer numSnippets={workflowNumSnippetsToCreate} html={successHtmlMsg} />}

    
    </div>
    }

    <StepperNavButtons totalSteps={3}
        currentStepNum={currentStepIndex}
        onChange={handleNavChange}
        showClose={submitted}
        onClose={handleClose}
    />
    </div>

  </>
);
}
