import { useState, useEffect, useContext  } from 'react';
import axios from 'axios';

import { Box, Card, CardActionArea, CardContent, CircularProgress, Divider,
     Paper, Stack, ThemeProvider, Typography} from  '@mui/material';

import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2

import { red, pink, indigo, green, blue, teal, grey } from '@mui/material/colors';

import { createTheme } from '@mui/material/styles';

import {store} from "../redux/store";
import {ccColorSecondaryAzure, ccColorSecondaryBronze, ccColorSecondaryGreen, ccColorSecondaryPink, ccColorSecondaryPurple, lionProductColors} from '../components/helpersStyles';

import {SettingsContext} from '../components/userSettingsContext'

import {SnippetTypeGrid, CcSnippetTypeGrid} from '../commonComponents/snippetTypesGrid';
import {LineChartOpens, PieChartOpens, PieChartDeviceType, PieChartMobileVsNonMobile,
    PieChartBrowser} from "../commonComponents/charts";

import { CcTitle } from '../commonComponents/styledTexts';

import CountUp from 'react-countup';


const theme = createTheme({
  palette: {
    // primary: {
      // light: will be calculated from palette.primary.main,
      // main: '#ff4400',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    // },
    // secondary: {
      // light: '#0066ff',
      // main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    // },
    // Provide every color token (light, main, dark, and contrastText) when using
    // custom colors for props in Material UI's components.
    // Then you will be able to use it like this: `<Button color="custom">`
    // (For TypeScript, you need to add module augmentation for the `custom` value)
    customCircularLoader: {
      // light: '#ffa726',
      main: '#000',
      // dark: '#ef6c00',
      // contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    // contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
  },
});


const LionAccountStat = (props) => {

    const [showProgress, setShowProgress] = useState(true);
    const [theNumber, setTheNumber] = useState(0);

    useEffect(() => {
        getStats(props.endpoint)
    }, [])

    const getStats = async (endpoint) => {
        try {
            // const url = `api/Stats/${endpoint}`;
            const url = `${process.env.REACT_APP_LION_API_URL}/Stats/${endpoint}`

            const response = await axios.get(url);
            const data = response.data;


            setTheNumber(data);
            setShowProgress(false)
                    
        } catch (error) {
            console.log(error);
        }  

        }

      
    return <ThemeProvider theme={theme}>
    <Stack>
        <div className=' font-roboto text-lg text-gray-600'>{props.title}</div>

        <Paper elevation={4} sx={{...props.sx, maxWidth: 500, minWidth: 250, minHeight: 50 }}>
            <Box display="flex" justifyContent="center" alignItems='center' sx={{minHeight: 50}} >
                {showProgress &&  <CircularProgress size={22} color='customCircularLoader' />}
                {!showProgress && !props.percentage && <Typography sx={{fontSize: 24, fontWeight: 'bold'}} >{new Intl.NumberFormat().format(theNumber)}</Typography>}
                {!showProgress && props.percentage && <Typography sx={{fontSize: 24, fontWeight: 'bold'}} >{theNumber} %</Typography>}
            </Box>
        
    </Paper>
        </Stack>
        </ThemeProvider>
}

const CcAccountStatsTile = (props) => {
    const [showProgress, setShowProgress] = useState(true);
    const [theNumber, setTheNumber] = useState(0);

    useEffect(() => {
        getStats(props.endpoint)
    }, [])

    const getStats = async (endpoint) => {
        try {
            // const url = `api/Stats/${endpoint}`;
            const url = `${process.env.REACT_APP_LION_API_URL}/Stats/${endpoint}`

            const response = await axios.get(url);
            const data = response.data;

            setTheNumber(data);
            setShowProgress(false)
                    
        } catch (error) {
            console.log(error);
        }  

        }

    return <div style={{backgroundColor: props.color}} className='h-36 px-6 py-5 w-full font-poppins' >
        <div className=' text-white'>{props.title}</div>
        {showProgress && <div className='flex flex-row justify-center mt-4 text-white'> <CircularProgress size={32} color='inherit'  /></div>}

        {!showProgress && <>
        <div className=' text-white font-bold text-3xl'>{/*new Intl.NumberFormat().format(theNumber)*/}<CountUp end={theNumber} /></div>

        {/*
            <div className='flex flex-row justify-end w-full'>
                <div style={{color: props.color}} className=' bg-white/70 rounded-sm font-semibold py-1 px-2 text-xs'>+9%</div>
            </div>
            <div className='flex flex-row justify-end w-full'>
                <div className=' text-white py-1  text-[10px]'>compared to last week</div>
            </div>
         */}
        </>
        }
    </div>
}
const CcAccountStatsBar = (props) => {

    return <div className='flex flex-row gap-x-0'>
        <CcAccountStatsTile color={ccColorSecondaryPink} title='Today'
                                            endpoint='opens/today'
                                            />
        <CcAccountStatsTile color={ccColorSecondaryGreen} title='This month'
                                            endpoint='opens/thisMonth'
                                            />
        <CcAccountStatsTile color={ccColorSecondaryPurple} title='This term'
                                            endpoint='opens/thisTerm'
                                            />
        <CcAccountStatsTile color={ccColorSecondaryAzure} title='Pacing'
                                            endpoint='opens/pacing'
                                            />
        <CcAccountStatsTile color={ccColorSecondaryBronze} title='Clicks this month'
                                            endpoint='clicks/thisMonth'
                                            />

    </div>
}


export default function HomeComponent() {

    const [enabledProductIds, setEnabledProductIds] = useState([]);
    const {settings, setSettings} = useContext(SettingsContext);

    useEffect(() => {
        // setEnabledProductIds(settings.enabledProductIds);
    }, [settings])

    return <Stack spacing={2} sx={{backgroundSize: 'cover', backgroundImage: `url("/assets//backgroundDashboardCircles.png")`}}>
            <CcTitle>Real-time usage</CcTitle>
            {/*
            <Stack direction='row' spacing={3} sx={{width: '100%'}}>
                <LionAccountStat title='Today'
                                    sx={{backgroundColor: indigo[200]}}
                                    endpoint='opens/today'
                />
                <LionAccountStat title='This month'
                                    sx={{backgroundColor: indigo[200]}}
                                    endpoint='opens/thisMonth'
                />
                <LionAccountStat title='This term'
                                    sx={{backgroundColor: indigo[200]}}
                                    endpoint='opens/thisTerm'
                />
                <LionAccountStat title='Pacing'
                                    sx={{backgroundColor: indigo[200]}}
                                    endpoint='opens/pacing'
                                    percentage
                />

            </Stack>
            <Stack direction='row' spacing={3} sx={{width: '100%'}}>
                <LionAccountStat title='Clicks this month'
                                    sx={{backgroundColor: pink[200]}}
                                    endpoint='clicks/thisMonth'
                />
            </Stack>
            */}
            <CcAccountStatsBar />
{/*
            <Stack direction='row' spacing={3} sx={{width: '100%'}}>
            <LineChartOpens />
            <PieChartOpens />
            <PieChartDeviceType />
            </Stack>
            <Stack direction='row' spacing={3} sx={{width: '100%'}}>
            <PieChartMobileVsNonMobile />
            <PieChartBrowser />
            </Stack>
*/}
            <Divider variant="middle" />
            <CcTitle>Click on an element to get started</CcTitle>
            {/*<SnippetTypeGrid />*/}
            <CcSnippetTypeGrid />
    </Stack>
}