'use client'

import { useRef, useState } from 'react';

import { Stack, TextField } from '@mui/material';


export default function LionInputCode(props) {
    const fieldIds = props.ids;

  const [theCode, setTheCode] = useState('');

  const getCode = () => {
    const numFields = fieldIds.length;
    let code = ''
    for (let i=0; i<numFields; i += 1) {
      const field = document.querySelector(`input[name=lion-code-${i}`);
      code = code.concat(field.value);
    }
    setTheCode(code);
    return code;
  }

    const handleChangeWithNextField = (event) => {
    

        // const nextfield = document.querySelector(`input[name=${keyName}${fieldIntIndex + 1}]`);
        const nextNum = parseInt(event.target.name.replace('lion-code-',''), 10) + 1;

        const nextField = document.querySelector(`input[name=lion-code-${nextNum}`);


        if (nextField != null) nextField.focus();
    
        props.returnCode(getCode());
      };

      
    return (
    <>


        <Stack direction="row" spacing={2} justifyContent="center" >
        {
            
            fieldIds.map((id, index) => 
                <TextField key={id} name={`lion-code-${index}`} 
                placeholder="-"
                onChange={(event) => {
                    handleChangeWithNextField(event);
                  }}
                onFocus={(event) => event.currentTarget.select()}
                InputProps={{
                  sx: {
                    width: { xs: 36, sm: 56 },
                    height: { xs: 36, sm: 56 },
                    '& input': { p: 0, textAlign: 'center' },
                  },
                  maxLength: 1,
                  inputMode: 'numeric', pattern: '[0-9]*',
                }}

              />
            )
            
        }

        </Stack>
    </>
    );
};
