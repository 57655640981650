import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useState } from 'react';

import { useAuth } from "../Login/AuthProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import Login from "../Login/Login";
import Logout from "../Login/Logout";
import ForgotPassword from "../Login/ForgotPassword";
import ResetPassword from "../Login/ResetPassword";
import SessionExpired from "../Login/SessionExpired";
import ActivateEmail from "../Login/ActivateEmail";
import PageLayout from "../Pages/PageLayout";
import PageTemplates from "../Pages/PageTemplates";
import PageSnippets from "../Pages/PageSnippets";
import PageContentSourcesOld from "../Pages/PageContentSourcesOld";
import PageContentSources from "../Pages/PageContentSources";
import PageAdminUsers from  "../Pages/PageAdminUsers";
import PageImpersonate from "../Pages/PageImpesonate";
import PageAdminInvoices from "../Pages/PageAdminInvoices";
import PageAdminColors from "../Pages/PageAdminColors";
import PageAdminFonts from "../Pages/PageAdminFonts";
import PageAdminSettings from "../Pages/PageAdminSettings";
import PageAdminArchive from "../Pages/PageAdminArchive";
import PageAdminUpdatePaymentConfirm from "../Pages/PageAdminUpdatePaymentConfirm";
import PageActivateLogin from  "../Pages/PageActivateLogin";
import PageEditTemplate from "../Pages/PageEditTemplate";
import PageTimerFromTemplate from "../Pages/PageTimerFromTemplate";
import PageTextImageFromTemplate from "../Pages/PageTextImageFromTemplate";
import PageGeneratorFromTemplate from "../Pages/PageGeneratorFromTemplate";
import PagePersAnimationsFromTemplate from "../Pages/PagePersAnimationsFromTemplate";
import PageGeneratorFromContentSource from "../Pages/PageGeneratorFromContentSource";
import PageVotesFromTemplate from "../Pages/PageVotesFromTemplate";
import PageMoodMeterFromTemplate from "../Pages/PageMoodMeterFromTemplate";
import PageClickCounterFromTemplate from "../Pages/PageClickCounterFromTemplate";


import TimerWizard from "../Wizards/timerWizard";
import TextImageWizard from "../Wizards/textImagesWizard";
import GeneratorWizard from "../Wizards/generatorWizard";
import VotesWizard from "../Wizards/votesWizard";
import OSTrackerWizard from "../Wizards/osTrackerWizard";
import PersAnimationsWizard from "../Wizards/persAnimationsWizard";

import TimerWorkflow from "../workflows/timerWorkflow";
import TextImagesWorkflow from "../workflows/textImagesWorkflow";
import GeneratorWorkFlow from "../workflows/generatorWorkflow";
import VotesWorkflow from "../workflows/votesWorkflow";
import MoodMeterWorkflow from "../workflows/moodmeterWorkflow";
import ClickCounterWorkflow from "../workflows/clickcounterWorkflow";
import OSTrackerWorkflow from "../workflows/osTrackerWorkflow";
import PersAnimationsWorkflow from "../workflows/persAnimationsWorkflow";

import ProductHomeTimers from "../Pages/ProductsHomes/ProductHomeTimers";
import ProductHomeTextImages from "../Pages/ProductsHomes/ProductHomeTextImages";
import ProductHomeContentAutomation from "../Pages/ProductsHomes/ProductHomeContentAutomation";
import ProductHomeVotes from "../Pages/ProductsHomes/ProductHomeVotes";
import ProductHomeMoodMeter from "../Pages/ProductsHomes/ProductHomeMoodMeter";
import ProductHomeClickCounter from "../Pages/ProductsHomes/ProductHomeClickCounter";
import ProductHomeOSTracker from "../Pages/ProductsHomes/ProductHomeOSTracker";
import ProductHomePersAnimation from "../Pages/ProductsHomes/ProductHomePersAnimation";

import ReportDashboard from "../Reports/reportDashboard";

import { NewUserWorkflow } from "../Stripe/newUserWorkflow";
import { PageSubscriptionResult } from "../Stripe/PageSubscriptionResult";

import Button from '@mui/material/Button';
import HomeComponent from "../components/HomeComponent";
import SettingsContextProvider from '../components/userSettingsContext'

const Routes = () => {
  const { token } = useAuth();
  const [settings, setSettings] = useState({enabledProductIds: []});

  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/login/:dest",
      element: <Login />,
    },
    {
      path: "/forgotPassword",
      element: <ForgotPassword />,
    },
    {
      path: "/resetPassword/:code1/:code2",
      element: <ResetPassword />,
    },
    {
      path: "/activateEmail/:code1/:code2",
      element: <ActivateEmail />,
    },
    {
      path: "/service",
      element: <div>Service Page<Button variant="contained">Ciao</Button></div>,
    },
    {
      path: "/about-us",
      element: <div>About Us</div>,
    },
    {
      path: "/stripe",
      children: [
        {
          path: "newUser",
          element:<NewUserWorkflow />,
        },
        {
          path: "subscriptionResult",
          element:<PageSubscriptionResult />,
        },
      ]
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/",
          element:  <SettingsContextProvider settings={settings} setSettings={setSettings}>
          <PageLayout selectedPage='home' ><HomeComponent /></PageLayout></SettingsContextProvider>,
        },
        {
          path: "impersonate",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                  <PageLayout ><PageImpersonate /></PageLayout>
                  </SettingsContextProvider>,
        },
        {
          path: "/admin",
          children: [
            {
              path: "users",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><PageAdminUsers /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "subscription-center",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><PageAdminInvoices /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "colors",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><PageAdminColors /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "fonts",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><PageAdminFonts /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "settings",
              element:  <SettingsContextProvider settings={settings} setSettings={setSettings}>
                        <PageLayout ><PageAdminSettings /></PageLayout>
                        </SettingsContextProvider>,
            },
            {
              path: "archive",
              element:  <SettingsContextProvider settings={settings} setSettings={setSettings}>
                        <PageLayout ><PageAdminArchive /></PageLayout>
                        </SettingsContextProvider>,
            },
            {
              path: "updatepayment",
              element:  <SettingsContextProvider settings={settings} setSettings={setSettings}>
                        <PageLayout ><PageAdminUpdatePaymentConfirm /></PageLayout>
                        </SettingsContextProvider>,
            },
        
          ]
        },
        {
          path: "/ph",
          children: [
            {
              path: "timers",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><ProductHomeTimers /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "timersFromTemplate/:templateId",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><PageTimerFromTemplate /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "textImages",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><ProductHomeTextImages /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "textImagesFromTemplate/:templateId",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><PageTextImageFromTemplate /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "generator",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><ProductHomeContentAutomation /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "generatorFromTemplate/:templateId/:dataConnectionId",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><PageGeneratorFromTemplate /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "generatorFromContentSource/:dataConnectionId",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><PageGeneratorFromContentSource /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "votes",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><ProductHomeVotes /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "votesFromTemplate/:templateId",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><PageVotesFromTemplate /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "moodmeter",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><ProductHomeMoodMeter /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "moodmeterFromTemplate/:templateId",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><PageMoodMeterFromTemplate /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "clickcounter",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><ProductHomeClickCounter /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "clickcounterFromTemplate/:templateId",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><PageClickCounterFromTemplate /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "appMatch",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><ProductHomeOSTracker /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "persAnimation",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><ProductHomePersAnimation /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "persAnimationsFromTemplate/:templateId",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><PagePersAnimationsFromTemplate /></PageLayout>
                      </SettingsContextProvider>,
            },
          ]
        },
        {
          path: "/workflow",
          children: [
            {
              path: "12",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><TimerWorkflow /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "9",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><TextImagesWorkflow /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "1",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><GeneratorWorkFlow /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "5",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><VotesWorkflow /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "6",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><ClickCounterWorkflow /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "7",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><MoodMeterWorkflow /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "13",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><OSTrackerWorkflow /></PageLayout>
                      </SettingsContextProvider>,
            },
            {
              path: "14",
              element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                      <PageLayout ><PersAnimationsWorkflow /></PageLayout>
                      </SettingsContextProvider>,
            },
          ]
        },
        {
          path: "/members/subscriptionResult",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                    <PageLayout><PageSubscriptionResult member /></PageLayout>
                    </SettingsContextProvider>,
        },
        {
          path: "/templates",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                    <PageLayout selectedPage='templates' ><PageTemplates /></PageLayout>
                    </SettingsContextProvider>,
        },
        {
          path: "/templates/edit/:snippetTypeId/:templateId",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                    <PageLayout ><PageEditTemplate /></PageLayout>
                    </SettingsContextProvider>,
        },
        {
          path: "/elements",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                    <PageLayout selectedPage='elements' ><PageSnippets /></PageLayout>
                    </SettingsContextProvider>,
        },
        {
          path: "/contentsourcesOld",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                    <PageLayout selectedPage='contentsources' ><PageContentSourcesOld /></PageLayout>
                    </SettingsContextProvider>,
        },
        {
          path: "/contentsources",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                    <PageLayout selectedPage='contentsources' ><PageContentSources /></PageLayout>
                    </SettingsContextProvider>,
        },
        {
          path: "/timers/:snippetId",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                    <PageLayout selectedPage='snippets' ><TimerWizard /*snippetId={params.id}*/ /></PageLayout>
                    </SettingsContextProvider>,
        },
        {
          path: "/textImages/:snippetId",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                    <PageLayout selectedPage='snippets' ><TextImageWizard /*snippetId={params.id}*/ /></PageLayout>
                    </SettingsContextProvider>,
        },
        {
          path: "/generator/:snippetId",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                    <PageLayout selectedPage='snippets' ><GeneratorWizard /></PageLayout>
                    </SettingsContextProvider>,
        },
        {
          path: "/votes/:snippetId",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                    <PageLayout selectedPage='snippets' ><VotesWizard snippetTypeId={5} /*snippetId={params.id}*/ /></PageLayout>
                    </SettingsContextProvider>,
        },
        {
          path: "/moodmeter/:snippetId",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                    <PageLayout selectedPage='snippets' ><VotesWizard snippetTypeId={7} /></PageLayout>
                    </SettingsContextProvider>,
        },
        {
          path: "/clickcounter/:snippetId",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                    <PageLayout selectedPage='snippets' ><VotesWizard snippetTypeId={6} /></PageLayout>
                    </SettingsContextProvider>,
        },
        {
          path: "/appMatch/:snippetId",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                    <PageLayout selectedPage='snippets' ><OSTrackerWizard /*snippetId={params.id}*/ /></PageLayout>
                    </SettingsContextProvider>,
        },
        {
          path: "/persAnimations/:snippetId",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                    <PageLayout selectedPage='snippets' ><PersAnimationsWizard /*snippetId={params.id}*/ /></PageLayout>
                    </SettingsContextProvider>,
        },
        {
          path: "/reports",
          element: <SettingsContextProvider settings={settings} setSettings={setSettings}>
                    <PageLayout selectedPage='reports' ><ReportDashboard /></PageLayout>
                    </SettingsContextProvider>,
        },
        {
          path: "/profile",
          element: <PageLayout >User profile</PageLayout>,
        },
        {
          path: "/logout",
          element: <Logout />,
        },
        {
          path: "/sessionexpired",
          element: <SessionExpired />,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/activateLogin",
      element: <PageActivateLogin />,
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;

