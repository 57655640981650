import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import TemplateEditor from '../components/templateEditor';


export default function PageEditTemplate() {

    let params = useParams();
    const navigate = useNavigate();

    const handleTemplateEditorClose = () => {
        switch (params.snippetTypeId) {
            case "12":
                navigate(`/ph/timers`)
                break;
            case "9":
                navigate(`/ph/textImages`)
                break;
            case "1":
                navigate(`/ph/generator`)
                break;
            case "5":
                navigate(`/ph/votes`)
                break;
            case "6":
                navigate(`/ph/clickcounter`)
                break;
            case "7":
                navigate(`/ph/moodmeter`)
                break;
            default:
                navigate(`/`)
                break;    
        }
    }
    return <div>
                <TemplateEditor id={params.templateId} 
                    closingCall={handleTemplateEditorClose}
                    snippetTypeId={params.snippetTypeId}
                />

    </div>
}
