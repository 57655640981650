
import { DataGridPro, useGridApiRef, GridActionsCellItem } from '@mui/x-data-grid-pro';
import {Button, Chip, Popper, Box, Typography, Stack, Tooltip, Popover, Paper,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';


import { useState, useEffect, useCallback, useContext } from 'react';
import { styled } from '@mui/material/styles';

// import Paper from 'src/theme/overrides/Paper';
import { Icon as Iconify } from '@iconify/react';

import axios from 'axios';

import {TransformDataForGrid}  from './helpers';
import {lionObjectsColors}  from './helpersStyles';
import {lionIcons}  from './helpersIcons';

// import {store} from '../redux/store'
// import { setLabelsTemplates } from '../redux/slices/userSettings';
import {SettingsContext} from '../components/userSettingsContext'

import { LabelManager } from './labelManager'

export default function TemplateGrid(props) {
  const {settings, setSettings} = useContext(SettingsContext);

  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupValue, setPopupValue] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({action: '', id:'', title:'', desc:''});

  const [popupLabelManagerOpen, setPopupLabelManagerOpen] = useState(false);
  const [popupLabelManagerValue, setPopupLabelManagerValue] = useState(false);
  const [popupLabelManagerAnchorEl, setPopupLabelManagerAnchorEl] = useState(null);
  const [tmpLabels, setTmpLabels] = useState([]);
  const [tmpSnippetId, setTmpSnippetId] = useState(0);
  const apiRef = useGridApiRef();


  useEffect(() => {
    //console.log(props.labelFilters)
    if (props.labelFilters !== undefined && props.labelFilters.length > 0) { 
      const conditionArr = props.labelFilters.map((f, index) => ({
                                                                  id: index,
                                                                  field: 'labels',
                                                                  value: f.labelId,
                                                                  operator: 'contains',                                                        
                                                                }))
      apiRef.current.setFilterModel({items: conditionArr});
      /*                                                          
      apiRef.current.setFilterModel({
      items: [
        {
          id: 1,
          field: 'labels',
          value: props.labelFilters[0].labelId,
          operator: 'contains',
        },
      ],
    },)
    */
  }
    else apiRef.current.setFilterModel({
      items: [ ],
    })

  }, [props.labelFilters])

const getData = useCallback(async () => {
    try {
      let url = `${process.env.REACT_APP_LION_API_URL}/Templates`;
      if (props.archive) url += `/archived`
      const response = await axios.get(url);
      //const url = `api/Templates`;
      //const response = await fetch(url, { method: "GET", });
  
      //response.data = await response.json();

      setData(TransformDataForGrid(response.data, 'templateId'));

    } catch (error) {
      console.log(error);
    }
  }
  ,[])

  useEffect(() => {getData()}, [getData]);

  
  const editDataConnection = (id) => {
    // alert(id);
    props.editCall(id);
  }
  const deleteTemplate = (templateId) => {
    // we open the confirmation dialog
    const dialogNewData = {action: 'delete', id:templateId, title:'Delete Template', desc:'Are you sure you want to delete this template?'};
    setDialogData(dialogNewData);
    setDialogOpen(true);
  }
  const archiveTemplate = (templateId) => {
    // we open the confirmation dialog
    const dialogNewData = {action: 'archive', id:templateId, title:'Archive Template', desc:'Are you sure you want to archive this template?'};
    setDialogData(dialogNewData);
    setDialogOpen(true);
  }

  const manageLabels = (event, id, labels) => {
    setPopupLabelManagerOpen(true);
    setPopupLabelManagerValue({snippetId: id, data: labels})
    setPopupLabelManagerAnchorEl(event.currentTarget);
    setTmpSnippetId(id);
  }

  const labelOnlyOperators = [
    {
      label: 'Contains',
      value: 'contains',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }

        return (params) => {
          console.log(params.row.labels)
          console.log(filterItem)
          const valid = params.row.labels.findIndex(l => l.labelId === filterItem.value);
          return valid !== -1;
        };
      },
      InputComponentProps: { type: 'number' },
      getValueAsString: (value) => `${value} Stars`,
    },
  ];

const rows = [
    { id: 1, col1: 'Hello', col2: 'World', colAspettoCustomizzato: 'Ciao' },
    { id: 2, col1: 'DataGridPro', col2: 'is Awesome', colAspettoCustomizzato: 'Ciaone' },
  ];

  let columnActions = {
    field: 'actions',
    type: 'actions',
    getActions: (params) => [
      <GridActionsCellItem icon={<Iconify icon='material-symbols:edit' width={20} />} onClick={() => (editDataConnection(params.id))} label="Edit" disabled={params.row.status === 'PENDING CHANGES'} />,
      <Tooltip title='Manage labels' placement='top'><GridActionsCellItem icon={<Iconify icon={lionIcons.Icon_Label} width={20} />} onClick={(event) => (manageLabels(event, params.id, params.row.labels))}  /></Tooltip>,
      <Tooltip title='Archive' placement='top'><GridActionsCellItem icon={<Iconify icon={lionIcons.Icon_Actions_Archive} width={20} />} onClick={() => archiveTemplate(params.id)} label="Archive"  /></Tooltip>,
    ]
  }
  if (props.archive) columnActions = {
    field: 'actions',
    type: 'actions',
    getActions: (params) => [
      <Tooltip title='Unarchive' placement='top'><GridActionsCellItem icon={<Iconify icon={lionIcons.Icon_Actions_UnArchive} width={20} />} onClick={() => unArchiveTemplateCall(params.id)} label="Archive"  /></Tooltip>,
      <GridActionsCellItem icon={<Iconify icon='material-symbols:delete-outline' width={20} />} onClick={() => deleteTemplate(params.id)} label="Delete"  />,
    ]
  }

  const columns = [
    { field: 'templateName', headerName: 'Template name', width: 150 },
    { field: 'description', headerName: 'Description', width: 150 },
    { field: 'created', headerName: 'Created', width: 160,
      valueFormatter: (params) => {
        if (params.value == null) return '';
        return new Date(Date.parse(params.value)).toLocaleString();
      } },
      { field: 'labels', headerName: 'Labels', width: 250,
        renderCell: (params) => {
            if (params.value.length === 0) return ''
            return <Stack direction='row' spacing={1}>
                    {params.value.map(l => <Tooltip key={l.labelId} title={l.labelName}><Chip  label={l.labelName} size='small'
                                            sx={{minWidth:70, maxWidth:100, backgroundColor: lionObjectsColors.Color_SnippetLabel}}/></Tooltip>)}
            </Stack> 
        },
        filterOperators: labelOnlyOperators
    },
    
  ];
  columns.push(columnActions)

  const handlePopoverOpen = (event) => {
    const field = event.currentTarget.dataset.field;
    const id = event.currentTarget.parentElement.dataset.id;
    const row = data.find(r => (r.id.toString() === id));

    // Normal case
    setPopupOpen(isOverflown(event.currentTarget.firstChild));

    setPopupValue(row[field]);
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {setPopupOpen(false)};

  
  function isOverflown(element) {
    try 
    {
      return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      );  
    }
    catch(ex)
    {
      return false;
    }
  }
      
const deleteTemplateCall = async (templateId) => {
  try {
    const url = `${process.env.REACT_APP_LION_API_URL}/Templates/delete/${templateId}`;
    const response = await axios.get(url);

    //const url = `api/Templates/delete/${templateId}`;
    //const response = await fetch(url, { method: "GET", });
    
  } catch (error) {
    console.log(error);
  }

}
const archiveTemplateCall = async (templateId) => {
  try {
    const url = `${process.env.REACT_APP_LION_API_URL}/Templates/archive/${templateId}`;
    const response = await axios.get(url);

    //const url = `api/Templates/delete/${templateId}`;
    //const response = await fetch(url, { method: "GET", });
    
  } catch (error) {
    console.log(error);
  }

}      
const unArchiveTemplateCall = async (templateId) => {
  try {
    const url = `${process.env.REACT_APP_LION_API_URL}/Templates/unarchive/${templateId}`;
    const response = await axios.get(url);

    //const url = `api/Templates/delete/${templateId}`;
    //const response = await fetch(url, { method: "GET", });
    getData();

  } catch (error) {
    console.log(error);
  }

}      
const handleDialogClose = async(action) => {
  if (action === 'delete') {
      await deleteTemplateCall(dialogData.id);
    // re-download data to refresh the grid
    getData();
  }
  else if (action === 'archive') {
    await archiveTemplateCall(dialogData.id);
  // re-download data to refresh the grid
  getData();
  }

  setDialogData({action: '', id:'', title:'', desc:''});

  setDialogOpen(false);
}

const handlePopupLabelManagerClose = (saveChanges = false) => {
  setPopupLabelManagerOpen(false)
  setPopupLabelManagerAnchorEl(null)
  //console.log(saveChanges)
  if (saveChanges === true) {
    saveLabels()
    console.log('save changes')

  }
  else {
    setTmpLabels([])
    setTmpSnippetId(0)
  }
};

const handleLabelChanges = (labels) => {
  setTmpLabels(labels)
}

const saveLabels = async () => {
  try {
    const url = `${process.env.REACT_APP_LION_API_URL}/User/Labels/update?snippetId=${tmpSnippetId}&product=template`;
    const response = await axios.post(url, tmpLabels);

    //const url = `api/User/Labels/update?snippetId=${tmpSnippetId}&product=template`;
    //const response = await fetch(url, { method: "POST", body: JSON.stringify(tmpLabels) });    
    //response.data = await response.json();

    if (response.data.length > 0) {
      //const allLabels = await store.getState().userSettings.labelsTemplates;
      const allLabels = settings.labelTemplates;
      console.log(allLabels)
      const newAllLabels = [...allLabels];

      // set tmpLabels with real newIds
      response.data.forEach(l => {
        const newLabel = tmpLabels.find(tL => tL.labelId === l.oldId);
        newLabel.labelId = l.newId;
        newAllLabels.push(newLabel);
      })
      // we update the store with the new labels
      // store.dispatch(setLabelsTemplates(newAllLabels));
      const newSettings = {...settings, labelTemplates: newAllLabels}
      setSettings(newSettings);
      console.log(newSettings)
    }
    apiRef.current.updateRows([{ id: tmpSnippetId, labels: tmpLabels }]);



  } catch (error) {
    console.log(error);
  }
}



    return (
        <>
            <div style={{ height: 400, width: '100%' }}>
            <DataGridPro rows={data} columns={columns}
                apiRef={apiRef}
                 componentsProps={{
                                  cell: {
                                    onMouseEnter: handlePopoverOpen,
                                    onMouseLeave: handlePopoverClose,
                                  },
        }} />

<Dialog onClose={() => handleDialogClose('abort')} open={dialogOpen}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description">
      <DialogTitle id="dialog-title">{dialogData.title}</DialogTitle>
      <DialogContent>
          <DialogContentText id="dialog-description">
            {dialogData.desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose('abort')}>Cancel</Button>
          <Button onClick={() => handleDialogClose(dialogData.action)} autoFocus>
            Confirm
          </Button>
        </DialogActions>

</Dialog>
        
<Popper classes={{ paper: "MuiPopover-paper" }}
        sx={{
          pointerEvents: 'none',
        }}
        open={popupOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
          <Typography sx={{ p: 1 }}>{popupValue}</Typography>
          </Box>
      </Popper>


      <Popover 
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
        open={popupLabelManagerOpen}
        anchorEl={popupLabelManagerAnchorEl}
        onClose={handlePopupLabelManagerClose}
        // disableRestoreFocus
      >
        <Paper sx={{ padding: '10px'}} elevation={4}>
          <Stack spacing={2}>
            <LabelManager data={popupLabelManagerValue} onChange={handleLabelChanges} product='template' />
            <Stack direction='row' spacing={1} display="flex" justifyContent="flex-end">
              <Button variant='outlined' color='secondary'  sx={{mr:1}} onClick={handlePopupLabelManagerClose} >Cancel</Button>
              <Button variant='outlined' color='secondary'  sx={{mr:1}} onClick={() => handlePopupLabelManagerClose(true)} >Confirm settings</Button>
            </Stack>
          </Stack>
          </Paper>
      </Popover>


            </div>

        </>

    )
}