import { useState, useEffect, useContext  } from 'react';
import { useNavigate } from 'react-router-dom';

import {SettingsContext} from '../components/userSettingsContext';
import {TabSelector} from '../commonComponents/generalComponents';
import {LionListbox, LionCombo} from  '../commonComponents/inputFields'

import { Alert, AlertTitle, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
             IconButton, Tooltip} from '@mui/material';
import axios from 'axios';
import { Icon as Iconify } from '@iconify/react';
import {InfoToolTip, LionLoaderGradient} from "../commonComponents/generalComponents";
import { lionIcons } from '../components/helpersIcons';
import { ccColorDarkTeal, ccColorSecondaryPink, ccColorSecondaryPurple } from '../components/helpersStyles';
import PageAdminColors from './PageAdminColors';
import PageAdminFonts from './PageAdminFonts'
import PageAdminQuickBlocks from './PageAdminQuickBlocks';
import { CcCreateNewButton, CcTitle } from '../commonComponents/styledTexts';

const TimerSettings = (props) => {
    const {settings, setSettings} = useContext(SettingsContext);
    const navigate = useNavigate();
    const [timeZones, setTimeZones] = useState(undefined);

    const [showLoader, setShowLoader] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);


    useEffect(() => {
        getTimezones()
      }, [])
    const getTimezones = async () => {
    try {  
        const url = `${process.env.REACT_APP_LION_API_URL}/Settings/timezones`;
        const response = await axios.get(url);
        const data = response.data;

        const result = data.map(d => {return {id: d.zoneId, name: d.zoneName}});
        setTimeZones(result);

    } catch (error) {
        //console.log(error);
    }
    }
    
      

    const handleSettingChange = (settingName, settingValue) => {
        const newTimerSettings = {...settings.accountSettings.timers, [settingName]: settingValue}
        const newAccountSettings = {...settings.accountSettings, timers: newTimerSettings}
        const newSettings = {...settings, accountSettings: newAccountSettings}
        setSettings(newSettings)
    }
    const handleUpdateSettings = async() => {
        setShowLoader(true)
        try {
          const url = `${process.env.REACT_APP_LION_API_URL}/User/UpdateAccountSettings`;
  
  
          const response = await axios.post(url, settings.accountSettings);
          if (response.data === true) {
            setShowSuccess(true)
          }
          else {
              setShowError(true)
          }

        } catch (error) {
          setShowError(true)
        }
        finally {
          setShowLoader(false);

        }
    }
    const handleGoToHome = () => {
        //navigate("/")
        setShowLoader(false)
        setShowSuccess(false)
        setShowError(false)
    }

    return <div>
        {!showLoader && !showSuccess && !showError && <div className='flex flex-col gap-4'>
            <div className='text-ccColorDarkTeal text-lg font-semibold'>Personalised timers</div>
            <div className='text-ccColorDarkTeal'>Select the correct format for the date/time merge for personalised countdown timers from the list below:</div>
            <div>
                <label htmlFor="snippetHref" className="block text-sm font-medium leading-6 text-gray-900">
                    Merged date format
                </label>
                <div className="">
                <input
                    type="text"
                    name="inputDateFormat"
                    id="timersInputDateFormat"
                    value={settings.accountSettings ? settings.accountSettings.timers.inputDateFormat : ''}
                    onChange={(e) => handleSettingChange('inputDateFormat', e.target.value)}
                    className="block w-56 rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                </div>
            </div>
            <div>
                <label htmlFor="snippetDescription" className="block text-sm font-medium leading-6 text-gray-900">
                    Default Time zone
                </label>
                <div>
                    <LionCombo items={timeZones}
                    onChange={(item) => handleSettingChange('timeZone', item.id)}
                    selectedItemId={settings.accountSettings ? settings.accountSettings.timers.timeZone : undefined}
                    zIndex={100}
                    />
                </div>
            </div>
            <div className='flex flex-row-reverse'>
                <button type="button" onClick={handleUpdateSettings} className="text-white bg-ccColorDarkTeal hover:bg-ccColorTeal  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Update</button>
            </div>
        </div>
        }

        {showLoader && <div className=" container max-w-sm mx-auto flex flex-row justify-center mb-8">
                <LionLoaderGradient /> 
            </div>
        }
        {showSuccess && <div className=" container max-w-md mx-auto flex flex-col justify-center mb-8">
                            <Alert severity="success">
                            <AlertTitle>Success</AlertTitle>
                            <div className='flex flex-col gap-2'>
                                <div>Settings have been updated</div>
                            </div>
                            </Alert>                      
                        </div>
        }
        {showError && <div className=" container max-w-md mx-auto flex flex-col justify-center mb-8">
                            <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            There was an error updating the settings. Please try again later
                            </Alert>                      
                        </div>
        }
        {(showSuccess || showError) && <div className='flex flex-row-reverse'>
                            <button type="button" class="text-white bg-ccColorDarkTeal hover:bg-ccColorTeal font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                onClick={handleGoToHome}
                            >Done</button>
                            </div>
        }

    </div>
}

const MergeSettings = (props) => {
    const {settings, setSettings} = useContext(SettingsContext);
    const navigate = useNavigate();

    const [showLoader, setShowLoader] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);    
      

    const handleSettingChange = (settingName, settingValue) => {
        const newMergeSettings = {...settings.accountSettings.mergeCodes, [settingName]: settingValue}
        const newAccountSettings = {...settings.accountSettings, mergeCodes: newMergeSettings}
        const newSettings = {...settings, accountSettings: newAccountSettings}
        setSettings(newSettings)
    }
    const handleUserDefinedMergeChange = (changeKeyOrValue, settingName, settingValue) => {
        const newUserDefinedMergeSettings = [...settings.accountSettings.mergeCodes.userDefined]
        const matchIndex = newUserDefinedMergeSettings.findIndex(s => s.key === settingName);
        if (changeKeyOrValue === 'value')
            newUserDefinedMergeSettings[matchIndex].value = settingValue;
        else
            newUserDefinedMergeSettings[matchIndex].key = settingValue;
            

        const newMergeSettings = {...settings.accountSettings.mergeCodes, userDefined: newUserDefinedMergeSettings}
        const newAccountSettings = {...settings.accountSettings, mergeCodes: newMergeSettings}
        const newSettings = {...settings, accountSettings: newAccountSettings}
        setSettings(newSettings)
    }
    const handleDeleteDefinedMerge = (settingName) => {
        const newUserDefinedMergeSettings = [...settings.accountSettings.mergeCodes.userDefined]
        const matchIndex = newUserDefinedMergeSettings.findIndex(s => s.key === settingName);
        newUserDefinedMergeSettings.splice(matchIndex, 1)

        const newMergeSettings = {...settings.accountSettings.mergeCodes, userDefined: newUserDefinedMergeSettings}
        const newAccountSettings = {...settings.accountSettings, mergeCodes: newMergeSettings}
        const newSettings = {...settings, accountSettings: newAccountSettings}
        setSettings(newSettings)

    }
    const handleAddDefinedMerge = () => {
        const newUserDefinedMergeSettings = [...settings.accountSettings.mergeCodes.userDefined, {key: "", value: ""}]

        const newMergeSettings = {...settings.accountSettings.mergeCodes, userDefined: newUserDefinedMergeSettings}
        const newAccountSettings = {...settings.accountSettings, mergeCodes: newMergeSettings}
        const newSettings = {...settings, accountSettings: newAccountSettings}
        setSettings(newSettings)

    }

    const handleUpdateSettings = async() => {
        setShowLoader(true)
        try {
          const url = `${process.env.REACT_APP_LION_API_URL}/User/UpdateAccountSettings`;
  
  
          const response = await axios.post(url, settings.accountSettings);
          if (response.data === true) {
            setShowSuccess(true)
          }
          else {
              setShowError(true)
          }

        } catch (error) {
          setShowError(true)
        }
        finally {
          setShowLoader(false);

        }
    }
    const handleGoToHome = () => {
        //navigate("/")
        setShowLoader(false)
        setShowSuccess(false)
        setShowError(false)
    }

    return <div>
        <div className='mb-2 text-ccColorDarkTeal'>Add the merge codes from your ESP here and we’ll automatically add them to Elements to save you time.</div>
        {!showLoader && !showSuccess && !showError && <div className='flex flex-col gap-4'>
            <div className='flex flex-row gap-24'>
                  <div>
                    <div className='flex flex-row gap-x-2 items-center'>
                        <label htmlFor="snippetHref" className="block text-sm font-medium leading-6 text-gray-900">
                            Firstname
                        </label>
                        <InfoToolTip 
                            placement='right'
                            headLine='Firstname'
                            message='Insert the merge code for the first name from your ESP, the same code you’d use to personalise a greeting like "Hi Firstname." It might look something like %%firstname%% or *|FNAME|*.'
                        />
                    </div>
                    <div className="">
                    <input
                        type="text"
                        name="mergeFirstname"
                        id="mergeFirstname"
                        value={settings.accountSettings ? settings.accountSettings.mergeCodes.firstname : ''}
                        onChange={(e) => handleSettingChange('firstname', e.target.value)}
                        className="block w-56 rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    </div>
                </div>

                <div>
                    <div className='flex flex-row gap-x-2 items-center'>
                        <label htmlFor="snippetHref" className="block text-sm font-medium leading-6 text-gray-900">
                            User Id
                        </label>
                        <InfoToolTip 
                            placement='right'
                            headLine='User Id'
                            message="Insert the merge code for the unique user identifier from your ESP."
                        />
                    </div>
                    <div className="">
                    <input
                        type="text"
                        name="mergeUserId"
                        id="mergeUserId"
                        value={settings.accountSettings ? settings.accountSettings.mergeCodes.userId : ''}
                        onChange={(e) => handleSettingChange('userId', e.target.value)}
                        className="block w-56 rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    </div>
                </div>

            </div>

            <div className='flex flex-row gap-24'>
                 <div>
                 <div className='flex flex-row gap-x-2 items-center'>
                     <label htmlFor="snippetHref" className="block text-sm font-medium leading-6 text-gray-900">
                        Mailing Id
                    </label>
                    <InfoToolTip 
                        placement='right'
                        headLine='Mailing Id'
                        message="Insert your ESP’s merge code for mailing id from your ESP."
                    />
                </div>
                    <div className="">
                    <input
                        type="text"
                        name="mergeMailingId"
                        id="mergeMailingId"
                        value={settings.accountSettings ? settings.accountSettings.mergeCodes.mailingId : ''}
                        onChange={(e) => handleSettingChange('mailingId', e.target.value)}
                        className="block w-56 rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    </div>
                </div>

                <div>
                    <div className='flex flex-row gap-x-2 items-center'>
                        <label htmlFor="snippetHref" className="block text-sm font-medium leading-6 text-gray-900">
                            Date of Birth
                        </label>
                        <InfoToolTip 
                            placement='right'
                            headLine='Date of Birth'
                            message="Add this merge code if you’re wanting to send content based around a subscriber’s birthday. This should be a date format merge code."
                        />
                    </div>
                    <div className="">
                    <input
                        type="text"
                        name="mergeDob"
                        id="mergeDob"
                        value={settings.accountSettings ? settings.accountSettings.mergeCodes.dob : ''}
                        onChange={(e) => handleSettingChange('dob', e.target.value)}
                        className="block w-56 rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    </div>
                </div>

            </div>


            <div className='flex flex-row gap-24'>

               <div>
                   <div className='flex flex-row gap-x-2 items-center'>
                    <label htmlFor="snippetHref" className="block text-sm font-medium leading-6 text-gray-900">
                            Language
                        </label>
                        <InfoToolTip 
                            placement='right'
                            headLine='Language'
                            message="Add the merge code here that determines a subscriber’s preferred language."
                        />
                    </div>
                    <div className="">
                    <input
                        type="text"
                        name="mergeLanguage"
                        id="mergeLanguage"
                        value={settings.accountSettings ? settings.accountSettings.mergeCodes.language : ''}
                        onChange={(e) => handleSettingChange('language', e.target.value)}
                        className="block w-56 rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    </div>
                </div>            

              <div>
                <div className='flex flex-row gap-x-2 items-center'>
                    <label htmlFor="snippetHref" className="block text-sm font-medium leading-6 text-gray-900">
                        Timer Personalized Date
                    </label>
                        <InfoToolTip 
                            placement='right'
                            headLine='Timer Personalized Date'
                            message="This merge code should be in a date format and could be for a specific event, for example the date/time that the subscriber signed up."
                        />
                    </div>
                    <div className="">
                    <input
                        type="text"
                        name="mergeTimerDate"
                        id="mergeTimerDate"
                        value={settings.accountSettings ? settings.accountSettings.mergeCodes.timerCustomDate : ''}
                        onChange={(e) => handleSettingChange('timerCustomDate', e.target.value)}
                        className="block w-56 rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    </div>
                </div>

            </div>

            <div className='w-[544px] border border-gray-300 rounded-md p-1'>
                <div className='text-ccColorDarkTeal text-lg font-semibold'> Add your own merge codes</div>
                <div className='text-ccColorDarkTeal text-sm mb-2'>In this section you can add your own custom merge codes from your ESP. This means you will be able to add blocks already connected to the data points in the design lab to streamline design and element creation. Please ensure you test these are correct before any live sends.</div>
                <CcCreateNewButton onClick={handleAddDefinedMerge}>Add your own merge code</CcCreateNewButton>

                <div className='flex flex-col gap-6'>
                {settings.accountSettings && settings.accountSettings.mergeCodes.userDefined.map((userSetting, index) => <div className='flex flex-row gap-12'>
                                            <div>
                                                <label htmlFor="snippetHref" className="block text-sm font-medium leading-6 text-gray-900">
                                                Name
                                                </label>
                                                <div className="">
                                                <input
                                                    type="text"
                                                    name="merge"
                                                    id={`${userSetting.key}${index}`}
                                                    value={userSetting.key}
                                                    onChange={(e) => handleUserDefinedMergeChange('key', userSetting.key, e.target.value)}
                                                    className="block w-56 rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="snippetHref" className="block text-sm font-medium leading-6 text-gray-900">
                                                Merge Code
                                                </label>
                                                <div className="flex flex-row gap-1">
                                                <input
                                                    type="text"
                                                    name="merge"
                                                    id={`${userSetting.value}${index}`}
                                                    value={userSetting.value}
                                                    onChange={(e) => handleUserDefinedMergeChange('value', userSetting.key, e.target.value)}
                                                    className="block w-56 rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                                <IconButton onClick={() => handleDeleteDefinedMerge(userSetting.key)}><Iconify icon={lionIcons.Icon_Actions_Delete} /></IconButton>
                                                </div>
                                            </div>
                                        </div>
                            )
                }
                </div>
            </div>

            <div className='flex flex-row-reverse'>
                <button type="button" onClick={handleUpdateSettings} className="text-white bg-ccColorDarkTeal hover:bg-gradient-to-br hover:bg-ccColorTeal font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Update</button>
            </div>
        </div>
        }

        {showLoader && <div className=" container max-w-sm mx-auto flex flex-row justify-center mb-8">
                <LionLoaderGradient /> 
            </div>
        }
        {showSuccess && <div className=" container max-w-md mx-auto flex flex-col justify-center mb-8">
                            <Alert severity="success">
                            <AlertTitle>Success</AlertTitle>
                            <div className='flex flex-col gap-2'>
                                <div>Settings have been updated</div>
                            </div>
                            </Alert>                      
                        </div>
        }
        {showError && <div className=" container max-w-md mx-auto flex flex-col justify-center mb-8">
                            <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            There was an error updating the settings. Please try again later
                            </Alert>                      
                        </div>
        }
        {(showSuccess || showError) && <div className='flex flex-row-reverse'>
                            <button type="button" class="text-white bg-ccColorDarkTeal hover:bg-ccColorTeal font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                onClick={handleGoToHome}
                            >Done</button>
                            </div>
        }

    </div>
}

const GeneralSettings = (props) => {
    const {settings, setSettings} = useContext(SettingsContext);
    const navigate = useNavigate();
    const [languages, setLanguages] = useState([]);

    const [showLoader, setShowLoader] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);    
      
    useEffect(() => {
        getLanguages()
      }, [])
    const getLanguages = async () => {
    try {  
        const url = `${process.env.REACT_APP_LION_API_URL}/Settings/languages`;
        const response = await axios.get(url);
        const data = response.data;

        setLanguages(data);

    } catch (error) {
        //console.log(error);
    }
    }
    
      
    const handleMappingChange = (lCode, lDisplayName) => {
        let newMappings = [...settings.accountSettings.general.languageMappings]
        const indexMatch = newMappings.findIndex(lm => lm.languageCode === lCode)

        if (indexMatch === -1) {
            newMappings.push({languageCode: lCode, displayName: lDisplayName})
        }
        else newMappings[indexMatch].displayName = lDisplayName;

        const newGeneralSettings = {...settings.accountSettings.general, languageMappings: newMappings}
        const newAccountSettings = {...settings.accountSettings, general: newGeneralSettings}
        const newSettings = {...settings, accountSettings: newAccountSettings}
        setSettings(newSettings)
    }
    const handleDefaultLanguageChange = (lang) => {
        
        const newGeneralSettings = {...settings.accountSettings.general, defaultLanguage: lang.id}
        const newAccountSettings = {...settings.accountSettings, general: newGeneralSettings}
        const newSettings = {...settings, accountSettings: newAccountSettings}
        setSettings(newSettings)

    }

    const handleUpdateSettings = async() => {
        setShowLoader(true)
        try {
          const url = `${process.env.REACT_APP_LION_API_URL}/User/UpdateAccountSettings`;
  
  
          const response = await axios.post(url, settings.accountSettings);
          if (response.data === true) {
            setShowSuccess(true)
          }
          else {
              setShowError(true)
          }

        } catch (error) {
          setShowError(true)
        }
        finally {
          setShowLoader(false);

        }
    }
    const handleGoToHome = () => {
        //navigate("/")
        setShowLoader(false)
        setShowSuccess(false)
        setShowError(false)
    }

    return <div>
        {!showLoader && !showSuccess && !showError && <div className='flex flex-col gap-4'>
            <div className='text-ccColorDarkTeal text-lg font-semibold'>Language Mappings</div>
            <div className='text-ccColorDarkTeal'>Automatically translate timer labels into your recipient's preferred language. When setting up a timer, you can choose the multi-language option. The timer will then include a language parameter that maps to these settings, ensuring the time labels are displayed in the correct language.</div>
            <div>
                <div className="text-sm font-medium leading-6 text-gray-900">
                Default language</div>
                <LionListbox items={languages.map(l => {return {id: l.languageCode, name: l.displayName}})}
                    selectedItemId={settings.accountSettings.general.defaultLanguage}
                    onChange={handleDefaultLanguageChange}
                />
            </div>  
            <div className='flex flex-row'>
                <div className='grid grid-cols-2 gap-y-2 gap-x-8'>
                {
                languages.map(l => {
                let currentMatch = undefined;
                if (settings.accountSettings.general.languageMappings) currentMatch = settings.accountSettings.general.languageMappings.find(lm => lm.languageCode === l.languageCode);
                const currentMapping = currentMatch === undefined ? '' : currentMatch.displayName;
                return <div key={l.languageCode} className='flex flex-row items-center gap-2'>
                <div className='w-32 text-ccColorDarkTeal font-semibold flex justify-end'>{l.displayName}</div>
                <Iconify icon="fluent:arrow-fit-16-regular" width={26} color={ccColorSecondaryPurple} />
                <input
                            type="text"
                            value={currentMapping}
                            onChange={(e) => handleMappingChange(l.languageCode, e.target.value)}
                            className=" block rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
            </div>

            })

            }

                </div>
                <div className=' ml-2 flex grow'></div>
            </div>

            <div className='flex flex-row-reverse'>
                <button type="button" onClick={handleUpdateSettings} className="text-white bg-ccColorDarkTeal hover:bg-gradient-to-br hover:bg-ccColorTeal font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Update</button>
            </div>
        </div>
        }

        {showLoader && <div className=" container max-w-sm mx-auto flex flex-row justify-center mb-8">
                <LionLoaderGradient /> 
            </div>
        }
        {showSuccess && <div className=" container max-w-md mx-auto flex flex-col justify-center mb-8">
                            <Alert severity="success">
                            <AlertTitle>Success</AlertTitle>
                            <div className='flex flex-col gap-2'>
                                <div>Settings have been updated</div>
                            </div>
                            </Alert>                      
                        </div>
        }
        {showError && <div className=" container max-w-md mx-auto flex flex-col justify-center mb-8">
                            <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            There was an error updating the settings. Please try again later
                            </Alert>                      
                        </div>
        }
        {(showSuccess || showError) && <div className='flex flex-row-reverse'>
                            <button type="button" class="text-white bg-ccColorDarkTeal hover:bg-ccColorTeal font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                onClick={handleGoToHome}
                            >Done</button>
                            </div>
        }

    </div>
}

export default function PageAdminSettings() {
    /*
    props:

    NO props 
    */
    const [currentTab, setCurrentTab] = useState("merge")


    return <div className='flex flex-col gap-3'>
        <CcTitle>Brand configuration centre</CcTitle>
        <div className='text-ccColorDarkTeal'>Configure your custom brand settings, including adding brand colours, custom fonts, ESP merge codes, time format preferences, and language mappings to ensure all the dots are joined up.</div>
            <TabSelector 
                items={[{id: 'merge', name: 'Merge Codes'},
                        {id: 'timers', name: 'Timers'},
                        {id: 'languageMapping', name: 'Language Mapping'},
                        {id: 'colors', name: 'Colors'},
                        {id: 'fonts', name: 'Fonts'},
                        {id: 'quickBlocks', name: 'Font Formatting'},
                        ]}
                selectedItemId={currentTab}
                onChange={setCurrentTab}
            />


            {currentTab === 'timers' &&
                <TimerSettings />
            }
            {currentTab === 'merge' &&
                <MergeSettings />
            }
            {currentTab === 'languageMapping' &&
                <GeneralSettings />
            }
            {currentTab === 'colors' &&
                <PageAdminColors />
            }
            {currentTab === 'fonts' &&
                <PageAdminFonts />
            }
            {currentTab === 'quickBlocks' &&
                <PageAdminQuickBlocks />
            }
        
    </div>
}