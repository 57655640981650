import { useEffect, useState } from 'react';

// @mui
import { Container, Typography, IconButton, Tooltip } from '@mui/material';

import { useTranslation } from 'react-i18next';

import SnippetsGrid from '../components/snippetsGrid';
import SnippetCAEditor from '../components/snippetCA_Editor';


import { Icon as Iconify } from '@iconify/react';
import axios from 'axios';
import { ccColorDarkTeal } from '../components/helpersStyles';
import { CcTitle } from '../commonComponents/styledTexts';
// ----------------------------------------------------------------------


export default function PageSnippets() {
  const { t, i18n } = useTranslation();

  const [addEditMode, setAddEditMode] = useState(false);
  const [snippetIdToEdit, setSnippetIdToEdit] = useState(null);
  const [snippetTypeIdToEdit, setSnippetTypeIdToEdit] = useState(null);
  const [templatePreviewImages, setTemplatePreviewImages] = useState(null); // set 1 time only. it contains info to get all preview images url for CA templates. It is passed to SnippetCAEditor as a prop


  const createSnippet = () => {
    setSnippetTypeIdToEdit(1); // for now you can only create a CA snippet

    if (templatePreviewImages == null) {
      getTemplatePreviewImagesInfo();
    }
    else setAddEditMode(true);
  }

  const editSnippet = (id, typeId) => {
    //console.log(id)
    //console.log(typeId)
    setSnippetIdToEdit(id);
    setSnippetTypeIdToEdit(typeId)

    if (templatePreviewImages == null) {
      getTemplatePreviewImagesInfo();
    }
    else setAddEditMode(true);

  }
const handleSnippetEditorClose = () => {
  setAddEditMode(false);
  setSnippetIdToEdit(null);

}
const getTemplatePreviewImagesInfo = async () => {
  try {
        const url = `${process.env.REACT_APP_LION_API_URL}/Templates/previewImages`;
        const response = await axios.get(url);
        const data = response.data;

        // const url = `api/Templates/previewImages`;
        // const response = await fetch(url, { method: "GET", });
        // const data = await response.json();
  
        setTemplatePreviewImages(data);
        setAddEditMode(true);
  } catch (error) {
    console.log(error);
  }
}


  return (
    <>

      <Container maxWidth='xl'>

      {!addEditMode && (
      <>
            <CcTitle className="mb-2" >{t('pageSnippets_title')}</CcTitle>
            {/*
          <Tooltip title="Create new snippet" placement='right' >
            <IconButton variant="contained" color='primary' onClick={createSnippet}>
              <Iconify icon="material-symbols:add-circle" width="48px" />
            </IconButton>
          </Tooltip>            
            */}

          <SnippetsGrid noEdit editCall={(id, typeId) => editSnippet(id, typeId)} />
      </>    )      }

        {addEditMode && <>
          {snippetTypeIdToEdit === 1 &&
            <SnippetCAEditor onClose={handleSnippetEditorClose} id={snippetIdToEdit} 
                          templateGalleryInfo={templatePreviewImages} />      
          }
        </>}

      </Container>
    </>
  );
}
