import { useState } from 'react';

import DatafeedGrid from "../components/datafeedGrid";
import DatafeedWizardOld from '../components/datafeedWizardOld';
import { Typography, IconButton, Tooltip } from '@mui/material';
import { Icon as Iconify } from '@iconify/react';


export default function PageContentSourcesOld() {
  
    const [addEditMode, setAddEditMode] = useState(false);
    const [connectionIdToEdit, setConnectionIdToEdit] = useState(null);
  
    const createDataConnection = () => {
      setAddEditMode(true);
    }
    const editDataConnection = (id) => {
      setAddEditMode(true);
      setConnectionIdToEdit(id);
    }
  
    return <div>
      {!addEditMode && (
      <>
            <Typography variant="h3" component="h1" paragraph>
              Content Connections
            </Typography>
          <Tooltip title="Create new content connection" placement='right' >
            <IconButton variant="contained" color='primary' onClick={createDataConnection}>
              <Iconify icon="material-symbols:add-circle" width="48px" />
            </IconButton>
          </Tooltip>

          <DatafeedGrid editCall={(id) => editDataConnection(id)} />
      </>    )      }

        {addEditMode && <>
        <DatafeedWizardOld id={connectionIdToEdit} closingCall={() => {setAddEditMode(false); setConnectionIdToEdit(null)}} />
        </>}
    </div>
}