import { useEffect, useState, useRef, useContext } from "react"
import { ImageUploader, InputNumber, Toggle} from "../commonComponents/inputFields"
import {LionColorPicker} from '../components/lionColorPicker'
import {SettingsContext} from '../components/userSettingsContext';
import {getColorMappingValue} from "../commonComponents/helperSettings";
import {TryParseIntDefault} from "../components/helpers";

import { Alert } from '@mui/material';
import { Link } from "react-router-dom";

import {Checkbox } from '@mui/material';

export default function VotesPreEditor(props) {

  const {settings, setSettings} = useContext(SettingsContext);
  const initTemplateProps = {canvasBackground: "#fff", numAnswers: 2,
                             votesForQuestion: false, totalVotes: false, votesForQuestionPerc: true,
                              canvasWidth: 320, canvasHeight: 320,
                                isVote: true, textImageDatabind: undefined,
                              canvasImageUrl: null, canvasDatabindType: null}
  if (props.snippetTypeId === 6) {
    //for click counter we change the predefined ticked checkboxes
    initTemplateProps.votesForQuestion = true;
    initTemplateProps.votesForQuestionPerc = false;
  }
                          
  const [useBackgroundImage, setUseBackgroundImage] = useState(true);
  const [canvasUploadedFiles, setCanvasUploadedFiles] = useState({});
  const [templateProps, setTemplateProps] = useState(initTemplateProps)
	const [availableMergeCodes, setAvailableMergeCodes] = useState([]);
  const [snippetTypeId, setSnippetTypeId] = useState(5); //when not provided we default to Polls = 5

  useEffect(() => {
    if (props.snippetTypeId) setSnippetTypeId(props.snippetTypeId)
  }, [])

  useEffect(( ) => {
    if (settings.accountSettings === undefined) return;
    if (props.templateProps === undefined) return;


    //console.log(props.templateProps)

    if (Object.keys(props.templateProps).length === 0) {
        //if props.templateProps is empty it's the first time we pass here
        const canvasColor = getColorMappingValue(settings, 10);
        if (canvasColor !== undefined) handleUpdateProperty('canvasBackground', canvasColor);
    
        if (props.onChange !== undefined) {
          const newProps = {...templateProps, canvasBackground: canvasColor}
          props.onChange(newProps)
        }
    }
    else {
      setTemplateProps(props.templateProps)
      //if props.templateProps show there is a file upload then we need to populate the UI
      if (props.templateProps.canvasImageUrl && props.templateProps.canvasImageUrl !== '') {
        setUseBackgroundImage(true)
        //setCanvasUploadedFiles({'Uploaded file': props.templateProps.canvasImageUrl})
        setCanvasUploadedFiles(props.templateProps.canvasImageUrl)
      }
    }    

  }, [settings, props.templateProps])

  /*
  useEffect(() => {
    if (props.templateProps !== undefined) setTemplateProps(props.templateProps)
    //const canvasColor = getColorMappingValue(settings, 7);
    //if (canvasColor !== undefined) handleUpdateProperty('canvasBackground', canvasColor);

  }, [])*/

    const handleUseBackgroundImageChange = (use) => {
      let newProps = {...templateProps}
        if (!use) {
          newProps = {...templateProps, 
            canvasImageUrl: '', canvasDatabindType: null}

        }
        else {
          const fn = Object.keys(canvasUploadedFiles);
          if (fn.length > 0) {
            newProps = {...templateProps, 
              canvasImageUrl: canvasUploadedFiles[fn[0]], canvasDatabindType: "none"}
          }


        }      
        setTemplateProps(newProps)
        if (Object.keys(newProps).length > 0) {
          if (props.onChange !== undefined) props.onChange(newProps)
        }
    
        setUseBackgroundImage(use);
      }

    const handleFileChange = (fileInfo) => {
      // this can only be 1 file for the canvas background
      //console.log(fileInfo)
      const filenames = fileInfo.fileMappings;
      const fileSizes = fileInfo.fileSizes;
    
      const fn = Object.keys(filenames);

      handleUpdateProperty('numAnswers', fn.length);      

      //we want to resize the canvas to fit the uploaded backgroud
      const newProps = {...templateProps, canvasWidth: fileSizes[fn[0]].width,
                          canvasHeight: fileSizes[fn[0]].height,
                          //canvasImageUrl: filenames[fn[0]],
                          canvasImageUrl: filenames,
                          canvasSizes: fileSizes,
                          numAnswers: fn.length,
                           canvasDatabindType: "none"}
      //console.log(newProps)
      setTemplateProps(newProps)
      if (props.onChange !== undefined) props.onChange(newProps)
  
    
      setCanvasUploadedFiles(filenames);
      //const newTemplateMetadata = handleModelMultiChange(["canvasImageUrl", "canvasDatabindType", "canvasWidth", "canvasHeight"], ['"' + filenames[fn[0]] + '"', '"none"', fileSizes[fn[0]].width, fileSizes[fn[0]].height])
      //getTemplatePreview(newTemplateMetadata, templateModel, previewUrlParamsValues, null);
      //if (props.onCanvasUploadedFileChange !== undefined) props.onCanvasUploadedFileChange(filenames);
    }
  const handleUpdateProperty = (propName, propValue) => {
    //console.log(propName)
    //console.log(propValue)
    const newProps = {...templateProps, [propName]: propValue}

    setTemplateProps(newProps)
    //console.log(newProps)
    if (props.onChange !== undefined) props.onChange(newProps)
  }

  const getNumAnswersMessage = () => {
    switch (snippetTypeId) {
      case 5:
        return "How many polling options will you include?"
      case 6:
        return "How many click counter images will you include?"
      case 7:
        return "How many options will you include?"
      default:
        return "How many options will you include?"
    }
  }

  const getUploaderExtraMessage = () => {
    switch (snippetTypeId) {
      case 5:
        return "To upload multiple poll options, select multiple images to upload"
      case 6:
        return "To upload multiple click counter options, select multiple images to upload"
      case 7:
        return "To upload multiple mood meter options, select multiple images to upload"
      default:
      return ""
    }
  }
  const getVoteFieldsPrettyText = (key) => {
    if (key === "votesForQuestion")
      switch (snippetTypeId) {
        case 5:
          return "Show number of votes"
        case 6:
          return "Number of clicks"
        case 7:
          return "Vote as number of clicks"
        default:
        return ""
      }
    if (key === "votesForQuestionPerc")
      switch (snippetTypeId) {
        case 5:
          return "Show votes as a percentage"
        case 7:
          return "Vote as Percentage"
        default:
        return ""
      }
    
  }

return <div className="flex flex-col gap-4">
            <Toggle displayName="Use background image"
                    onChange={handleUseBackgroundImageChange}
                    checked={useBackgroundImage} />
            
    {!useBackgroundImage &&
                  <div className="flex flex-row gap-x-8">                  
                    <div>  
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Background Color
                      </label>
                      <LionColorPicker color={templateProps.canvasBackground}
                          onChange={(color) => {handleUpdateProperty('canvasBackground', color)}}
                          />
                    </div>            
                  </div>
    }
    {useBackgroundImage && <ImageUploader onChange={handleFileChange}
                                        uploadedFiles={canvasUploadedFiles}
                                        endpoint="utils/unmanaged/uploadImage"
                                        removeEndpoint="utils/unmanaged/removeImage"
                                        extraMessage={getUploaderExtraMessage()}
                                         />

    }
    {!useBackgroundImage && <>
      <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        Canvas Size
      </label>
      <div className="flex flex-row items-center gap-2">
        <div>Width:</div>
        <input id="cwidth" className=" max-w-44 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                          value={templateProps.canvasWidth}
                          onChange={(e) => {handleUpdateProperty('canvasWidth', TryParseIntDefault(e.target.value, ''))}}
                    />
        <div className=" ml-2">Height:</div>
        <input id="cheight" className="max-w-44 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                          value={templateProps.canvasHeight}
                          onChange={(e) => {handleUpdateProperty('canvasHeight', TryParseIntDefault(e.target.value, ''))}}
                    />
      </div>
    </div>

    <div className=" max-w-48">
    <InputNumber 
    displayName={getNumAnswersMessage()}
        value={templateProps.numAnswers}
        min={1}
        max={6}
        onChange={(val) => {handleUpdateProperty('numAnswers', val)}}        
    />
    </div>

    </>
    }

    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        Which of these text values do you need?
      </label>
      <div className="flex flex-row items-center gap-3">
        {(snippetTypeId  === 5 || snippetTypeId  === 6 || snippetTypeId  === 7) &&
          <div className="flex flex-row items-center">
              <Checkbox
                  checked={templateProps.votesForQuestion}
                  onChange={(e) => {handleUpdateProperty('votesForQuestion', !templateProps.votesForQuestion)}}
              />
              <div>{getVoteFieldsPrettyText('votesForQuestion')}</div>
          </div>
        }
        {snippetTypeId  === 6 &&
          <div className="flex flex-row items-center">
              <Checkbox
                  checked={templateProps.totalVotes}
                  onChange={(e) => {handleUpdateProperty('totalVotes', !templateProps.totalVotes)}}
              />
              <div>Total number of clicks</div>
          </div>
        }
        {(snippetTypeId  === 5 || snippetTypeId  === 7) &&
          <div className="flex flex-row items-center">
              <Checkbox
                  checked={templateProps.votesForQuestionPerc}
                  onChange={(e) => {handleUpdateProperty('votesForQuestionPerc', !templateProps.votesForQuestionPerc)}}
              />
              <div>{getVoteFieldsPrettyText('votesForQuestionPerc')}</div>
          </div>
        }
      </div>
    </div>


</div>


}