import { Link, useNavigate } from "react-router-dom";

import TemplateGallery from "../../commonComponents/CC_templateGallery";
import SnippetsGrid from '../../components/snippetsGrid';
import DatafeedGrid from "../../components/datafeedGrid";

import {linkStyle} from "../../components/helpersStyles";
import { IconButton, Tooltip } from '@mui/material';
import { Icon as Iconify } from '@iconify/react';
import { CcCreateNewSnippet, CcSubSectionTitle, CcTitle } from '../../commonComponents/styledTexts';

export default function ProductHomeContentAutomation() {
    const navigate = useNavigate();

    const handleCreateDataConnection = () => {
        navigate("/contentsources");
    }

    return <div>
            <CcTitle>
                Generator Images
            </CcTitle>
            <Link to={`/workflow/1`} style={linkStyle}> 
                <CcCreateNewSnippet className="mt-4" >Create new Generator Image</CcCreateNewSnippet>
            </Link>
            <div className=" mt-3 border border-gray-300 rounded-md p-1 flex flex-col gap-2">
                <CcSubSectionTitle>Start with existing Template</CcSubSectionTitle>
                <div>
                <TemplateGallery productTypeId={1} />
                </div>
            </div>

            <CcSubSectionTitle className="mt-6">Content Connections</CcSubSectionTitle>
            <div className="h-[360px]">
                <DatafeedGrid
                    newElementMode
                    onCreateFirstDataConnectionClicked={handleCreateDataConnection}
                />
            </div>

            <CcSubSectionTitle className="mt-6">Latest Text Generator Elements</CcSubSectionTitle>
            <SnippetsGrid productTypeId={1}
                 //editCall={(id, typeId) => editSnippet(id, typeId)}
             />

    </div>
}