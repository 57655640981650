import TemplateGallery from "../../commonComponents/CC_templateGallery";
import SnippetsGrid from '../../components/snippetsGrid';
import { Link } from "react-router-dom";
import {linkStyle} from "../../components/helpersStyles";
//import { IconButton, Tooltip } from '@mui/material';
//import { Icon as Iconify } from '@iconify/react';
import { CcCreateNewSnippet, CcSubSectionTitle, CcTitle } from '../../commonComponents/styledTexts';
import { useEffect } from "react";

export default function ProductHomeTimers() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [])

    return <div>
            <CcTitle>
                Timers
            </CcTitle>
            <div className="mt-2 text-ccColorDarkTeal">Create your custom timers here. Count up, countdown, or personalised for each individual.</div>
            <Link to={`/workflow/12`} style={linkStyle}>
                <CcCreateNewSnippet className="mt-4" >Create new timer</CcCreateNewSnippet>
            </Link>
            <div className=" mt-3 border border-gray-300 rounded-md p-2 flex flex-col gap-2">
                <CcSubSectionTitle>Start with existing Template</CcSubSectionTitle>
                <div>
                <TemplateGallery productTypeId={12} />
                </div>
            </div>

            <CcSubSectionTitle className=" mt-6">Latest Timers Elements</CcSubSectionTitle>
            <SnippetsGrid productTypeId={12}
                 //editCall={(id, typeId) => editSnippet(id, typeId)}
             />

    </div>
}