import { useState, useEffect, useContext, useCallback  } from 'react';
import axios from 'axios';

import { Box, Chip, CircularProgress, Divider,
     Paper, Stack, Tooltip} from  '@mui/material';


import { createTheme, ThemeProvider } from '@mui/material/styles';

import {ccColorSecondaryAzure, ccColorSecondaryBronze, ccColorSecondaryGreen, ccColorSecondaryPink, ccColorSecondaryPurple, lionProductColors} from '../components/helpersStyles';

import {SettingsContext} from '../components/userSettingsContext'

import {LineChartOpens, PieChartOpens, PieChartDeviceType, PieChartMobileVsNonMobile,
    PieChartBrowser} from "../commonComponents/charts";

import { CcTitle } from '../commonComponents/styledTexts';
import { Icon as Iconify } from '@iconify/react';

import CountUp from 'react-countup';
import { DataGridPro, useGridApiRef, useGridApiContext, GridActionsCellItem } from '@mui/x-data-grid-pro';
import {TransformDataForGrid}  from '../components/helpers';


import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import {dateRangeShortcutsItems} from '../components/helpersDatetime';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import utc from 'dayjs/plugin/utc';

import {lionObjectsColors}  from '../components/helpersStyles';
import './reportDashboard.css'


dayjs.extend(utc);

const CcStatsTile = (props) => {
    const [showProgress, setShowProgress] = useState(true);
    const [theNumber, setTheNumber] = useState(0);

    useEffect(() => {
        if (props.value === undefined) setShowProgress(true);
        else {
            setShowProgress(false)
            setTheNumber(props.value)
        }
    }, [props.value])


    return <div style={{backgroundColor: props.color}} className='h-36 px-6 py-5 w-full font-poppins' >
        <div className=' text-white'>{props.title}</div>
        {showProgress && <div className='flex flex-row justify-center mt-4 text-white'> <CircularProgress size={32} color='inherit'  /></div>}

        {!showProgress && <>
        <div className=' text-white font-bold text-3xl'>{/*new Intl.NumberFormat().format(theNumber)*/}<CountUp end={theNumber} /></div>
        {/* 
        <div className='flex flex-row justify-end w-full'>
            <div style={{color: props.color}} className=' bg-white/70 rounded-sm font-semibold py-1 px-2 text-xs'>+9%</div>
        </div>
        <div className='flex flex-row justify-end w-full'>
            <div className=' text-white py-1  text-[10px]'>compared to last week</div>
        </div>
        */}
        </>
        }
    </div>
}
const CcMainStatsBar = (props) => {
    const [totalOpens, setTotalOpens] = useState(undefined)
    const [totalClicks, setTotalClicks] = useState(undefined)
    const [numActiveElements, setNumActiveElements] = useState(undefined)
    const [numActiveCountries, setNumActiveCountries] = useState(undefined)
    const [numActiveDevices, setNumActiveDevices] = useState(undefined)

    useEffect(() => {
        setTotalOpens(undefined)
        setTotalClicks(undefined)
        setNumActiveElements(undefined)
        setNumActiveCountries(undefined)
        setNumActiveDevices(undefined)

        getStats()
        getNumActiveCountries()
        getNumActiveDevices()
    }, [props.filter])

    const getStats = async () => {
        try {
            const url = `${process.env.REACT_APP_LION_API_URL}/Stats/mainStats`

            const response = await axios.post(url, props.filter);
            const data = response.data;
            setTotalOpens(data.totalOpens)
            setTotalClicks(data.totalClicks)
            setNumActiveElements(data.numActiveElements)

                    
        } catch (error) {
            console.log(error);
        }  

        }
    const getNumActiveCountries = async () => {
        try {
            const url = `${process.env.REACT_APP_LION_API_URL}/Stats/activeCountries`

            const response = await axios.post(url, props.filter);
            const data = response.data;
            setNumActiveCountries(data)

                    
        } catch (error) {
            console.log(error);
        }  

        }
    const getNumActiveDevices = async () => {
        try {
            const url = `${process.env.REACT_APP_LION_API_URL}/Stats/activeDevices`

            const response = await axios.post(url, props.filter);
            const data = response.data;
            setNumActiveDevices(data)

                    
        } catch (error) {
            console.log(error);
        }  

        }
        

    return <div className='flex flex-row gap-x-0'>
        <CcStatsTile color={ccColorSecondaryPink} title='Image requests'
                                            value={totalOpens}
                                            />
        <CcStatsTile color={ccColorSecondaryGreen} title='Clicks'
                                            value={totalClicks}
                                            />
        <CcStatsTile color={ccColorSecondaryPurple} title='Active Elements'
                                            value={numActiveElements}
                                            />
        <CcStatsTile color={ccColorSecondaryAzure} title='Active Countries'
                                            value={numActiveCountries}
                                            />
        <CcStatsTile color={ccColorSecondaryBronze} title='Active Devices'
                                            value={numActiveDevices}
                                            />

    </div>
}

const OpenBySnippetGrid = (props) => {
    const [data, setData] = useState([]);
    const totalOpensStyle='font-bold'

    const columns = [
        { field: 'id', headerName: 'Element id', width: 80 },
        { field: 'snippetTypeId', headerName: 'Type', width: 80,
        renderCell: (params) => {
            if (params.value == null) return <></>;
            if (params.value === 1) return <Tooltip title="Generator" placement='right'><Iconify icon='carbon:ibm-cloud-pak-business-automation' width={38} className='text-ccColorDarkTeal' /></Tooltip>
            if (params.value === 5) return <Tooltip title="Vote" placement='right'><Iconify icon='mdi:vote' width={38} className='text-ccColorDarkTeal' /></Tooltip>
            if (params.value === 6) return <Tooltip title="Click Counter" placement='right'><Iconify icon='tdesign:gesture-click' width={38} className='text-ccColorDarkTeal' /></Tooltip>
            if (params.value === 7) return <Tooltip title="Mood Meter" placement='right'><Iconify icon='fa:smile-o' width={38} className='text-ccColorDarkTeal' /></Tooltip>
            if (params.value === 9) return <Tooltip title="Data Visual" placement='right'><Iconify icon='bi:images' width={38} className='text-ccColorDarkTeal' /></Tooltip>
            if (params.value === 12) return <Tooltip title="Timer" placement='right'><Iconify icon='ph:timer' width={38} className='text-ccColorDarkTeal' /></Tooltip>
            if (params.value === 13) return <Tooltip title="App Match" placement='right'><Iconify icon='fluent:app-unspan-28-filled' width={38} className='text-ccColorDarkTeal' /></Tooltip>
            return <></>
          }
        },                            
        { field: 'snippetName', headerName: 'Name', width: 200 },
        { field: 'snippetDesc', headerName: 'Description', width: 200 },
        { field: 'created', headerName: 'Created', width: 160, 
          valueFormatter: (params) => {
            if (params.value == null) return '';
            return new Date(Date.parse(params.value)).toLocaleDateString();
          } },
          { field: 'labels', headerName: 'Labels', width: 200,
            renderCell: (params) => {
                if (params.value.length === 0) return ''
                return <Stack direction='row' spacing={1}>
                        {params.value.map(l => <Tooltip key={l.labelId} title={l.labelName}><Chip  label={l.labelName} size='small'
                                                sx={{color: 'white', width:70, backgroundColor: lionObjectsColors.Color_SnippetLabel}}/></Tooltip>)}
                </Stack> 
            }
        },
        { field: 'totalOpens', type: 'number', headerName: 'Images', width: 120, cellClassName: 'totalOpens' },
        { field: 'totalClicks', type: 'number', headerName: 'Clicks', width: 120, cellClassName: 'totalClicks' },
        
      ];

    const getData = useCallback(async () => {
        try {
          let url = `${process.env.REACT_APP_LION_API_URL}/Stats/opensBySnippetId`;
          const response = await axios.post(url, props.filter);
    
          // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Snippets`;
          // const response = await fetch(url, { method: "GET", });
          // response.data = await response.json();
    
          setData(TransformDataForGrid(response.data, 'snippetId'));
    
        } catch (error) {
          console.log(error);
        }
    
    
    }
      ,[props.filter])
    
    useEffect(() => {getData()}, [getData]);

    return <div style={{ maxHeight: 400, width: '100%' }}>
            <DataGridPro sx={{backgroundColor: '#fff'}}
             rows={data} columns={columns}
                hideFooter
                />
        </div>

      
}

const themeOriginal = createTheme({
    palette: {
      primary: {
        main: "#cc11aa"
      }
    },
    components: {
      MuiDateRangePickerDay: {
        styleOverrides: {
          day: {
            "&.Mui-selected": {
              backgroundColor: "hotpink"
            }
          },
          rangeIntervalDayHighlightStart: {
            backgroundColor: "hotpink"
          },
          rangeIntervalDayHighlightEnd: {
            backgroundColor: "hotpink"
          },
          rangeIntervalDayHighlight: {
            backgroundColor: "yellow"
          }
        }
      }
    }
  });
  const theme = createTheme({
    palette: {
      primary: {
        main: ccColorSecondaryPink
      }
    },
  });


export default function ReportDashboard() {

    const [enabledProductIds, setEnabledProductIds] = useState([]);
    const {settings, setSettings} = useContext(SettingsContext);
    const [filter, setFilter] = useState({startDate: dayjs().subtract(7, 'day'), endDate: dayjs()})


      
    useEffect(() => {
        // setEnabledProductIds(settings.enabledProductIds);
    }, [settings])

    const handleDateRangeChange = (newRange) => {
        console.log(newRange)
        const newFilter = {...filter}
        //console.log(newRange[1].toJSON())

        if (newRange[0] !== null)
        {
            newFilter.startDate = newRange[0]
        }
        if (newRange[1] !== null)
        {
            newFilter.endDate = newRange[1]
        }
        setFilter(newFilter)
    }

    return <Stack spacing={2}>
            <CcTitle>Reports</CcTitle>
            <div className='flex flex-row items-center gap-x-2'>
                <div className='text-ccColorDarkTeal text-lg'>Select date:</div>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <ThemeProvider theme={theme}>
                    <DateRangePicker sx={{width: 280, }}
                            onChange={handleDateRangeChange}
                            timezone="UTC"
                            value={[filter.startDate, filter.endDate]}
                            slots={{ field: SingleInputDateRangeField }}
                            slotProps={{
                                shortcuts: {
                                  items: dateRangeShortcutsItems,
                                  changeImportance: 'set', //'set' leaves calendar open
                                },
                                actionBar: { actions: [] },
                              }} />
                    </ThemeProvider>
                </LocalizationProvider>
            </div>
            <CcTitle>At a glance</CcTitle>
            <CcMainStatsBar filter={filter} />
{/*
            <Stack direction='row' spacing={3} sx={{width: '100%'}}>
            <LineChartOpens />
            <PieChartOpens />
            <PieChartDeviceType />
            </Stack>
            <Stack direction='row' spacing={3} sx={{width: '100%'}}>
            <PieChartMobileVsNonMobile />
            <PieChartBrowser />
            </Stack>
*/}
            <Divider variant="middle" />
            <CcTitle>Images by Element</CcTitle>
            <OpenBySnippetGrid filter={filter} />

            <Divider variant="middle" />
            <CcTitle>Impressions over time</CcTitle>
            <LineChartOpens filter={filter} />

    </Stack>
}