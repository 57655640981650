import { useNavigate } from 'react-router-dom';
import {SettingsContext} from '../components/userSettingsContext';
import { useState, useEffect, useContext } from "react"

import {CcStepper2, StepperNavButtons} from "../commonComponents/stepper"

import axios from 'axios';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';

import {InfoToolTip, LionLoaderGradient} from '../commonComponents/generalComponents'
import {SnippetCodeViewer} from '../commonComponents/snippetGeneric'
import {InputText, InputURL, FileSelector, Toggle} from '../commonComponents/inputFields'


import { Icon as Iconify } from '@iconify/react';

import { getErrorListFromYupError, getErrorMessage, snippetDataValidationSchemaOSTracker } from '../components/helperErrors'
import { ccColorTeal } from '../components/helpersStyles';



const RuleManager = (props) => {
  //props:
  // action = {href: "http...", imgB64: "sfsf"}


  const handleImageChange = (dataURL) => {
    let imgB64 = ''

    if (dataURL && dataURL !== '') {
      const dataParts = dataURL.split('base64,')
      imgB64 = dataParts[1]
    }
    const newAction = props.action === undefined ? {href: "", imgB64: imgB64} : {...props.action, imgB64: imgB64}

    if (props.onChange) props.onChange(newAction)
  }
  const handleHrefChange = (href) => {
    const newAction = props.action === undefined ? {href: href, imgB64: "", includeMPP: false} : {...props.action, href: href}

    if (props.onChange) props.onChange(newAction)
  }

  const handleMPPChange = (e) => {
    const checked = e.target.checked
    const newAction = props.action === undefined ? {href: "", imgB64: "", includeMPP: checked} : {...props.action, includeMPP: checked}

    if (props.onChange) props.onChange(newAction)
  }

    return <div className='w-full border border-gray-300 text-ccColorDarkTeal rounded p-4'
                style={props.osName === 'iOS' ? {paddingBottom: '24px'} : {}}>
            <div className='flex flex-row gap-x-4 items-center text-lg font-semibold'>
                {props.osName === 'Other' ? <>
                      <div className='mx-[88px]'>Anything else</div>
                  </>
                  :
                  <div className='flex flex-col relative'>
                    <div className='flex flex-row gap-x-4 items-center'>
                      <div>When</div>
                      {props.osName === 'iOS' &&  
                          <img src='/assets/logoIOS.png' alt='iOS logo' width={96} height={96} />
                      }
                      {props.osName === 'Android' &&  
                          <img src='/assets/logoAndroid.png' alt='iOS logo' width={96} height={96} />
                      }
                      {props.osName === 'Desktop' &&  
                          <img src='/assets/logoPC.png' alt='iOS logo' width={96} height={96} />
                      }
                      <div className='mr-5'>is detected</div>
                    </div>
                    {props.osName === 'iOS' && <div className='absolute top-[108px] flex flex-row items-center'>
                      <FormGroup>
                        <FormControlLabel control={<Checkbox sx={{marginLeft: '-10px', '&.Mui-checked': {color: ccColorTeal, },}}
                                                   checked={props.action.includeMPP ? props.action.includeMPP : false}
                                                   onChange={handleMPPChange} />} label={<div className='text-sm font-semibold'>Include MPP in this rule</div>} />
                      </FormGroup>
                      </div>}
                  </div> 
                }

                <Iconify icon='tabler:arrow-big-right-filled' width={64} />
                <div className='flex flex-col ml-2'>
                    <label htmlFor="LinkTo" className="block text-sm font-medium font-poppins leading-6 text-gray-900 mt-1">
                        Link to
                    </label>
                    <div className='w-[520px]'>
                      <InputURL
                            value={props.action ? props.action.href : undefined}
                            onChange={handleHrefChange}
                            error={getErrorMessage(props.validationErrorsList, 'snippetSettingsDefaultHref')}
                      />
                    </div>
                    <label htmlFor="snippetHref" className="block text-sm font-medium font-poppins leading-6 text-gray-900 mt-4">
                          <div>Show this image</div>
                          {getErrorMessage(props.validationErrorsList, 'snippetSettingsDefaultImg') &&
                            <div className='text-sm font-roboto font-semibold text-red-600'>{getErrorMessage(props.validationErrorsList, 'snippetSettingsDefaultImg')}</div>
                          }
                    </label>
                    <FileSelector 
                      onChange={handleImageChange}
                      base64={props.action ? props.action.imgB64 : undefined}
                    />

                </div>

            </div>
    </div>
}

export function OSTrackerElementManager(props) {
  /*
  props:
  templateId: (must be provided for new )
  snippetInfo: (must be provided for edits )

  onChange: returns all the snippet info
  */

  const {settings, setSettings} = useContext(SettingsContext);

  const [snippetName, setSnippetName] = useState('')
  const [snippetDesc, setSnippetDesc] = useState('')
  const [snippetSettings, setSnippetSettings] = useState({iOS: {}, Android:{}, Desktop:{}, Other:{}})


  useEffect(() => {
    if (props.snippetInfo) { //we are in edit
      //console.log(props.snippetInfo)
      setSnippetName(props.snippetInfo.snippetName)
      setSnippetDesc(props.snippetInfo.snippetDesc)
      setSnippetSettings(props.snippetInfo.snippetSettings)
    }
    else {
      
    }

  }, [props.templateId, props.snippetInfo, settings])


  const prepareDataToPost = (updatePropName, updatePropValue) => {
    const result = {snippetId: 0, snippetName: '', snippetDesc: '', 
    labels: [{labelId: 0, labelName: ''}], href: '',
    snippetTypeId: 13,
    filters: [], orderBy: [],
    dataConnectionId: 0, templateId: 0, dataConnectionInfo: {}};

    result.snippetName = snippetName;
    result.snippetDesc = snippetDesc;
    result.snippetSettings = snippetSettings;

    if (updatePropName) {
      result[updatePropName] = updatePropValue;
    }
      //console.log(result)
      return result;
  }

  const handleSnippetNameChange = (newValue) => {
    setSnippetName(newValue)
    if (props.onChange) props.onChange(prepareDataToPost('snippetName', newValue))
  }
  const handleSnippetDescChange = (newValue) => {
    setSnippetDesc(newValue)
    if (props.onChange) props.onChange(prepareDataToPost('snippetDesc', newValue))
  }
const handleRuleChange = (ruleName, action) => {
  const newSnippetSettings = {...snippetSettings, [ruleName]: action}

  setSnippetSettings(newSnippetSettings)
  if (props.onChange) props.onChange(prepareDataToPost('snippetSettings', newSnippetSettings))

}

  return <div className='flex flex-col gap-4 justify-center items-center'>
      <div className=" w-1/2 mt-10 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
    <div className="sm:col-span-3">
      <InputText
              displayName="Name"
              value={snippetName}
              onChange={(val) => {handleSnippetNameChange(val);}}
              error={getErrorMessage(props.validationErrorsList, 'snippetName')}
      />
    </div>

    <div className="sm:col-span-3">
      <InputText
                displayName="Description"
                value={snippetDesc}
                onChange={(val) => {handleSnippetDescChange(val);}}
                error={getErrorMessage(props.validationErrorsList, 'snippetDesc')}
      />
    </div>
  
        </div>

        <RuleManager osName="iOS"
              action={snippetSettings.iOS}
              onChange={(action) => handleRuleChange('iOS', action)}
          />
        <RuleManager osName="Android"
              action={snippetSettings.Android}
              onChange={(action) => handleRuleChange('Android', action)}
         />
        <RuleManager osName="Desktop"
              action={snippetSettings.Desktop}
              onChange={(action) => handleRuleChange('Desktop', action)}
         />
        <RuleManager osName="Other"
              action={snippetSettings.Other}
              onChange={(action) => handleRuleChange('Other', action)}
              validationErrorsList={props.validationErrorsList}
          />
    </div>

}

export default function OSTrackerWorkflow(props) {
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();
    const [templateId, setTemplateId] = useState(null);
    const [templateHasTimeLabels, setTemplateHasTimeLabels] = useState(false); // we check if the selected template contains time labels (by checking its templateJson)
    const [snippetData, setSnippetData] = useState({snippetName: '', snippetDesc: '', snippetSettings: {Other: {}}});


    const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [activeErrorList, setActiveErrorList] = useState([]);
        

    const getSnippetInfo = async (snippetId) => {
      /*
      try {  
        const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/${snippetId}`;
        const response = await axios.get(url);
        const data = response.data;


      const newTemplate = {...selectedTemplate}
      newTemplate.templateId = data.templateId;
      setSelectedTemplate(newTemplate);
      setSnippetName(data.snippetName)
      setSnippetDesc(data.description)
      setSelectedTimerDate(data.timerDate)
      setSelectedTimeZoneId(data.timerDateTimezoneIANA);
      setTimerSettings(JSON.parse(data.timerSetting));

      } catch (error) {
        console.log(error);
      }
      */
    }
    const templateContainsTimeLabels = (templateJson) => {
      let result = false;
      const blocks = templateJson.model.children[0].children;

      blocks.forEach(block => {
        if (block.className === "Text" && block.attrs.id.startsWith('timerLabel')) result = true;
      })
      console.log(result)
      return result;
    }

    const submitDataSnippet = async () => {
      try {
        //console.log(snippetData)
        snippetDataValidationSchemaOSTracker.validateSync(snippetData, {abortEarly: false});
        setActiveErrorList([])
      }
      catch (e) {
        //console.log(JSON.parse(JSON.stringify(e)))
        const errorList = getErrorListFromYupError(e)
        setActiveErrorList(errorList)
        return false;
      }


      setShowLoader(true);
      setSuccessHtmlMsg('');
    
      try {
          let url = `${process.env.REACT_APP_LION_API_URL}/Snippets/create/13`;
          //if (params.snippetId !== null) url = `${process.env.REACT_APP_LION_API_URL}/Snippets/update/13/${params.snippetId}`;
          const dataToPost = {...snippetData, snippetSettings: JSON.stringify(snippetData.snippetSettings)}

          const response = await axios.post(url, dataToPost);
    
          setSuccessHtmlMsg(response.data);
          setShowSuccessMsg(true);
      } catch (error) {
        setShowSuccessMsg(false);
        setShowErrorMsg(true);
        console.log(error);
      }
      setShowLoader(false);
      setSubmitted(true)

      return true;
    }
  const handleElementChange = (snippetInfo) => {
    //console.log(snippetInfo)
    setSnippetData(snippetInfo)

    if (activeErrorList.length > 0) {
      try {
        snippetDataValidationSchemaOSTracker.validateSync(snippetInfo, {abortEarly: false});
        setActiveErrorList([])
      }
      catch (e) {
        const errorList = getErrorListFromYupError(e)
        setActiveErrorList(errorList)
        return;
      }  
    }
  }
      
    const handleNavChange = async(navInfo) => {
      if (navInfo.actionType === 'Submit') {
            const valid = await submitDataSnippet()
            if (valid) setCurrentStep(navInfo.nextIndex);
        }
      else setCurrentStep(navInfo.nextIndex);
      }
    
    const handleSaveExitClicked = () => {
        navigate("/ph/osTracker")
    }
    const handleNextClicked = (tId, tJson) => {
        setTemplateId(tId)
        setTemplateHasTimeLabels(templateContainsTimeLabels(tJson));
        setCurrentStep(2);
    }
    const handleClose = () => {
        navigate("/ph/osTracker")
    }
  

    return <div>
        <CcStepper2
            steps={[{ name: 'Configure Element', href: '#' },
                    { name: 'Get Element', href: '#' },
                ]}
            currentStepIndex={currentStep}
        />
        <div className="border border-2 border-gray-200 rounded-md p-2">

            {currentStep === 0 && <OSTrackerElementManager
                                    onChange={handleElementChange}
                                    validationErrorsList={activeErrorList}
              />}

            {currentStep === 1 && <div className='flex justify-center'>
                {!showLoader && !showSuccessMsg && !showErrorMsg && <div>Confirm</div> }
                {showLoader && <LionLoaderGradient /> }
                {showSuccessMsg && <SnippetCodeViewer numSnippets={1} html={successHtmlMsg} />}      
            </div>}

            {currentStep !== 1 &&
                    <StepperNavButtons totalSteps={2}
                    currentStepNum={currentStep}
                    onChange={handleNavChange}
                    showClose={submitted}
                    onClose={handleClose}
                />
            }
        </div>

    </div>
}