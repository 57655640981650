import { useNavigate } from 'react-router-dom';

import {LionStepper, CcStepper2, StepperNavButtons} from '../commonComponents/stepper'
import {LionCombo, LionListbox} from  '../commonComponents/inputFields'
import {TemplateGalleryViewer} from '../commonComponents/templateGallery'
import {LionLoaderGradient} from '../commonComponents/generalComponents'
import { Icon as Iconify } from '@iconify/react';
import { IconButton } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useEffect, useState } from 'react';
import {SnippetCodeViewer} from '../commonComponents/snippetGeneric'


import { useParams } from "react-router-dom";
import axios from 'axios';

import {TextImagesElementManager} from "../workflows/textImagesWorkflow";

import { getErrorListFromYupError, getErrorMessage, snippetDataValidationSchemaBase } from '../components/helperErrors'

// ----------------------------------------------------------------------



export default function PersAnimationsWizard(props) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState({templateId: undefined});
  const [templateDicJson, setTemplateDicJson] = useState({}); //{templateId: {templateJson}} - we keep a dic with template json, it gets updated at step 1
  const [templateHasTimeLabels, setTemplateHasTimeLabels] = useState(false); // we check if the selected template contains time labels (by checking its templateJson)
  //const [timerSettings, setTimerSettings] = useState({"labels": {"defaultLanguage": "en", "customLanguageMapping":[]}}) //{"labels": {"defaultLanguage": "en", "customLanguageMapping":{}}}
  const [snippetData, setSnippetData] = useState(undefined)


  const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [activeErrorList, setActiveErrorList] = useState([]);


  let params = useParams();
  const navigate = useNavigate();


  useEffect(() => {
      //console.log(params)
    if (params.snippetId !== undefined) {
      getSnippetInfo(params.snippetId)
    }
  }, [params])

  const getSnippetInfo = async (snippetId) => {
    try {  
      const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/${snippetId}`;
      const response = await axios.get(url);
      const data = response.data;

      // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Snippets/info/${snippetId}`;
    //const response = await fetch(url, { method: "GET", });    
    // const data = await response.json();

    const newTemplate = {...selectedTemplate}
    newTemplate.templateId = data.templateId;
    setSelectedTemplate(newTemplate);
    //setSnippetName(data.snippetName)
    //setSnippetDesc(data.description)
    //setSelectedTimerDate(data.timerDate)
    //setSelectedTimeZoneId(data.timerDateTimezoneIANA);
    //setTimerSettings(JSON.parse(data.timerSetting));
      setSnippetData(data);

    } catch (error) {
      console.log(error);
    }
  }
  const getTemplateJson = async (templateId) => {
    try {  
      const url = `${process.env.REACT_APP_LION_API_URL}/Templates/info/${templateId}`;
      const response = await axios.get(url);
      const data = response.data;

      // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Templates/info/${templateId}`;
      // const response = await fetch(url, { method: "GET", });    
      // const data = await response.json();

    const newTemplateDic = {...templateDicJson, [templateId]: data}
    setTemplateDicJson(newTemplateDic);

    setTemplateHasTimeLabels(templateContainsTimeLabels(data))

  } catch (error) {
      console.log(error);
    }
  }

  const handleNavChange = async(navInfo) => {
    if (navInfo.actionType === 'Navigation'){
      setCurrentStepIndex(navInfo.nextIndex);
      if (navInfo.nextIndex === 1) {
        const currentTemplateIds = Object.keys(templateDicJson);
        if (!currentTemplateIds.includes(selectedTemplate.templateId.toString())) {
          getTemplateJson(selectedTemplate.templateId);
        }
        else setTemplateHasTimeLabels(templateContainsTimeLabels(templateDicJson[selectedTemplate.templateId]))
      }
    }
    else if (navInfo.actionType === 'Submit') {
      const valid = await submitData();
      if (!valid) return;
      setCurrentStepIndex(navInfo.nextIndex);
    }
  }

  const handleSelectedTemplateChange = (tInfo) => {
    if (tInfo !== undefined) setSelectedTemplate(tInfo)
    const newSnippetData = {...snippetData, templateId: tInfo.templateId}
    
    setSnippetData(newSnippetData)
  }
  const submitData = async () => {
    try {
      snippetDataValidationSchemaBase.validateSync(snippetData, {abortEarly: false});
      setActiveErrorList([])
    }
    catch (e) {
      const errorList = getErrorListFromYupError(e)
      setActiveErrorList(errorList)
      console.log(errorList)
      return false;
    }

    setShowLoader(true);
    setSuccessHtmlMsg('');
  
    try {
        let url = `${process.env.REACT_APP_LION_API_URL}/Snippets/create/ca`;
        if (params.snippetId !== null) url = `${process.env.REACT_APP_LION_API_URL}/Snippets/update/14/${params.snippetId}`;
    
        const response = await axios.post(url, snippetData);
        // const response = await fetch(url, { method: "POST", body: JSON.stringify(dataToPost) });    
        // response.data = await response.json();
  
        setSuccessHtmlMsg(response.data);
        setShowSuccessMsg(true);
    } catch (error) {
      setShowSuccessMsg(false);
      setShowErrorMsg(true);
      console.log(error);
    }
    setShowLoader(false);
    setSubmitted(true);
    return true;
  }

  const templateContainsTimeLabels = (templateJson) => {
    let result = false;
    const blocks = templateJson.model.children[0].children;

    blocks.forEach(block => {
      if (block.className === "Text" && block.attrs.id.startsWith('timerLabel')) result = true;
    })
    return result;
  }

  const handleElementChange = (snippetInfo) => {
    //console.log(snippetInfo)
    setSnippetData(snippetInfo)
    if (activeErrorList.length > 0) {
      try {
        snippetDataValidationSchemaBase.validateSync(snippetInfo, {abortEarly: false});
        setActiveErrorList([])
      }
      catch (e) {
        const errorList = getErrorListFromYupError(e)
        setActiveErrorList(errorList)
        return;
      }  
    }

  }
  const handleClose = () => {
    navigate("/ph/persAnimation")
  }

 return (
  <>
    <div className='border border-gray-300 rounded-md shadow-md p-1'>
    <div className='flex flex-row-reverse'>
      <IconButton variant="contained" sx={{color: '#d1d5db'}} onClick={handleClose} >
        <Iconify icon="material-symbols-light:cancel-outline" width="44px" color='#d1d5db' />
      </IconButton>

    </div>
    <CcStepper2
        steps={[{ name: 'Template selection' }, { name: 'Configure' }, { name: 'Finish' }]}
        currentStepIndex={currentStepIndex}
    />

      {currentStepIndex === 0 && <div>
        <TemplateGalleryViewer productTypeId={14}
        onChange={handleSelectedTemplateChange}
        selectedTemplateId={selectedTemplate.templateId}
        />
      </div>
      }

    {currentStepIndex === 1 && 
        <TextImagesElementManager
            onChange={handleElementChange}
            snippetInfo={snippetData}
            templateHasTimeLabels={templateHasTimeLabels}
            validationErrorsList={activeErrorList}
        />
      
      
    }

    {currentStepIndex === 2 && <div className='flex justify-center'>
      {!showLoader && !showSuccessMsg && !showErrorMsg && <div>Confirm</div> }
      {showLoader && <LionLoaderGradient /> }
      {showSuccessMsg && <SnippetCodeViewer numSnippets={1} html={successHtmlMsg} />}

    
    </div>
    }

    <StepperNavButtons totalSteps={3}
        currentStepNum={currentStepIndex}
        onChange={handleNavChange}
        showClose={submitted}
        onClose={handleClose}
    />
    </div>

  </>
);
}
