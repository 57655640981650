import { useEffect, useState, useContext } from 'react';
import {Button, Chip, Popper, Box, Typography,
    FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Paper, Popover,
     Select, Snackbar, TextField, Tooltip, Stack} from '@mui/material';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';


import { Theme, useTheme } from '@mui/material/styles';
import {lionObjectsColors}  from './helpersStyles';
import {lionIcons}  from './helpersIcons';

import { Icon as Iconify } from '@iconify/react';

// import {store} from '../redux/store'
// import { setLabels } from '../redux/slices/userSettings';
import {SettingsContext} from '../components/userSettingsContext'

//import {makeStyles} from '@mui/styles';

export function LabelManager(props) {
    const [snippetId, setSnippetId] = useState(0); // for legacy the name is snippetId. It is now used for different products, although with the same name (snippet)
    // const [labels, setLabels] = useState([]); // currently selected labels
    const [labelIds, setLabelIds] = useState([]); // currently selected label Ids
    const [allLabels, setAllLabels] = useState([]); // all labels from user settings
    const [newLabelName, setNewLabelName] = useState('');
    const {settings, setSettings} = useContext(SettingsContext);

    useEffect(() => {
      
      async function getDataFromStore() {
        let _settings = [];
        if (props.product === undefined) _settings = await settings.labels;
        else {
          switch (props.product) {
            case "snippet":
              _settings = await settings.labels;
              break;
            case "template":
              _settings = await settings.labelTemplates;
              break;
            default:
              break;
          }
        }

        if (!Array.isArray(_settings)) setAllLabels([])
        else setAllLabels(_settings);
      };
      getDataFromStore();
    }, [settings]);


    useEffect(() => {
        setSnippetId(props.data.snippetId)
        // setLabels(props.data.data)
        if (props.data.data !== undefined) setLabelIds(props.data.data.map(l => l.labelId))
    }, [props.data])

const theme = useTheme();
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(id, labelIds, theme) {
  return {
    fontWeight:
    labelIds.findIndex(l => l === id) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const handleLabelChange = (event) => {
  console.log(event.target.value)
  setLabelIds(event.target.value)
  const outputLabels = prepareOutputLabels(event.target.value, allLabels);
  if (props.onChange !== undefined) props.onChange(outputLabels);

};
const addNewLabel = (name) => {
  // first we check if name already exists
  const labelExists = allLabels.find(l => l.labelName === name);

  if (labelExists === undefined) {
    // new name -> we create the label and added it as selected
    const tempId = Math.floor(Math.random() * 1000000) * -1; // fake id negative
    const newAllLabels = [...allLabels, {labelId: tempId, labelName: name}]
    setAllLabels(newAllLabels);
  
    const newLabelIds = [...labelIds, tempId];
    setLabelIds(newLabelIds);  
    const outputLabels = prepareOutputLabels(newLabelIds, newAllLabels);
    if (props.onChange !== undefined) props.onChange(outputLabels);
  }
  else 
    // existing name: no need to add a new label and we set it as selected only if itsn't already selected
    if (labelIds.indexOf(labelExists.labelId) === -1) {
      const newLabelIds = [...labelIds, labelExists.labelId];
      setLabelIds(newLabelIds);  
      const outputLabels = prepareOutputLabels(newLabelIds, allLabels);
      if (props.onChange !== undefined) props.onChange(outputLabels);  
    }
  
  
}

const prepareOutputLabels = (labelIds, allLabels) => {
  console.log(labelIds)
  console.log(allLabels)
  // function to prepare a labels array to be passed to the onChange event
  const outputLabels = labelIds.map(id => {
    const labelInfo = allLabels.find(l => l.labelId === id)
    return {labelId: id, labelName: labelInfo.labelName}
  })
  return  outputLabels;
}


    if (props.compact) return <Stack spacing={1} direction='row' display='flex' alignItems='center' >    
    <FormControl sx={{ m: 0, width: 350 }}>
        <InputLabel sx={{backgroundColor: '#fff', pr:1}} id="demo-multiple-chip-label">Edit labels for this Element</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={labelIds}
          onChange={handleLabelChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => {
                const labelInfo = allLabels.find(l => l.labelId === value)
                return labelInfo ? <Chip key={value} label={labelInfo.labelName}
                          sx={{color: 'white', backgroundColor: lionObjectsColors.Color_SnippetLabel}} /> : <></>
          })}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {(allLabels !== undefined && allLabels.length > 0) && allLabels.map((l) => (
            <MenuItem
              key={l.labelId}
              value={l.labelId}
              style={getStyles(l.labelId, labelIds, theme)}
            >
              {l.labelName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField 
            label='Add new label'
            value={newLabelName}
            onChange={(e) => setNewLabelName(e.target.value)}
    />

    <IconButton 
        disabled={newLabelName.length === 0}
        size="small"
        color="inherit"
        onClick={() => addNewLabel(newLabelName)}
    >
    <Iconify icon={lionIcons.Icon_Actions_TickOutlined} />
    </IconButton>

    </Stack>
    return <Stack spacing={1} >
      <Typography>Edit labels for this Element</Typography>
      {allLabels.length > 0 &&
      <FormControl sx={{ m: 1, width: 350 }}>
          <InputLabel sx={{background: 'white', color:'#17425d', pr:1, '&.MuiInputLabel-root.Mui-focused': {color: '#357e86'}}}>Labels</InputLabel>
          <Select
            sx={{"&.MuiOutlinedInput-root": {
              /*"& fieldset": {
                borderColor: '#17425d'
              },*/
              "&:hover fieldset": {
                borderColor: "#17425d"
              },
              "&.Mui-focused fieldset": {
                borderColor: "#357e86"
              }
            }        
          }}
            id="demo-multiple-chip"
            multiple
            value={labelIds}
            onChange={handleLabelChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => {
                  const labelInfo = allLabels.find(l => l.labelId === value)
                  return labelInfo ? <Chip key={value} label={labelInfo.labelName}
                            sx={{color: 'white', backgroundColor: lionObjectsColors.Color_SnippetLabel}} /> : <></>
            })}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {(allLabels !== undefined && allLabels.length > 0) && allLabels.map((l) => (
              <MenuItem
                key={l.labelId}
                value={l.labelId}
                style={getStyles(l.labelId, labelIds, theme)}
              >
                {l.labelName}
              </MenuItem>
            ))}
          </Select>
      </FormControl>
      }
      <Grid2 container display="flex" justifyContent="center" alignItems="center">
        <Grid2 xs={4}>
          <Typography>Add new label</Typography>
        </Grid2>
        <Grid2 xs={6}>
          <TextField 
                  //sx={{fieldset: { borderColor: "red" }}}
                  sx={{"& .MuiOutlinedInput-root": {
                    /*"& fieldset": {
                      borderColor: '#17425d'
                    },*/
                    "&:hover fieldset": {
                      borderColor: "#17425d"
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#357e86"
                    }
                  }}}   
        
                  value={newLabelName}
                  onChange={(e) => setNewLabelName(e.target.value)}
                  size="small"
            />
        </Grid2>
        <Grid2 xs={2}>
          <IconButton 
              disabled={newLabelName.length === 0}
              size="small"
              color="inherit"
              onClick={() => addNewLabel(newLabelName)}
            >
            <Iconify icon={lionIcons.Icon_Actions_TickOutlined} />
          </IconButton>
        </Grid2>
      </Grid2>
    </Stack>
}

export function LabelSelector(props) {
  const [snippetId, setSnippetId] = useState(0); // for legacy the name is snippetId. It is now used for different products, although with the same name (snippet)
  const [labelIds, setLabelIds] = useState([]); // currently selected label Ids
  const [allLabels, setAllLabels] = useState([]); // all labels from user settings
  const [newLabelName, setNewLabelName] = useState('');
  const {settings, setSettings} = useContext(SettingsContext);

  useEffect(() => {
    async function getDataFromStore() {
      let _settings = [];
      if (props.product === undefined) _settings = await settings.labels;
      else {
        switch (props.product) {
          case "snippet":
            _settings = await settings.labels;
            break;
          case "template":
            _settings = await settings.labelTemplates;
            break;
          default:
            break;
        }
      }
      if (_settings !== undefined) setAllLabels(_settings);
    };
    getDataFromStore();
  }, [settings]);


  function LabelSelectorPopover() {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  const handleLabelClick = (labelId) => {
    const newIds = [...labelIds];
    const alreadyClicked = labelIds.includes(labelId);
    if (!alreadyClicked) newIds.push(labelId);

    handleClose();
    setLabelIds(newIds);
    if (props.onChange !== undefined) {
      const result = prepareOutputLabels(newIds, allLabels);
      props.onChange(result)
    }
  
  }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    return (
      <div>        
        <IconButton onClick={handleClick}>
      <Iconify icon="ion:filter-outline" />
      </IconButton>
  
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className='flex flex-col gap-2 p-1'>
          {
            (allLabels !== undefined && allLabels.length > 0) && allLabels.map(l => <div className=' cursor-pointer rounded-md p-2 hover:bg-slate-200' key={l.labelId}
                                onClick={() => handleLabelClick(l.labelId)}
                                >
                                 {l.labelName}
                              </div>)

          }
          </div>
        </Popover>
      </div>
    );
  }

  
const theme = useTheme();
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
PaperProps: {
  style: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    width: 250,
  },
},
};


const prepareOutputLabels = (labelIds, allLabels) => {
// function to prepare a labels array to be passed to the onChange event
const outputLabels = labelIds.map(id => {
  const labelInfo = allLabels.find(l => l.labelId === id)
  return {labelId: id, labelName: labelInfo.labelName}
})
return  outputLabels;
}
const handleDelete = (labelId) => {
  const idIndex = labelIds.findIndex(id => id === labelId);
  if (idIndex === -1) return;

  const newIds = [...labelIds];

  newIds.splice(idIndex, 1);

  setLabelIds(newIds);

  if (props.onChange !== undefined) {
    const result = prepareOutputLabels(newIds, allLabels);
    props.onChange(result)
  }
}


  return <div className=' p-2 w-80 border border-gray-400 rounded-md'>
    <div className='grid grid-cols-12 gap-1'>
      <div className='col-span-10 flex items-center'>
        {labelIds === undefined || labelIds.length === 0 ?
        <div>Filter by Labels</div>
        :
        <div>
          {labelIds.map(id => {
              const labelInfo = allLabels.find(l => l.labelId === id)

              return <Chip key={id} label={labelInfo.labelName}
                          onDelete={() => handleDelete(id)}
              sx={{color: 'white', backgroundColor: lionObjectsColors.Color_SnippetLabel, mt: '4px', mr: '4px'}} />
            })}
        </div>
      }
      </div>
      <div className=' col-span-2'>
        <LabelSelectorPopover />
      </div>
    </div>
  </div>
  
}
