import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { GridApi, DataGridPro, useGridApiRef, GridActionsCellItem } from '@mui/x-data-grid-pro';

import {Button, Chip, Popper, Popover, Box, Typography, 
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Stack, Tooltip} from '@mui/material';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { useState, useEffect, useCallback } from 'react';
// import Paper from 'src/theme/overrides/Paper';
import { Icon as Iconify } from '@iconify/react';


import {TransformDataForGrid}  from './helpers';
import {ccColorDarkTeal, ccColorSecondaryPink, lionObjectsColors}  from './helpersStyles';
import {lionIcons}  from './helpersIcons';
import { CcLoader } from '../commonComponents/generalComponents';
import { CCCustomFooter } from "../components/helperDataGrid";

export function DatafeedPicker(props) {
  const [activeDataConnection, setActiveDataConnection] = useState(undefined);
useEffect(() => {
                  if (props.selectedDataconnectionId !== undefined) {
                    setActiveDataConnection(props.selectedDataconnectionId)
                }
}, [props.selectedDataconnectionId])

  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = Boolean(anchorEl);
  const handleCloseMenu = () => {
    setAnchorEl(null);
    if (props.onDataConnectionChange !== undefined) props.onDataConnectionChange(activeDataConnection);
  };

  const handleClick  = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleRowSelection = (datafeedInfo) => {
    setActiveDataConnection(datafeedInfo);
  }

  const handleDisconnectAndClose = () => {
    setAnchorEl(null);
    setActiveDataConnection(undefined)
    if (props.onDataConnectionChange !== undefined) props.onDataConnectionChange(undefined);
  };


  return <>
  <Button sx={{width: '140px'}} variant="text" size='small'
          color='secondary'
          endIcon={<Iconify icon={activeDataConnection === undefined ? lionIcons.Icon_Databind_disconnected : lionIcons.Icon_Databind_connected} />}
          onClick={handleClick} >{activeDataConnection === undefined ? 'Not connected' : 'Connected'}</Button>
  <Popover      open={openMenu}
                      anchorEl={anchorEl}
                      onClose={handleCloseMenu}
                      anchorOrigin={{
                        vertical: 'bottom',
                      }}
            >
              <Box sx={{width: '800px', p:1}}>
                <Stack direction='row' spacing={2}>
                  <DatafeedGrid selectMode
                                selectedDataconnectionId={props.selectedDataconnectionId}
                                onDatafeedSelected={(datafeedInfo) => handleRowSelection(datafeedInfo)} />
                  <Stack spacing={2} sx={{display: 'flex', alignItems: 'center', justifyContent:'flex-end'}}>
                  <Button sx={{width: '140px'}} variant="outlined" 
                            color='secondary'
                            disabled={activeDataConnection === undefined}
                            onClick={handleCloseMenu} 
                            >Use Selected data connection
                    </Button>
                    <Button sx={{width: '140px'}} variant="outlined" 
                            color='secondary'
                            onClick={handleDisconnectAndClose} 
                            >Disconnect
                    </Button>
                  </Stack>

                </Stack>
              </Box>
  </Popover>
</>


}

export default function DatafeedGrid(props) {
  /*
  props:

  selectMode: displays a reduced set of columns. No edit nor action btn... you can only select
  newElementMode: Only 1 actions button to create a new element from a content source
  */
  const apiRef = useGridApiRef();
  const navigate = useNavigate();

  const allColumns = [
    { field: 'feedname', headerName: 'Feed name', width: props.mini ? 220 : 200 },
    { field: 'description', headerName: 'Description', width: 200 },
    { field: 'created', headerName: 'Created', width: 180,
      valueFormatter: (params) => {
        if (params.value == null) return '';
        return new Date(Date.parse(params.value)).toLocaleString();
      } },
      { field: 'feedType', headerName: 'Type', width: props.mini ? 80 : 180,
      renderCell: (params) => {
        let chipIcon = 'grommet-icons:resources';
        const valueToReturn = params.value.replace('_', ' ');
        let styles = "inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10";
        switch (valueToReturn)
        {
          case 'WEB SCRAPE': 
            chipIcon = 'icon-park-outline:web-page';
            styles = "inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10";
            break;
            case 'CATALOG':
            styles = "inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20";
            chipIcon = 'fluent-mdl2:product-catalog';
            break;
          default:
            break;
        }
        styles = "flex flex-row gap-2 " + styles;
        if (props.mini) return <span className={styles}>
        <Iconify icon={chipIcon} width={20}  />
      </span>
        // else return <Chip sx={{backgroundColor: chipColour, color:'#fff'}} label={valueToReturn} icon={<Iconify icon={chipIcon} style={{color: 'white'}}  />} /> 
        else return <span className={styles}>
                        <Iconify icon={chipIcon} width={20}  />
                        {valueToReturn}
                    </span>
      } ,
      },
                        
    { field: 'endpointURL', headerName: 'URL', width: 200 },
    { field: 'lastUpdated', headerName: 'Last Updated', width: 180,
    valueFormatter: (params) => {
      if (params.value == null) return '';
      return new Date(Date.parse(params.value)).toLocaleString();
    } },
    { field: 'status', headerName: 'Status', width: 80,
    renderCell: (params) => {
      let chipColour = 'green';
      let chipIcon = 'mdi:tick-circle-outline';
      switch (params.value)  {
        case 'ERROR':
        chipColour = 'red'; 
        chipIcon = 'mdi:warning-circle-outline';
        break;
        case 'PENDING CHANGES':
        chipColour = 'orange'; 
        chipIcon = 'mdi:info-circle-outline';
        break;
        default:
          break;
      }
      return <Iconify icon={chipIcon} color={chipColour} width="42px" height="42px" /> } ,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <Tooltip title='Edit content source' placement='top'><GridActionsCellItem icon={<Iconify icon='material-symbols:edit' width={20} />} onClick={() => (editDataConnection(params.id))} label="Edit" disabled={params.row.status === 'PENDING CHANGES'} /></Tooltip>,
        <Tooltip title='Refresh content source' placement='top'><GridActionsCellItem icon={<Iconify icon='mdi:refresh' width={20} />} onClick={() => (refreshDataConnection(params.id))} label="Refresh" /></Tooltip>,
        <Tooltip title='Delete content source' placement='top'><GridActionsCellItem icon={<Iconify icon='material-symbols:delete-outline' width={20} />} onClick={() => deleteDataConnection(params.id)} label="Delete"  /></Tooltip>,
      ]
    }
    
  ];

if (props.compact || props.mini) {
  let index = allColumns.findIndex(c => c.field === 'actions');
  if (index > -1) {
    allColumns.splice(index, 1);
  }
  index = allColumns.findIndex(c => c.field === 'created');
  if (index > -1) {
    allColumns.splice(index, 1);
  }
}
if (props.mini) {
  let index = allColumns.findIndex(c => c.field === 'lastUpdated');
  if (index > -1) {
    allColumns.splice(index, 1);
  }
}


  const selectModeColumns = [
    { field: 'feedname', headerName: 'Feed name', width: 150 },
    { field: 'description', headerName: 'Description', width: 150 },
    { field: 'created', headerName: 'Created', width: 160,
      valueFormatter: (params) => {
        if (params.value == null) return '';
        return new Date(Date.parse(params.value)).toLocaleString();
      } },
      { field: 'feedType', headerName: 'Type', width: 180,
      renderCell: (params) => {
        let chipColour = lionObjectsColors.Color_WebResource;
        let chipIcon = 'grommet-icons:resources';
        const valueToReturn = params.value.replace('_', ' ');
        switch (valueToReturn)
        {
          case 'WEB SCRAPE': 
            chipColour = lionObjectsColors.Color_WebScrape; 
            chipIcon = 'icon-park-outline:web-page';
            break;
            case 'CATALOG': 
            chipColour = lionObjectsColors.Color_Catalog; 
            chipIcon = 'fluent-mdl2:product-catalog';
            break;
          default:
            break;
        }
        return <Chip sx={{backgroundColor: chipColour, color:'#fff'}} label={valueToReturn} icon={<Iconify icon={chipIcon} style={{color: 'white'}}  />} />} ,
      },        
  ];

  const newElementModeColumns = [
    { field: 'feedname', headerName: 'Feed name', width: props.mini ? 200 : 150 },
    { field: 'description', headerName: 'Description', width: 150 },
    { field: 'created', headerName: 'Created', width: 160,
      valueFormatter: (params) => {
        if (params.value == null) return '';
        return new Date(Date.parse(params.value)).toLocaleString();
      } },
      { field: 'feedType', headerName: 'Type', width: props.mini ? 80 : 180,
      renderCell: (params) => {
        let chipIcon = 'grommet-icons:resources';
        const valueToReturn = params.value.replace('_', ' ');
        let styles = "inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10";
        switch (valueToReturn)
        {
          case 'WEB SCRAPE': 
            chipIcon = 'icon-park-outline:web-page';
            styles = "inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10";
            break;
            case 'CATALOG':
            styles = "inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20";
            chipIcon = 'fluent-mdl2:product-catalog';
            break;
          default:
            break;
        }
        styles = "flex flex-row gap-2 " + styles;
        if (props.mini) return <span className={styles}>
        <Iconify icon={chipIcon} width={20}  />
      </span>
        // else return <Chip sx={{backgroundColor: chipColour, color:'#fff'}} label={valueToReturn} icon={<Iconify icon={chipIcon} style={{color: 'white'}}  />} /> 
        else return <span className={styles}>
                        <Iconify icon={chipIcon} width={20}  />
                        {valueToReturn}
                    </span>
      } ,
      },
                        
    { field: 'endpointURL', headerName: 'URL', width: 150 },
    { field: 'lastUpdated', headerName: 'Last Updated', width: 160,
    valueFormatter: (params) => {
      if (params.value == null) return '';
      return new Date(Date.parse(params.value)).toLocaleString();
    } },
    { field: 'status', headerName: 'Status', width: 60,
    renderCell: (params) => {
      let chipColour = 'green';
      let chipIcon = 'mdi:tick-circle-outline';
      switch (params.value)  {
        case 'ERROR':
        chipColour = 'red'; 
        chipIcon = 'mdi:warning-circle-outline';
        break;
        case 'PENDING CHANGES':
        chipColour = 'orange'; 
        chipIcon = 'mdi:info-circle-outline';
        break;
        default:
          break;
      }
      return <Iconify icon={chipIcon} color={chipColour} width="42px" height="42px" /> } ,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <Tooltip title='Create new element from this content source' placement='right'><GridActionsCellItem icon={<Iconify icon='fluent:new-20-filled' width={20} />} onClick={() => createElementFromContentSource(params.id) } label="New from content source"  /></Tooltip>,
      ]
    }
    
  ];
const createElementFromContentSource = (dataConId) => {
  window.location.href = `/ph/generatorFromContentSource/${dataConId}`;
  //navigate(`/ph/generatorFromContentSource/${dataConId}`)
}

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(allColumns);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupValue, setPopupValue] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({action: '', id:'', title:'', desc:''});
  const [dataIsLoading, setDataIsLoading] = useState(false)

  useEffect(() => {
    if (props.selectedDataconnectionId !== undefined) {
      apiRef.current.selectRow(props.selectedDataconnectionId);
    }
  }, [props.selectedDataconnectionId])

const getData = useCallback(async () => {
    try {
      setDataIsLoading(true)
      const url = `${process.env.REACT_APP_LION_API_URL}/Feeds`;
      const response = await axios.get(url);

      // const url = `api/Feeds`;
      //const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Feeds`;

      //const response = await fetch(url, { method: "GET", });
  
      //const data = await response.json();
      const data = response.data;

      setData(TransformDataForGrid(data, 'feedId'));

    } catch (error) {
      console.log(error);
    }
    finally {setDataIsLoading(false)}
  }
  ,[])

  useEffect(() => {getData()}, [getData]);
  useEffect(() => {
    if (props.refreshData === true) {getData();
      // console.log('refresh yes')
    }
    else {
      // console.log('refresh no')
    }
  
  }, [props.refreshData]);

  useEffect(() => {
    if (props.selectMode) setColumns(selectModeColumns);
    else if (props.newElementMode) setColumns(newElementModeColumns)
    else setColumns(allColumns);
  }, [props.selectMode, props.newElementMode])
  
const editDataConnection = (id) => {
  // alert(id);
  props.editCall(id);
}
const refreshDataConnection = async(id) => {
  const dialogNewData = {action: 'delete', id:id, title:'Refresh in progress', desc:'it may take a few minutes for your feed to update', showCloseButton: true};
  setDialogData(dialogNewData);
  setDialogOpen(true);

  try {
    const url = `${process.env.REACT_APP_LION_API_URL}/Internal/lambda/synch/${id}`;
    const response = axios.get(url);
        
  } catch (error) {
    console.log(error);
  }

}

const deleteDataConnection = (dataConnectionId) => {
  // we open the confirmation dialog
  const dialogNewData = {action: 'delete', id:dataConnectionId, title:'Delete Generator Content Source', desc:'are you sure you want to delete this content source?'};
  setDialogData(dialogNewData);
  setDialogOpen(true);
}

    const rows = [
        { id: 1, col1: 'Hello', col2: 'World', colAspettoCustomizzato: 'Ciao' },
        { id: 2, col1: 'DataGridPro', col2: 'is Awesome', colAspettoCustomizzato: 'Ciaone' },
      ];


      const handlePopoverOpen = (event) => {
        const field = event.currentTarget.dataset.field;
        const id = event.currentTarget.parentElement.dataset.id;
        const row = data.find(r => (r.id.toString() === id));

        // special case for the errorMsg column
        if (field === 'status' && (row.status === 'ERROR' || row.status === 'PENDING CHANGES')) {
          setPopupOpen(row[field] != null);

          if (row.status === 'ERROR') setPopupValue(`Error: ${row.lastErrorMsg}`);
          else setPopupValue(`${row.status}`);
          setAnchorEl(event.currentTarget);
  
          return;
        }

        // Normal case
        setPopupOpen(isOverflown(event.currentTarget.firstChild) || (props.mini && field == "feedType")); //we want to open on feedType when the grid is in "mini" mode

        setPopupValue(row[field]);
        setAnchorEl(event.currentTarget);
      };
      const handlePopoverClose = () => {setPopupOpen(false)};

      
      function isOverflown(element) {
        return (
          element.scrollHeight > element.clientHeight ||
          element.scrollWidth > element.clientWidth
        );
      }
      
const deleteFeed = async (feedId) => {
  try {
    const url = `${process.env.REACT_APP_LION_API_URL}/Feeds/delete/${feedId}`;
    const response = await axios.get(url);

    //const url = `api/Feeds/delete/${feedId}`;
    //const response = await fetch(url, { method: "GET", });

    // const data = await response.json();

        
  } catch (error) {
    console.log(error);
  }

}      
const handleDialogClose = (action) => {
  if (action === 'delete') {
      deleteFeed(dialogData.id);

    // re-download data to refresh the grid
    getData();

  }

  setDialogData({action: '', id:'', title:'', desc:''});

  setDialogOpen(false);
}

const NoRowsCustomMessage = (props) => {
  return <div className='flex flex-col gap-6 items-center mt-16'>
            <div>No data connections found in your account yet</div>
            <button type="button" className={`focus:outline-none text-white bg-ccColorSecondaryPink hover:bg-ccColorSecondaryPurple font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900 ${props.className}`}
                    onClick={() => {if (props.onCreateFirstDataConnectionClicked) props.onCreateFirstDataConnectionClicked()}}
            >
              <div className='flex flex-row gap-2 items-center font-poppins'>
                <Iconify icon={lionIcons.Icon_Actions_Add} width={26} />
                Create new content connection
              </div>
            </button>
          </div>
}
const CustomLoader = () => {
  return <div className='flex flex-col gap-4 items-center justify-center mt-16'>
    <CcLoader />
    <div>Loading...</div>
  </div>
}

    return (
        <>
            
            <DataGridPro rows={data} columns={columns}
                        loading={dataIsLoading}
                        sx={{
                          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                          },
                        }}
                        apiRef={apiRef}
                  onRowClick={(params) => { if (props.onDatafeedSelected !== undefined) props.onDatafeedSelected(params.row)}}
                 componentsProps={{
                                  cell: {
                                    onMouseEnter: handlePopoverOpen,
                                    onMouseLeave: handlePopoverClose,
                                  },                         
                                  }} 
                  slots={{
                    noRowsOverlay: NoRowsCustomMessage,
                    loadingOverlay: CustomLoader,
                    footer: CCCustomFooter,
                  }}
                  slotProps={{
                    noRowsOverlay: {onCreateFirstDataConnectionClicked: props.onCreateFirstDataConnectionClicked},
                    footer: { counter: data.length, titleCounter: "Number of Generator Content Connections" },
                  }}  
            />

<Dialog onClose={() => handleDialogClose('abort')} open={dialogOpen}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description">
      <DialogTitle id="dialog-title" sx={{color: ccColorDarkTeal}}>{dialogData.title}</DialogTitle>
      <DialogContent>
          <DialogContentText id="dialog-description">
            {dialogData.desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialogData.showCloseButton ?
          <Button sx={{color: ccColorSecondaryPink}} onClick={() => handleDialogClose('abort')}>Close</Button>
            :
            <>
            <Button sx={{color: ccColorSecondaryPink}} onClick={() => handleDialogClose('abort')}>Cancel</Button>
            <Button sx={{color: ccColorSecondaryPink}} onClick={() => handleDialogClose('delete')} autoFocus>
              Confirm
            </Button>
            </>
          }
        </DialogActions>

</Dialog>
        
<Popper classes={{ paper: "MuiPopover-paper" }}
        sx={{
          pointerEvents: 'none',
        }}
        open={popupOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
          <Typography sx={{ p: 1 }}>{popupValue}</Typography>
          </Box>
      </Popper>

            

        </>

    )
}