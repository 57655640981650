import { useNavigate } from 'react-router-dom';

import {LionStepper, StepperNavButtons, CcStepper2} from '../commonComponents/stepper'
import {InputText, LionListbox, Toggle} from  '../commonComponents/inputFields'
import {TemplateGalleryViewer} from '../commonComponents/templateGallery'
import {LionLoaderGradient} from '../commonComponents/generalComponents'
import { Icon as Iconify } from '@iconify/react';
import { IconButton } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useEffect, useState } from 'react';
import {SnippetCodeViewer} from '../commonComponents/snippetGeneric'

import { useParams } from "react-router-dom";
import axios from 'axios';

import {TextImagesElementManager} from "../workflows/textImagesWorkflow";
import { getErrorListFromYupError, getErrorMessage, snippetDataValidationSchemaBase } from '../components/helperErrors'


// ----------------------------------------------------------------------

export const TimerLanguageManager = (props) => {
  const [settings, setSettings] = useState({"labels": {"defaultLanguage": "en", "customLanguageMapping":[{}]}})
  const [languages, setLanguages] = useState([{id: '', name: ''}]); //languages in the right format for the LionListbox
  const [defaultLanguage, setDefaultLanguage] = useState(undefined); //languages in the right format for the LionListbox
/*
props:
settings: the object the the language settings
allLanguages:

onChange
*/
  useEffect(() => {
    if (props.allLanguages !== undefined) {
      const langs = props.allLanguages.map(l => {return {id: l.languageCode, name: l.displayName}})
      setLanguages(langs)
    }

    if (props.settings !== undefined) {
      setSettings(props.settings);
      if (props.settings.labels !== undefined) setDefaultLanguage(props.settings.labels.defaultLanguage) 
    }
  }, [props.settings, props.allLanguages])

  const handleMappingChange = (code, mapping) => {
    const currentMatch = settings.labels.customLanguageMapping.find(lm => lm.code === code);
    let newMappings = [...settings.labels.customLanguageMapping];


    if (currentMatch === undefined) {
      newMappings.push({'code': code, 'mapping': mapping})
    }
    else {
      newMappings.find(m => m.code === code).mapping = mapping
    }
    const newLabels = {...settings.labels, customLanguageMapping: newMappings}
    const newSettings = {...settings, labels: newLabels};
    setSettings(newSettings)

    if (props.onChange !== undefined) props.onChange(newSettings);
  }
  const handleDefaultLanguageChange = (lang) => {
    const newLabels = {...settings.labels, defaultLanguage: lang.id}
    const newSettings = {...settings, labels: newLabels}

    setSettings(newSettings)
    setDefaultLanguage(lang.id)

    if (props.onChange !== undefined) props.onChange(newSettings);

  }

  return <div className='flex flex-col gap-4'>
           <div className="text-sm font-semibold leading-6 text-gray-900">
              Timer Label Settings</div>

          <div className='flex flex-col'>
            <div className="text-sm font-medium leading-6 text-gray-900">
              Default language</div>
            <LionListbox items={languages}
                  selectedItemId={defaultLanguage}
                  onChange={handleDefaultLanguageChange}
            />
          </div>
          <div className='flex flex-col'>
            <div className="text-sm font-medium leading-6 text-gray-900">
              Custom mapping (optional)</div>
          </div>
          {languages.map(l => {
            let currentMatch = undefined;
            if (settings.labels !== undefined) currentMatch = settings.labels.customLanguageMapping.find(lm => lm.code === l.id);
            const currentMapping = currentMatch === undefined ? '' : currentMatch.mapping;
            return <div key={l.id} className='flex flex-row items-center gap-2'>
            <div>{l.name}</div>
            <Iconify icon="fluent:arrow-fit-16-regular" width={26} />
            <input
                        type="text"
                        value={currentMapping}
                        onChange={(e) => handleMappingChange(l.id, e.target.value)}
                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />

          </div>

          })

          }
  </div>
}

export const VotesHrefManager = (props) => {
  const [hrefs, setHrefs] = useState([])

  //used to enter question-specific href

  useEffect(() => {
    const newHrefs = []


    if (props.num && props.hrefs && props.hrefs.length > 0) {      
      for (let i=0; i < props.num; i +=1) newHrefs.push('');
      props.hrefs.forEach((href, index) => newHrefs[index] = href);
    }

    setHrefs(newHrefs)
  }, [props.num, props.hrefs])

  const handleHrefChange = (val, index) => {
    const newHrefs = [...hrefs]
    newHrefs.splice(index, 1, val)

    setHrefs(newHrefs)
  
    if (props.onHrefsChange) props.onHrefsChange(newHrefs)
  }

const handleDisplayMutipleHrefsChange = (checked) => {
  const newHrefs = []

  if (checked) {
    if (props.num) {
      for (let i=0; i < props.num; i +=1) newHrefs.push('');
    }
  }
  setHrefs(newHrefs)

  if (props.onHrefsChange) props.onHrefsChange(newHrefs)

}

  return <div>
                <Toggle displayName="Direct each image to a unique Href"
                    onChange={handleDisplayMutipleHrefsChange}
                    checked={hrefs.length > 0}
                    />

          <div className='mt-2 flex flex-col gap-y-2'>
            {hrefs.map((href, index) =>  <InputText
                  displayName={`${props.hrefName} Href ${index + 1}`}
                  value={href}
                  onChange={(val) => {handleHrefChange(val, index);}}
                  //error={getErrorMessage(props.validationErrorsList, 'href')}
              />              
            )}
          </div>
        </div>

}

export default function VotesWizard(props) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState({templateId: undefined});
  const [templateDicJson, setTemplateDicJson] = useState({}); //{templateId: {templateJson}} - we keep a dic with template json, it gets updated at step 1
  const [templateHasTimeLabels, setTemplateHasTimeLabels] = useState(false); // we check if the selected template contains time labels (by checking its templateJson)
  const [timerSettings, setTimerSettings] = useState({"labels": {"defaultLanguage": "en", "customLanguageMapping":[]}}) //{"labels": {"defaultLanguage": "en", "customLanguageMapping":{}}}
  const [snippetData, setSnippetData] = useState(undefined)
  const [voteHrefs, setVoteHrefs] = useState([])

  const [snippetTypeId, setSnippetTypeId] = useState(5) // this is used to share this component with polls, moodmeter and clickcounter

  const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [activeErrorList, setActiveErrorList] = useState([]);


  let params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.snippetTypeId) setSnippetTypeId(props.snippetTypeId)
  }, [props.snippetTypeId])

  useEffect(() => {
      //console.log(params)
    if (params.snippetId !== undefined) {
      getSnippetInfo(params.snippetId)
    }
  }, [params])

  const getSnippetInfo = async (snippetId) => {
    try {  
      const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/${snippetId}`;
      const response = await axios.get(url);
      const data = response.data;

      // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Snippets/info/${snippetId}`;
    //const response = await fetch(url, { method: "GET", });    
    // const data = await response.json();

    const newTemplate = {...selectedTemplate}
    newTemplate.templateId = data.templateId;
    setSelectedTemplate(newTemplate);
    //setSnippetName(data.snippetName)
    //setSnippetDesc(data.description)
    //setSelectedTimerDate(data.timerDate)
    //setSelectedTimeZoneId(data.timerDateTimezoneIANA);
    //setTimerSettings(JSON.parse(data.timerSetting));
      setSnippetData(data);
      if (data.multipleHrefs && data.multipleHrefs !== null) setVoteHrefs(data.multipleHrefs)


    } catch (error) {
      console.log(error);
    }
  }
  const getTemplateJson = async (templateId) => {
    try {  
      const url = `${process.env.REACT_APP_LION_API_URL}/Templates/info/${templateId}`;
      const response = await axios.get(url);
      const data = response.data;

      // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Templates/info/${templateId}`;
      // const response = await fetch(url, { method: "GET", });    
      // const data = await response.json();

    const newTemplateDic = {...templateDicJson, [templateId]: data}
    setTemplateDicJson(newTemplateDic);

    setTemplateHasTimeLabels(templateContainsTimeLabels(data))

  } catch (error) {
      console.log(error);
    }
  }

  const handleNavChange = async(navInfo) => {
    if (navInfo.actionType === 'Navigation'){
      setCurrentStepIndex(navInfo.nextIndex);
      if (navInfo.nextIndex === 1) {
        const currentTemplateIds = Object.keys(templateDicJson);
        if (!currentTemplateIds.includes(selectedTemplate.templateId.toString())) {
          getTemplateJson(selectedTemplate.templateId);
        }
        else setTemplateHasTimeLabels(templateContainsTimeLabels(templateDicJson[selectedTemplate.templateId]))
      }
    }
    else if (navInfo.actionType === 'Submit') {
      const valid = await submitData();
      if (!valid) return;
      setCurrentStepIndex(navInfo.nextIndex);
    }
  }

  const handleSelectedTemplateChange = (tInfo) => {
    if (tInfo !== undefined) setSelectedTemplate(tInfo)
    const newSnippetData = {...snippetData, templateId: tInfo.templateId}
    
    setSnippetData(newSnippetData)
  }
  const submitData = async () => {
    try {
      snippetDataValidationSchemaBase.validateSync(snippetData, {abortEarly: false});
      setActiveErrorList([])
    }
    catch (e) {
      const errorList = getErrorListFromYupError(e)
      setActiveErrorList(errorList)
      console.log(errorList)
      return false;
    }

    setShowLoader(true);
    setSuccessHtmlMsg('');
  
    try {
        let url = `${process.env.REACT_APP_LION_API_URL}/Snippets/create/ca`;
        if (params.snippetId !== null) url = `${process.env.REACT_APP_LION_API_URL}/Snippets/update/${snippetTypeId}/${params.snippetId}`;
    
        const dataToPost = {...snippetData, multipleHrefs: voteHrefs} // added voteHrefs to this call
        const response = await axios.post(url, dataToPost);
        // const response = await fetch(url, { method: "POST", body: JSON.stringify(dataToPost) });    
        // response.data = await response.json();
  
        setSuccessHtmlMsg(response.data);
        setShowSuccessMsg(true);
    } catch (error) {
      setShowSuccessMsg(false);
      setShowErrorMsg(true);
      console.log(error);
    }
    setShowLoader(false);
    setSubmitted(true);
    return true
  }

  const templateContainsTimeLabels = (templateJson) => {
    let result = false;
    const blocks = templateJson.model.children[0].children;

    blocks.forEach(block => {
      if (block.className === "Text" && block.attrs.id.startsWith('timerLabel')) result = true;
    })
    return result;
  }

  const handleElementChange = (snippetInfo) => {
    //console.log(snippetInfo)
    setSnippetData(snippetInfo)
    if (activeErrorList.length > 0) {
      try {
          snippetDataValidationSchemaBase.validateSync(snippetInfo, {abortEarly: false});
          setActiveErrorList([])
        }
        catch (e) {
          const errorList = getErrorListFromYupError(e)
          setActiveErrorList(errorList)
        }    
  }

  }
  const handleClose = () => {
    switch (snippetTypeId) {
      case 5: 
        navigate("/ph/textImages")
      break;
      case 6: 
        navigate("/ph/clickcounter")
      break;
      case 7: 
        navigate("/ph/moodmeter")
      break;
      default:
        navigate("/ph/textImages")
      break;
    }
  }

  const handleHrefsChange = (hrefs) => {
    //console.log(hrefs)
    setVoteHrefs(hrefs)
  }


 return (
  <>
    <div className='border border-gray-300 rounded-md shadow-md p-1'>
    <div className='flex flex-row-reverse'>
      <IconButton variant="contained" sx={{color: '#d1d5db'}} >
        <Iconify icon="material-symbols-light:cancel-outline" width="44px" color='#d1d5db' />
      </IconButton>

    </div>
    <CcStepper2
      steps={[{ name: 'Template selection', href: '#' },
              { name: 'Configure', href: '#' },
              { name: 'Finish', href: '#' },
          ]}
      currentStepIndex={currentStepIndex}
    />


      {currentStepIndex === 0 && <div>
        <TemplateGalleryViewer productTypeId={snippetTypeId}
        onChange={handleSelectedTemplateChange}
        selectedTemplateId={selectedTemplate.templateId}
        />
      </div>
      }

    {currentStepIndex === 1 && <div>
        <TextImagesElementManager
            onChange={handleElementChange}
            snippetInfo={snippetData}
            templateHasTimeLabels={templateHasTimeLabels}
            validationErrorsList={activeErrorList}
        />
        <div className='flex flex-col items-center mt-4'>
          <div className='w-1/2'>
            <VotesHrefManager
              num={templateDicJson[selectedTemplate.templateId] ? templateDicJson[selectedTemplate.templateId].templates.length : undefined}
              hrefName="Poll option"
              hrefs={voteHrefs}
              onHrefsChange={handleHrefsChange}
            />
          </div>
        </div>
      </div>
    }

    {currentStepIndex === 2 && <div className='flex justify-center'>
      {!showLoader && !showSuccessMsg && !showErrorMsg && <div>Confirm</div> }
      {showLoader && <LionLoaderGradient /> }
      {showSuccessMsg && <SnippetCodeViewer forVote numSnippets={templateDicJson[selectedTemplate.templateId].templates.length}  html={successHtmlMsg} />}

    
    </div>
    }

    <StepperNavButtons totalSteps={3}
        currentStepNum={currentStepIndex}
        onChange={handleNavChange}
        showClose={submitted}
        onClose={handleClose}
    />
    </div>

  </>
);
}
