import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
//
// import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  columsSettings: {},
  anotherProp: {}
};

const slice = createSlice({
  name: 'dataConnections',
  initialState,
  reducers: {
    // Test
    setColumsSettings(state, action) {
      state.columsSettings = action.payload;
    },
    setAnotherProp(state, action) {
      state.anotherProp = action.payload;
    },


  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setColumsSettings,
    setAnotherProp,
} = slice.actions;

// ----------------------------------------------------------------------
