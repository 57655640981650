import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { useEffect, useState } from "react";
import axios from "axios";

import { CcStepper2 } from "../commonComponents/stepper";
import { CcButtonPink } from "../commonComponents/styledTexts";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const loginPageURL = "";

// Retrieve the "payment_intent_client_secret" query parameter appended to
// your return_url by Stripe.js
const clientSecret = new URLSearchParams(window.location.search).get(
  'payment_intent_client_secret'
);

const firstname = new URLSearchParams(window.location.search).get('fName');

const PaymentIntentResult = (props) => {
    const stripe = useStripe();
    //const [message, setMessage] = useState("");
    const [paymentStatus, setPaymentStatus] = useState(""); // can be 1. succeeded, 2. processing, 3. requires_payment_method

    useEffect(() => {
        if (!stripe) {
          return;
        }
    
        const clientSecret = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        );
    
        if (!clientSecret) {
          return;
        }
    
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            setPaymentStatus(paymentIntent.status)
            if (paymentIntent.status === "succeeded") updateEsp(paymentIntent.id);

            //setPaymentStatus("requires_payment_methodxxx")
            /*
          switch (paymentIntent.status) {
            case "succeeded":
              setMessage("Payment succeeded!");
              break;
            case "processing":
              setMessage("Your payment is processing.");
              break;
            case "requires_payment_method":
              setMessage("Your payment was not successful, please try again.");
              break;
            default:
              setMessage("Something went wrong.");
              break;
          }
            */
        });
      }, [stripe]);

    const updateEsp = async (pi) => {
    try {
        const url = `${process.env.REACT_APP_LION_API_URL}/user/updateesp/${pi}`;
        const response = await axios.get(url);
                
    } catch (error) {
        console.log(error);
    }
    }
  
      return <div className="flex justify-center">
        {paymentStatus === 'succeeded' && <div className="mx-[10%]">
            <div class="flex items-center p-4 mb-4 text-base text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
                <div className="flex gap-x-2">
                    <span class="font-semibold">Success</span> 
                    <div>Your payment was successful and your card details have been saved</div>
                </div>
            </div>
            {(props.member === undefined || props.member === false) &&
              <div class="flex items-center justify-center p-4 mt-8 mb-4 border border-gray-200 rounded-lg">
                <div className="flex flex-col">
                    <div className="text-ccColorDarkTeal mb-2">Congratulations {firstname}, your account is ready!</div>
                    <CcButtonPink onClick={() => window.location.href = '../login'}>Login now</CcButtonPink>
                    
                </div>
              </div>
            }
        </div>
        }
        {paymentStatus === 'processing'  && <div className="mx-[10%]">
            <div class="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800" role="alert">
                <div className="flex gap-x-2">
                    <span class="font-semibold">Processing</span> 
                    <div>We are processing your payment details and we will send you an email when everything has been completed</div>
                </div>
            </div>
        </div>
        }
        {paymentStatus === 'requires_payment_method'  && <div className="mx-[10%]">
            <div class="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
                <div className="flex gap-x-2">
                    <span class="font-semibold">Error</span> 
                    <div>Your payment was not successful, please try again.</div>
                </div>
            </div>
        </div>
        }
        {paymentStatus.length > 0 && paymentStatus !== 'succeeded' && paymentStatus !== 'processing' && paymentStatus !== 'requires_payment_method' && <div className="mx-[10%]">
            <div class="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
                <div className="flex gap-x-2">
                    <span class="font-semibold">Error</span> 
                    <div>Something went wrong, please try again.</div>
                </div>
            </div>
        </div>
        }
      </div>
}

export const PageSubscriptionResult = (props) => {

    let options = {
        // passing the SetupIntent's client secret
        //clientSecret: '{{CLIENT_SECRET}}',
        clientSecret: clientSecret,
        // Fully customizable with appearance API.
        appearance: {/*...*/},
      };
  
    
    return <div style={{height: '100vh', backgroundSize: 'cover', backgroundImage: `url("/assets//backgroundDashboardCircles.png")`}}>
          {props.member === undefined  &&
                <div className='flex flex-row items-center w-full gap-20 font-poppins bg-gradient-to-r from-ccColorDarkTeal to-ccColorTeal'>
            <img src='/assets/logoWhite.png' className=' h-20 w-auto ml-8' alt='logo'/>
         </div>
        }

            <div className=" mx-[10%] mb-8">
              {(props.member === undefined || props.member === false) &&
                <CcStepper2
                steps={[{ name: 'Create account', href: '#' },
                        { name: 'Payment options', href: '#' },
                        { name: 'Complete', href: '#' },
                    ]}
                currentStepIndex={2}
                />
              }
            </div>
                <Elements stripe={stripePromise} options={options}>

                    <PaymentIntentResult member={props.member} />
                </Elements>
        </div>
}