import { useState, useEffect, useContext  } from 'react';

import {SettingsContext} from '../components/userSettingsContext';

import { Alert, AlertTitle, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
             IconButton, Tooltip} from '@mui/material';
import axios from 'axios';
import { Icon as Iconify } from '@iconify/react';
import {LionLoaderGradient} from "../commonComponents/generalComponents";
import {LionColorListbox} from "../commonComponents/inputFields";
import {getColorMappingValue} from "../commonComponents/helperSettings";
import { CcActionButton } from '../commonComponents/styledTexts';

const ColorMappingManager = (props) => {    
    /*
    props:

    NO props 
    */
    const {settings, setSettings} = useContext(SettingsContext);
    const [allBlocks, setAllBlocks] = useState([])

    useEffect(() => {
        getBlocksDictionary()
    }, [])

    const getBlocksDictionary = async () => {
        try {
              const url = `${process.env.REACT_APP_LION_API_URL}/Settings/blocksProperties`;
              const response = await axios.get(url);
              const data = response.data;
      
              setAllBlocks(response.data);
        } catch (error) {
          console.log(error);
        }
      }
    const renderMappingList = () => {
        let currentGroupName = '';
        let resultJSX = [];
        let userColors = [{id: "", name: "No mapping"}];
        if (settings.accountSettings !== undefined) Array.prototype.push.apply(userColors, [...settings.accountSettings.brandColors].map(c => {return {id: c.colorCodeHex, name: c.colorCodeHex }}))
        let colorZIndex = 1000;

        //[{id: 'id', name: 'name'}]

        allBlocks.forEach(b => {
            colorZIndex -= 10;
            if (currentGroupName !== b.groupName) {
                resultJSX.push(<><hr className=' mt-2'></hr>
                <div className=' font-semibold'>{b.groupName}</div>
                </>
                )
                currentGroupName = b.groupName
            }
            resultJSX.push(<div className='flex flex-row-reverse items-center gap-2'>
                <div className='w-36'>
                    <LionColorListbox items={userColors}
                                zIndex={colorZIndex}
                                onChange={(newColor) => handleColorMappingChange(b.blockPropertyId, newColor.id)}
                                selectedItemId={getColorMappingValue(settings, b.blockPropertyId)}
                    />
                </div>
                <div>{b.blockPropertyName}</div>
                </div>
                )
        })
        return resultJSX;
    }
    const handleColorMappingChange = async(blockPropertyId, color) => {
        /*
        "blocksPropertiesMappings": [{"blockPropertyId": 1, "propValue": "#000"}]
        */
       const newBlocksPropertiesMappings = [...settings.accountSettings.blocksPropertiesMappings]
        if (color === '') {
            //if an empty string is passed it means we want to remove this mapping
            const matchedIndex = newBlocksPropertiesMappings.findIndex(bp => bp.blockPropertyId === blockPropertyId);
            if (matchedIndex !== -1) {
                newBlocksPropertiesMappings.splice(matchedIndex, 1)         
            }
        }
        else {
            const matchedProp = newBlocksPropertiesMappings.find(bp => bp.blockPropertyId === blockPropertyId);
            if (matchedProp !== undefined) matchedProp.propValue = color;
            else newBlocksPropertiesMappings.push({"blockPropertyId": blockPropertyId, "propValue": color});
        }


       const newAccountSettings = {...settings.accountSettings, blocksPropertiesMappings: newBlocksPropertiesMappings}
       const newSettings = {...settings, accountSettings: newAccountSettings}

       //setShowLoader(true)
       try {
         const url = `${process.env.REACT_APP_LION_API_URL}/User/UpdateAccountSettings`;
 
 
         const response = await axios.post(url, newSettings.accountSettings);
         if (response.data === true) {
           //setShowSuccess(true)
         }
         else {
             //setShowError(true)
         }

       } catch (error) {
         //setShowError(true)
       }
       finally {
         //setShowLoader(false);

       }

       setSettings(newSettings)


    }
    /*
    const getColorMappingValue = (blockPropertyId) => {
        if (settings.accountSettings === undefined) return undefined;

        const matchedProp = settings.accountSettings.blocksPropertiesMappings.find(bp => bp.blockPropertyId === blockPropertyId);
        return (matchedProp === undefined) ? undefined : matchedProp.propValue;
    }
    */

    return <div>
            <div className='flex justify-center mb-2 text-lg text-gray-500'>Manage Colour Mappings</div>
            <div className='flex justify-center mb-2 text-sm text-gray-500'>By creating colour mappings, you can use ‘Quick Design’ features throughout the design process. Don’t worry, you can change these at any stage in the design lab too.</div>
            <div className='flex flex-col gap-1'>
                {renderMappingList()}
            </div>
    </div>
}

export default function PageAdminColors() {
    /*
    props:

    NO props 
    */
    const {settings, setSettings} = useContext(SettingsContext);
    const [addColors, setAddColors] = useState(false);
    const [addColorsTextAreaValues, setAddColorsTextAreaValues] = useState('');
    const [invalidColors, setInvalidColors] = useState([]);
    const [colorIdOnHover, setColorIdOnHover] = useState(0);

    const [showLoader, setShowLoader] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState({action: '', id:'', title:'', desc:''});

    const closeAddColorsForm = () => {
        setAddColors(false);
        setShowError(false)
        setShowSuccess(false)
        setShowLoader(false)
        setAddColorsTextAreaValues('')
    }
    const submitAddColors = async() => {
        setInvalidColors([]);
        const errorColors = []
        const inputColors = addColorsTextAreaValues.split('\n');
        let newcolors = [...settings.accountSettings.brandColors]

        inputColors.forEach(c => {
            if (c === '') return;
            const currentCode = c.startsWith('#') ? c : '#' + c;
            if (/^#[0-9a-f]{3}([0-9a-f]{3})?$/i.test(currentCode)) {
                newcolors.push({colorId: 0, colorCodeHex: currentCode})
            }
            else errorColors.push(c);
        })

        if (errorColors.length > 0) {
            setInvalidColors(errorColors);
            return;
        }
        newcolors = assignMissingColorIds(newcolors);

        const newAccountSettings = {...settings.accountSettings, brandColors: newcolors}
        const newSettings = {...settings, accountSettings: newAccountSettings}

        setShowLoader(true)
        try {
          const url = `${process.env.REACT_APP_LION_API_URL}/User/UpdateAccountSettings`;
  
  
          const response = await axios.post(url, newSettings.accountSettings);
          if (response.data === true) {
            setShowSuccess(true)
          }
          else {
              setShowError(true)
          }

        } catch (error) {
          setShowError(true)
        }
        finally {
          setShowLoader(false);

        }

        setSettings(newSettings)
    }

    const assignMissingColorIds = (colors) => {
        // colorId will be 0 if it's not yet assigned
        // we find the max id used and we increment  from that value
        let maxIdFound = 0;
        colors.forEach(c => {
            if (c.colorId > maxIdFound) maxIdFound = c.colorId;
        })

        let currentId = maxIdFound +1;
        colors.forEach(c => {
            if (c.colorId === 0) {
                c.colorId = currentId;
                currentId += 1;
            }
        })
        return colors;
    }
    const handleDeleteColor = (colorId) => {
        // we open the confirmation dialog
        const dialogNewData = {action: 'delete', id:colorId, title:'Delete Color', desc:'By deleting this color you will lose all mappings associated to this color'};
        setDialogData(dialogNewData);
        setDialogOpen(true);
    }
    const handleDialogClose = (action) => {
    if (action === 'delete') {
        deleteColorCall(dialogData.id);
    
    }
    
    setDialogData({action: '', id:'', title:'', desc:''});
    setDialogOpen(false);
    }

    const deleteColorCall = async (colorId) => {

        let newcolors = [...settings.accountSettings.brandColors]
        const colorIndex = newcolors.findIndex(c => c.colorId === colorId);
        const colorCode = newcolors[colorIndex].colorCodeHex;
        if (colorIndex !== -1) {
            newcolors.splice(colorIndex, 1);
        }
        //we also want to delete any mappings with this color
        const newBlocksPropertiesMappings = settings.accountSettings.blocksPropertiesMappings.filter(cm => cm.propValue !== colorCode);

        const newAccountSettings = {...settings.accountSettings, brandColors: newcolors, blocksPropertiesMappings: newBlocksPropertiesMappings}
        const newSettings = {...settings, accountSettings: newAccountSettings}

        try {
            const url = `${process.env.REACT_APP_LION_API_URL}/User/UpdateAccountSettings`;
    
            const response = await axios.post(url, newSettings.accountSettings);
            if (response.data === true) {
              setShowSuccess(true)
              setSettings(newSettings)
            }
            else {
                setShowError(true)
            }
  
          } catch (error) {
            setShowError(true)
          }
          finally {
            setShowLoader(false);
  
          }
    }
    

    return <div>
        {!addColors && 
            <div className='grid grid-cols-12 gap-x-8'>
            <div className=' col-span-9'>
                <div className='flex flex-col gap-1 w-full'>
                    <div className='text-ccColorDarkTeal text-lg font-semibold'>Brand colours</div>

                    {/*
                    <Tooltip title="Add colors" placement='right' >
                        <IconButton variant="contained" color='primary' sx={{width: 64}} onClick={() => {setAddColors(true); setShowSuccess(false); setShowError(false); setShowLoader(false)}} >
                            <Iconify icon="material-symbols:add-circle" width="48px" />
                        </IconButton>
                    </Tooltip>                    
                    */}
                        <CcActionButton className="w-fit" onClick={() => {setAddColors(true); setShowSuccess(false); setShowError(false); setShowLoader(false)}} >
                        Add brand colours
                        </CcActionButton>

                    {(settings.accountSettings === undefined || settings.accountSettings.brandColors.length === 0) &&
                        <div className=' container mx-auto max-w-96 mt-24 text-2xl text-gray-400'>No colors defined</div>
                    }
                    {(settings.accountSettings !== undefined && settings.accountSettings.brandColors.length > 0) && <div className='grid grid-cols-3 gap-10'>
                        {settings.accountSettings.brandColors.map(c =><div className='flex flex-col relative' 
                                                                            onMouseEnter={() => {setColorIdOnHover(c.colorId)}}
                                                                            onMouseLeave={() => {setColorIdOnHover(0)}}>
                                        {colorIdOnHover === c.colorId &&
                                        <div className=' absolute top-7 right-1'>
                                            <IconButton variant="contained" sx={{width: 48, height:48, backgroundColor: '#fff'}} onClick={() => {handleDeleteColor(c.colorId)}} >
                                                <Iconify icon="material-symbols:cancel-outline" width="48px" />
                                            </IconButton>
                                        </div>
                                        }
                                        <div>{c.colorCodeHex}</div>
                                        <div style={{backgroundColor: c.colorCodeHex}} className=' h-32 cursor-pointer'>
                                        </div>
                                    </div>)}
                    </div>
                    }
                </div>
            </div>
            <div className=' col-span-3'>
                <ColorMappingManager />
            </div>
        </div>
        }
        {addColors && <div className=' rounded shadow-lg shadow-lionGradient2Via p-1'>
            <div className='flex flex-row-reverse'>
            <IconButton variant="contained" color='primary' onClick={() => closeAddColorsForm()}  >
                <Iconify icon="material-symbols:cancel-outline" width="48px" />
            </IconButton>
            </div>
            {!showLoader && !showSuccess && !showError && <div>
                <div className='container mx-auto max-w-md'>
                <Alert severity="info" className=''>
                    <div className='flex flex-col gap-2'>
                        <div>
                            You can add multiple colors, every colors in a new line
                        </div>
                        <div>
                            Supported formats: #fff, #ffffff, with or without the # symbol
                        </div>
                    </div>
                </Alert>                      

                <label for="message" class="block mb-1 mt-5 text-sm font-medium text-gray-900 dark:text-white">Add colors</label>
                <textarea id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                            placeholder={"#ffffff\n#000000"}
                            value={addColorsTextAreaValues}
                            onChange={(e) => setAddColorsTextAreaValues(e.target.value)}
                            >
                </textarea>
                {invalidColors.length > 0 &&
                        <Alert severity="error" className=' mt-2'>
                            <div className='flex flex-col gap-2'>
                                <div>
                                    Invalid colors:
                                </div>
                                {invalidColors.map(c => <div>
                                    {c}
                                </div>)

                                }
                            </div>
                        </Alert>
                }

                </div>
                <div className='flex flex-row-reverse'>
                    <button type="button" class="text-white bg-lionGradient2Via hover:bg-lionGradient2From font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                        onClick={submitAddColors}
                    >Submit</button>
                </div>
            </div>
            }
            {showLoader && <div className=" container max-w-sm mx-auto flex flex-row justify-center mb-8">
                    <LionLoaderGradient /> 
                </div>
              }
            {showSuccess && <div className=" container max-w-md mx-auto flex flex-col justify-center mb-8">
                                <Alert severity="success">
                                <AlertTitle>Success</AlertTitle>
                                <div className='flex flex-col gap-2'>
                                    <div>Colors have been updated</div>
                                </div>
                                </Alert>                      
                            </div>
            }
            {showError && <div className=" container max-w-md mx-auto flex flex-col justify-center mb-8">
                                <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                There was an error updating the colors. Please try again later
                                </Alert>                      
                            </div>
            }
            {(showSuccess || showError) && <div className='flex flex-row-reverse'>
                                <button type="button" class="text-white bg-lionGradient2Via hover:bg-lionGradient2From font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                    onClick={() => closeAddColorsForm()}
                                >Close</button>
                                </div>
            }

        </div>
        }

      <Dialog onClose={() => handleDialogClose('abort')} open={dialogOpen}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description">
      <DialogTitle id="dialog-title">{dialogData.title}</DialogTitle>
      <DialogContent>
          <DialogContentText id="dialog-description">
            {dialogData.desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose('abort')}>Cancel</Button>
          <Button onClick={() => handleDialogClose('delete')} autoFocus>
            Confirm
          </Button>
        </DialogActions>

</Dialog>

    </div>
}