import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userSettingsReducer from './slices/userSettings';
import dataConnectionsReducer from './slices/dataConnections';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  userSettings: userSettingsReducer,
  dataConnections: dataConnectionsReducer,
});

export { rootPersistConfig, rootReducer };
