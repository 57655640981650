
import React from "react";
import { Link } from "react-router-dom";

import {TextField, Container, Box} from '@mui/material'
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { Alert, CircularProgress } from "@mui/material";
import axios from 'axios'
import CssBaseline from '@mui/material/CssBaseline';


import { Icon as Iconify } from '@iconify/react';
import { ccColorDarkTeal, ccColorLightTeal, ccColorSecondaryAzure, ccColorSecondaryBronze, ccColorSecondaryPurple, ccColorTeal } from "../components/helpersStyles";




const ForgotPassword = () => {
  const [loginError, setLoginError] = React.useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = React.useState('');
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

    const { setToken } = useAuth();
    const navigate = useNavigate();

  
    async function sendForgotPasswordEmail(username) {
        setRequestInProgress(true)
      try {
        let url = `${process.env.REACT_APP_LION_API_URL}/Accounts/forgotPassword`;
        let response = await axios.post(url, {loginName: username});

        let data = response.data;
        if (data.success) {
            setSuccess(true);
        }
        else {
            setLoginErrorMsg(data.errorMessage);
            setLoginError(true)
    
        }
              
      } catch (error) {
        setLoginErrorMsg("Ops something went wrong. Please try again later");
        setLoginError(true)
      }
      finally {
        setRequestInProgress(false)
      }
    }


    const handleSubmit = (event) => {
      setLoginError(false)
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      sendForgotPasswordEmail(data.get('user'));


    };

    return (
      <div style={{height: '100vh', backgroundSize: 'cover', backgroundImage: `url("/assets//backgroundDashboardCircles.png")`}}>
          <div className='flex flex-row items-center w-full gap-20 font-poppins bg-gradient-to-r from-ccColorDarkTeal to-ccColorTeal'>
            <img src='/assets/logoWhite.png' className=' h-20 w-auto ml-8' alt='logo'/>
         </div>
         {!success &&
            <div className="flex flex-col justify-center items-center">
            <div className="mt-12 w-12 h-12 rounded-full bg-ccColorDarkTeal flex justify-center items-center"><Iconify icon="ic:outline-lock" color="white" width={32} /></div>
            <div className="mt-2 text-2xl text-ccColorDarkTeal">Forgot password</div>
            <div className="mt-4 text-ccColorDarkTeal">Please insert your username and we will send you an email to reset your password</div>
            
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
            sx={{
                marginTop: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
            sx={{backgroundColor: 'white', "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                borderColor: ccColorTeal
                },
                "&.Mui-focused fieldset": {
                borderColor: ccColorTeal
                }
            },
            "& label.Mui-focused": {
                    color: ccColorTeal
                },
            }}
                margin="normal"
                required
                fullWidth
                id="user"
                label="Username"
                name="user"
                autoComplete="user"
                autoFocus
                onChange={() => setLoginError(false)}
                />

                <button type="submit" className={`mt-6 w-full focus:outline-none text-white bg-ccColorSecondaryPink hover:bg-ccColorSecondaryPurple font-medium rounded-lg text-sm px-5 py-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900`}>
                <div className='flex flex-row gap-2 items-center justify-center font-poppins'>
                    <div className="font-bold">Send</div>
                </div>
                </button>
            </Box>
            {loginError && <Alert severity="error">{loginErrorMsg}</Alert>}
            {requestInProgress && <CircularProgress sx={{color: ccColorDarkTeal}} />}
            </Box>
        </Container>

            </div>
         }
         {
            success && <div className="mt-24 flex flex-col justify-center items-center">
                    <Alert severity="success"><div>
                            <div className="font-bold">Thanks for requesting to reset your password.</div>
                            <div>An email is on its way to you with a magic link to reset your password.</div>
                            {/*<div className="mt-4">Go to the <Link to={`/login`} className=" text-ccColorDarkTeal text-sm font-semibold">Login Page</Link></div>*/}
                        </div></Alert>
                </div>
         }

      </div>
    );

  };

  export default ForgotPassword;