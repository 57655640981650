import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {TimerElementManager} from "../workflows/timerWorkflow"
import {LionLoaderGradient} from '../commonComponents/generalComponents'
import {SnippetCodeViewer} from '../commonComponents/snippetGeneric'

import { getErrorListFromYupError, getErrorMessage, snippetDataTimerValidationSchema } from '../components/helperErrors'
import { CcActionButton } from "../commonComponents/styledTexts";

export default function PageTimerFromTemplate() {
    const [snippetData, setSnippetData] = useState({});

    const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [activeErrorList, setActiveErrorList] = useState([]);
    const [validationDo, setValidationDo] = useState(false); //we use this flag to apply validation only after the submit button has been pressed once

    let params = useParams();
    const navigate = useNavigate();

    const handleClose = () => {
        navigate('/ph/timers')
    }
    const handleTimerElementChange = (snippetInfo) => {
        console.log('entrato')
        setSnippetData(snippetInfo)
        if (validationDo) {
            try {
                snippetDataTimerValidationSchema.validateSync(snippetInfo, {abortEarly: false});
                setActiveErrorList([])
              }
              catch (e) {
                const errorList = getErrorListFromYupError(e)
                setActiveErrorList(errorList)
                //return false;
              }    
        }
    }

const submitDataSnippet = async () => {
    setValidationDo(true)
    try {
        //console.log(snippetData)
        snippetDataTimerValidationSchema.validateSync(snippetData, {abortEarly: false});
        setActiveErrorList([])
        }
        catch (e) {
        //console.log(JSON.parse(JSON.stringify(e)))
        const errorList = getErrorListFromYupError(e)
        setActiveErrorList(errorList)
        return false;
        }

          
    setShowLoader(true);
    setSuccessHtmlMsg('');
    
    try {
        let url = `${process.env.REACT_APP_LION_API_URL}/Snippets/create/12`;
        
        const response = await axios.post(url, snippetData);
    
        setSuccessHtmlMsg(response.data);
        setShowSuccessMsg(true);
    } catch (error) {
        setShowSuccessMsg(false);
        setShowErrorMsg(true);
        console.log(error);
    }
    setShowLoader(false);
    setSubmitted(true)
    }

    return <div>
        {!submitted && !showLoader &&
        <TimerElementManager
            onChange={handleTimerElementChange}
            templateId={params.templateId}
            validationErrorsList={activeErrorList}
        />
        }
        <div className='flex justify-center mt-20 mb-5'>
            {showLoader && <LionLoaderGradient /> }
            {showSuccessMsg && <SnippetCodeViewer numSnippets={1} html={successHtmlMsg} />}      
        </div>        

        <div className='flex flex-col gap-4 justify-center items-center'>
            {!submitted && !showLoader &&  <div className="w-1/2 flex flex-row-reverse">
                <CcActionButton type="button" onClick={submitDataSnippet} >Submit</CcActionButton>
                </div>
            }
            {submitted &&  <div className="w-1/2 flex flex-row-reverse">
                <CcActionButton type="button" onClick={handleClose} >Close</CcActionButton>
                </div>
            }
        </div>
    </div>
}
