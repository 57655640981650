import { useNavigate } from 'react-router-dom';
import {SettingsContext} from '../components/userSettingsContext';
import { useState, useEffect, useContext } from "react"

import PersAnimationsPreEditor from '../preEditors/persAnimationsPreEditor';

import {CcStepper2, StepperNavButtons} from "../commonComponents/stepper"
import TemplateEditor from '../components/templateEditor';

import dayjs from 'dayjs';
import axios from 'axios';

import {InfoToolTip, LionLoaderGradient} from '../commonComponents/generalComponents'
import {SnippetCodeViewer} from '../commonComponents/snippetGeneric'
import { InputText } from '../commonComponents/inputFields';

import { getErrorListFromYupError, getErrorMessage, snippetDataValidationSchemaBase } from '../components/helperErrors'


export function PersAnimationsElementManager(props) {
  /*
  props:
  templateId: (must be provided for new )
  snippetInfo: (must be provided for edits )

  onChange: returns all the snippet info
  validationErrorsList: 
  */

  const {settings, setSettings} = useContext(SettingsContext);

  //const [timerSettings, setTimerSettings] = useState({ "timerType": "countdown", "hrefAfterExpiry": ""}) //"labels": {"defaultLanguage": "en", "customLanguageMapping":[]} LABELS NOT USED ANYMORE
  const [snippetName, setSnippetName] = useState('')
  const [snippetDesc, setSnippetDesc] = useState('')
  const [snippetHref, setSnippetHref] = useState('')
  //const [selectedTimeZoneId, setSelectedTimeZoneId] = useState("Europe/London");
  //const [selectedTimerDate, setSelectedTimerDate] = useState(dayjs().toJSON());
  const [templateId, setTemplateId] = useState(undefined);


  useEffect(() => {
    if (props.templateId === undefined && props.snippetInfo === undefined && settings === undefined) return;

    if (props.templateId) setTemplateId(props.templateId)
    if (props.snippetInfo) { //we are in edit
      setSnippetName(props.snippetInfo.snippetName)
      setSnippetDesc(props.snippetInfo.snippetDesc)
      //setSelectedTimerDate(props.snippetInfo.timerDate)
      //setSelectedTimeZoneId(props.snippetInfo.timerDateTimeZone)
      setSnippetHref(props.snippetInfo.href)
      //setTimerSettings(JSON.parse(props.snippetInfo.timerSettings))
      setTemplateId(props.snippetInfo.templateId)
    }
    else {
    }

  }, [props.templateId, props.snippetInfo, settings])


  const prepareDataToPost = (updatePropName, updatePropValue) => {
    const result = {snippetId: 0, snippetName: '', snippetDesc: '', 
    labels: [{labelId: 0, labelName: ''}], href: '',
    snippetTypeId: 12,
    filters: [], orderBy: [],
    dataConnectionId: 0, templateId: 0, dataConnectionInfo: {}};
    result.templateId = templateId;
    result.snippetName = snippetName;
    result.snippetDesc = snippetDesc;
    result.href = snippetHref;
    //result.timerDate = selectedTimerDate;
    //result.timerDateTimeZone = selectedTimeZoneId;
    //result.timerSettings = newTimerSettings ? JSON.stringify(newTimerSettings) : JSON.stringify(timerSettings);

    if (updatePropName) {
      result[updatePropName] = updatePropValue;
      //console.log(result)
  }
    return result;
  }

  const handleSnippetNameChange = (newValue) => {
    setSnippetName(newValue)
    if (props.onChange) props.onChange(prepareDataToPost('snippetName', newValue))
  }
  const handleSnippetDescChange = (newValue) => {
    setSnippetDesc(newValue)
    if (props.onChange) props.onChange(prepareDataToPost('snippetDesc', newValue))
  }
  const handleSnippetHrefChange = (newValue) => {
    
    setSnippetHref(newValue)
    if (props.onChange) props.onChange(prepareDataToPost('href', newValue))
  }

  return <div>
  <div className='flex flex-col gap-4 justify-center items-center'>
      <div className=" w-1/2 mt-10 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
    <div className="sm:col-span-3">
      <InputText
                displayName="Code name"
                value={snippetName}
                onChange={(val) => {handleSnippetNameChange(val);}}
                error={getErrorMessage(props.validationErrorsList, 'snippetName')}
            />
    </div>

    <div className="sm:col-span-3">
      <InputText
                displayName="Description"
                value={snippetDesc}
                onChange={(val) => {handleSnippetDescChange(val);}}
                error={getErrorMessage(props.validationErrorsList, 'snippetDesc')}
            />
    </div>


    <div className="sm:col-span-6">
      <InputText
                displayName="Href"
                value={snippetHref}
                onChange={(val) => {handleSnippetHrefChange(val);}}
                error={getErrorMessage(props.validationErrorsList, 'href')}
            />
    </div>

  

</div>

</div>
  </div>
}

export default function PersAnimationsWorkflow(props) {
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();
    const [preEditorProps, setPreEditorProps] = useState({});
    const [templateId, setTemplateId] = useState(null);
    const [templateHasTimeLabels, setTemplateHasTimeLabels] = useState(false); // we check if the selected template contains time labels (by checking its templateJson)
    const [templateJson, setTemplateJson] = useState({}); // we keep the template josn only to see if it contains time labels
    const [snippetData, setSnippetData] = useState({});


    const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [activeErrorList, setActiveErrorList] = useState([]);
  

    const getSnippetInfo = async (snippetId) => {
      /*
      try {  
        const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/${snippetId}`;
        const response = await axios.get(url);
        const data = response.data;


      const newTemplate = {...selectedTemplate}
      newTemplate.templateId = data.templateId;
      setSelectedTemplate(newTemplate);
      setSnippetName(data.snippetName)
      setSnippetDesc(data.description)
      setSelectedTimerDate(data.timerDate)
      setSelectedTimeZoneId(data.timerDateTimezoneIANA);
      setTimerSettings(JSON.parse(data.timerSetting));

      } catch (error) {
        console.log(error);
      }
      */
    }
    const templateContainsTimeLabels = (templateJson) => {
      let result = false;
      const blocks = templateJson.model.children[0].children;

      blocks.forEach(block => {
        if (block.className === "Text" && block.attrs.id.startsWith('timerLabel')) result = true;
      })
      console.log(result)
      return result;
    }

    const submitDataSnippet = async () => {
      try {
        snippetDataValidationSchemaBase.validateSync(snippetData, {abortEarly: false});
        setActiveErrorList([])
      }
      catch (e) {
        const errorList = getErrorListFromYupError(e)
        setActiveErrorList(errorList)
        console.log(errorList)
        return false;
      }

      setShowLoader(true);
      setSuccessHtmlMsg('');
    
      try {
          let url = `${process.env.REACT_APP_LION_API_URL}/Snippets/create/14`;
          //if (params.snippetId !== null) url = `${process.env.REACT_APP_LION_API_URL}/Snippets/update/12/${params.snippetId}`;
          
          const response = await axios.post(url, snippetData);
    
          setSuccessHtmlMsg(response.data);
          setShowSuccessMsg(true);
      } catch (error) {
        setShowSuccessMsg(false);
        setShowErrorMsg(true);
        console.log(error);
      }
      setShowLoader(false);
      setSubmitted(true)

      return true;
    }
  const handleTimerElementChange = (snippetInfo) => {
    //console.log(snippetInfo)
    setSnippetData(snippetInfo)

    if (activeErrorList.length > 0) {
      try {
        snippetDataValidationSchemaBase.validateSync(snippetInfo, {abortEarly: false});
        setActiveErrorList([])
      }
      catch (e) {
        const errorList = getErrorListFromYupError(e)
        setActiveErrorList(errorList)
        return;
      }  
    }

  }
      
    const handleNavChange = async (navInfo) => {
        if (navInfo.actionType === 'Navigation'){
            setCurrentStep(navInfo.nextIndex);
        }
        else if (navInfo.actionType === 'Submit') {
            const success =  await submitDataSnippet();
            if (!success) return;
            setCurrentStep(3)
        }
      }
    
    const handleSaveExitClicked = () => {
        navigate("/ph/persAnimation")
    }
    const handleNextClicked = (tId, tJson) => {
        setTemplateId(tId)
        setTemplateJson(tJson)
        setTemplateHasTimeLabels(templateContainsTimeLabels(tJson));
        setCurrentStep(2);
    }
    const handleClose = () => {
        navigate("/ph/persAnimation")
    }
  
    const handlePreEditorChange = (propsDic) => {
        //console.log(propsDic)
        setPreEditorProps(propsDic)
    }

    return <div>
        <CcStepper2
            steps={[{ name: 'Configure Template', href: '#' },
                    { name: 'Finalize Template', href: '#' },
                    { name: 'Configure Element', href: '#' },
                    { name: 'Get Element', href: '#' },
                ]}
            currentStepIndex={currentStep}
        />
        <div className="border border-2 border-gray-200 rounded-md p-2">
            {currentStep === 0 && 
                    <PersAnimationsPreEditor
                        templateProps={preEditorProps}
                        onChange={handlePreEditorChange}
                    />}

            {currentStep === 1 &&
                    <TemplateEditor id={templateId}
                    embeddedInWizard
                    onPreviousClicked={() => setCurrentStep(0)}
                    onSaveExitClicked={handleSaveExitClicked}
                    onNextClicked={handleNextClicked}
                    buildFromDictionary={preEditorProps}
                    //closingCall={() => {setAddEditMode(false); setTemplateIdToEdit(null)}}
                    snippetTypeId={14}

                    />
            }
            {currentStep === 2 && <PersAnimationsElementManager
                                    onChange={handleTimerElementChange}
                                    templateId={templateId}
                                    templateHasTimeLabels={templateHasTimeLabels}
                                    validationErrorsList={activeErrorList}
                                  />}

            {currentStep === 3 && <div className='flex justify-center'>
                {!showLoader && !showSuccessMsg && !showErrorMsg && <div>Confirm</div> }
                {showLoader && <LionLoaderGradient /> }
                {showSuccessMsg && <SnippetCodeViewer numSnippets={1} html={successHtmlMsg} />}      
            </div>}

            {currentStep !== 1 &&
                    <StepperNavButtons totalSteps={4}
                    currentStepNum={currentStep}
                    onChange={handleNavChange}
                    showClose={submitted}
                    onClose={handleClose}
                />
            }
        </div>

    </div>
}