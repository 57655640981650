import { useEffect, useState, useRef, useContext } from "react"
import { ImageUploader, LionListbox, Toggle} from "../commonComponents/inputFields"
import {LionColorPicker} from '../components/lionColorPicker'
import {SettingsContext} from '../components/userSettingsContext';
import {getColorMappingValue} from "../commonComponents/helperSettings";
import {TryParseIntDefault} from "../components/helpers";

import { Alert } from '@mui/material';
import { Link } from "react-router-dom";

export default function TextImagesPreEditor(props) {

  const {settings, setSettings} = useContext(SettingsContext);
  const initTemplateProps = {canvasBackground: "#fff", 
                              canvasWidth: 640, canvasHeight: 250,
                                isTextImage: true, textImageDatabind: undefined,
                              canvasImageUrl: null, canvasDatabindType: null}

  const [useBackgroundImage, setUseBackgroundImage] = useState(false);
  const [canvasUploadedFiles, setCanvasUploadedFiles] = useState({});
  const [templateProps, setTemplateProps] = useState(initTemplateProps)
	const [availableMergeCodes, setAvailableMergeCodes] = useState([]);

  useEffect(( ) => {
    if (settings.accountSettings === undefined) return;
    if (props.templateProps === undefined) return;

		const mergeCodes = [{id: 'manual', name: "I'll manually bind later"}] //ATTENTION: if you change this you need to change the reference in the templateEdittor.js

    if (settings.accountSettings.mergeCodes.firstname !== '') mergeCodes.push({id: settings.accountSettings.mergeCodes.firstname, name: 'Firstname'})
    if (settings.accountSettings.mergeCodes.dob !== '') mergeCodes.push({id: settings.accountSettings.mergeCodes.dob, name: 'Date of Birth'})


		if (settings.accountSettings.mergeCodes.userDefined.length > 0) {
			settings.accountSettings.mergeCodes.userDefined.forEach(userSetting => {
						if (userSetting.value !== '') mergeCodes.push({id: userSetting.value, name: userSetting.key})
					});

		}
		setAvailableMergeCodes(mergeCodes);

    //console.log(props.templateProps)

    if (Object.keys(props.templateProps).length === 0) {
        //if props.templateProps is empty it's the first time we pass here
        const canvasColor = getColorMappingValue(settings, 8);
        if (canvasColor !== undefined) handleUpdateProperty('canvasBackground', canvasColor);
    
        if (props.onChange !== undefined) {
          const newProps = {...templateProps, canvasBackground: canvasColor}
          if (mergeCodes.length > 0) {
            newProps.textImageDatabind = mergeCodes[0].name
            newProps.textImageDatabindId = mergeCodes[0].id
          }
          props.onChange(newProps)
        }
    }
    else {
      setTemplateProps(props.templateProps)
      //console.log(props.templateProps)
      //if props.templateProps show there is a file upload then we need to populate the UI
      if (props.templateProps.canvasImageUrl && props.templateProps.canvasImageUrl !== '') {
        setUseBackgroundImage(true)
        setCanvasUploadedFiles({'Uploaded file': props.templateProps.canvasImageUrl})
      }
    }    

  }, [settings, props.templateProps])

  /*
  useEffect(() => {
    if (props.templateProps !== undefined) setTemplateProps(props.templateProps)
    //const canvasColor = getColorMappingValue(settings, 7);
    //if (canvasColor !== undefined) handleUpdateProperty('canvasBackground', canvasColor);

  }, [])*/

  const handleUseBackgroundImageChange = (use) => {
    let newProps = {...templateProps}
      if (!use) {
        newProps = {...templateProps, 
          canvasImageUrl: '', canvasDatabindType: null}

      }
      else {
        const fn = Object.keys(canvasUploadedFiles);
        if (fn.length > 0) {
          newProps = {...templateProps, 
            canvasImageUrl: canvasUploadedFiles[fn[0]], canvasDatabindType: "none"}
        }


      }      
      setTemplateProps(newProps)
      if (Object.keys(newProps).length > 0) {
        if (props.onChange !== undefined) props.onChange(newProps)
      }
  
      setUseBackgroundImage(use);
  }

  const handleFileChange = (fileInfo) => {
    // this can only be 1 file for the canvas background
    const filenames = fileInfo.fileMappings;
    const fileSizes = fileInfo.fileSizes;
  
    const fn = Object.keys(filenames);
    //we want to resize the canvas to fit the uploaded backgroud
    const newProps = {...templateProps, canvasWidth: fileSizes[fn[0]].width,
                        canvasHeight: fileSizes[fn[0]].height,
                        canvasImageUrl: filenames[fn[0]], canvasDatabindType: "none"}
    //console.log(newProps)
    setTemplateProps(newProps)
    if (props.onChange !== undefined) props.onChange(newProps)

  
    setCanvasUploadedFiles(filenames);
    //const newTemplateMetadata = handleModelMultiChange(["canvasImageUrl", "canvasDatabindType", "canvasWidth", "canvasHeight"], ['"' + filenames[fn[0]] + '"', '"none"', fileSizes[fn[0]].width, fileSizes[fn[0]].height])
    //getTemplatePreview(newTemplateMetadata, templateModel, previewUrlParamsValues, null);
    //if (props.onCanvasUploadedFileChange !== undefined) props.onCanvasUploadedFileChange(filenames);
  }
  const handleUpdateProperty = (propName, propValue) => {
    //console.log(propName)
    //console.log(propValue)
    const newProps = {...templateProps, [propName]: propValue}

    setTemplateProps(newProps)

    if (props.onChange !== undefined) props.onChange(newProps)
  }

  const handleUpdateTextImageDatabind = (selectedItem) => {
    //console.log(propName)
    //console.log(propValue)
    const newProps = {...templateProps, textImageDatabind: selectedItem.name, textImageDatabindId: selectedItem.id}

    setTemplateProps(newProps)

    if (props.onChange !== undefined) props.onChange(newProps)
  }

return <div className="flex flex-col gap-4">
            <Toggle displayName="Use background image"
                    onChange={handleUseBackgroundImageChange}
                    checked={useBackgroundImage} />
            
    {!useBackgroundImage &&
                  <div className="flex flex-row gap-x-8">                  
                    <div>  
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Background Color
                      </label>
                      <LionColorPicker color={templateProps.canvasBackground}
                          onChange={(color) => {handleUpdateProperty('canvasBackground', color)}}
                          />
                    </div>            
                  </div>
    }
    {useBackgroundImage && <ImageUploader onChange={handleFileChange}
                                        uploadedFiles={canvasUploadedFiles}
                                        endpoint="utils/unmanaged/uploadImage"
                                         />

    }
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        Canvas Size
      </label>
      <div className="flex flex-row items-center gap-2">
        <div>Width:</div>
        <input id="cwidth" className=" max-w-44 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                          value={templateProps.canvasWidth}
                          onChange={(e) => {handleUpdateProperty('canvasWidth', TryParseIntDefault(e.target.value, ''))}}
                    />
        <div className=" ml-2">Height:</div>
        <input id="cheight" className="max-w-44 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                          value={templateProps.canvasHeight}
                          onChange={(e) => {handleUpdateProperty('canvasHeight', TryParseIntDefault(e.target.value, ''))}}
                    />
      </div>
    </div>

    {availableMergeCodes.length > 0 &&
      <div>
        <label className="block text-sm font-medium leading-6 text-gray-900">
        Bind Data Field to Data Canvas Block
        </label>
        <LionListbox
          items={availableMergeCodes}
          zIndex={99}
          selectedItemId={templateProps.textImageDatabindId}
          onChange={(selectedItem) => {handleUpdateTextImageDatabind(selectedItem); }}
        />
      </div>
    }
    {availableMergeCodes.length === 1 &&
      <Alert severity="info" sx={{maxWidth: 400}}>
        <div className="flex flex-col gap-2">
          <div>No pre-defined merge codes found</div>
          <Link to={`/admin/settings`}>Define merge codes in the <span className="font-bold">setting area</span></Link>
        </div>
      </Alert>
    }

</div>


}