import { useNavigate } from 'react-router-dom';


import {Alert, AlertTitle, Box, Button, CircularProgress, IconButton,
    FormControl, MenuItem,
    Popover, Select, Snackbar,
    Stack, ThemeProvider, Typography} from '@mui/material';

import Dialog from '@mui/material/Dialog';

import LoadingButton from '@mui/lab/LoadingButton';
import { useParams } from "react-router-dom";

import { useState, useEffect, useRef, } from 'react';
import { createTheme } from '@mui/material/styles';

import Grid2 from '@mui/material/Unstable_Grid2';

import { grey, green } from '@mui/material/colors';
import {ConditionsManager, FilterViewer, FilterTextViewer} from "../components/filtersManager";
import {OrderByManager} from "../components/orderByManager";
import {HrefSelector} from "../components/hrefSelector";
import {TemplatesGenerator} from "../components/templatesGenerator"

import GeneratorPreEditor from "../preEditors/generatorPreEditor"


import { Icon as Iconify } from '@iconify/react';
import axios from 'axios';
import { lionIcons } from '../components/helpersIcons';
import { GetFilterDefFromMetadata}  from '../components/helpers';

import DatafeedGrid from '../components/datafeedGrid';
import DatafeedWizard from '../components/datafeedWizard';
import TemplateEditor from '../components/templateEditor';

import {LionNumberInput} from '../components/lionNumberInput';
import { LabelManager } from '../components/labelManager';
import {HtmlTooltip} from '../components/helpersComponents';
import  SnippetHtmlViewer  from '../components/snippetHtmlViewer';

//import TemplateGalleryViewer from "../commonComponents/CC_templateGallery";
import {TemplateGalleryViewer} from '../commonComponents/templateGallery'

import { CcStepper2} from "../commonComponents/stepper"
import { Transition } from '@headlessui/react'
import {ImagePopover} from "../commonComponents/CC_templateGallery";
import {ccColorDarkTeal, ccColorTeal, lionObjectsColors}  from "../components/helpersStyles";
import {CcButton, InputText, InputNumber, LionListbox} from "../commonComponents/inputFields"
import {LionColorPicker} from "../components/lionColorPicker";
import { CcTitle } from '../commonComponents/styledTexts';

import { getErrorListFromYupError, getErrorMessage, snippetDataValidationSchemaGenerator } from '../components/helperErrors'

import { CcLoader } from '../commonComponents/generalComponents';

const theme = createTheme({
  palette: {
    // primary: {
      // light: will be calculated from palette.primary.main,
      // main: '#ff4400',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    // },
    // secondary: {
      // light: '#0066ff',
      // main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    // },
    // Provide every color token (light, main, dark, and contrastText) when using
    // custom colors for props in Material UI's components.
    // Then you will be able to use it like this: `<Button color="custom">`
    // (For TypeScript, you need to add module augmentation for the `custom` value)
    customCircularLoader: {
      // light: '#ffa726',
      main: '#fff',
      // dark: '#ef6c00',
      // contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    // contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
  },
});



function SelectedIcon(props) {
return <Box sx={{position: 'absolute', bottom: '0px', left: '40px',
       padding: '5px', backgroundColor: '#fff', zIndex:99999,
       color: green[500]}}>
 <Stack direction='row' spacing={1}>
   <Iconify icon="teenyicons:tick-circle-outline" />
   <Typography>Selected</Typography>
 </Stack>
</Box>
}

const EasingFunctionSelector = (props) => {
  //const [easingFunction, setEasingFunction] = useState(undefined)
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);


  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu  = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleEasingFunctionChange = (fName) => {
    if (props.onChange) props.onChange(fName)
    handleCloseMenu()
  }

  return <>
    {props.value === undefined && <CcButton
                                     onClick={handleOpenMenu} 
                                     >Select an Easing Function</CcButton>
    } 
    {props.value && <div className='flex flex-row items-center gap-3 pl-1 border border-gray-300 rounded-md'>
      <div>{props.value}</div>
      <div className='flex grow'></div>
      <IconButton sx={{m:0}}><Iconify icon={lionIcons.Icon_Actions_Edit} onClick={handleOpenMenu} /></IconButton>
    </div>
    }


    <Popover open={openMenu}
                    anchorEl={anchorEl}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    >
      <div className=' w-[700px] h-fit p-2'>
        <div className=' mb-4 text-lg font-bold text-ccColorDarkTeal'>
          Select an Easing Function
        </div>
        
        <div className=' grid grid-cols-3 gap-y-8'>

          <div className='flex flex-col max-w-[150px] p-2 rounded-md hover:bg-slate-300'
              onClick={() => handleEasingFunctionChange('easeInSine')}
          >
            <div className='text-gray-400 hover:text-white stroke-ccColorDarkTeal hover:stroke-ccColorOrange cursor-pointer relative after:absolute after:top-0 after:right-0 after:-bottom-[1px] after:-left-[1px] after:border-l-[1px] after:border-b-[1px]'>
              <svg className='fill-none' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 85">
                <path d="M1 84c14 1 47.75 1 123-83"></path>
              </svg>
              <div className='absolute top-[1px] left-[5px]'>x</div>
              <div className='absolute right-[8px] bottom-[4px]'>t</div>
            </div>
            <div className='flex justify-center'>easeInSine</div>
          </div>

          <div className='flex flex-col max-w-[150px] p-2 rounded-md hover:bg-slate-300'
              onClick={() => handleEasingFunctionChange('easeOutSine')}
          >
            <div className='text-gray-400 hover:text-white stroke-ccColorDarkTeal hover:stroke-ccColorOrange cursor-pointer relative after:absolute after:top-0 after:right-0 after:-bottom-[1px] after:-left-[1px] after:border-l-[1px] after:border-b-[1px]'>
              <svg className='fill-none' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 85">
                <path d="M1 84C76.25 0 110 0 124 1"></path>
              </svg>
              <div className='absolute top-[1px] left-[5px]'>x</div>
              <div className='absolute right-[8px] bottom-[4px]'>t</div>
            </div>
            <div className='flex justify-center'>easeOutSine</div>
          </div>

          <div className='flex flex-col max-w-[150px] p-2 rounded-md hover:bg-slate-300'
              onClick={() => handleEasingFunctionChange('easeInOutSine')}
          >
            <div className='text-gray-400 hover:text-white stroke-ccColorDarkTeal hover:stroke-ccColorOrange cursor-pointer relative after:absolute after:top-0 after:right-0 after:-bottom-[1px] after:-left-[1px] after:border-l-[1px] after:border-b-[1px]'>
              <svg className='fill-none' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 85">
                <path d="M1 84C46.25 85 78.75 0 124 1"></path>
              </svg>
              <div className='absolute top-[1px] left-[5px]'>x</div>
              <div className='absolute right-[8px] bottom-[4px]'>t</div>
            </div>
            <div className='flex justify-center'>easeInOutSine</div>
          </div>
          
          <div className='flex flex-col max-w-[150px] p-2 rounded-md hover:bg-slate-300'
              onClick={() => handleEasingFunctionChange('easeInBounce')}
          >
            <div className='text-gray-400 hover:text-white stroke-ccColorDarkTeal hover:stroke-ccColorOrange cursor-pointer relative after:absolute after:top-0 after:right-0 after:-bottom-[1px] after:-left-[1px] after:border-l-[1px] after:border-b-[1px]'>
              <svg className='fill-none' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 85">
               <path d="M1 84l.24-.51 1.24-.39 1.24-.26 1.24-.13 1.24-.01 1.24.12 1.24.25 1.24.38 1.24.5L12.4 83l1.24-.97 1.24-.85 1.24-.72 1.24-.6 1.24-.47 1.24-.34 1.24-.21 1.24-.09 1.24.04 1.24.17 1.24.29 1.24.43 1.24.54 1.24.68 1.24.8 1.24.93 1.24 1.06 1.24-1.34 1.24-2.15 1.24-2.03 1.24-1.9 1.24-1.77 1.24-1.65 1.24-1.52 1.24-1.39 1.24-1.26 1.24-1.14 1.24-1.01 1.24-.88 1.24-.76 1.24-.63 1.24-.5 1.24-.38 1.24-.25 1.24-.12 1.24.01 1.24.13 1.24.26 1.24.39 1.24.51 1.24.64 1.24.77 1.24.9 1.24 1.02 1.24 1.15 1.24 1.27 1.24 1.41L71.92 73l1.24 1.66 1.24 1.78 1.24 1.91 1.24 2.04 1.24 2.17 1.24-.23 1.24-4.51 1.24-4.39 1.24-4.25 1.24-4.13 1.24-4 1.24-3.88 1.24-3.75 1.24-3.62 1.24-3.49 1.24-3.37L93 39.7l1.24-3.11 1.24-2.99 1.24-2.85 1.24-2.74 1.24-2.6 1.24-2.48 1.24-2.35 1.24-2.22 1.24-2.1 1.24-1.97 1.24-1.84 1.24-1.71 1.24-1.59 1.24-1.46 1.24-1.34 1.24-1.2 1.24-1.08 1.24-.96 1.24-.82 1.24-.7 1.24-.57 1.24-.45 1.24-.32 1.24-.19"></path>
              </svg>
              <div className='absolute top-[1px] left-[5px]'>x</div>
              <div className='absolute right-[8px] bottom-[4px]'>t</div>
            </div>
            <div className='flex justify-center'>easeInBounce</div>
          </div>

          <div className='flex flex-col max-w-[150px] p-2 rounded-md hover:bg-slate-300'
              onClick={() => handleEasingFunctionChange('easeOutBounce')}
          >
            <div className='text-gray-400 hover:text-white stroke-ccColorDarkTeal hover:stroke-ccColorOrange cursor-pointer relative after:absolute after:top-0 after:right-0 after:-bottom-[1px] after:-left-[1px] after:border-l-[1px] after:border-b-[1px]'>
              <svg className='fill-none' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 85">
                <path d="M1 84l.24-.06 1.24-.19 1.24-.32 1.24-.45 1.24-.57 1.24-.7 1.24-.82 1.24-.96 1.24-1.08 1.24-1.2 1.24-1.34 1.24-1.46 1.24-1.59 1.24-1.71 1.24-1.84 1.24-1.97 1.24-2.1 1.24-2.22 1.24-2.35 1.24-2.48 1.24-2.6 1.24-2.74 1.24-2.85 1.24-2.99L31 44.3l1.24-3.24 1.24-3.37 1.24-3.49 1.24-3.62 1.24-3.75 1.24-3.88 1.24-4 1.24-4.13 1.24-4.25 1.24-4.39 1.24-4.51 1.24-.23 1.24 2.17 1.24 2.04 1.24 1.91 1.24 1.78L52.08 11l1.24 1.53 1.24 1.41 1.24 1.27 1.24 1.15 1.24 1.02 1.24.9 1.24.77 1.24.64 1.24.51 1.24.39 1.24.26 1.24.13 1.24.01 1.24-.12 1.24-.25 1.24-.38 1.24-.5 1.24-.63 1.24-.76 1.24-.88 1.24-1.01 1.24-1.14 1.24-1.26 1.24-1.39 1.24-1.52 1.24-1.65 1.24-1.77 1.24-1.9 1.24-2.03 1.24-2.15L90.52.31l1.24 1.06L93 2.3l1.24.8 1.24.68 1.24.54 1.24.43 1.24.29 1.24.17 1.24.04 1.24-.09 1.24-.21 1.24-.34 1.24-.47 1.24-.6 1.24-.72 1.24-.85L111.6 1l1.24-.95 1.24.5 1.24.38 1.24.25 1.24.12 1.24-.01 1.24-.13 1.24-.26 1.24-.39"></path>
              </svg>
              <div className='absolute top-[1px] left-[5px]'>x</div>
              <div className='absolute right-[8px] bottom-[4px]'>t</div>
            </div>
            <div className='flex justify-center'>easeOutBounce</div>
          </div>

          <div className='flex flex-col max-w-[150px] p-2 rounded-md hover:bg-slate-300'
              onClick={() => handleEasingFunctionChange('easeInOutBounce')}
          >
            <div className='text-gray-400 hover:text-white stroke-ccColorDarkTeal hover:stroke-ccColorOrange cursor-pointer relative after:absolute after:top-0 after:right-0 after:-bottom-[1px] after:-left-[1px] after:border-l-[1px] after:border-b-[1px]'>
              <svg className='fill-none' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 85">
                <path d="M1 84l.24-.45 1.24-.2 1.24.06 1.24.31 1.24-.22 1.24-.91 1.24-.66 1.24-.4 1.24-.15 1.24.1 1.24.36 1.24.61 1.24.87 1.24-.14 1.24-2.09 1.24-1.84 1.24-1.58 1.24-1.33 1.24-1.07 1.24-.83 1.24-.56 1.24-.31 1.24-.06 1.24.2 1.24.45 1.24.7 1.24.96 1.24 1.21 1.24 1.47 1.24 1.72 1.24 1.98 1.24.96 1.24-4.44 1.24-4.2 1.24-3.93 1.24-3.69 1.24-3.43 1.24-3.17 1.24-2.93 1.24-2.67 1.24-2.41 1.24-2.16 1.24-1.9 1.24-1.66 1.24-1.39 1.24-1.15 1.24-.89 1.24-.63 1.24-.38L62 42l1.24-.13 1.24-.38 1.24-.63 1.24-.89 1.24-1.15 1.24-1.39 1.24-1.66 1.24-1.9 1.24-2.16 1.24-2.42 1.24-2.66 1.24-2.93 1.24-3.17 1.24-3.43 1.24-3.69 1.24-3.93 1.24-4.2L84.32.84l1.24.96 1.24 1.98 1.24 1.72 1.24 1.47 1.24 1.21 1.24.96 1.24.7 1.24.45 1.24.2 1.24-.06 1.24-.31 1.24-.57 1.24-.82 1.24-1.07 1.24-1.33 1.24-1.58 1.24-1.84 1.24-2.09 1.24-.14 1.24.87 1.24.61 1.24.36 1.24.1 1.24-.15 1.24-.4 1.24-.66L117.8.5l1.24-.22 1.24.31 1.24.06 1.24-.2"></path>
              </svg>
              <div className='absolute top-[1px] left-[5px]'>x</div>
              <div className='absolute right-[8px] bottom-[4px]'>t</div>
            </div>
            <div className='flex justify-center'>easeInOutBounce</div>
          </div>

          <div className='flex flex-col max-w-[150px] p-2 rounded-md hover:bg-slate-300'
              onClick={() => handleEasingFunctionChange('easeInCubic')}
          >
            <div className='text-gray-400 hover:text-white stroke-ccColorDarkTeal hover:stroke-ccColorOrange cursor-pointer relative after:absolute after:top-0 after:right-0 after:-bottom-[1px] after:-left-[1px] after:border-l-[1px] after:border-b-[1px]'>
              <svg className='fill-none' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 85">
                <path d="M1 84c39 1 82.75 1 123-83"></path>
              </svg>
              <div className='absolute top-[1px] left-[5px]'>x</div>
              <div className='absolute right-[8px] bottom-[4px]'>t</div>
            </div>
            <div className='flex justify-center'>easeInCubic</div>
          </div>

          <div className='flex flex-col max-w-[150px] p-2 rounded-md hover:bg-slate-300'
              onClick={() => handleEasingFunctionChange('easeOutCubic')}
          >
            <div className='text-gray-400 hover:text-white stroke-ccColorDarkTeal hover:stroke-ccColorOrange cursor-pointer relative after:absolute after:top-0 after:right-0 after:-bottom-[1px] after:-left-[1px] after:border-l-[1px] after:border-b-[1px]'>
              <svg className='fill-none' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 85">
                <path d="M1 84C41.25 0 85 0 124 1"></path>
              </svg>
              <div className='absolute top-[1px] left-[5px]'>x</div>
              <div className='absolute right-[8px] bottom-[4px]'>t</div>
            </div>
            <div className='flex justify-center'>easeOutCubic</div>
          </div>

          <div className='flex flex-col max-w-[150px] p-2 rounded-md hover:bg-slate-300'
              onClick={() => handleEasingFunctionChange('easeInOutCubic')}
          >
            <div className='text-gray-400 hover:text-white stroke-ccColorDarkTeal hover:stroke-ccColorOrange cursor-pointer relative after:absolute after:top-0 after:right-0 after:-bottom-[1px] after:-left-[1px] after:border-l-[1px] after:border-b-[1px]'>
              <svg className='fill-none' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 85">
                <path d="M1 84C81.25 85 43.75 0 124 1"></path>
              </svg>
              <div className='absolute top-[1px] left-[5px]'>x</div>
              <div className='absolute right-[8px] bottom-[4px]'>t</div>
            </div>
            <div className='flex justify-center'>easeInOutCubic</div>
          </div>

        </div>


      </div>
    </Popover>

  </>
}

const FadeInSelector = (props) => {
  const [fadeInFunction, setFadeInFunction] = useState(undefined)
  const [loopType, setLoopType] = useState("noLoop")
  const [loopTimes, setLoopTimes] = useState(1)
  const allFunctionNames = [{id: "", name: "None"},
                            {id: "FromThumbnail", name: "Zooming"},
                            {id: "InCircles", name: "Circles"},
                            {id: "InSvgPath", name: "Path"},
                            {id: "Rotate3DY", name: "Rotate"},
                            {id: "SlideFromBottom", name: "Slide From Bottom"},
                            {id: "SlideFromTop", name: "Slide From Top"},
                            {id: "SlideFromLeft", name: "Slide From Left"},
                            {id: "SlideFromRight", name: "Slide From Right"},
                            {id: "HorizontalSlices", name: "Horizontal Slices"},
                            {id: "VerticalSlices", name: "Vertical Slices"},
                            ]
  const functionParamsToShow = {"FromThumbnail": [{name: "duration"}, {name: "loop"}, {name: "border"}, {name: "canvasBackgroundColor", default: "#ffffff"}],
                                   "InCircles": [{name: "duration"}, {name: "loop"}, {name: "border"}, {name: "canvasBackgroundColor", default: "#ffffff"}],
                                   "InSvgPath": [{name: "duration"}, {name: "loop"}, {name: "border"}, {name: "canvasBackgroundColor", default: "#ffffff"}],
                                   "Rotate3DY": [{name: "duration"}, {name: "loop"}, {name: "canvasBackgroundColor", default: "#ffffff"}],
                                   "SlideFromBottom": [{name: "duration"}, {name: "loop"}, {name: "canvasBackgroundColor", default: "#ffffff"}],
                                   "SlideFromTop": [{name: "duration"}, {name: "loop"}, {name: "canvasBackgroundColor", default: "#ffffff"}],
                                   "SlideFromLeft": [{name: "duration"}, {name: "loop"}, {name: "canvasBackgroundColor", default: "#ffffff"}],
                                   "SlideFromRight": [{name: "duration"}, {name: "loop"}, {name: "canvasBackgroundColor", default: "#ffffff"}],
                                   "HorizontalSlices": [{name: "duration"}, {name: "loop"}, {name: "numSlices"}, {name: "canvasBackgroundColor", default: "#ffffff"}],
                                   "VerticalSlices": [{name: "duration"}, {name: "loop"}, {name: "numSlices"}, {name: "canvasBackgroundColor", default: "#ffffff"}],
                                  }

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu  = (event) => {
    setAnchorEl(event.currentTarget);
  }

  /*
  props:

  fadeInFunction: {"name": "a string", parameters: {}}

  onChange: returns the updated fadeInFunction object
  */

  useEffect(() => {
    //merging default values if not provided
    //console.log(props.fadeInFunction)
    if (props.fadeInFunction) {
      let defaultValuesAdded = false;
      const newParams = {...props.fadeInFunction.parameters}
      if (props.fadeInFunction.parameters.duration === undefined) {
        newParams['duration'] = 1;
        defaultValuesAdded = true;
      }
      if (props.fadeInFunction.parameters.borderSize === undefined) {
        newParams['borderSize'] = 0;
        defaultValuesAdded = true;
      }
      if (props.fadeInFunction.parameters.borderColor === undefined) {
        newParams['borderColor'] = '#000000';
        defaultValuesAdded = true;
      }
      
      if (props.fadeInFunction.parameters.canvasBackgroundColor === undefined) {
        newParams['canvasBackgroundColor'] = '#FFFFFF';
        defaultValuesAdded = true;
      }
      
      if (props.fadeInFunction.parameters.easingFunction === undefined) {
        newParams['easingFunction'] = 'easeOutSine';
        defaultValuesAdded = true;
      }
      if (props.fadeInFunction.parameters.numSlices === undefined) {
        newParams['numSlices'] = 8;
        defaultValuesAdded = true;
      }
      if (defaultValuesAdded) {
        const newFadeInFunction = {...props.fadeInFunction, parameters: newParams}
        setFadeInFunction(newFadeInFunction)
        if (props.onChange) props.onChange(newFadeInFunction)
        //console.log(newFadeInFunction)
      }
      else {
        setFadeInFunction(props.fadeInFunction)
      }
    }
  }, [props.fadeInFunction])

  const handleFadeInFunctionNameChange = (newValue) => {
    let newFadeInFunction = undefined;
    if (newValue.id !== '') {
      newFadeInFunction = {...fadeInFunction, name: newValue.id, parameters: {}}
    } 

    setFadeInFunction(newFadeInFunction);
    if (props.onChange) props.onChange(newFadeInFunction)
  }
const handleFadeInFunctionChange = (paramName, paramValue) => {
	const newParams =  {...fadeInFunction.parameters, [paramName]: paramValue}
    const newFadeInFunction = {...fadeInFunction, parameters: newParams}

    setFadeInFunction(newFadeInFunction);

    if (props.onChange) props.onChange(newFadeInFunction)
}


const handleLoopChange = (lpType, lpTimes) => {
  let lpValue = undefined;
  if (lpType === 'loopForever') lpValue = 0;
  if (lpType === 'loopTimes') {
    lpValue = lpTimes;
    setLoopTimes(lpTimes)
  }
  handleFadeInFunctionChange('loop', lpValue)
}

const getFunctionPrettyName = (fName) => {
  switch(fName) {
    case "FromThumbnail":
      return "Zooming";
    case "InCircles":
      return "Circles";
    case "InSvgPath":
      return "Path";
    case "Rotate3DY":
      return "Rotate";
    default:
      return fName;
  }
}

  const mustRender = (propName) => {
	if (fadeInFunction === undefined) return false;
	if (fadeInFunction.name === '') return false;

	const allParams = functionParamsToShow[fadeInFunction.name]
	const paramMatched = allParams.find(p => p.name === propName)

	return paramMatched;
  }

  return <div>
    {fadeInFunction === undefined && <CcButton
                                     onClick={handleOpenMenu} 
                                     >Add Fade-In Effect</CcButton>
    } 
    {fadeInFunction && <div className='flex flex-row items-center gap-3 p-1 border border-gray-300 rounded-md'>
      <div>{getFunctionPrettyName(fadeInFunction.name)}</div>
      <div className='flex grow'></div>
      <IconButton><Iconify icon="mdi:animation-plus-outline" onClick={handleOpenMenu} /></IconButton>
    </div>
    }

    <Popover open={openMenu}
                    anchorEl={anchorEl}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    >
      <div className=' w-[700px] min-h-96 h-fit p-2'>
        <div className=' mb-4 text-lg font-bold text-ccColorDarkTeal'>
          Fade-in settings
        </div>
        <div className='mb-4 flex flex-row gap-12'>
          <div className='flex flex-col'>
            <div>Animation Type</div>
            <LionListbox items={allFunctionNames}
              zIndex={10}
              selectedItemId={fadeInFunction ? fadeInFunction.name : undefined}
              onChange={handleFadeInFunctionNameChange}
          />
          </div>
          {fadeInFunction &&
          <div className='flex flex-col'>
            <div>Easing function</div>
            <EasingFunctionSelector 
              value={fadeInFunction ? fadeInFunction.parameters.easingFunction : undefined}
              onChange={(fName) => handleFadeInFunctionChange('easingFunction', fName)}
             />
          </div>
          }
        </div>
         <div className='flex flex-col gap-3'>
			{mustRender('duration') && <div className=' w-36'>
				<InputNumber displayName="Animation duration"
					value={fadeInFunction.parameters && fadeInFunction.parameters.duration ? fadeInFunction.parameters.duration : 5}
          min={1}
          max={10}
					onChange={(val) => handleFadeInFunctionChange('duration', val)}
				/>
			</div>
			}
      {mustRender('canvasBackgroundColor') && <div className='flex flex-col'>
          <div>Background Color</div>
          <div className=' w-36'>
          <LionColorPicker //color={fill}      
              color={fadeInFunction.parameters && fadeInFunction.parameters.canvasBackgroundColor ? fadeInFunction.parameters.canvasBackgroundColor : "#FFFFFF"}
              onChange={(color) => handleFadeInFunctionChange('canvasBackgroundColor', color)}
              />
          </div>
        </div>
      }

			{mustRender('numSlices') && <div className=' w-36'>
				<InputNumber displayName="Num Slices"
					value={fadeInFunction.parameters && fadeInFunction.parameters.numSlices ? fadeInFunction.parameters.numSlices : 8}
          min={2}
          max={20}
					onChange={(val) => handleFadeInFunctionChange('numSlices', val)}
				/>
			</div>
			}
			{mustRender('loop') && <div>
				<label htmlFor="email" className={`block text-sm font-medium leading-6 text-gray-900`}>
					Loop
				</label>
				<div className='flex flex-row gap-5'>
					<div class="flex items-center">
						<input id="default-radio-1" type="radio" 
								value="noLoop"
                checked={fadeInFunction.parameters && fadeInFunction.parameters.loop === undefined}
								onClick={() => handleLoopChange('noLoop')}
								 name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
						<label for="default-radio-1" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No loop</label>
					</div>
					<div class="flex items-center">
						<input id="default-radio-2" type="radio" 
								value="loopForever"
                checked={fadeInFunction.parameters && fadeInFunction.parameters.loop === 0}
								onClick={() => handleLoopChange('loopForever')}
								 name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
						<label for="default-radio-2" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Loop forever</label>
					</div>
					<div class="flex flex-row items-center gap-1">
						<input id="default-radio-3" type="radio" 
								value="loopTimes" 
                checked={fadeInFunction.parameters && fadeInFunction.parameters.loop > 0}
								onClick={(e) => handleLoopChange('loopTimes', loopTimes)}
								name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
						<label for="default-radio-3" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Loop</label>
						<div className=' w-36'>
							<InputNumber
								value={loopTimes}
                min={1}
								onChange={(val) => handleLoopChange('loopTimes', val)}
								/></div>
						<label for="default-radio-3" class="text-sm font-medium text-gray-900 dark:text-gray-300">times</label>
					</div>
				</div>
			</div>
			}
			{mustRender('border') && <div className='flex flex-row gap-8'>
        <div className='flex flex-col'>
          <div>Border Size</div>
          <div className=' w-36'>
            <InputNumber
              value={fadeInFunction.parameters && fadeInFunction.parameters.borderSize ? fadeInFunction.parameters.borderSize : 0}
              min={0}
              max={30}
              onChange={(val) => handleFadeInFunctionChange('borderSize', val)}
            />
          </div>
        </div>
        <div className='flex flex-col'>
          <div>Border Color</div>
          <div className=' w-36'>
          <LionColorPicker //color={fill}      
              color={fadeInFunction.parameters && fadeInFunction.parameters.borderColor ? fadeInFunction.parameters.borderColor : "#000000"}
              onChange={(color) => handleFadeInFunctionChange('borderColor', color)}
              />
          </div>
        </div>
      </div>
			}

         </div>
      </div>
    </Popover>

    </div>
}

const PartitionByManager = (props) => {
  const [isCatalog, setIsCatalog] = useState(null); //NULL: show spinner, false: show nothing, true: show component 
  const [fields, setFields] = useState([])
  const [selectedFields, setSelectedFields] = useState(['null', 'null'])

  useEffect(() => {
    if (props.selectedFields) setSelectedFields(props.selectedFields)
    else setSelectedFields(['null', 'null'])

  }, [props.selectedFields])

  const handleOnChangeSelectedFields = (f1, f2) => {
    if (props.onChange) props.onChange([f1, f2])
  } 

  const getDataConnectionInfo = async (dataConnectionId) => {
    try {
          const url = `${process.env.REACT_APP_LION_API_URL}/Feeds/info/${dataConnectionId}`;
          const response = await axios.get(url);
        
          const dataTypeInMetadata = response.data.feedType === 'CATALOG';
          setIsCatalog(dataTypeInMetadata)
          const filters = GetFilterDefFromMetadata(JSON.parse(response.data.metadata), dataTypeInMetadata);
          setFields(filters.map(f => (f.conditionName)))

           
    } catch (error) {
      console.log(error);
    }
   }

   
  useEffect(() => {
    if (props.dataConnectionId) {
      getDataConnectionInfo(props.dataConnectionId)
    }
  }, [props.dataConnectionId])

  return      <Stack direction='row' spacing={1} width='100%' display='flex' alignItems='center'>
    {isCatalog === null &&     <CcLoader size={32} />

    }
    {isCatalog && <div className='flex flex-row items-center gap-x-2'>
        <Typography variant='h6'>Dedupe by</Typography>
        <FormControl size='small'>
            <Select
                  id="partitionByField1"
                value={selectedFields[0]}
                  onChange={(e) => handleOnChangeSelectedFields(e.target.value, selectedFields[1])}
                >
                <MenuItem value='null'>Not Specified</MenuItem>
                {fields.map(f => <MenuItem value={f}>{f}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl size='small'>
            <Select
                  id="partitionByField2"
                value={selectedFields[1]}
                onChange={(e) => handleOnChangeSelectedFields(selectedFields[0], e.target.value)}
                >
                <MenuItem value='null'>Not Specified</MenuItem>
                {fields.map(f => <MenuItem value={f}>{f}</MenuItem>)}
            </Select>
          </FormControl>

    </div>

    }
</Stack>

}

export function GeneratorElementManager(props) {
  /*
  props:
  templateId: (must be provided for new )
  snippetInfo: (must be provided for edits )
  templateMetadata: mandatory to pass to see previews

  onChange: returns all the snippet info
  */

  const activeStep = 3; //refactor - remove this variable
  const initialState = {snippetId: 0, snippetName: '', snippetDesc: '', 
  labels: [{labelId: 0, labelName: ''}], href: '',
  snippetTypeId: 1,
  filters: [], orderBy: [],
  dataConnectionId: 0, templateId: 0, dataConnectionInfo: {}};
  
  
  const [snippetInfo, setSnippetInfo] = useState(initialState);
  const [popupLabelManagerValue, setPopupLabelManagerValue] = useState({snippetId: 0, data: []});
  const [workflowNumSnippetsToCreate, setWorkflowNumSnippetsToCreate] = useState(1);
  const [snippetPreviewImages, setSnippetPreviewImages] = useState({1: {state: 'empty'}}); // Dictionary keys are pos 1, 2 etc... state can be: 'empty', 'loading' or 'loaded' 
  //const [templateMetadata, setTemplateMetadata] = useState(undefined);
  const [showTestLinkForPreviewPosition, setShowTestLinkForPreviewPosition] = useState(0)



  useEffect(() => {
    //console.log(props.snippetInfo)
    if (props.snippetInfo && props.templateId) {
      setSnippetInfo({...props.snippetInfo, templateId: props.templateId})
      if (props.snippetInfo.labels && props.snippetInfo.labels.length > 0) {
        //prepare an object that the label manager can use
        const labels = {snippetId: 0, data: props.snippetInfo.labels}
        setPopupLabelManagerValue(labels)
      }
    }

  }, [props.snippetInfo, props.templateId])

  const handleChange = (propName, propValue) => {
    const newSnippetInfo = {...snippetInfo, [propName]: propValue}
    setSnippetInfo(newSnippetInfo);
    if (props.onChange) props.onChange(newSnippetInfo)
  }
  const handleLabelChanges = (lbls) => {
    const newSnippetInfo = {...snippetInfo, labels: lbls}
    setSnippetInfo(newSnippetInfo);
    if (props.onChange) props.onChange(newSnippetInfo)
      // console.log(newSnippetInfo)
  }
  const handleNumSnippetsToCreate = (num) => {
    setWorkflowNumSnippetsToCreate(num)
    const numSnippetPreviews = Object.keys(snippetPreviewImages).length;
    let newPreviews = {}
  
    if (numSnippetPreviews < num)
    {
      // we need to add 1 preview
      newPreviews = {...snippetPreviewImages, [num]: {state: 'empty'}}
      setSnippetPreviewImages(newPreviews);
    }
    if (numSnippetPreviews > num)
    {
      // we need to remove 1 preview
      newPreviews = {...snippetPreviewImages}
      delete newPreviews[num + 1]
      setSnippetPreviewImages(newPreviews);
    }
    if (props.onNumSnippetsChange) props.onNumSnippetsChange(num)
  }
  const handleFilterChange = (filtersValue) => {
    //console.log(filtersValue)
    handleChange('filters', filtersValue);
  }
  const handleOrderByChange = (orderByValue) => {
  //console.log(orderByValue)
  handleChange('orderBy', orderByValue);
  }
  const handlePartitionByChange = (partitionByValue) => {
    console.log(partitionByValue)
    handleChange('partitionBy', partitionByValue);

  }
  const handleFadeInFunctionChange = (newFunction) => {
    const newSnippetInfo = {...snippetInfo, fadeInFunction: newFunction}
    setSnippetInfo(newSnippetInfo);
    if (props.onChange) props.onChange(newSnippetInfo);
    /*
    const newMetadata = {...templatesMetadata[snippetInfo.templateId].metadata, fadeInFunction: newFunction }
    const newTemplateMetadata = {...templatesMetadata[snippetInfo.templateId], metadata: newMetadata}
    const newTemplatesMetadata = {...templatesMetadata, [snippetInfo.templateId]: newTemplateMetadata};
  
    setTemplatesMetadata(newTemplatesMetadata)
    */
  }
  const getSnippetPreviews = async () => {
    // show loaders first
    let snippetPreviewImagesLoadingState = {}
    for (let i=1; i <= workflowNumSnippetsToCreate; i+=1) {
      snippetPreviewImagesLoadingState = {...snippetPreviewImagesLoadingState, [i]: {state: 'loading'}}
    }
    setSnippetPreviewImages(snippetPreviewImagesLoadingState)
  
    /*
    const requestObj = {...templatesMetadata[snippetInfo.templateId].metadata,
                           previewItems:{filters: snippetInfo.filters, orderBy: snippetInfo.orderBy}}
    */
    const requestObj = {...props.templateMetadata, previewItems:{filters: snippetInfo.filters, orderBy: snippetInfo.orderBy, partitionBy: snippetInfo.partitionBy}, previewFadeInSettings: snippetInfo.fadeInFunction}
    const responses = []
    console.log(requestObj)
    for (let i=1; i <= workflowNumSnippetsToCreate; i+=1) {
      try {
        const url = `${process.env.REACT_APP_LION_API_URL}/Templates/preview`;
  
        requestObj.previewItems.position = i;
        //console.log(requestObj)
        const response =  axios.post(url, requestObj).then(response => {
          // console.log(response.data.dataRow)
  
          // we find the link and add it to test in the UI
          let link = snippetInfo.href;
          const regex = "\\$\\{(.*?)\\}"
          const matches = [...link.matchAll(regex)];
          matches.forEach(m => {
            link = link.replaceAll(m[0], response.data.dataRow[m[1]])
          })
  
  
          responses.push({pos: i, state:'loaded', imageBase64: response.data.imageBase64, dataRow: response.data.dataRow, href: link});
  
          if (responses.length === workflowNumSnippetsToCreate)
          {
            // we are at the last request
            const newSnippetPreviewImages = {}
  
            responses.forEach(r => {
              newSnippetPreviewImages[r.pos] = r
            })
            setSnippetPreviewImages(newSnippetPreviewImages)
            // console.log(newSnippetPreviewImages);
          }
        })
  
        // setPreviewLoading(false);
        // setPreviewImageData(`data:image/jpg;base64,${response.data.imageBase64}`);
        // setSampleData(response.data.dataRow)
  
      } catch (error) {
        // setPreviewLoading(false);
        console.log(error);
      }
      
    }
  }
  const updateTestLinks = (link) => {
    // if images are not 'loaded' we do nothing
    if (snippetPreviewImages[1].state !== 'loaded') return;
   
    // we find the link and add it to test in the UI
    const newSnippetPreviewImages = {}
    const regex = "\\$\\{(.*?)\\}"
    const matches = [...link.matchAll(regex)];
    
    Object.keys(snippetPreviewImages).forEach(key => {
      let testLink = link;
      matches.forEach(m => {
        testLink = testLink.replaceAll(m[0], snippetPreviewImages[key].dataRow[m[1]])
      })
      newSnippetPreviewImages[key] = {...snippetPreviewImages[key], href: testLink}
    })
    setSnippetPreviewImages(newSnippetPreviewImages)
  
  }
              
      
  return <Grid2 container spacing={1}>
  <Grid2 xs={6}>
  <Stack spacing={2} >
    <InputText displayName="Name"
          value={snippetInfo.snippetName}      
          onChange={(val) => {handleChange('snippetName', val);}}
          error={getErrorMessage(props.validationErrorsList, 'snippetName')}
    />
    <InputText displayName="Description"
          value={snippetInfo.snippetDesc}      
          onChange={(val) => {handleChange('snippetDesc', val);}}
          error={getErrorMessage(props.validationErrorsList, 'snippetDesc')}
    />

      <LabelManager data={popupLabelManagerValue}
              compact
              onChange={handleLabelChanges}
        />
      <HrefSelector href={snippetInfo.href} fields={props.availableFieldsForHref}
                  onChange={(link) => {handleChange('href', link); updateTestLinks(link);}}
                  error={getErrorMessage(props.validationErrorsList, 'href') === undefined ? false : true}
                  helperText={getErrorMessage(props.validationErrorsList, 'href')}
      
      />
      <LionNumberInput sx={{minWidth: 330}}
          value={1}
          minValue={1}
          // increment={5}
          onChange={handleNumSnippetsToCreate}
       label='Numer of images you wish to generate' />

      <Stack direction='row' spacing={1} width='100%' display='flex' alignItems='center'>
       <Typography variant='h6'>Order by</Typography>
       <OrderByManager availableFields={props.availableFieldsForOrderBy}
                       initialConditions={snippetInfo.orderBy}
                       onChange={handleOrderByChange}
       />
     </Stack>

    <PartitionByManager dataConnectionId={props.dataConnectionId}
                       selectedFields={snippetInfo.partitionBy}
                       onChange={handlePartitionByChange}
    
    />

     <Stack spacing={0} width='100%'>
       <Typography variant='h6'>Filters</Typography>
       <ConditionsManager sx={{marginTop: 50}} availableConditions={props.availableFilters}
                               onConditionChange={handleFilterChange}
                               // clearFilter={clearFilter}
                               initialConditions={snippetInfo.filters}
                               urlParameters
                               showResetButton
                 />

     </Stack>
     <div className='flex flex-col'>
      <Typography variant='h6'>Fade In effects</Typography>
      <div className=' w-48'>
        <FadeInSelector
            fadeInFunction={snippetInfo.fadeInFunction}
            onChange={handleFadeInFunctionChange}
          />
      </div>
     </div>
   </Stack>

  </Grid2>
  <Grid2 xs={6}>
  <Stack>
      <Box display='flex' justifyContent='center' >
          <Button sx={{minWidth:150}} color='secondary' onClick={getSnippetPreviews} >
              <Iconify icon='mdi:eye-refresh-outline' width={48} />
          </Button>
      </Box>
      <Grid2 container spacing={1} sx={{ height: 530, overflowY: 'scroll'}}>
      <ThemeProvider theme={theme}>
        {
          Object.keys(snippetPreviewImages).map(i => {
            if (snippetPreviewImages[i].state === 'empty') return  (
                                        <Grid2 xs={6} display='flex' justifyContent='center' >
                                          <Box sx={{ height:250, width:200, border: `4px solid ${grey[500]}`,
                                                    borderRadius: '20px', padding: '10px', cursor:'pointer',
                                                    backgroundColor: `${grey[500]}`}}
                                                    display='flex' justifyContent='center'
                                            >
                                                <Stack spacing={2} display='flex' alignItems='center'>
                                                    <Iconify icon='streamline:interface-page-controller-loading-1-progress-loading-load-wait-waiting' width={148} />
                                                    <Typography sx={{fontSize:'22px', fontWeight:'bold'}}>Preview</Typography>
                                                </Stack>
                                          </Box>
                                        </Grid2>)
            if (snippetPreviewImages[i].state === 'loaded') return (
                                        <Grid2 xs={6} display='flex' justifyContent='center' >
                                          <Box sx={{ height:250, width:200, border: `4px solid ${grey[500]}`,
                                                    borderRadius: '20px', padding: '10px', cursor:'pointer',
                                                    backgroundColor: `${grey[500]}`,
                                                  position:'relative'}}
                                                  onMouseEnter={() => setShowTestLinkForPreviewPosition(i)}
                                                  onMouseLeave={() => setShowTestLinkForPreviewPosition(0)}
                                                  display='flex' justifyContent='center'
                                            >
                                              <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={`data:image/jpg;base64,${snippetPreviewImages[i].imageBase64}`} alt='Preview' />
                                              {showTestLinkForPreviewPosition === i && <div className=' absolute top-[25px] flex flex-row items-center gap-3'>
                                                <Button sx={{backgroundColor: ccColorDarkTeal}} variant='contained' href={snippetPreviewImages[i].href} target='_blank' rel='noreferrer' >Test Link</Button>
                                                <ImagePopover imgSrc={`data:image/jpg;base64,${snippetPreviewImages[i].imageBase64}`}></ImagePopover>
                                                </div>
                                                }
                                          </Box>
                                        </Grid2>) 
              return (
                <Grid2 key={i} xs={6} display='flex' justifyContent='center' >
                  <Box sx={{ height:250, width:200, border: `4px solid ${grey[500]}`,
                            borderRadius: '20px', padding: '10px', cursor:'pointer',
                            backgroundColor: `${grey[500]}`}}
                            display='flex' justifyContent='center' alignItems='center'
                    >
                      <CircularProgress color='customCircularLoader' size={64} />
                  </Box>
                </Grid2>)
          })
        }
        </ThemeProvider>
      </Grid2>
  </Stack>
      
  </Grid2>
</Grid2>

}

export default function GeneratorWorkFlow(props) {


const initialState = {snippetId: 0, snippetName: '', snippetDesc: '', 
                   labels: [{labelId: 0, labelName: ''}], href: '',
                   snippetTypeId: 1,
                   filters: [], orderBy: [],
                   dataConnectionId: 0, templateId: 0, dataConnectionInfo: {}};


const routeParams = useParams();
const [workflowDataConnectionId, setWorkflowDataConnectionId] = useState(null);
const [workflowDataConnectionInfo, setWorkflowDataConnectionInfo] = useState(null); // only used to read the feedType to display automatic template generator
const [workflowTemplatePreviewImages, setWorkflowTemplatePreviewImages] = useState(null); // set 1 time only. it contains info to get all preview images url for CA templates. It is passed to SnippetCAEditor as a prop
//const [createNewDataConnection, setCreateNewDataConnection] = useState(false);
const [dataConnectionNeedRefresh, setDataConnectionNeedRefresh] = useState(false);
//const [createNewTemplate, setCreateNewTemplate] = useState(false);
//const [createNewTemplateAuto, setCreateNewTemplateAuto] = useState(false);
//const [templateNeedRefresh, setTemplateNeedRefresh] = useState(false);


const [availableFilters, setAvailableFilters] = useState([]);
const [availableFieldsForOrderBy, setAvailableFieldsForOrderBy] = useState([]);
const [availableFieldsForHref, setAvailableFieldsForHref] = useState([]);



const [activeStep, setActiveStep] = useState(0);
const [snippetInfo, setSnippetInfo] = useState(initialState);
const [submitLoading, setSubmitLoading] = useState(false);
//const [showTemplateInfoForId, setShowTemplateInfoForId] = useState(0);
const [templateDataConnectionMetadata, setTemplateDataConnectionMetadata] = useState({}); // dictionary {templateId: {dataTypeInMetadata: true, filters: filters}}
// templateMetadata: every time user selects a template we need to check for its feed's metadata so that we can populate the filters and order by combos..              
const [templatesMetadata, setTemplatesMetadata] = useState({}); // dictionary {templateId: {metadata: ''}}

const [showSuccessMsg, setShowSuccessMsg] = useState(false);
const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
const [showErrorMsg, setShowErrorMsg] = useState(false);
const [showLoader, setShowLoader] = useState(false);
const [snackBarClipboardOpen, setSnackBarClipboardOpen] = useState(false);

const [preEditorProps, setPreEditorProps] = useState({});
const [templateId, setTemplateId] = useState(undefined);
const [templateMetadata, setTemplateMetadata] = useState(undefined);
const [workflowNumSnippetsToCreate, setWorkflowNumSnippetsToCreate] = useState(1);

const [addMode, setAddMode] = useState(false); //only used in an account with no feed to give a way to create a new feed from this page
const [activeErrorList, setActiveErrorList] = useState([]);

const navigate = useNavigate();

/*
const createDataConnection = () => {
  setCreateNewDataConnection(true);
}
const createTemplate = () => {
  setCreateNewTemplate(true);
}
const createTemplateAuto = () => {
  setCreateNewTemplateAuto(true);
}
*/


// load data for edit mode -----
const getSnippetInfo = async (snippetId) => {
   try {
         const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/${snippetId}`;
         const response = await axios.get(url);
       setSnippetInfo(response.data);
       if (response.data.dataConnectionId !== undefined) {
         const dataTypeInMetadata = response.data.dataConnectionInfo.feedType === 'CATALOG';
         const filters = GetFilterDefFromMetadata(JSON.parse(response.data.dataConnectionInfo.metadata), dataTypeInMetadata);
         setAvailableFilters(filters);
         setAvailableFieldsForOrderBy(filters.map(f => ({fieldName: f.conditionName, dataType: f.dataType})))
         setAvailableFieldsForHref(filters.map(f => (f.conditionName)))
         setWorkflowDataConnectionId(response.data.dataConnectionId)
       }
       // setCurrentTemplateId(response.data.templateId);
      } catch (error) {
     console.log(error);
   }
 }

const getDataConnectionInfo = async (dataConnectionId) => {
 try {
       const url = `${process.env.REACT_APP_LION_API_URL}/Feeds/info/${dataConnectionId}`;
       const response = await axios.get(url);
     
       const dataTypeInMetadata = response.data.feedType === 'CATALOG';
       const filters = GetFilterDefFromMetadata(JSON.parse(response.data.metadata), dataTypeInMetadata);
       setAvailableFilters(filters);
       setAvailableFieldsForOrderBy(filters.map(f => ({fieldName: f.conditionName, dataType: f.dataType})))
       setAvailableFieldsForHref(filters.map(f => (f.conditionName)))

       const newTemplateDataConnectionMetadata = {...templateDataConnectionMetadata, [dataConnectionId]:{'dataTypeInMetadata': dataTypeInMetadata, 'filters': filters }}
       setTemplateDataConnectionMetadata(newTemplateDataConnectionMetadata);
       // console.log(newTemplateDataConnectionMetadata)
     
 } catch (error) {
   console.log(error);
 }
}
const getTemplateMetadata = async (templateId) => {
  if (Object.prototype.hasOwnProperty.call(templatesMetadata, templateId)) {
    // console.log('already loaded');
    return;
  }

  try {
        const url = `${process.env.REACT_APP_LION_API_URL}/Templates/info/${templateId}`;
        const response = await axios.get(url);
      
 
        const newTemplatesMetadata = {...templatesMetadata, [templateId]:{'metadata': response.data }}
        setTemplatesMetadata(newTemplatesMetadata);
        console.log(newTemplatesMetadata)
      
  } catch (error) {
    console.log(error);
  }
 }

 
const getTemplatePreviewImagesInfo = async (preselectId) => {
    try {
          const url = `${process.env.REACT_APP_LION_API_URL}/Templates/previewImages`;
          const response = await axios.get(url);
          setWorkflowTemplatePreviewImages(response.data);
          if (preselectId !== undefined) setCurrentTemplateId(preselectId);
    } catch (error) {
      console.log(error);
    }
  }
  

useEffect(() => {
 if (routeParams.id === undefined) {
           // console.log('New');
        }
       else {
         getSnippetInfo(routeParams.id);
        }
}, []);

useEffect(() => {
    if (workflowTemplatePreviewImages == null) getTemplatePreviewImagesInfo();
}, [])

useEffect(() => {
  // this is necessary for the edit. 
  // when editing we don't have some objects defined and we need to run "setCurrentTemplateId"
  // in order to have a consistent state
  if (activeStep === 1 && routeParams.id !== undefined) {
    setCurrentTemplateId(snippetInfo.templateId);
  }

}, [activeStep])

const moveToNextStep = () => {

   const isValidStep = validateStep(activeStep);
   if (isValidStep) {  
     setActiveStep(activeStep + 1);
   }
 }

 const moveToPreviousStep = () => {        
   setActiveStep(activeStep - 1);
 }

 const validateStep = (numStep) => {
   let isValid = true;
   isValid = true;

   switch(numStep) {
        case 0:
            break;
        case 1:
            break;
        case 2:
            break;
        case 3:
            //validation of this steps needs to happen inside the GeneratorElementManager component

            /*
            if (snippetInfo.snippetName === '') errorVarStep0.errorTemplateName = true;
            else errorVarStep0.errorTemplateName = false;
            if (snippetInfo.snippetDesc === '') errorVarStep0.errorTemplateDescription = true;
            else errorVarStep0.errorTemplateDescription = false;
            if (snippetInfo.href === '') errorVarStep0.errorHref = true;
            else errorVarStep0.errorHref = false;

            if (snippetInfo.snippetName === '' || snippetInfo.snippetDesc === '' || snippetInfo.href === '') isValid = false;
            //setValidationVariables({...validationVariables, '0': errorVarStep0});
            */
            break;
        case 4:
            break;
        default:
           break;
     }
     console.log(isValid)
     return isValid;
 }

const setCurrentTemplateId = (id) => {
  const newSnippetInfo = {...snippetInfo, templateId: id, filters: [], orderBy: []} // filters and orderBy are reset because if you change a dataConnection you need to work with different fields and previous filter won't be valid
  setSnippetInfo(newSnippetInfo);
  getTemplateMetadata(id)
  const dataConId = getDataConnectionIdOfTemplate(id)
  if (dataConId !== undefined && dataConId !== null) {
    if (Object.prototype.hasOwnProperty.call(templateDataConnectionMetadata, dataConId) === false) {
      getDataConnectionInfo(dataConId)
    }
    else {
      const tInfo = templateDataConnectionMetadata[dataConId];
      const filters = tInfo.filters;
      setAvailableFilters(filters);
      setAvailableFieldsForOrderBy(filters.map(f => ({fieldName: f.conditionName, dataType: f.dataType})))
      setAvailableFieldsForHref(filters.map(f => (f.conditionName)))

    }
  }
}
const getDataConnectionIdOfTemplate = (templateId) => {
    const template = workflowTemplatePreviewImages.find(tInfo => tInfo.templateId === templateId);
    return template.dataConnectionId;
}

/*
const handleChange = (propName, propValue) => {
  const newSnippetInfo = {...snippetInfo, [propName]: propValue}
  setSnippetInfo(newSnippetInfo);
}
*/


const submitData = async () => {
  try {
    snippetDataValidationSchemaGenerator.validateSync(snippetInfo, {abortEarly: false});
    setActiveErrorList([])
  }
  catch (e) {
    const errorList = getErrorListFromYupError(e)
    setActiveErrorList(errorList)
    console.log(errorList)
    return false;
  }

  setShowLoader(true);
  setSubmitLoading(true);
  setSuccessHtmlMsg('');
  const dataToPost = {...snippetInfo, fadeInFunction: JSON.stringify(snippetInfo.fadeInFunction)}

  try {
  let url = `${process.env.REACT_APP_LION_API_URL}/Snippets/create/1`;
  if (routeParams.id !== undefined) url = `${process.env.REACT_APP_LION_API_URL}/Snippets/update/1/${routeParams.id}`;
  // console.log(snippetInfo)
  const response = await axios.post(url, dataToPost);
  setSuccessHtmlMsg(response.data);
  setShowSuccessMsg(true);
  } catch (error) {
  setShowSuccessMsg(false);
  setShowErrorMsg(true);
  console.log(error);
  }
  setShowLoader(false);
  setSubmitLoading(false);
  return true;
}


const copyHtmlCodeToClipboard = (code) => {
setSnackBarClipboardOpen(true);
navigator.clipboard.writeText(code);
}
const handleCloseSnackBarClipboard = () => {
setSnackBarClipboardOpen(false);
}
const SnackBarAction = (
<>
 <IconButton
   size="small"
   aria-label="close"
   color="inherit"
   onClick={handleCloseSnackBarClipboard}
 >
   <Iconify icon={lionIcons.Icon_Actions_Cancel} />
 </IconButton>
</>
);

/*
const handleCloseDialogNewConnection = (x) => {
  setCreateNewDataConnection(false)
console.log(x)
}
const handleCloseDialogNewTemplate = () => {
  setCreateNewTemplate(false)
}
const handleCloseDialogNewTemplateAuto = () => {
  setCreateNewTemplateAuto(false)
}

const handleDataConnectionCreated = (info) => {
  console.log(info)
setDataConnectionNeedRefresh(true)
}
const handleTemplateCreated = (id) => {
  console.log(id)
  setTemplateNeedRefresh(true)
  getTemplatePreviewImagesInfo(id)
}
const handleTemplateCreatedAuto = (id) => {
  console.log(id)
  setCreateNewTemplateAuto(false)
  setTemplateNeedRefresh(true)
  getTemplatePreviewImagesInfo(id)
}
*/


const handlePreEditorChange = (propsDic) => {
  //console.log('pre editor change')
  //console.log(propsDic)
  setPreEditorProps(propsDic)
}
const handleSaveExitClicked = () => {
  navigate("/ph/generator")
}
const handleNextClicked = (tId, tJson) => {
  setTemplateId(tId)
  setTemplateMetadata(tJson)
  const newSnippetInfo = {...snippetInfo, templateId: tId}
  console.log(newSnippetInfo)
  setSnippetInfo(newSnippetInfo)
  setActiveStep(3);
}
const handleDataFeedSelectionChange = (dataConId) => {
  if (Object.prototype.hasOwnProperty.call(templateDataConnectionMetadata, dataConId) === false) {
    getDataConnectionInfo(dataConId)
  }
  else {
    const tInfo = templateDataConnectionMetadata[dataConId];
    const filters = tInfo.filters;
    setAvailableFilters(filters);
    setAvailableFieldsForOrderBy(filters.map(f => ({fieldName: f.conditionName, dataType: f.dataType})))
    setAvailableFieldsForHref(filters.map(f => (f.conditionName)))

  }

}
const handleSnippetChange = (newSnippetInfo) => {
  setSnippetInfo(newSnippetInfo)
  try {
    snippetDataValidationSchemaGenerator.validateSync(newSnippetInfo, {abortEarly: false});
    setActiveErrorList([])
  }
  catch (e) {
    const errorList = getErrorListFromYupError(e)
    setActiveErrorList(errorList)
  }

  //console.log(newSnippetInfo)
}
const handleSubmit = async() => {
  const isValid = await submitData()
  if (isValid) setActiveStep(4);
}

 return <>
         {addMode && <>
        <DatafeedWizard  closingCall={() => {setAddMode(false);}} />
        </>}

{!addMode && <>
         <CcStepper2
            steps={[{ name: 'Data Connection', href: '#' },
                    { name: 'Configure Template', href: '#' },
                    { name: 'Finalize Template', href: '#' },
                    { name: 'Element', href: '#' },
                    { name: 'Confirm', href: '#' },
                ]}
            currentStepIndex={activeStep}
        />


 { activeStep === 0 &&
   <Stack spacing={3}  >
    <CcTitle>Select your connection</CcTitle>
    <DatafeedGrid
    onDatafeedSelected={(dataConnection) => {setWorkflowDataConnectionId(dataConnection.id); setWorkflowDataConnectionInfo(dataConnection); handleDataFeedSelectionChange(dataConnection.id);}}
    compact
    selectedDataconnectionId={workflowDataConnectionId}
    refreshData={dataConnectionNeedRefresh}
    onCreateFirstDataConnectionClicked={() => setAddMode(true)}
    />
    {/*
        <Dialog open={createNewDataConnection} onClose={handleCloseDialogNewConnection}
            fullWidth maxWidth='lg' >
          <DatafeedWizard 
          id={null}
         closingCall={() => {setCreateNewDataConnection(false)}}
         onDataConnectionCreated={handleDataConnectionCreated}
         />

        </Dialog>
   */}

   </Stack>
  
 }




  {activeStep === 1 && 
    <GeneratorPreEditor
        templateProps={preEditorProps}
        onChange={handlePreEditorChange}
    />}

      {activeStep === 2 &&
              <TemplateEditor id={templateId}
              embeddedInWizard
              onPreviousClicked={() => setActiveStep(1)}
              onSaveExitClicked={handleSaveExitClicked}
              onNextClicked={handleNextClicked}
              buildFromDictionary={{...preEditorProps, dataConnectionId: workflowDataConnectionId}}
              //closingCall={() => {setAddEditMode(false); setTemplateIdToEdit(null)}}
              snippetTypeId={1}

              />
      }



 {/*activeStep === 1 &&
   <Stack spacing={3} >
    <Typography variant='h4'>Select an existing template for this connection</Typography>

{workflowDataConnectionInfo !== null && workflowDataConnectionInfo.feedType === 'CATALOG' && <Button onClick={createTemplateAuto} sx={{width: 200}}>Auto Generation</Button>}

    { workflowTemplatePreviewImages === null && <Box display='flex' justifyContent='center'>
                <CircularProgress sx={{mt: 10}} size={64} />
            </Box>
    }


    <div className='grid grid-cols-4 gap-3 h-80 overflow-auto'>

        {workflowTemplatePreviewImages !== undefined && workflowTemplatePreviewImages.filter(item => (item.dataConnectionId === workflowDataConnectionId)).map((item) => (
        <div  key={item.templateId} className='flex flex-col gap-1' >
            <div className=' relative w-full h-[280px] flex flex-row items-center justify-center bg-gray-200 p-2 rounded-md'
                    onMouseEnter={() => setShowTemplateInfoForId(item.templateId)}
                    onMouseLeave={() => setShowTemplateInfoForId(0)}
                    onClick={(e) => {setCurrentTemplateId(item.templateId)}}
            >
            <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}}
                src={`${item.previewURL}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${item.previewURL}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item.templateName}
                loading="lazy"
            />
            {item.templateId === snippetInfo.templateId &&
                <SelectedIcon />
            }
            {item.templateId === snippetInfo.templateId &&
                <div className='absolute bottom-1 flex flex-row gap-1 items-center text-green-600 bg-white rounded p-1'>
                     <Iconify icon="teenyicons:tick-circle-outline"  />
                     <div className=' mt-1'>Selected</div>
                </div>
            }
              <Transition className=" absolute w-full h-full"
                show={showTemplateInfoForId === item.templateId}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                //enter="transition transform duration-1000"
                //enterFrom="translate-y-0"
                //enterTo="translate-y-[150px]"
                //leave="transition transform duration-150"
                //leaveFrom="translate-y-1/2"
                //leaveTo="translate-y-0"
            >
                <div className='absolute w-full top-1/2 h-1/2 rounded-md' style={{backgroundColor: 'rgba(70, 70, 70, 0.75)'}}>
                    <div className='flex flex-col gap-1 p-1'>
                        <div className='flex flex-row gap-2 text-white font-bold items-center'>
                            <div>{item.templateName}</div>
                            <div className='flex grow' ></div>
                            <ImagePopover imgSrc={item.previewURL}></ImagePopover>
                        </div>
                        <div className='flex flex-row gap-2 text-white items-center'>
                          <Iconify icon="iconoir:calendar" width="26" height="26" />
                          <div >{jsonDateTimeToDateOnlyPretty(item.created)}</div>
                        </div>
                        <div className='flex flex-row flex-nowrap gap-1'>
                        {item.labels.map(l => <Chip  label={l.labelName} size='small'
                                sx={{minWidth:70, maxWidth:100, backgroundColor: lionObjectsColors.Color_SnippetLabel}}/>)}
                        </div>
                    </div>
                </div>
            </Transition>

            </div>
        </div>
        ))
      }
    </div>

    <Dialog open={createNewTemplate} onClose={handleCloseDialogNewTemplate}
            fullWidth maxWidth='lg' >
              <TemplateEditor id={null} closingCall={() => {setCreateNewTemplate(false);}}
              connectToDataConnectionId={workflowDataConnectionId}
              onTemplateCreated={handleTemplateCreated}
              
              />
    </Dialog>

    <Dialog open={createNewTemplateAuto} onClose={handleCloseDialogNewTemplateAuto}
            fullWidth maxWidth='lg'
             >
              <Box sx={{p:2}}>
                <TemplatesGenerator 
                  dataConnectionId={workflowDataConnectionId}
                  onTemplateCreated={handleTemplateCreatedAuto}
                 />
              </Box>
    </Dialog>

   </Stack>
    */}

{ activeStep === 3 && <GeneratorElementManager
                        templateId={templateId}
                        dataConnectionId={workflowDataConnectionId}
                        snippetInfo={snippetInfo}
                        availableFilters={availableFilters}
                        availableFieldsForOrderBy={availableFieldsForOrderBy}
                        availableFieldsForHref={availableFieldsForHref}
                        templateMetadata={templateMetadata}
                        onChange={handleSnippetChange}
                        onNumSnippetsChange={(num) => setWorkflowNumSnippetsToCreate(num)}
                        validationErrorsList={activeErrorList}
                        />

}

{
       activeStep === 4 &&
         <Stack display='flex' justifyContent='center' alignItems='center'>

          {showLoader && <CircularProgress size={64} /> }
           {showSuccessMsg && <Stack spacing={4}><Alert severity="success" sx={{ml:'15%', mr:'15%'}}
                               >
                           <AlertTitle>Success</AlertTitle>
                           <Typography>Element created successfully</Typography>
                         </Alert>
                         <SnippetHtmlViewer numSnippets={workflowNumSnippetsToCreate}
                                            html={successHtmlMsg} />
                         </Stack>}

           {showErrorMsg && <Alert severity="error">
             <AlertTitle>Error</AlertTitle>
             Something went wrong while saving your snippet
           </Alert>}
         </Stack>
   }


{activeStep !== 2 &&
   <Box display="flex" justifyContent="flex-end" sx={{m:1}} >
     {(!showSuccessMsg && !showErrorMsg) && activeStep > 0 && <Button variant="contained" onClick={moveToPreviousStep} sx={{mr:1, backgroundColor: ccColorTeal, ":hover":{backgroundColor: ccColorDarkTeal}}}>Previous</Button>} 
     {(!showSuccessMsg && !showErrorMsg) && activeStep < 3 && <Button sx={{backgroundColor: ccColorTeal, ":hover":{backgroundColor: ccColorDarkTeal}}} variant="contained" onClick={moveToNextStep} 
                                                                    disabled={(workflowDataConnectionId == null)}
                                                                    >Next</Button>}
     {(!showSuccessMsg && !showErrorMsg) && activeStep === 3 && <LoadingButton sx={{backgroundColor: ccColorTeal, ":hover":{backgroundColor: ccColorDarkTeal}}} variant="contained" 
           onClick={handleSubmit} 
           loading={submitLoading} >Submit</LoadingButton>}
     {(showSuccessMsg || showErrorMsg) && <Button variant="contained" href='/ph/generator'>Close</Button>}
 </Box>
}

 <Snackbar
   anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
   open={snackBarClipboardOpen}
   autoHideDuration={1500}
   onClose={handleCloseSnackBarClipboard}
   message="Copied to clipboard"
   action={SnackBarAction}
 />

</>}

 </>
}
