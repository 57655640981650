'use client'

import { useState, useEffect, useCallback } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link, Paper, Stack, TextField, Typography } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { red, pink, indigo, green, blue, teal, grey } from '@mui/material/colors';

import { Icon as Iconify } from '@iconify/react';
import {lionIcons}  from './helpersIcons';


export default function ImageLibraryPicker(props) {
    const [filesTree, setFilesTree] = useState(null);
    const [selectedNode, setSelectedNode] = useState({});
    const [selectedImageId, setSelectedImageId] = useState("");
    const [showEditForNodeId, setShowEditForNodeId] = useState("");
    const [renameNodeId, setRenameNodeId] = useState("");
    const [renameNodePrettyname, setRenameNodePrettyname] = useState("");
    const [deleteNodeId, setDeleteNodeId] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState({action: '', id:'', title:'', desc:''});
  
    // const [isLoading, setIsLoading] = useState(false);

    /*
    const getData = useCallback(async (folderFilter) => {
        try {
          const url = `${process.env.REACT_APP_LION_API_URL}/User/ImageLibrary?folderFilter=${folderFilter}`;
          const response = await axios.get(url);
          if (folderFilter === '') setFilesTree(response.data); // first call
          else {
            // part of the tree has been already downloaded, we need to update it
            updateFilesTree(folderFilter, response.data);
          }
        
        } catch (error) {
          console.log(error);
        }
      }
      ,[])
*/

      const getData = async () => {
        try {
          // const url = `${process.env.REACT_APP_LION_API_URL}/User/ImageLibrary?folderFilter=`;
          // const response = await axios.get(url);

          const url = `api/User/ImageLibrary?folderFilter=`;
          const response = await fetch(url, { method: "GET", });
      
          const data = await response.json();

          
          setFilesTree(data); // first call
          // at the moment we don't have a strategy to refresh the items view after an upload
          // setSelectedNode({children: response.data})
          // after an upload we need to programmatically set the selected node in the tree
          // but for now this doesn't seem possible

        } catch (error) {
          console.log(error);
        }
      }

      const renameCommitToServer = async (nodeId, newName) => {
        try {
          // const url = `${process.env.REACT_APP_LION_API_URL}/User/ImageLibrary/rename/${nodeId}?newName=${newName}`;
          // const response = await axios.get(url);
          const url = `api/User/ImageLibrary/rename/${nodeId}?newName=${newName}`;
          const response = await fetch(url, { method: "GET", });
      
          // const data = await response.json();

        } catch (error) {
          console.log(error);
        }
      }
      const deleteCommitToServer = async (nodeId) => {
        try {
          // const url = `${process.env.REACT_APP_LION_API_URL}/User/ImageLibrary/delete/${nodeId}`;
          // const response = await axios.get(url);

          const url = `api/User/ImageLibrary/delete/${nodeId}`;
          const response = await fetch(url, { method: "GET", });

        } catch (error) {
          console.log(error);
        }
      }


      useEffect(() => {
        getData();
/*      if (filesTree === null) {
            getData();
          console.log('calling for data');
        } */
      }, [props.insertedIds])
      

    const renderTree = (nodes) => {
    if (nodes === null || nodes === undefined) return <></>;
    if (Array.isArray(nodes)) {
      const result = nodes.map(n => !n.isFolder ? <></> : <TreeItem key={n.id} nodeId={n.id.toString()} label={n.prettyName}>
      {Array.isArray(n.children)
      ? n.children.map((node) => !node.isFolder ? <></> : renderTree(node))
      : null}
      </TreeItem>
      )
      return result;
    }
    return <TreeItem key={nodes.id} nodeId={nodes.id.toString()} label={nodes.prettyName} />
   };

   const getNodeById = (id, startNodes) => {
    let result = {};
    if (startNodes === undefined) startNodes = filesTree;
    if (startNodes.length > 0) {
      for (let i=0; i<startNodes.length; i+=1) {
        const node = startNodes[i];
        if (node.id === id) {return node;}
        if (node.children.length > 0) {
          result = getNodeById(id, node.children);
        }
      }
    }
    else if (startNodes.id === id) {
      // this is a leaf
      console.log('found it');
      return result;
    }
    return result;
   }

   const handleNodeSelection = (id) => {
    let resultNode = {}
    if (id === 'root') resultNode = {children: filesTree};
    else {
      resultNode = getNodeById(id);
    }
    setSelectedNode(resultNode);
    if (props.onSelectedNodeChange !== undefined) props.onSelectedNodeChange(resultNode);
   }

   const handleNodeClick = (url, imageId) => {
    setSelectedImageId(imageId);
    if (props.onChange !== undefined) props.onChange(url);
   }

   const showNodeEditOptions = (node) => {
      if (node === null) {
        setShowEditForNodeId("");
        return;
      }
      setShowEditForNodeId(node.id);
   }

   const handleRenameNode = (nodeId, newName) => {
    renameCommitToServer(nodeId, newName)
    const node = getNodeById(nodeId);
    node.prettyName = newName;
    setRenameNodeId('')
   }

   const handleDeleteNode = (nodeId) => {
    
    const dialogNewData = {action: 'delete', id:nodeId, title:'Delete Image', desc:'Are you sure you want to delete this image? Make sure it is not used in any tag and template before'};
    setDialogData(dialogNewData);
    
    setDialogOpen(true);
   }

   const handleDialogClose = (action) => {
    if (action === 'delete') {
      deleteCommitToServer(dialogData.id)
      const node = getNodeById(dialogData.id);
      node.isDeleted = true;
    }
    setDialogData({action: '', id:'', title:'', desc:''});
  
    setDialogOpen(false);
  }

return <>
          <Grid2 container spacing={1} >
            <Grid2 xs={4}>
              <TreeView onNodeSelect={(e, ids) => handleNodeSelection(ids)}
                    aria-label="rich object"
                    defaultCollapseIcon={<Iconify icon='carbon:subtract-alt' />}
                    defaultExpanded={['root']}
                    defaultExpandIcon={<Iconify icon='material-symbols:add-circle-outline' />}
                    defaultEndIcon={<Iconify icon='uil:equal-circle' />}
                    sx={{ height: 200, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                    >
                <TreeItem key='root' nodeId='root' label='Root' >
                  {renderTree(filesTree)}              
                </TreeItem>
              </TreeView>                     
            </Grid2>
            <Grid2 xs={8}>
              <Grid2 container spacing={2} sx={{ height: 480, overflowY: 'auto' }} >
                {selectedNode.children !== undefined && 
                  selectedNode.children.map(n => n.isFolder || n.isDeleted ? <></> :
                                        <Grid2 xs={4} >                                          
                                            <Stack onClick={() => handleNodeClick(n.url, n.id)} spacing={1} 
                                                  onMouseEnter={() => showNodeEditOptions(n)}
                                                  onMouseLeave={() => showNodeEditOptions(null)}
                                                    sx={{display: 'flex', alignItems: 'center', cursor: 'pointer', borderRadius: '15px',
                                                     outline: (n.id === selectedImageId && n.id !== renameNodeId) ? `${teal[500]} solid 1px` : '#fff solid 0px'}} >
                                              <Paper sx={{p:1}} elevation={4}>
                                                <img alt={n.prettyName} src={n.url} style={{width:'120px', height:'160px'}} />
                                              </Paper>
                                                {renameNodeId !== n.id && <Typography>{n.prettyName}</Typography>}
                                                {renameNodeId === n.id && <Stack direction='row' spacing={0}>
                                                                            <TextField sx={{width: '100px'}}
                                                                              size="small"
                                                                              value={renameNodePrettyname}
                                                                              onChange={(e) => setRenameNodePrettyname(e.target.value)}
                                                                            />
                                                                                <IconButton onClick={() => handleRenameNode(n.id, renameNodePrettyname)} aria-label="edit">
                                                                                <Iconify icon='mdi:tick-circle-outline' />
                                                                                </IconButton>
                                                                                <IconButton onClick={() => setRenameNodeId('')} aria-label="delete">
                                                                                <Iconify icon='mdi:alpha-x-circle-outline' />
                                                                                </IconButton>
                                                                        </Stack>
                                                }
                                                {props.editEnabled && showEditForNodeId === n.id && <Stack direction='row'>
                                                                                <IconButton onClick={() => {setRenameNodeId(n.id); setRenameNodePrettyname(n.prettyName)}} aria-label="edit">
                                                                                <Iconify icon='material-symbols:edit' />
                                                                                </IconButton>
                                                                                <IconButton onClick={() => handleDeleteNode(n.id)} aria-label="delete">
                                                                                <Iconify icon='material-symbols:delete-outline' />
                                                                                </IconButton>
                                                                              </Stack>}                           
                                            </Stack>                                          
                                        </Grid2> )
                                        
                                        }
              </Grid2>
            </Grid2>
          </Grid2>
          <Dialog onClose={() => handleDialogClose('abort')} open={dialogOpen}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description">
      <DialogTitle id="dialog-title">{dialogData.title}</DialogTitle>
      <DialogContent>
          <DialogContentText id="dialog-description">
            {dialogData.desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose('abort')}>Cancel</Button>
          <Button onClick={() => handleDialogClose('delete')} autoFocus>
            Confirm
          </Button>
        </DialogActions>

</Dialog>

    </>
}