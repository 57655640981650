import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const dateRangeShortcutsItems = [
    {
      label: 'This Week',
      getValue: () => {
        const today = dayjs.utc();
        return [today.startOf('week'), today.endOf('week')];
      },
    },
    {
      label: 'Last Week',
      getValue: () => {
        const today = dayjs.utc();
        const prevWeek = today.subtract(7, 'day');
        return [prevWeek.startOf('week'), prevWeek.endOf('week')];
      },
    },
    {
      label: 'Last 7 Days',
      getValue: () => {
        const today = dayjs.utc();
        return [today.subtract(7, 'day'), today];
      },
    },
    {
      label: 'Current Month',
      getValue: () => {
        const today = dayjs.utc();
        return [today.startOf('month'), today.endOf('month')];
      },
    },
    { label: 'Reset', getValue: () => [null, null] },
  ];

  export {dateRangeShortcutsItems}