import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import {LionLoaderGradient} from "../commonComponents/generalComponents"

const Logout = () => {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    setToken();
    navigate("/profile", { replace: true });
  };

  
  setTimeout(() => {
    handleLogout();
  }, 3 * 1000);

  return <div className="flex flex-col w-full items-center mt-12 gap-2">
    <div>Logging out</div>
    <LionLoaderGradient />
    </div>;
};

export default Logout;

