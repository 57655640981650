import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

import { CcStepper2 } from "../commonComponents/stepper"
import { InputText, InputTextPassword } from "../commonComponents/inputFields";
import { CcActionButton } from "../commonComponents/styledTexts";
import { LionLoader, LionLoaderBig, LionLoaderSmall } from "../commonComponents/generalComponents";

import { object as yupObject, string, } from 'yup';
import { getErrorListFromYupError, getErrorMessage } from '../components/helperErrors';

import axios from 'axios';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import "./stripe.css";
import { ccColorDarkTeal, ccColorTeal } from "../components/helpersStyles";
import { Icon as Iconify } from '@iconify/react';

const emailString = string().email();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


export const ActivateSubscriptionForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }
    setIsLoading(true)
    setErrorMessage(null)

    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: props.returnURL ? props.returnURL : `${process.env.REACT_APP_STRIPE_SUBSCRIPTION_CHECKOUT_RETURN_URL}?fName=${encodeURIComponent(props.customerFirstname)}`,
      }
    });
    setIsLoading(false)

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      console.log(error)
      if (error.type !== "validation_error") setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form className="stripeForm" onSubmit={handleSubmit}>
      <PaymentElement />
      <button className="stripeButton mt-4" disabled={!stripe || !elements} id="submit">
        {isLoading &&
          <div role="status" className="flex justify-center">
              <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-ccColorSecondaryPink" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
          </div>
        }
        {!isLoading && <>Submit</>}
        
        </button>
      {/* Show error message to your customers */}
      {errorMessage && <div class="flex items-center p-4 mt-3 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
                        <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                        </svg>
                        <div>
                          {errorMessage}
                        </div>
                      </div>
      }
    </form>
  )
};

export const NewUserWorkflow = (props) => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [email, setEmail] = useState("")
    const [emailExistsMsg, setEmailExistsMsg] = useState("")
    const [businessName, setBusinessName] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [firstname, setFirstname] = useState("")
    const [surname, setSurname] = useState("")

    const [loginName, setLoginName] = useState("")
    const [loginNameExistsMsg, setLoginNameExistsMsg] = useState(null)
    const [clientSecret, setClientSecret] = useState(undefined)
    const [isValidPlan, setIsValidPlan] = useState(false)
    const [planInfo, setPlanInfo] = useState(undefined)
    const [freeLoginSuccess, setFreeLoginSuccess] = useState(false)

    const [activeErrorList, setActiveErrorList] = useState([]);

    const [showLoaderProductInfo, setShowLoaderProductInfo] = useState(true)
    const [showLoaderUserForm, setShowLoaderUserForm] = useState(false)
    const [showLoaderUserFormFreeTrial, setShowLoaderUserFormFreeTrial] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const URLparams = new URLSearchParams(document.location.search);
    const plan = URLparams.get("plan");
    
    const URL_PRICING_PAGE = "https://codechemistry.io/pricing/" 

    
    useEffect(() => {
      //check for a valid plan name
      if (plan !== null) {
        checkProductIsValid(plan);
      }
      else {
        setIsValidPlan(false)
        setShowLoaderProductInfo(false)
      }
    }, [plan])

    let options = {
      // passing the SetupIntent's client secret
      //clientSecret: '{{CLIENT_SECRET}}',
      clientSecret: clientSecret,
      // Fully customizable with appearance API.
      appearance: {
      
        variables: {
          colorPrimary: ccColorDarkTeal,
          colorBackground: '#ffffff',
          colorText: '#30313d',
          colorDanger: '#df1b41',
          fontFamily: 'Roboto, Poppins, Ideal Sans, system-ui, sans-serif',
          spacingUnit: '2px',
          borderRadius: '4px',
          // See all possible variables below
        }
      },
    };
    
    const userFormValidationSchema = yupObject({
      businessName: string().required("Required field"),
      firstname: string().required("Required field"),
      surname: string().required("Required field"),
      loginName: string().required("Required field").matches(/^(?!.*@).*$/,{message: "Must not contain the @ character"}).min(6, "Must be at least 6 characters long"),
      password: string().required("Required field").min(8, "Must be at least 8 characters long"),
      confirmPassword: string().test(
        'validatePassword',
        '',
        (value, context) => {
          if (value === '' || value !== context.parent.password)
            return context.createError({path: 'confirmPassword', message: "Passwords don't match"});
          else return true;
        }
      )
    });

    const createUserFormDataObject = () => {
      return {businessName: businessName, loginName: loginName, password: password, confirmPassword: confirmPassword, firstname: firstname, surname: surname}
    }
    const validateUserForm = (formData) => {
      let result = false;
      try {
        userFormValidationSchema.validateSync(formData, {abortEarly: false});
        setActiveErrorList([])
        result = true;
      }
      catch (e) {
        const errorList = getErrorListFromYupError(e)
        //console.log(errorList)
        setActiveErrorList(errorList)
        result = false;
      }
      if (email === '') {
        setEmailExistsMsg("Email is required")
        result = false;
      }

      return result;
    }

    const handleEmailChange = (newEmail) => {
        setEmail(newEmail)
        const isValidEmail = emailString.isValidSync(newEmail);
        if (isValidEmail) {
          checkEmailExists(newEmail)
        }
      }
    
    const checkEmailExists = async (email) => {
      if (email === '') {
        setEmailExistsMsg("Email is required")
        return;
      }
    try {
        const url = `${process.env.REACT_APP_LION_API_URL}/email/exists/${email}`;
        const response = await axios.get(url);

        if (response.data === true) setEmailExistsMsg("This email already exists")
        else setEmailExistsMsg("")

    return response.data;
                
    } catch (error) {
        console.log(error);
    }
    }
    
    const handleLoginChange = (newLogin) => {
      const objToValidate = createUserFormDataObject()
      objToValidate.loginName = newLogin;

        if (activeErrorList.length > 0) validateUserForm(objToValidate)
        setLoginName(newLogin)
        if (newLogin.length >= 6) {
            checkLoginExists(newLogin)
        }
      }
    const handleBusinessNameChange = (newName) => {
      const objToValidate = createUserFormDataObject()
      objToValidate.businessName = newName
      if (activeErrorList.length > 0) validateUserForm(objToValidate)

        setBusinessName(newName)
    }
    const handleFirstnameChange = (newName) => {
      const objToValidate = createUserFormDataObject()
      objToValidate.firstname = newName
      if (activeErrorList.length > 0) validateUserForm(objToValidate)

        setFirstname(newName)
    }
    const handleSurnameChange = (newName) => {
      const objToValidate = createUserFormDataObject()
      objToValidate.surname = newName
      if (activeErrorList.length > 0) validateUserForm(objToValidate)

        setSurname(newName)
    }

    const handleConfirmPasswordChange = (newName) => {
      const objToValidate = createUserFormDataObject()
      objToValidate.confirmPassword = newName
      //if (activeErrorList.length > 0) validateUserForm(objToValidate)
      validateUserForm(objToValidate)

        setConfirmPassword(newName)
    }
    const handlePasswordChange = (newName) => {
      const objToValidate = createUserFormDataObject()
      objToValidate.password = newName
      if (activeErrorList.length > 0) validateUserForm(objToValidate)

        setPassword(newName)
    }

    const checkLoginExists = async (login) => {
        try {
            const url = `${process.env.REACT_APP_LION_API_URL}/email/login/exists/${login}`;
            const response = await axios.get(url);
            if (response.data === true) setLoginNameExistsMsg("This login already exists")
            else setLoginNameExistsMsg(null)
    
        return response.data;
                    
        } catch (error) {
            console.log(error);
        }    
    }

    const checkProductIsValid = async(prodId) => {
      try {
          setShowLoaderProductInfo(true)
          const url = `${process.env.REACT_APP_LION_API_URL}/stripe/product/isvalid/${prodId}`;
          const response = await axios.get(url);
  
          const isValid = response.data.isValid;
          setIsValidPlan(isValid)
          if (isValid) setPlanInfo(response.data.product)
          else setPlanInfo(undefined)
      } catch (error) {
          return false;
      }
      finally {setShowLoaderProductInfo(false)}

  }

  const submitCreateUser = async() => {
    
    const objToValidate = createUserFormDataObject()
    const isValidForm = validateUserForm(objToValidate);

    if (!isValidForm) return;

    try {
      window.lintrk('track', { conversion_id: 18911274 });
    }
    catch (e){}

    try {
      setShowLoaderUserForm(true)
      setShowLoaderUserFormFreeTrial(true)
      setErrorMessage("")
        //const url = `${process.env.REACT_APP_LION_API_URL}/create-new-user`;
        const url = `${process.env.REACT_APP_LION_API_URL}/stripe/create-new-user-with-subscription`;
        const response = await axios.post(url, {subscriptionPlan: plan, email: email, name: loginName, businessName: businessName, password: password, firstname: firstname, surname: surname});

        //if (response.data === true) setLoginNameExistsMsg("This login already exists")
        //else setLoginNameExistsMsg("")
        if (response.data.error) {
          if (response.data.field === "email") setEmailExistsMsg(response.data.error);
          else setErrorMessage(response.data.error);
          return;
        }
        if (response.data.loginType === "free") {
          //it's a free login
          setFreeLoginSuccess(response.data.success)
        }
        else {
          options.clientSecret = response.data.clientSecret;
          setClientSecret(response.data.clientSecret)
        }

        setCurrentStepIndex(1);
        //console.log(options);
                
    } catch (error) {
        //console.log(error);
        setErrorMessage("Oops something went wrong");
    }
    finally {setShowLoaderUserForm(false); setShowLoaderUserFormFreeTrial(false)}

    }

    return <div style={{backgroundSize: 'cover', minHeight: 'calc(100vh - 20px)', backgroundImage: `url("/assets//backgroundDashboardCircles.png")`}}>

        <Helmet>
            {/* Adding linkedin tracking */}
            <script type="text/javascript">{`_linkedin_partner_id = "6535586"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id); `}</script>
            <script type="text/javascript">{`(function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);`}</script>
            <noscript>{`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=6535586&fmt=gif" />`}</noscript>
        </Helmet>


          <div className='flex flex-row items-center w-full gap-20 font-poppins bg-gradient-to-r from-ccColorDarkTeal to-ccColorTeal'>
            <img src='/assets/logoWhite.png' className=' h-20 w-auto ml-8' alt='logo'/>
         </div>

    {errorMessage.length > 0 && <div className="flex flex-col items-center justify-center mt-1">
          <div class="w-96 flex p-4 mb-0 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
                <div className="flex gap-x-2">
                    <span class="font-semibold">Error</span> 
                    <div>{errorMessage}</div>
                </div>
            </div>
          </div>
    }
      {showLoaderProductInfo &&
        <div className="mt-16">
        <LionLoaderBig />
        <div className=" mt-2 flex justify-center text-ccColorDarkTeal ">Loading product data...</div>
      </div>
      }
    
    {isValidPlan && !showLoaderProductInfo &&
          <div className=" mx-[10%]">
                <CcStepper2
                steps={[{ name: 'Create account', href: '#' },
                        { name: 'Payment options', href: '#' },
                    ]}
                currentStepIndex={currentStepIndex}
                />

    <div className="grid grid-cols-2">
      {currentStepIndex === 0 && !showLoaderUserForm &&
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
              <div className=" sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="font-poppins text-center text-2xl font-bold leading-9 tracking-tight text-ccColorDarkTeal">
                  Create an account
                </h2>
              </div>

              <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-5">
                  <InputText displayName="Firstname" 
                        value={firstname}
                        onChange={handleFirstnameChange}
                        error={getErrorMessage(activeErrorList, 'firstname')}
                  />
              </div>
              <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-5">
                  <InputText displayName="Surname" 
                        value={surname}
                        onChange={handleSurnameChange}
                        error={getErrorMessage(activeErrorList, 'surname')}
                  />
              </div>
              <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-5">
                  <InputText displayName="Business email" 
                        value={email}
                        onChange={handleEmailChange}
                        error={emailExistsMsg}
                  />
              </div>
              <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-5">
                  <InputText displayName="Business name" 
                        value={businessName}
                        onChange={handleBusinessNameChange}
                        error={getErrorMessage(activeErrorList, 'businessName')}
                        />
              </div>
              <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-5">
                  <InputText displayName="Username" 
                        value={loginName}
                        onChange={handleLoginChange}
                        error={loginNameExistsMsg ?? getErrorMessage(activeErrorList, 'loginName')}
                  />
              </div>
              <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-5">
                  <InputTextPassword displayName="Password" 
                        value={password}
                        onChange={handlePasswordChange}
                        error={getErrorMessage(activeErrorList, 'password')}
                  />
              </div>
              <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-5">
                  <InputTextPassword displayName="Confirm Password" 
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        error={getErrorMessage(activeErrorList, 'confirmPassword')}
                  />
              </div>
              <div className="mt-3 max-w-sm mx-auto font-poppins text-xs tracking-tight text-gray-900">
                  By creating an account, you agree to our <a href="https://codechemistry.io/terms-conditions/" target="_blank" rel='noreferrer' class="font-medium text-ccColorTeal hover:underline">Terms</a> and have read and acknowledged our <a href="https://codechemistry.io/privacy-policy/" target="_blank" rel='noreferrer' class="font-medium text-ccColorTeal hover:underline">Privacy Policy</a> 
                </div>

              <div className=" mx-auto w-full max-w-sm mt-6">
                    <CcActionButton className=" w-full flex justify-center"
                    onClick={submitCreateUser}
                    >
                      Next
                    </CcActionButton>
              </div>

            </div>      
      }
      {currentStepIndex === 0 && showLoaderUserForm &&
      <div className="flex flex-col justify-center items-center">
        <LionLoader />
        <div className="mt-2 text-ccColorDarkTeal">Creating account</div>
      </div>
      }
      {currentStepIndex === 1 && clientSecret &&
      <div>
            <Elements stripe={stripePromise} options={options}>
      <ActivateSubscriptionForm customerFirstname={firstname} />
    </Elements>
      </div>
        
      }
      
      <div className="mt-[58px] flex flex-col gap-6 items-center font-poppins">
        <div className=" text-2xl font-bold text-ccColorDarkTeal">{planInfo.productName}</div>
        <div style={{color: planInfo.metadata.textColor}} className=" text-2xl  mt-2">{planInfo.metadata.price}</div>
        {JSON.parse(planInfo.metadata.features).map(f => <div className=" text-ccColorDarkTeal font-semibold">{f}</div>)}
        <div className="mt-2">Thinking of a different plan? <a href={URL_PRICING_PAGE} class="font-medium text-ccColorTeal hover:underline">Click here to view other options</a></div>
      </div>
    </div>
    </div>
    }
    {!isValidPlan && !showLoaderProductInfo &&
          <div className=" mx-[10%]">
      {currentStepIndex === 0 &&  <div className="grid grid-cols-1 md:grid-cols-2">

        <Helmet>
            {/* Adding GA to the free trial page */}
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-3SCG4MYL8S"></script>
            <script type="application/ld+json">{`window.dataLayer = window.dataLayer || [];
                          function gtag(){dataLayer.push(arguments);}
                          gtag('js', new Date());
                          gtag('config', 'G-3SCG4MYL8S');`}
            </script>
        </Helmet>

        <div className="flex flex-col px-6 font-poppins text-ccColorDarkTeal mt-12 bg-white rounded">
          <div className="font-bold text-2xl mt-3">Turbocharge Your Email Campaigns</div>
          <div className="mt-4">Create innovative, engaging content using dynamic elements. Boost message performance, revenue, and customer loyalty with a platform that's more affordable, flexible, and feature-packed than the competition.</div>
          <div className="mt-5 font-semibold">Why Create a Free Trial Account?</div>
          <div className="mt-4 flex flex-col gap-y-4">
            <div className="flex flex-row gap-x-1">
                  <div className="w-5 pt-[2px]"><Iconify icon="mdi:tick-circle" width={18} height={18} color="#22c55e" /></div>
                  <div><span className="font-semibold">Full Access to All Features for 7 days - </span> no limitations or paywalls.</div>
            </div>
            <div className="flex flex-row gap-x-1">
              <div className="w-5 pt-[2px]"><Iconify icon="mdi:tick-circle" width={18} height={18} color="#22c55e" /></div>
              <div><span className="font-semibold">1000 Free Test Images - </span> enjoy 1000 images for free to test with.</div>
            </div>
            <div className="flex flex-row gap-x-1">
              <div className="w-5 pt-[2px]"><Iconify icon="mdi:tick-circle" width={18} height={18} color="#22c55e" /></div>
              <div><span className="font-semibold">No Credit Card Required - </span> sign up today, risk-free & card free.</div>
            </div>
            <div className="flex flex-row gap-x-1">
              <div className="w-5 pt-[2px]"><Iconify icon="mdi:tick-circle" width={18} height={18} color="#22c55e" /></div>
              <div><span className="font-semibold">ESP Agnostic - </span> compatible with any email service provider.</div>
            </div>
            <div className="flex flex-row gap-x-1">
              <div className="w-5 pt-[2px]"><Iconify icon="mdi:tick-circle" width={18} height={18} color="#22c55e" /></div>
              <div><span className="font-semibold">Boost Email Performance - </span> more clicks, engagement, and revenue.</div>
            </div>
            <div className="flex flex-row gap-x-1">
              <div className="w-5 pt-[2px]"><Iconify icon="mdi:tick-circle" width={18} height={18} color="#22c55e" /></div>
              <div><span className="font-semibold">Quick & Easy Setup - </span> no coding needed, just design, generate, copy, paste, and go.</div>
            </div>
            <div className="flex flex-row gap-x-1">
              <div className="w-5 pt-[2px]"><Iconify icon="mdi:tick-circle" width={18} height={18} color="#22c55e" /></div>
              <div><span className="font-semibold">Flexible Plans - </span> monthly, annual, and Black Friday/Peak packages available.</div>
            </div>
          </div>

          <div className="mt-6">Looking for paid plans? <a href={URL_PRICING_PAGE} class="font-medium text-ccColorTeal hover:underline">Check out other plans options here.</a></div>
        </div>

          <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className=" sm:mx-auto sm:w-full sm:max-w-sm">
              <h2 className="mt-3 font-poppins text-center text-2xl font-bold leading-9 tracking-tight text-ccColorDarkTeal">
                Create a free account
              </h2>
            </div>
            <div className="flex justify-center text-sm mx-auto max-w-sm mt-4 font-poppins"><div className=" text-center">Complete the form to create your free account.</div></div>

            <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-5">
                      <InputText displayName="Firstname" 
                            value={firstname}
                            onChange={handleFirstnameChange}
                            error={getErrorMessage(activeErrorList, 'firstname')}
                      />
            </div>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-5">
                <InputText displayName="Surname" 
                      value={surname}
                      onChange={handleSurnameChange}
                      error={getErrorMessage(activeErrorList, 'surname')}
                />
            </div>

            <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-5">
                <InputText displayName="Business email" 
                      value={email}
                      onChange={handleEmailChange}
                      error={emailExistsMsg}
                />
            </div>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-5">
                <InputText displayName="Business name" 
                      value={businessName}
                      onChange={handleBusinessNameChange}
                      error={getErrorMessage(activeErrorList, 'businessName')}
                      />
            </div>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-5">
                <InputText displayName="Username" 
                      value={loginName}
                      onChange={handleLoginChange}
                      error={loginNameExistsMsg ?? getErrorMessage(activeErrorList, 'loginName')}
                      />
            </div>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-5">
                      <InputTextPassword displayName="Password" 
                            value={password}
                            onChange={handlePasswordChange}
                            error={getErrorMessage(activeErrorList, 'password')}
                      />
                  </div>
                  <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-5">
                      <InputTextPassword displayName="Confirm Password" 
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            error={getErrorMessage(activeErrorList, 'confirmPassword')}
                      />
                  </div>
            <div className="mt-3 max-w-sm mx-auto font-poppins text-xs tracking-tight text-gray-900">
                By creating an account, you agree to our <a href="https://codechemistry.io/terms-conditions/" target="_blank" rel='noreferrer' class="font-medium text-ccColorTeal hover:underline">Terms</a> and have read and acknowledged our <a href="https://codechemistry.io/privacy-policy/" target="_blank" rel='noreferrer' class="font-medium text-ccColorTeal hover:underline">Privacy Policy</a> 
              </div>

            <div className=" mx-auto w-full max-w-sm mt-6">
                  <CcActionButton className=" w-full flex justify-center"
                  onClick={submitCreateUser}
                  >
                    {showLoaderUserFormFreeTrial ? 
                            <LionLoaderSmall />
                            :
                            <>Create</>
                    }
                  </CcActionButton>
            </div>

          </div>
      </div>
      }
      {currentStepIndex === 1 && 
      <div>
        {freeLoginSuccess && <div className="flex flex-col items-center justify-center mt-10">
            <div class="w-96 flex items-center justify-center p-4 mb-4 text-green-800 border border-green-300 rounded-lg bg-green-50">
                <div className="flex flex-col">
                    <div className=" mb-2 font-semibold">Success</div>                    
                </div>
            </div>
            <div class="w-96 flex items-center justify-center p-4 mb-4 border border-gray-200 rounded-lg">
                <div className="flex flex-col text-ccColorDarkTeal">
                    <div className=" mb-2">Your account is set up. Please verify your email address and then login.</div>
                </div>
            </div>
          </div>}
        {!freeLoginSuccess && <div className="flex flex-col items-center justify-center mt-10">
          <div class="w-96 flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
                <div className="flex gap-x-2">
                    <span class="font-semibold">Error</span> 
                    <div>Something went wrong, please try again.</div>
                </div>
            </div>
            <div class="w-96 flex items-center justify-center p-4 mb-4 border border-gray-200 rounded-lg">
                <div className="flex flex-col">
                    <button className="ocus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                     onClick={() => window.location.reload()}>Go back</button>
                    
                </div>
            </div>

          </div>}
      </div>
        
      }

            </div>}
    </div>
}