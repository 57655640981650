'use client'

import { CheckIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import {lionGradient1} from '../components/helpersStyles'
import { Icon as Iconify } from '@iconify/react';
import { CcActionButton } from './styledTexts';
/*
props
steps = [{ name: 'Job details', href: '#', }] where href is optional
currentStepIndex = 0 (zero-based)
*/

const _steps = [
  { id: '1', name: 'Job details', href: '#', status: 'complete' },
  { id: '2', name: 'Application form', href: '#', status: 'current' },
  { id: '3', name: 'Preview', href: '#', status: 'upcoming' },
]

export function Stepper(props) {
    const [steps, setSteps] = useState(_steps);

    useEffect(() => {
        const getStatus = (stepIndex, currentStepIndex) => {
            if (currentStepIndex === undefined) {
                return stepIndex === 0 ? 'current' : 'upcoming' 
            }

            if (stepIndex < currentStepIndex) return 'complete'
            else if (stepIndex === currentStepIndex) return 'current'
            else return 'upcoming'
        }

        if (props.steps !== undefined) {
            const newSteps = props.steps.map((step, index) => {
                return {id: index + 1, name: step.name, 
                    href: step.herf === undefined ? '#' : step.href,
                status:  getStatus(index, props.currentStepIndex)}
            })
            setSteps(newSteps);
        }
    },[props.steps])


  return (
    <nav aria-label="Progress">
      <ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {step.status === 'complete' ? (
              <a href={step.href} className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                    <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                </span>
              </a>
            ) : step.status === 'current' ? (
              <a href={step.href} className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                  <span className="text-indigo-600">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
              </a>
            ) : (
              <a href={step.href} className="group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                </span>
              </a>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export function LionStepper(props) {
  /*
  props
  steps = [{ name: 'Job details', href: '#', }] where href is optional
  currentStepIndex = 0 (zero-based)
  */

  
  const renderStep = (stepNum, stepName, isLastStep) => {
    return  <div className={!isLastStep ? 'grow' : 'flex items-center'}> <div className='flex flex-row gap-2 items-center'> 
      <div className=" w-12 h-12 rounded-full bg-gradient-to-r from-lionGradient1From via-lionGradient1Via to-lionGradient1To p-1">
        <div className="flex h-full w-full rounded-full items-center justify-center bg-white">
          <h1 className="bg-gradient-to-r gradient-to-r from-lionGradient1From via-lionGradient1Via to-lionGradient1To inline-block text-transparent font-bold bg-clip-text ">{stepNum}</h1>
        </div>
      </div>
      <div>{stepName}</div>
      {!isLastStep && <hr className=" h-1 grow mx-auto my-4 bg-gray-200 border-0 rounded md:my-10 dark:bg-gray-700"></hr>}
  </div>
  </div>
  }
  const renderActiveStep = (stepNum, stepName, isLastStep) => {
    return  <div className={!isLastStep ? 'grow' : 'flex items-center'}> <div className='flex flex-row gap-2 items-center'> 
      <div className=" w-12 h-12 rounded-full bg-gradient-to-r from-lionGradient1From via-lionGradient1Via to-lionGradient1To flex items-center justify-center">
          <div className=' font-bold'>{stepNum}</div>
      </div>
      <div className=' font-bold'>{stepName}</div>
      {!isLastStep && <hr className=" h-1 grow mx-auto my-4 bg-gray-200 border-0 rounded md:my-10 dark:bg-gray-700"></hr>}
  </div>
  </div>
  }
  const renderCompletedStep = (stepNum, stepName, isLastStep) => {
    return  <div className={!isLastStep ? 'grow' : 'flex items-center'}> <div className='flex flex-row gap-2 items-center'> 
      <div className=" w-12 h-12 rounded-full bg-gradient-to-r from-lionGradient1From via-lionGradient1Via to-lionGradient1To flex items-center justify-center">
        <CheckIcon className="h-6 w-6 text-black" aria-hidden="true" />
      </div>
      <div>{stepName}</div>
      {!isLastStep && <hr className=" h-1 grow mx-auto my-4 bg-gray-200 border-0 rounded md:my-10 dark:bg-gray-700"></hr>}
  </div>
  </div>
  }

  return   <div className='flex flex-row gap-3 w-full justify-between'>
    {props.steps.map((step, index) => {
                                const isLast = index + 1 === props.steps.length;
                                if (index === props.currentStepIndex) return renderActiveStep(index + 1, step.name, isLast);
                                else if (index < props.currentStepIndex) return renderCompletedStep(index + 1, step.name, isLast);
                                else return renderStep(index + 1, step.name, isLast);
                                })}
  </div>
}

export function CcStepper(props) {
  /*
  props
  steps = [{ name: 'Job details', href: '#', }] where href is optional
  currentStepIndex = 0 (zero-based)
  */

  
  const renderStep = (stepNum, stepName, isLastStep) => {
    return  <div className={!isLastStep ? 'grow' : 'flex items-center'}> <div className='flex flex-row gap-2 items-center'> 
      <div className=" w-12 h-12 rounded-full bg-gradient-to-r from-ccColorDarkTeal to-ccColorLightTeal p-1">
        <div className="flex h-full w-full rounded-full items-center justify-center bg-white">
          <h1 className="bg-gradient-to-r gradient-to-r from-ccColorDarkTeal to-ccColorLightTeal inline-block text-transparent font-bold bg-clip-text ">{stepNum}</h1>
        </div>
      </div>
      <div className=' font-poppins text-ccColorDarkTeal'>{stepName}</div>
      {!isLastStep && <hr className=" h-1 grow mx-auto my-4 bg-gray-200 border-0 rounded md:my-10 dark:bg-gray-700"></hr>}
  </div>
  </div>
  }
  const renderActiveStep = (stepNum, stepName, isLastStep) => {
    return  <div className={!isLastStep ? 'grow' : 'flex items-center'}> <div className='flex flex-row gap-2 items-center'> 
      <div className=" w-12 h-12 rounded-full bg-gradient-to-r from-ccColorDarkTeal to-ccColorLightTeal flex items-center justify-center">
          <div className=' font-bold'>{stepNum}</div>
      </div>
      <div className=' font-poppins font-semibold text-ccColorDarkTeal'>{stepName}</div>
      {!isLastStep && <hr className=" h-1 grow mx-auto my-4 bg-gray-200 border-0 rounded md:my-10 dark:bg-gray-700"></hr>}
  </div>
  </div>
  }
  const renderCompletedStep = (stepNum, stepName, isLastStep) => {
    return  <div className={!isLastStep ? 'grow' : 'flex items-center'}> <div className='flex flex-row gap-2 items-center'> 
      <div className=" w-12 h-12 rounded-full bg-gradient-to-r from-ccColorDarkTeal to-ccColorLightTeal flex items-center justify-center">
        <CheckIcon className="h-6 w-6 text-black" aria-hidden="true" />
      </div>
      <div>{stepName}</div>
      {!isLastStep && <hr className=" h-1 grow mx-auto my-4 bg-gray-200 border-0 rounded md:my-10 dark:bg-gray-700"></hr>}
  </div>
  </div>
  }

  return   <div className='flex flex-row gap-3 w-full justify-between'>
    {props.steps.map((step, index) => {
                                const isLast = index + 1 === props.steps.length;
                                if (index === props.currentStepIndex) return renderActiveStep(index + 1, step.name, isLast);
                                else if (index < props.currentStepIndex) return renderCompletedStep(index + 1, step.name, isLast);
                                else return renderStep(index + 1, step.name, isLast);
                                })}
  </div>
}

export function CcStepper2(props) {
  /*
  props
  steps = [{ name: 'Job details', href: '#', }] where href is optional
  currentStepIndex = 0 (zero-based)
  */

  
  const renderStep = (stepNum, stepName, isLastStep) => {
    return  <div className={!isLastStep ? 'grow' : 'flex items-center'}> <div className='flex flex-row gap-2 items-center'> 
      <div className=" w-12 h-12 rounded-full bg-ccColorDarkTeal p-1">
        <div className="flex h-full w-full rounded-full items-center justify-center bg-white">
          <h1 className=" bg-ccColorDarkTeal inline-block text-transparent font-bold bg-clip-text ">{stepNum}</h1>
        </div>
      </div>
      <div className=' font-poppins text-ccColorDarkTeal'>{stepName}</div>
      {!isLastStep && <hr className=" h-1 grow mx-auto my-4 bg-gray-200 border-0 rounded md:my-10 dark:bg-gray-700"></hr>}
  </div>
  </div>
  }
  const renderActiveStep = (stepNum, stepName, isLastStep) => {
    return  <div className={!isLastStep ? 'grow' : 'flex items-center'}> <div className='flex flex-row gap-2 items-center'> 
      <div className=" w-12 h-12 rounded-full bg-ccColorDarkTeal flex items-center justify-center">
          <div className=' text-white font-bold'>{stepNum}</div>
      </div>
      <div className=' font-poppins font-semibold text-ccColorDarkTeal'>{stepName}</div>
      {!isLastStep && <hr className=" h-1 grow mx-auto my-4 bg-gray-200 border-0 rounded md:my-10 dark:bg-gray-700"></hr>}
  </div>
  </div>
  }
  const renderCompletedStep = (stepNum, stepName, isLastStep) => {
    return  <div className={!isLastStep ? 'grow' : 'flex items-center'}> <div className='flex flex-row gap-2 items-center'> 
      <div className=" w-12 h-12 rounded-full bg-ccColorDarkTeal flex items-center justify-center text-white">
        {/*<CheckIcon className="h-6 w-6 text-white" aria-hidden="true" /> */}
        <Iconify icon="mdi:tick" width={24} />
      </div>
      <div className=' font-poppins text-ccColorDarkTeal'>{stepName}</div>
      {!isLastStep && <hr className=" h-1 grow mx-auto my-4 bg-gray-200 border-0 rounded md:my-10 dark:bg-gray-700"></hr>}
  </div>
  </div>
  }

  return   <div className='flex flex-row gap-3 w-full justify-between'>
    {props.steps.map((step, index) => {
                                const isLast = index + 1 === props.steps.length;
                                if (index === props.currentStepIndex) return renderActiveStep(index + 1, step.name, isLast);
                                else if (index < props.currentStepIndex) return renderCompletedStep(index + 1, step.name, isLast);
                                else return renderStep(index + 1, step.name, isLast);
                                })}
  </div>
}

export function StepperNavButtons(props) {
  /*
  props
  totalSteps: num
  currentStepNum: zero-based num
  onChange:
  canMoveForward: true
  showClose: show the "close" btn and hides all other btns
  */


  const handleClick = (incr) => {
    const result = {actionType: 'Navigation', nextIndex: props.currentStepNum + incr}
    if (incr === 1 && props.currentStepNum + 2 === props.totalSteps) {
      // clicked on submit
      result.actionType = 'Submit'
      //result.nextIndex = undefined
    }
    if (props.onChange !== undefined) props.onChange(result)
  }
  const handleCloseClick = () => {
    if (props.onClose !== undefined) props.onClose();
  }

  return <div className='flex flex-row justify-end space-x-2 w-full'>
    {(props.showClose === undefined || props.showClose === false) && <div>
      {/* Previous Btn */
      props.currentStepNum > 0 && props.currentStepNum + 1 !== props.totalSteps &&
        <CcActionButton className="me-2 mb-2 mt-2" onClick={() => handleClick(-1)} >Previous</CcActionButton>
        //<button type="button" onClick={() => handleClick(-1)} className="text-white bg-gradient-to-r from-lionGradient2From via-lionGradient2Via to-lionGradient2To hover:bg-gradient-to-br hover:from-lionGradient2From hover:to-lionGradient2From font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Previous</button>
      }
      {/* Next Btn Active */
      props.currentStepNum + 1 < props.totalSteps && (props.canMoveForward === undefined || (props.canMoveForward !== undefined && props.canMoveForward)) &&
        <CcActionButton className="me-2 mb-2 mt-2" onClick={() => handleClick(1)} >{props.currentStepNum === props.totalSteps - 2 ? "Submit" : "Next"}</CcActionButton>
      }
      {/* Next Btn Disabled */
      props.currentStepNum + 1 < props.totalSteps && (props.canMoveForward !== undefined && !props.canMoveForward) &&
        <button type="button" className="text-white bg-slate-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 cursor-not-allowed" disabled>Next</button>
      }
      {props.currentStepNum + 1 === props.totalSteps &&
        <button type="button" className="text-white bg-slate-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 cursor-not-allowed" disabled>Submit</button>
      }
    </div>
    }
    {props.showClose &&  <CcActionButton className="me-2 mb-2 mt-2" onClick={handleCloseClick} >Close</CcActionButton>


    }
    </div>
}
