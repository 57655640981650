
import React, { useContext, useState, useEffect} from "react";
import {SettingsContext} from '../components/userSettingsContext';

import { HexColorPicker, HexColorInput } from "react-colorful";
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem,
       Popover, Select, Stack, TextField, Typography }  from '@mui/material';

import { Icon as Iconify } from '@iconify/react';
import {lionIcons}  from './helpersIcons';
import {TryParseIntDefault} from './helpers';




export const LionColorPicker = ({ color, onChange, label }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showPicker, setShowPicker] = useState(false);
    const [colorStr, setColorStr] = useState(color);

    const {settings, setSettings} = useContext(SettingsContext);

    useEffect(() => {
        setColorStr(color);
    }, [color])
  
    const handleColorChange = (cStr) => {
        if (!cStr.startsWith("#")) cStr = `#${cStr}`;
        setColorStr(cStr);
        if(isValidColor(cStr)) onChange(cStr);
    }

    const isValidColor = (strColor) => {
        const reg=/^#([0-9a-f]{3}){1,2}$/i;        
        return reg.test(strColor);
      }
      
      
    const openPreview = (event) => {
        setAnchorEl(event.currentTarget);
        setShowPicker(true);
      }
  
      const closePreview = () => {
        setAnchorEl(null);
        setShowPicker(false);
      }

      

    return (
        <Stack direction='row' spacing={1} sx={{display: 'flex', alignItems: 'center'}}>
        <Box sx={{position: 'relative'}} >
            <Box
            
            sx={{width:'28px', height: '28px', borderRadius: '8px',
            border: '3px solid #fff',
            backgroundColor: color, boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)'
            ,cursor: 'pointer'
            }}
            onClick={(e) => openPreview(e)}
            />
          <Popover open={showPicker}
                    anchorEl={anchorEl}
                    onClose={closePreview}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
          >
                <Box sx={{m: '20px'}}>
                    <div className="flex flex-row gap-x-6">
                      <HexColorPicker color={color} onChange={onChange} />            
                      <div className="-mt-2 flex flex-col h-[250px] text-ccColorDarkTeal font-poppins">
                        <div className="w-[300px] font-semibold">Your brand colours</div>                        
                          
                          {(settings.accountSettings !== undefined && settings.accountSettings.brandColors.length > 0) && <div className="mt-2 grid grid-cols-3 gap-x-4 gap-y-6 overflow-auto">
                        {settings.accountSettings.brandColors.map(c =><div className='flex flex-col relative' 
                                                                            //onMouseEnter={() => {setColorIdOnHover(c.colorId)}}
                                                                            //onMouseLeave={() => {setColorIdOnHover(0)}}
                                                                            >
                                        <div className="text-sm">{c.colorCodeHex}</div>
                                        <div style={{backgroundColor: c.colorCodeHex}} className='h-6 cursor-pointer'
                                          onClick={() => onChange(c.colorCodeHex)}
                                        >
                                        </div>
                                    </div>)}
                          </div>
                          }                          
                      </div>
                    </div>
                </Box>
            </Popover>
        </Box>

        <TextField sx={{width: '100px'}}
        value={colorStr}
        onChange={(e) => handleColorChange(e.target.value)}
        size="small"
        label={label === undefined ? '' : label}
        />
    </Stack>
    );
  };

  export const LionLinearGradientPicker = (props) => {
    const [colors, setColors] = useState(['#000', '#000']);
    const [gradientDirection, setGradientDirection] = useState('horizontal');

    useEffect(() => {
      if (props.colorsStops !== undefined) {
        const newColors = [];
        props.colorsStops.forEach((c, index) => {
          if (index % 2 !== 0) newColors.push(c);
        });
        setColors(newColors)
      }
    }, [props.colorsStops])

    useEffect(() => {
      if (props.direction !== undefined) setGradientDirection(props.direction)
    }, [props.direction])

    const handleChangeColor = (color, colorIndex) => {
      const newColors = [...colors];
      newColors.splice(colorIndex, 1, color);
      setColors(newColors);
      if (props.onChange !== undefined) {
        const result = prepareOutput();
        props.onChange({...result, 'colors': newColors});
      }
    }

    const addColor = (color) => {
      const newColors = [...colors, '#000'];
      setColors(newColors);
      if (props.onChange !== undefined) {
        const result = prepareOutput();
        props.onChange({...result, 'colors': newColors});
      }
    }
    const removeColor = (index) => {
      const newColors = [...colors];
      newColors.splice(index, 1);

      setColors(newColors);
      if (props.onChange !== undefined) {
        const result = prepareOutput();
        props.onChange({...result, 'colors': newColors});
      }
    }

    const handleChangeDirection = (dir) => {
      setGradientDirection(dir);

      if (props.onChange !== undefined) {
        const result = prepareOutput();
        props.onChange({...result, direction: dir});
      }
    }

const prepareOutput = () => ({'colors': colors, direction: gradientDirection, gradientType: 'linear'})

if (props.saas)
    return (
    <Stack spacing={1}>
          {colors.map((c, index) => <Stack key={index} direction='row' spacing={1} display='flex' alignItems='center'>
            <Typography>Color {index + 1}</Typography>
            <LionColorPicker color={c} onChange={(e) => {handleChangeColor(e, index)}}/>
          </Stack>)}
      </Stack>)
  else 
  return(
    <Stack spacing={1}>
          {colors.map((c, index) => <Stack key={index} direction='row' spacing={1} display='flex' alignItems='center'>
            <Typography>Color {index + 1}</Typography>
            <LionColorPicker color={c} onChange={(e) => {handleChangeColor(e, index)}}/>
            {index === 0 && <Button variant="outlined" color="secondary" onClick={addColor}>Add Color</Button>}
            {index > 1 && <IconButton onClick={() => {removeColor(index)}} ><Iconify icon={lionIcons.Icon_Actions_Delete} /></IconButton>}
          </Stack>)}
          <FormControl fullWidth size="small">
            <InputLabel id="gradient-direction">Direction</InputLabel>
            <Select
              labelId="gradient-direction"
              id="gradient-direction-select"
              value={gradientDirection}
              label="Direction"
              onChange={(e) => handleChangeDirection(e.target.value)}
            >
              <MenuItem value='horizontal'>Horizontal</MenuItem>
              <MenuItem value='vertical'>Vertical</MenuItem>
              <MenuItem value='diagonal'>Diagonal</MenuItem>
              <MenuItem value='custom'>Custom</MenuItem>
            </Select>
          </FormControl>
    </Stack>)
    
  }

  export const LionRadialGradientPicker = (props) => {

    const [colors, setColors] = useState(['#000', '#000']);
    const [gradientStartX, setGradientStartX] = useState(0);
    const [gradientStartY, setGradientStartY] = useState(0);
    const [gradientRadius, setGradientRadius] = useState(50);

    useEffect(() => {
      if (props.colorsStops !== undefined) {
        const newColors = [];
        props.colorsStops.forEach((c, index) => {
          if (index % 2 !== 0) newColors.push(c);
        });
        setColors(newColors)
      }
    }, [props.colorsStops])

    useEffect(() => {
      if (props.startX !== undefined) setGradientStartX(props.startX)
      if (props.startY !== undefined) setGradientStartY(props.startY)
      if (props.radius !== undefined) setGradientRadius(props.radius)
    }, [props.startX, props.startY, props.radius])

    const handleChangeColor = (color, colorIndex) => {
      const newColors = [...colors];
      newColors.splice(colorIndex, 1, color);
      setColors(newColors);
      if (props.onChange !== undefined) {
        const result = prepareOutput();
        props.onChange({...result, 'colors': newColors});
      }
    }

    const addColor = (color) => {
      const newColors = [...colors, '#000'];
      setColors(newColors);
      if (props.onChange !== undefined) {
        const result = prepareOutput();
        props.onChange({...result, 'colors': newColors});
      }
    }
    const removeColor = (index) => {
      const newColors = [...colors];
      newColors.splice(index, 1);

      setColors(newColors);
      if (props.onChange !== undefined) {
        const result = prepareOutput();
        props.onChange({...result, 'colors': newColors});
      }
    }

    
    const handlePropChange = (propName, propValue) => {
      switch(propName) {
        case "startX":
          setGradientStartX(propValue);
          break;
        case "startY":
          setGradientStartY(propValue);
          break;
        case "radius":
          setGradientRadius(propValue);
          break;
        default:
          break;
            }

      if (props.onChange !== undefined) {
        const result = prepareOutput();
        props.onChange({...result, [propName]: propValue});
      }
    }

const prepareOutput = () => ({'colors': colors, startX: gradientStartX, startY: gradientStartY, radius: gradientRadius, gradientType: 'radial'})

    return <Stack spacing={1}>
          {colors.map((c, index) => <Stack key={index} direction='row' spacing={1} display='flex' alignItems='center'>
            <Typography>Color {index + 1}</Typography>
            <LionColorPicker color={c} onChange={(e) => {handleChangeColor(e, index)}}/>
            {index === 0 && <Button variant="outlined" color="secondary" onClick={addColor}>Add Color</Button>}
            {index > 1 && <IconButton onClick={() => {removeColor(index)}} ><Iconify icon={lionIcons.Icon_Actions_Delete} /></IconButton>}
          </Stack>)}
          <Stack direction='row' spacing={3} >
            <Stack direction='row' spacing={1} display='flex' alignItems='center'>
                  <Typography>Start X</Typography>
                  <TextField sx={{width: '80px'}}
                        value={gradientStartX}
                        onChange={(e) => handlePropChange('startX', TryParseIntDefault(e.target.value, 0))}
                        size="small"
                      />

            </Stack>
            <Stack direction='row' spacing={1} display='flex' alignItems='center'>
                  <Typography>Start Y</Typography>
                  <TextField sx={{width: '80px'}}
                        value={gradientStartY}
                        onChange={(e) => handlePropChange('startY', TryParseIntDefault(e.target.value, 0))}
                        size="small"
                      />

            </Stack>
          </Stack>
          <Stack direction='row' spacing={1} display='flex' alignItems='center'>
                  <Typography>Radius</Typography>
                  <TextField sx={{width: '80px'}}
                        value={gradientRadius}
                        onChange={(e) => handlePropChange('radius', TryParseIntDefault(e.target.value, 0))}
                        size="small"
                      />

            </Stack>
    </Stack>

  }

  export const LionColorAndBorderPicker = (props) => {
    // border width selection is not yet implemented, it's not usefull in the saas ui for now
    
    /*
    props:

    data: {"color": "#fff", "width": 1}

    */
    const [color, setColor] = useState(['#000', '#000']);
    const [borderWidth, setBorderWidth] = useState(1);


    useEffect(() => {
      if (props.data !== undefined) {
        setBorderWidth(props.data.width)
        setColor(props.data.color)
      }
    }, [props.data])

    const handleChangeColor = (color) => {
      setColor(color);
      if (props.onChange !== undefined) {
        const result = prepareOutput();
        props.onChange({...result, 'color': color});
      }
    }


    const handleChangeWidth = (width) => {
      setBorderWidth(width);

      if (props.onChange !== undefined) {
        const result = prepareOutput();
        props.onChange({...result, 'width': width});
      }
    }

const prepareOutput = () => ({'color': color, 'width': borderWidth})

    return <Stack spacing={1}>
          <Stack direction='row' spacing={1} display='flex' alignItems='center'>
            <LionColorPicker color={color} onChange={(e) => {handleChangeColor(e)}}/>
          </Stack>
      </Stack>
    
  }
