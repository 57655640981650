import { Fragment, useEffect, useState } from "react"
import { Box, Button, IconButton, Popover, Snackbar, Stack, TextField, Tooltip, Typography} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import {InputText, InputNumber} from './inputFields'

import { Icon as Iconify } from '@iconify/react';

import { lionIcons } from '../components/helpersIcons';
import { ccColorDarkTeal } from "../components/helpersStyles";

import { styled } from '@mui/material/styles';
import * as React from 'react';

export const LionLoader = (props) => {

    return <div role="status" className=" h-[400px] flex justify-center items-center" >
    <svg aria-hidden="true" className="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-ccColorTeal" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
    <span className="sr-only">Loading...</span>
  </div>
  } 
export const LionLoaderBig = (props) => {

return <div className="flex justify-center items-center" >
<svg aria-hidden="true" className="w-32 h-32 text-gray-200 animate-spin dark:text-gray-600 fill-ccColorTeal" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
</div>
} 
export const LionLoaderSmall = (props) => {

    return <div className="flex justify-center items-center" >
    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-ccColorSecondaryPink" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
    <span className="sr-only">Loading...</span>
    </div>
} 
    
  export const LionLoaderGradient = (props) => {

    return       <div className=" w-16 h-16 animate-spin rounded-full bg-gradient-to-r from-lionGradient1From via-lionGradient1Via to-lionGradient1To p-1">
    <div className="flex h-full w-full rounded-full items-center justify-center bg-white">
    </div>
  </div>
  } 
  export const CcLoader = (props) => {

    return <div>
        {props.size ? 
            <svg aria-hidden="true" style={{width: props.size, height: props.size}} className="text-gray-200 animate-spin dark:text-gray-600 fill-ccColorTeal" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
            :
            <svg aria-hidden="true" className="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-ccColorTeal" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
        }
  </div>
  } 

export const AlertInfo = (props) => {
/*
props:
title
message

*/
return <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
<div className="flex">
<div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
<div>
    <p className="font-bold">{props.title}</p>
    <p className="text-sm">{props.message}</p>
</div>
</div>
</div>
} 

export const AlertSuccess = (props) => {
    /*
    props:
    title
    message
    
    */
    return <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
    <div className="flex">
    <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
    <div>
        <p className="font-bold">{props.title === undefined ? "Success" : props.title}</p>
        <p className="text-sm">{props.message}</p>
    </div>
    </div>
    </div>
    } 
    
export function SnippetHtmlViewer(props) {
/*
props:
numSnippets: num
html: string
*/

const [indexes, setIndexes] = useState([]);
const [htmlImg, setHtmlImg] = useState('');
const [htmlHref, setHtmlHref] = useState('');
const [snackBarClipboardOpen, setSnackBarClipboardOpen] = useState(false);

useEffect(() => {
    if (props.numSnippets !== undefined) {
        const inds = [];
        for (let i = 0; i < props.numSnippets; i+=1) {
            inds.push(i+1);
        }
        setIndexes(inds)
    }
}, []);

useEffect(() => {
    if (props.html === undefined || props.html === null || props.html === '') return;

    let regex = "src=\"(.*?)\""
    let matches = props.html.match(regex)

    // console.log(matches[1])
    setHtmlImg(matches[1]);

    regex = "href=\"(.*?)\""
    matches = props.html.match(regex)
    // console.log(matches[1])
    setHtmlHref(matches[1]);

}, [props.html]);

const copyHtmlCodeToClipboard = (code) => {
    setSnackBarClipboardOpen(true);
    navigator.clipboard.writeText(code);
    }

const copyAllToClipboard = () => {
    console.log('entrato')
    let result = ''
    indexes.forEach(i => {
        const snippetNum = `element=${i}`;
        const imgSrc = htmlImg.replace('element=1', snippetNum);
        const href = htmlHref.replace('element=1', snippetNum);

        result += `Image  ${i}\n`;
        result += `${imgSrc}\n`;
        result += `URL  ${i}\n`;
        result += `${href}\n\n`;
    })

    setSnackBarClipboardOpen(true);
    navigator.clipboard.writeText(result);
    }

const handleCloseSnackBarClipboard = () => {
    setSnackBarClipboardOpen(false);
    }

const SnackBarAction = (
    <>
        <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackBarClipboard}
        >
        <Iconify icon={lionIcons.Icon_Actions_Cancel} />
        </IconButton>
    </>
    );
                
// sx={{fontFamily: 'monospace'}}
return <Stack spacing={1} display='flex' alignItems='center' >
    <Box sx={{ maxHeight: 350, overflowY: 'scroll'}} >
{indexes.map(i => {
    const snippetNum = `element=${i}`;
    const imgSrc = htmlImg.replace('element=1', snippetNum);
    const href = htmlHref.replace('element=1', snippetNum);

    return <Stack spacing={0} key={i}><Stack spacing={1} direction='row' display='flex' alignItems='center'>
                <Typography sx={{width:70}} display='flex' justifyContent='flex-end'>Image {i}</Typography>
                <TextField sx={{minWidth: 500}} size='small'
                value={imgSrc} 
                />
                <IconButton color="inherit" onClick={() => copyHtmlCodeToClipboard(imgSrc)} >
                    <Iconify icon='heroicons-outline:clipboard-copy' width={42} />
                </IconButton>
            </Stack>
            <Stack key={i} spacing={1} direction='row' display='flex' alignItems='center'>
                <Typography sx={{width:70}} display='flex' justifyContent='flex-end'>URL {i}</Typography>
                <TextField sx={{minWidth: 500}} size='small'
                value={href} 
                />
                <IconButton color="inherit" onClick={() => copyHtmlCodeToClipboard(href)} >
                    <Iconify icon='heroicons-outline:clipboard-copy' width={42} />
                </IconButton>
            </Stack>
            </Stack>
})}
</Box>
<Button sx={{width:200}} startIcon={<Iconify icon='heroicons-outline:clipboard-copy' width={42} />}
        onClick={copyAllToClipboard} >
    Copy all</Button>
<Snackbar
anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
open={snackBarClipboardOpen}
autoHideDuration={1500}
onClose={handleCloseSnackBarClipboard}
message="Copied to clipboard"
action={SnackBarAction}
/>


</Stack>
}

export const LionProgressBar = (props) => {

    const [progressPerc, setProgressPerc] = useState(0);

    useEffect(() => {
        if (props.value === undefined) {setProgressPerc(0); return;}

        setProgressPerc(Math.round(props.value * 100))
    }, [props.value])

    return <div className="flex flex-row gap-1 items-center">
            <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div class=" bg-ccColorTeal h-2.5 rounded-full" style={{width: `${progressPerc}%`}}></div>
            </div>
            {progressPerc === 100 && <div className=" mt-0.5 text-sm text-ccColorTeal">100%</div>}
    </div>
}

export const SnippetInfoForm = (props) => {
    const [snippetInfo, setSnippetInfo] = useState({snippetName: '', description: '', href: ''})

    useEffect(() => {
            if (props.snippetInfo !== undefined) setSnippetInfo(props.snippetInfo)
    }, [props.snippetInfo])

    const handleFormPropChange = (propName, propValue) => {
            const newSnippetInfo = {...snippetInfo, [propName]: propValue}
            setSnippetInfo(newSnippetInfo)
            if (props.onChange !== undefined) props.onChange(newSnippetInfo)
    }

    return <div className='rounded-md max-w-2xl bg-gray-100 p-12'>
                <div className='flex flex-col gap-4'>
                    <InputText displayName="Name" value={snippetInfo.snippetName} onChange={(val) =>handleFormPropChange('snippetName', val)} />
                    <InputText displayName="Description" value={snippetInfo.description} onChange={(val) =>handleFormPropChange('description', val)} />
                    <InputText displayName="Href" value={snippetInfo.href} onChange={(val) =>handleFormPropChange('href', val)} />
                </div>
            </div>
}

export const TabSelector = (props) => {
    /*
    props:
        items: [{id: 'timers', name: 'Timers'}] 
        selectedItemId: 'timers'

        onChange: it passes the clicked tab id

    NO props 
    */
    const [selectedTabId, setSelectedTabId] = useState('')
    //        let newcolors = [...settings.accountSettings.brandColors]

    const styleNotSelected = "cursor-pointer inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300";
    const styleSelected = "cursor-pointer inline-block p-4 text-ccColorSecondaryPurple border-b-2 border-ccColorSecondaryPurple rounded-t-lg active dark:text-blue-500 dark:border-blue-500"

    useEffect(() => {
        if (props.items) {
            if (props.selectedItemId) setSelectedTabId(props.selectedItemId)
            else setSelectedTabId(props.items[0].id);
        }
    }, [props.items, props.selectedItemId])

    const handleClick = (tabId) => {
        setSelectedTabId(tabId)
        if (props.onChange) props.onChange(tabId);
    }

    return <div>
<div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
    <ul class="flex flex-wrap -mb-px">
        {props.items && props.items.map (item =>  
                                                <li class="me-2">
                                                <a onClick={() => handleClick(item.id)}
                                                    className={item.id === selectedTabId ? styleSelected : styleNotSelected}
                                                    >{item.name}</a>
                                            </li>
                                    )

        }
    </ul>
</div>

    </div>
}

export const InfoToolTip = (props) => {
    /*
    props:
    headLine
    message
    */

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
      }));

    return <>
        <LightTooltip 
            title={<div className="flex flex-col gap-2">
                        <div className=" text-base font-bold bg-white text-gray-700">{props.headLine}</div>
                        <div className=" text-sm bg-white text-gray-700">{props.message}</div>
                </div>}
        placement={props.placement} >
            <Iconify icon="material-symbols:info-outline" width={28} color={ccColorDarkTeal} />
        </LightTooltip>
    </>
}

export const InfoToolTipNoMaxWidth = (props) => {
    /*
    props:
    headLine
    message
    */

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: 11,
          maxWidth: 'none',
        },
      }));

    return <>
        <LightTooltip 
            title={<div className="flex flex-col gap-2">
                        <div className=" text-base font-bold bg-white text-gray-700">{props.headLine}</div>
                        <div className=" text-sm bg-white text-gray-700">{props.message}</div>
                </div>}
        placement={props.placement} >
            <Iconify icon="material-symbols:info-outline" width={28} color={ccColorDarkTeal} />
        </LightTooltip>
    </>
}
