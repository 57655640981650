import { useState, useEffect, useContext  } from 'react';

import {TabSelector} from '../commonComponents/generalComponents';
import SnippetsGrid from '../components/snippetsGrid';
import TemplateGrid from '../components/templatesGrid';


export default function PageAdminArchive() {
    const [currentTab, setCurrentTab] = useState("snippets")

    return <div className='flex flex-col gap-3'>
            <TabSelector 
                items={[{id: 'snippets', name: 'Snippets'},
                        {id: 'templates', name: 'Templates'},
                        ]}
                selectedItemId={currentTab}
                onChange={setCurrentTab}
            />
        {currentTab === 'snippets' &&
            <div>
                <SnippetsGrid 
                     archive
                 />
            </div>
        }
        {currentTab === 'templates' &&
            <div>
                <TemplateGrid 
                    archive
                  />
            </div>
        }

        </div>
}