import TemplateGallery from "../../commonComponents/CC_templateGallery";
import SnippetsGrid from '../../components/snippetsGrid';
import { Link } from "react-router-dom";
import {linkStyle} from "../../components/helpersStyles";
import { IconButton, Tooltip } from '@mui/material';
import { Icon as Iconify } from '@iconify/react';
import { CcCreateNewSnippet, CcSubSectionTitle, CcTitle } from '../../commonComponents/styledTexts';

export default function ProductHomeVotes() {
    return <div>
            <CcTitle>
                Polls
            </CcTitle>
            <div className="text-ccColorDarkTeal">Invite recipients to actively participate with your content by including polls. Don’t forget to use the click data wisely!</div>
            <Link to={`/workflow/5`} style={linkStyle}> 
                <CcCreateNewSnippet className="mt-4" >Create new polls</CcCreateNewSnippet>
            </Link>
            <div className=" mt-3 border border-gray-300 rounded-md p-1 flex flex-col gap-2">
                <CcSubSectionTitle>Start with existing Template</CcSubSectionTitle>
                <div>
                <TemplateGallery productTypeId={5} />
                </div>
            </div>

            <CcSubSectionTitle className=" mt-6">Latest Polls Elements</CcSubSectionTitle>
            <SnippetsGrid productTypeId={5}
                 //editCall={(id, typeId) => editSnippet(id, typeId)}
             />

    </div>
}