const getBounds = require('svg-path-bounds'); // https://www.npmjs.com/package/svg-path-bounds
const svgpath = require('svgpath'); // https://www.npmjs.com/package/svgpath


function getRatingsBlockSize(path, gapIcon, numIcons) { 
    const [left, top, right, bottom] = getBounds(path);
    const pathWidth = right - left;
    const pathHeight = bottom - top;
    return { width: pathWidth * numIcons + gapIcon * (numIcons - 1),
              height: pathHeight + 2};
  }
  

export { getRatingsBlockSize };

