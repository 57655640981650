
import { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const SettingsContext = createContext({enabledProductIds: [], navMenu: []});
    
export function SettingsContextProvider({ children, settings, setSettings }) {

    useEffect(() => {
        const getData = async () => {
            let result = {};
              try {
                const url = `${process.env.REACT_APP_LION_API_URL}/User/Settings`;
                //const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/User/Settings`;
                
                const response = await axios.get(url);
                const result = response.data;
    
                /*
                const res = await fetch(url, {
                  method: "GET",
                });
                if (res.status === 200) {
                    result = await res.json();
                    // console.log(result)
                
                } else {
                  // display an error
                }
          */
                const _cultures = await getSupportedCultures();
                const _labels = await getLabels()
                const _labelTemplates = await getLabelsTemplates();
                const _navMenu = await getNavMenuItems();

                setSettings({...settings, enabledProductIds: result.enabledProductIds,
                  feedCustomColumnsPretty: result.feedCustomColumnsPretty,
                cultures: _cultures, labels: _labels, labelTemplates: _labelTemplates,
              navMenu: _navMenu, accountSettings: result.accountSettings,
                canImpersonate: result.canImpersonate, initials: result.initials,
                trialEndDate: result.trialEndDate});
        
              } catch (error) {
                console.log(error);
              }
              return result;
            }

        const getSupportedCultures = async () => {
          let result = {};
            try {
              const url = `${process.env.REACT_APP_LION_API_URL}/User/Cultures`;

              const response = await axios.get(url);
              const result = response.data;
      
              // console.log(result)
              return result;

            } catch (error) {
              console.log(error);
            }
            return result;
          }
        const getLabels = async () => {
          let result = {};
          try {
              const url = `${process.env.REACT_APP_LION_API_URL}/User/Labels`;
              const response = await axios.get(url);
              result = response.data;
      
            } catch (error) {
              console.log(error);
            }
      
          return result;
          }
        const getLabelsTemplates = async () => {
          let result = {};
          try {
              const url = `${process.env.REACT_APP_LION_API_URL}/User/Labels?product=template`;
    
              const response = await axios.get(url);
              result = response.data;
        
            } catch (error) {
              console.log(error);
            }
      
          return result;
        }    
        const getNavMenuItems = async () => {
          let result = {};
          try {
              const url = `${process.env.REACT_APP_LION_API_URL}/User/ActiveFeatures/navMenu`;
    
              const response = await axios.get(url);
              const result = response.data;
        
            } catch (error) {
              console.log(error);
            }
      
          return result;
        }    
        getData();

    }, []);


    return (
      <SettingsContext.Provider
        value={{settings, setSettings}}
      >
        {children}
      </SettingsContext.Provider>
    );
  }

export default SettingsContextProvider