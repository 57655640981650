
import { useState, useEffect, useContext  } from 'react';
import { Link } from "react-router-dom";

import { Box, Card, CardActionArea, CardContent, CircularProgress, Divider,
     Paper, Stack, ThemeProvider, Typography} from  '@mui/material';

import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2

import { red, pink, indigo, green, blue, teal, grey } from '@mui/material/colors';

import { createTheme } from '@mui/material/styles';

import {store} from "../redux/store";
import {lionProductColors} from '../components/helpersStyles';

import {SettingsContext} from '../components/userSettingsContext'


const LionProductButton = (props) => {
    // eslint-disable-next-line react/jsx-no-duplicate-props    
    let testStyle = 'border rounded-md shadow-md border-gray-200 px-2 py-5 sm:px-6 min-w-[200px] max-w-[500px] ';
    
    switch (props.productId) {
        case 1:
            testStyle += 'bg-orange-400 hover:bg-orange-600';
            break;
        case 2:
            testStyle += 'bg-amber-400 hover:bg-amber-600';
            break;
        case 3:
            testStyle += 'bg-yellow-400 hover:bg-yellow-600';
            break;
        case 4:
            testStyle += 'bg-lime-400 hover:bg-lime-600';
            break;
        case 5:
            testStyle += 'bg-teal-400 hover:bg-teal-600';
            break;
        case 6:
            testStyle += 'bg-cyan-400 hover:bg-cyan-600';
            break;
        case 7:
            testStyle += 'bg-purple-400 hover:bg-purple-600';
            break;
        case 8:
            testStyle += 'bg-blue-400 hover:bg-blue-600';
            break;
        case 9:
            testStyle += 'bg-red-400 hover:bg-red-600';
            break;
        case 10:
            testStyle += 'bg-pink-400 hover:bg-pink-600';
            break;
        case 11:
            testStyle += 'bg-indigo-400 hover:bg-indigo-600';
            break;
        case 12:
            testStyle += 'bg-violet-400 hover:bg-violet-600';
            break;
            }
    
    const linkStyle = {
        textDecoration: "none",
        color: 'inherit'
        };
            
    return <Link to={`${props.workflowHref}`} style={linkStyle} 
    //href={`${props.workflowHref}`}
    > 
                <div className={testStyle}
                 >
    
            <p className="font-bold text-xl text-[#222b5c]" >
          {props.title}
        </p>
        <p className="text-[#222b5c]">
          {props.description}
        </p>
    
    </div>
    </Link>
    }
    
const CcSnippetTypeCard = (props) => {

    const linkStyle = {
        textDecoration: "none",
        color: 'inherit'
        };

    // shadow: offsetX offseY blur-radius spread-radius
    return <Link to={`${props.workflowHref}`} style={linkStyle} >
    <div style={{boxShadow: '0px 0px 8px 2px rgba(0,0,0,0.5)'}} className='w-[400px] border-0 rounded-lg p-3 bg-white'>
        <div className='flex flex-row'>
            <div className='w-[284px] text-xl font-poppins text-ccColorDarkTeal font-semibold'>{props.title}</div>
            <button type="button" className={`focus:outline-none text-white bg-ccColorTeal hover:bg-ccColorDarkTeal font-medium rounded text-xs px-2 py-0 h-6`}>
                <div className='items-center font-poppins'>
                    Create now
                </div>
            </button>
        </div>
        <div className='w-[298px] h-[48px] mt-2 text-xs font-semibold font-poppins text-ccColorDarkTeal'>{props.subTitle}</div>
        <img src={`/assets/elements/${props.imageURL}`} width={376} height={127} className='rounded bg-slate-200' />
    </div>
    </Link>
}

export function SnippetTypeGrid() {

    const [enabledProductIds, setEnabledProductIds] = useState([]);
    const {settings, setSettings} = useContext(SettingsContext);

    useEffect(() => {
        // setEnabledProductIds(settings.enabledProductIds);
    }, [settings])


    return  <Grid2 container spacing={3}>
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(9) &&
                <Grid2 xs={3}>
                    <LionProductButton title='Text Images'
                                        description='(turn your data into creative, personalised images)'
                                        sx={{backgroundColor: lionProductColors.DataVisual}}
                                        workflowHref='ph/textImages'
                                        productId={9}
                    />
                </Grid2>
                }
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(2) &&
                <Grid2 xs={3}>
                    <LionProductButton title='Progress bar'
                                        description='(turn your data into creative, personalised images)'
                                        sx={{backgroundColor: lionProductColors.ProgressBar}}
                                        workflowHref='generator'
                                        productId={2}
                    />
                </Grid2>
                }
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(3) &&
                <Grid2 xs={3}>
                    <LionProductButton title='Icon progress bar'
                                        description='(turn your data into creative, personalised images)'
                                        sx={{backgroundColor: lionProductColors.IconProgressBar}}
                                        workflowHref='iconProgressBar'
                                        productId={3}
                    />
                </Grid2>
                }
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(4) &&
                <Grid2 xs={3}>
                    <LionProductButton title='Scratch off'
                                        description='(turn your data into creative, personalised images)'
                                        sx={{backgroundColor: lionProductColors.ScatchOff}}
                                        workflowHref='generator'
                                        productId={4}
                    />
                </Grid2>
                }
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(5) &&
                <Grid2 xs={3}>
                    <LionProductButton title='Vote'
                                        description='(turn your data into creative, personalised images)'
                                        sx={{backgroundColor: lionProductColors.Vote}}
                                        workflowHref='ph/votes'
                                        productId={5}
                    />
                </Grid2>
                }
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(6) &&
                <Grid2 xs={3}>
                    <LionProductButton title='Engagement counter'
                                        description='(turn your data into creative, personalised images)'
                                        sx={{backgroundColor: lionProductColors.EngagementCounter}}
                                        workflowHref='generator'
                                        productId={6}
                    />
                </Grid2>
                }
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(7) &&
                <Grid2 xs={3}>
                    <LionProductButton title='Sentiment'
                                        description='(turn your data into creative, personalised images)'
                                        sx={{backgroundColor: lionProductColors.Sentiment}}
                                        workflowHref='generator'
                                        productId={7}
                    />
                </Grid2>
                }
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(8) &&
                <Grid2 xs={3}>
                    <LionProductButton title='Barcode'
                                        description='(turn your data into creative, personalised images)'
                                        sx={{backgroundColor: lionProductColors.Barcode}}
                                        workflowHref='generator'
                                        productId={8}
                    />
                </Grid2>
                }
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(1) &&
                <Grid2 xs={3}>
                    <LionProductButton title='Generator'
                                        description='(turn your data into creative, personalised images)'
                                        sx={{backgroundColor: lionProductColors.Generator}}
                                        workflowHref='ph/generator'
                                        productId={1}
                    />
                </Grid2>
                }
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(10) &&
                <Grid2 xs={3}>
                    <LionProductButton title='Instagram'
                                        description='(turn your data into creative, personalised images)'
                                        sx={{backgroundColor: lionProductColors.Instagram}}
                                        workflowHref='generator'
                                        productId={10}
                    />
                </Grid2>
                }
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(11) &&
                <Grid2 xs={3}>
                    <LionProductButton title='Twitter'
                                        description='(turn your data into creative, personalised images)'
                                        sx={{backgroundColor: lionProductColors.Twitter}}
                                        workflowHref='generator'
                                        productId={11}
                                        />
                </Grid2>
                }
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(12) &&
                <Grid2 xs={3}>
                    <LionProductButton title='Timers'
                                        description='(turn your data into creative, personalised images)'
                                        sx={{backgroundColor: lionProductColors.Timer}}
                                        workflowHref='ph/timers'
                                        productId={12}
                    />
                </Grid2>
                }
           </Grid2>
}

export const CcSnippetTypeGrid = (props) => {
    const {settings, setSettings} = useContext(SettingsContext);
    const [enabledProducts, setEnabledProducts] = useState([])

    useEffect(() => {
        if (settings.enabledProductIds) generateProductsArray(settings.enabledProductIds);
    }, [settings])

    const generateProductsArray = (ids) => {
        //from the setting product ids, we need to generate an array  of objects with ids  and titles
        // we also have to create "sub-products" (like for example with the timers that are splitted in up, down,)

        const productsData = {12: [{title: "Count Up Timer", subtitle: "Display the time elapsed since the recipient completed a particular action or event.", workflow: "ph/timers", imageURL: "countUp.png"},
                                {title: "Count Down Timer", subtitle: "Display the time remaining until a particular event, sale or deadline ends.", workflow: "ph/timers", imageURL: "countDown.png"},
                                {title: "Personalised Timer", subtitle: "Timers with a unique end time for each recipient. Use for birthday campaigns, timed offers and more.", workflow: "ph/timers", imageURL: "persTimer.png"},
                                ],
                            9: {title: "Data Canvas", subtitle: "Turn CRM data into beautifully branded personalised and eye-catching imagery.", workflow: "ph/textImages", imageURL: "persImg.png"},
                            5: {title: "Polls", subtitle: "Invite recipients to actively participate by voting in real-time email polls.", workflow: "ph/votes", imageURL: "polls.png"},
                            6: {title: "Click Counter", subtitle: "Showcase the number of engagements on key content throughout your email.", workflow: "ph/clickcounter", imageURL: "clickCounter.png"},
                            7: {title: "Mood Meter", subtitle: "Monitor recipient satisfaction with this auto-updating email rating feature.", workflow: "ph/moodmeter", imageURL: "sentiment.png"},
                            1: {title: "Generator", subtitle: "Connect a feed, design your template, and generate automated and personalised content. Add rules to display the content you need.", workflow: "ph/generator", imageURL: "generator.png"},
                            2: {title: "Progress Bar", subtitle: "Visually represent progress towards a goal or milestone. Perfect for loyalty programs, donations and tracking achievements.", workflow: "generator", imageURL: "progressBar.png"},
                            3: {title: "Progress bar From Icons", subtitle: "Use icons that gradually fill up or change colour to display progress for a vote, milestone or goal.", workflow: "iconProgressBar", imageURL: "iconProgress.png"},
                            10: {title: "Instagram", subtitle: "Display your latest instagram posts directly from your Instagram page.", workflow: "generator", imageURL: "insta.png"},
                            8: {title: "Barcode", subtitle: "Turn data codes into barcodes to be scanned in store or at sign in.", workflow: "generator", imageURL: "barcode.png"},
                            13: {title: "App Match", subtitle: "Detect the operating system used to open an email and display operating system specific content.", workflow: "ph/appMatch", imageURL: "appMatch.png"},
                            14: {title: "Personalised Animations", subtitle: "Turn CRM data into beautifully branded personalised and eye-catching imagery.", workflow: "ph/persAnimation", imageURL: "persImg.png"},
        }

        const result = [];
        ids.forEach(id => {
            if (Array.isArray(productsData[id])) {
                productsData[id].forEach(sT => {
                    result.push({productId: id, subtitle: sT.subtitle, title: sT.title, workflow: sT.workflow, imageURL: sT.imageURL})
                })

            }
            else {
                if (productsData[id] === undefined) return;
                result.push({productId: id, subtitle: productsData[id].subtitle, title: productsData[id].title, workflow: productsData[id].workflow, imageURL: productsData[id].imageURL})
            }
            
        });
        setEnabledProducts(result)
    }

    return <div className='grid justify-items-center grid-cols-2 [@media(min-width:1260px)]:grid-cols-3 [@media(min-width:1800px)]:grid-cols-4 gap-y-6 '>
                {enabledProducts.map((p, index) => {
                        let justifyClass = ''
                        if (index % 4 === 0) justifyClass += ' [@media(min-width:1800px)]:justify-self-start'
                        if (index % 4 === 1) justifyClass += ' [@media(min-width:1800px)]:justify-self-center'
                        if (index % 4 === 2) justifyClass += ' [@media(min-width:1800px)]:justify-self-center'
                        if (index % 4 === 3) justifyClass += ' [@media(min-width:1800px)]:justify-self-end'

                        if (index % 3 === 0) justifyClass += ' [@media(min-width:1260px)]:justify-self-start'
                        if (index % 3 === 1) justifyClass += ' [@media(min-width:1260px)]:justify-self-center'
                        if (index % 3 === 2) justifyClass += ' [@media(min-width:1260px)]:justify-self-end'

                        if (index % 2 === 0) justifyClass += ' justify-self-start'
                        if (index % 2 === 1) justifyClass += ' justify-self-end'
                        //we need to apply a different justify-self class for items at the beginning and at the end of each row
                        return  <div className={justifyClass}><CcSnippetTypeCard title={p.title}
                                    subTitle={p.subtitle}
                                    workflowHref={p.workflow}
                                    productId={p.productId}
                                    imageURL={p.imageURL}
                                /></div>

                    })
                }
        </div>

/*
    return <div className='grid justify-items-center grid-cols-2 [@media(min-width:1260px)]:grid-cols-3 gap-y-6 '>
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(12) && <>
        <div className='justify-self-start'><CcSnippetTypeCard title="Count up timer"
                            subTitle="Display the time elapsed since the recipient completed a particular action or event"
                            workflowHref='ph/timers'
                            productId={12}
                    /></div>
        <CcSnippetTypeCard title="Count down timer"
                            subTitle="Display the time remaining until a particular event, sale or deadline ends"
                            workflowHref='ph/timers'
                            productId={12}
                />
        <CcSnippetTypeCard title="Personalised timer"
                             subTitle="Display the time remaining for each individual recipient. Perfect for triggered emails such as time-sensitive sign up offers"
                             workflowHref='ph/timers'
                             productId={12}
                  />
                </>}
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(9) && <>
                    <CcSnippetTypeCard title="Personalised image"
                            subTitle="Turn CRM data into beautifully branded personalised and eye-catching imagery"
                            workflowHref='ph/textImages'
                            productId={9}
                />
                </>}
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(5) && <>
                    <CcSnippetTypeCard title="Polls"
                            subTitle="Turn CRM data into beautifully branded personalised and eye-catching imagery"
                            workflowHref='ph/votes'
                            productId={5}
                />
                </>}
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(6) && <>
                    <CcSnippetTypeCard title="Click Counter"
                            subTitle="Turn CRM data into beautifully branded personalised and eye-catching imagery"
                            workflowHref='ph/textImages'
                            productId={6}
                />
                </>}
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(7) && <>
                    <CcSnippetTypeCard title="Sentiment tracker"
                            subTitle="Turn CRM data into beautifully branded personalised and eye-catching imagery"
                            workflowHref='ph/generator'
                            productId={7}
                />
                </>}
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(1) && <>
                    <CcSnippetTypeCard title="Generator"
                            subTitle="Turn CRM data into beautifully branded personalised and eye-catching imagery"
                            workflowHref='ph/generator'
                            productId={1}
                />
                </>}
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(2) && <>
                    <CcSnippetTypeCard title="Progress Bar"
                            subTitle="Turn CRM data into beautifully branded personalised and eye-catching imagery"
                            workflowHref='ph/generator'
                            productId={2}
                />
                </>}
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(3) && <>
                    <CcSnippetTypeCard title="Progress bar from icons"
                            subTitle="Turn CRM data into beautifully branded personalised and eye-catching imagery"
                            workflowHref='ph/generator'
                            productId={3}
                />
                </>}
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(10) && <>
                    <CcSnippetTypeCard title="Instagram"
                            subTitle="Turn CRM data into beautifully branded personalised and eye-catching imagery"
                            workflowHref='ph/generator'
                            productId={10}
                />
                </>}
                {settings.enabledProductIds !== undefined && settings.enabledProductIds.includes(8) && <>
                    <CcSnippetTypeCard title="Barcode"
                            subTitle="Turn CRM data into beautifully branded personalised and eye-catching imagery"
                            workflowHref='ph/generator'
                            productId={8}
                />
                </>}
        </div>
    */
}