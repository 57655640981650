'use client'

import { useEffect, useState } from 'react';
import { Button, Stack, TextField,} from '@mui/material';
import { Icon as Iconify } from '@iconify/react';

     
export function LionNumberInput(props) {

    const [number, setNumber] = useState('');
    const [incr, setIncr] = useState(1);

    useEffect(() => {
        if (props.value !== undefined) setNumber(props.value)
        if (props.increment !== undefined) setIncr(props.increment)
    }, [props.value, props.increment])

    const handleUpdateNumber = (increment) => {
        const newNumber = number + increment;
        if (props.minValue !== undefined) {
            if (newNumber < props.minValue) return;
        }
        setNumber(newNumber)
        if (props.onChange !== undefined) props.onChange(newNumber);
    }

    return <Stack direction='row' spacing={0} >
        <TextField sx={{...props.sx}}
            label={props.label === undefined ? "" : props.label}
            value={number}
            size={props.size}
            // onChange={(e) => {handleChange('snippetName', e.target.value); validateSingleControlInStep(activeStep, 'templateName');}}
            />
        <Stack spacing={0}>
        <Button sx={{m:0, p:0, height: props.size === "small" ? 20 : 28, width:48, minWidth:48}}
        onClick={()=>handleUpdateNumber(incr)}
        ><Iconify icon='bxs:up-arrow' /></Button>
        <Button sx={{m:0, p:0, height:props.size === "small" ? 20 : 28, width:48, minWidth:48}}
        onClick={()=>handleUpdateNumber(-incr)}        
        ><Iconify icon='bxs:up-arrow' rotate={2} /></Button>
        </Stack>
    </Stack>
 }
