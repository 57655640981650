import {Alert, AlertTitle, Box, Button, CircularProgress, IconButton,
         Paper, Snackbar,
         Stack, Step, StepLabel, Stepper, TextField, Typography} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';


import { useState, useEffect, useRef, } from 'react';
import axios from 'axios';

import Grid2 from '@mui/material/Unstable_Grid2';

import { grey, green } from '@mui/material/colors';
import {ConditionsManager, FilterViewer, FilterTextViewer} from "./filtersManager";
import {OrderByManager} from "./orderByManager";
import {HrefSelector} from "./hrefSelector";

import { Icon as Iconify } from '@iconify/react';
import { lionIcons } from './helpersIcons';
import { GetFilterDefFromMetadata}  from './helpers';


 
function SelectedIcon(props) {
return <Box sx={{position: 'absolute', bottom: '0px', left: '140px',
            padding: '5px', backgroundColor: '#fff', zIndex:99999,
            color: green[500]}}>
      <Stack direction='row' spacing={1}>
        <Iconify icon="teenyicons:tick-circle-outline" />
        <Typography>Selected</Typography>
      </Stack>
</Box>
}

export default function SnippetCAEditor(props) {

    const initialState = {snippetId: 0, snippetName: '', description: '', 
                        labels: [{labelId: 0, labelName: ''}], href: '',
                        snippetTypeId: 1,
                        filters: [], orderBy: [],
                        dataConnectionId: 0, templateId: 0, dataConnectionInfo: {}};

    const initialValidationVars = {
                  '0': {errorTemplateName: false, errorTemplateDescription: false,
                          msgTemplateName: 'Name must not be empty', msgTemplateDescription: 'Description must not be empty' },
                  '1': {errorEndpointURL: false, errorCsvSeparator: false,
                  msgEndpointURL: 'Endpoint URL must not be empty', msgCsvSeparator: 'Separator must not be empty' }
                
                  }

                          
    const [activeStep, setActiveStep] = useState(0);
    const [snippetInfo, setSnippetInfo] = useState(initialState);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [showTemplateInfoForId, setShowTemplateInfoForId] = useState(0);
    const [validationVariables, setValidationVariables] = useState(initialValidationVars);
    const [availableFilters, setAvailableFilters] = useState([]);
    const [availableFieldsForOrderBy, setAvailableFieldsForOrderBy] = useState([]);
    const [availableFieldsForHref, setAvailableFieldsForHref] = useState([]);
    const [templateDataConnectionMetadata, setTemplateDataConnectionMetadata] = useState({}); // dictionary {templateId: {dataTypeInMetadata: true, filters: filters}}
    // templateMetadata: every time user selects a template we need to check for its metadata so that we can populate the filters and order by combos..              
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [snackBarClipboardOpen, setSnackBarClipboardOpen] = useState(false);


    // load data for edit mode -----
    const getSnippetInfo = async (snippetId) => {
        try {
              const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/${snippetId}`;
              const response = await axios.get(url);
              // const url = `api/Snippets/${snippetId}`;
              // const response = await fetch(url, { method: "GET", });
              // response.data = await response.json();
      
            setSnippetInfo(response.data);
            if (response.data.dataConnectionId !== undefined) {
              const dataTypeInMetadata = response.data.dataConnectionInfo.feedType === 'CATALOG';
              const filters = GetFilterDefFromMetadata(JSON.parse(response.data.dataConnectionInfo.metadata), dataTypeInMetadata);
              setAvailableFilters(filters);
              setAvailableFieldsForOrderBy(filters.map(f => ({fieldName: f.conditionName, dataType: f.dataType})))
              setAvailableFieldsForHref(filters.map(f => (f.conditionName)))
  
            }
        } catch (error) {
          console.log(error);
        }
      }

    const getDataConnectionInfo = async (dataConnectionId) => {
      try {
            const url = `${process.env.REACT_APP_LION_API_URL}/Feeds/info/${dataConnectionId}`;
            const response = await axios.get(url);

            // const url = `api/Feeds/info/${dataConnectionId}`;
            // const response = await fetch(url, { method: "GET", });
            // response.data = await response.json();

            const dataTypeInMetadata = response.data.feedType === 'CATALOG';
            const filters = GetFilterDefFromMetadata(JSON.parse(response.data.metadata), dataTypeInMetadata);
            setAvailableFilters(filters);
            setAvailableFieldsForOrderBy(filters.map(f => ({fieldName: f.conditionName, dataType: f.dataType})))
            setAvailableFieldsForHref(filters.map(f => (f.conditionName)))

            const newTemplateDataConnectionMetadata = {...templateDataConnectionMetadata, [dataConnectionId]:{'dataTypeInMetadata': dataTypeInMetadata, 'filters': filters }}
            setTemplateDataConnectionMetadata(newTemplateDataConnectionMetadata);
            // console.log(newTemplateDataConnectionMetadata)
          
      } catch (error) {
        console.log(error);
      }
    }



    useEffect(() => {
      if (props.id == null) {
                console.log('New');
             }
            else getSnippetInfo(props.id);
    }, [props.id]);



    const moveToNextStep = () => {
        const isValidStep = validateStep(activeStep);
        if (isValidStep) {  
          setActiveStep(activeStep + 1);
        }
      }

      const moveToPreviousStep = () => {        
        setActiveStep(activeStep - 1);
      }

      const validateStep = (numStep) => {
        let isValid = true;
        isValid = true;
        const errorVarStep0 = initialValidationVars['0'];
  
        switch(numStep) {
            case 0:
              if (snippetInfo.snippetName === '') errorVarStep0.errorTemplateName = true;
              else errorVarStep0.errorTemplateName = false;
              if (snippetInfo.description === '') errorVarStep0.errorTemplateDescription = true;
              else errorVarStep0.errorTemplateDescription = false;
  
              if (snippetInfo.snippetName === '' || snippetInfo.description === '' ) isValid = false;
              setValidationVariables({...validationVariables, '0': errorVarStep0});
              break;
              case 1:
                break;
              default:
              break;
          }
          return isValid;
      }
  
const setCurrentTemplateId = (id) => {
  const newSnippetInfo = {...snippetInfo, templateId: id, filters: [], orderBy: []} // filters and orderBy are reset because if you change a dataConnection you need to work with different fields and previous filter won't be valid
  setSnippetInfo(newSnippetInfo);
 const dataConId = getDataConnectionIdOfTemplate(id)
  if (dataConId !== undefined && dataConId !== null) {
      if (Object.prototype.hasOwnProperty.call(templateDataConnectionMetadata, dataConId) === false) {
        getDataConnectionInfo(dataConId)
      }
      else {
        const tInfo = templateDataConnectionMetadata[dataConId];
        const filters = tInfo.filters;
        setAvailableFilters(filters);
        setAvailableFieldsForOrderBy(filters.map(f => ({fieldName: f.conditionName, dataType: f.dataType})))
        setAvailableFieldsForHref(filters.map(f => (f.conditionName)))

      }
  }
}
const getDataConnectionIdOfTemplate = (templateId) => {
  const template = props.templateGalleryInfo.find(tInfo => tInfo.templateId === templateId);
  return template.dataConnectionId;
}

const handleChange = (propName, propValue) => {
  const newSnippetInfo = {...snippetInfo, [propName]: propValue}
  setSnippetInfo(newSnippetInfo);
}

const handleFilterChange = (filtersValue) => {
  // console.log(filtersValue)
  handleChange('filters', filtersValue);
}
const handleOrderByChange = (orderByValue) => {
  console.log(orderByValue)
  handleChange('orderBy', orderByValue);
}

const validateSingleControlInStep = (numStep, controlName, controlCustomData) => {
  const errorVarStep0 = validationVariables['0'];
  const errorVarStep1 = validationVariables['1'];

  switch(numStep) {
      case 0:
        switch(controlName) {
          case 'templateName':
            if (snippetInfo.templateName === '') errorVarStep0.errorTemplateName = true;
            else errorVarStep0.errorTemplateName = false;    
          break;
          case 'description':
            if (snippetInfo.description === '') errorVarStep0.errorTemplateDescription = true;
            else errorVarStep0.errorTemplateDescription = false;    
          break;
        default:
          break;
        }

        setValidationVariables({...validationVariables, '0': errorVarStep0});
        break;
        default:
        break;
    }
}

const submitData = async () => {
  setShowLoader(true);
  setSubmitLoading(true);
  setSuccessHtmlMsg('');

  try {
      let url = `${process.env.REACT_APP_LION_API_URL}/Snippets/create/ca`;
      if (props.id !== null) url = `${process.env.REACT_APP_LION_API_URL}/Snippets/update/${props.id}`;
      // let url = `api/Snippets/create/ca`;
      // if (props.id !== null) url = `api/Snippets/update/${props.id}`;
      // console.log(snippetInfo)
  
      const response = await axios.post(url, snippetInfo);
      // const response = await fetch(url, { method: "POST", body: JSON.stringify(snippetInfo) });    
      // response.data = await response.json();

      setSuccessHtmlMsg(response.data);
      setShowSuccessMsg(true);
  } catch (error) {
    setShowSuccessMsg(false);
    setShowErrorMsg(true);
    console.log(error);
  }
  setShowLoader(false);
  setSubmitLoading(false);
}

const copyHtmlCodeToClipboard = (code) => {
  setSnackBarClipboardOpen(true);
  navigator.clipboard.writeText(code);
  }
const handleCloseSnackBarClipboard = () => {
  setSnackBarClipboardOpen(false);
  }
  const SnackBarAction = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackBarClipboard}
      >
        <Iconify icon={lionIcons.Icon_Actions_Cancel} />
      </IconButton>
    </>
  );

  

      return <>
      <Paper elevation={4} sx={{pb:1}}>
          <Box display="flex" justifyContent="flex-end" p={0.5} >
            <IconButton variant="contained" color='primary' onClick={props.onClose} >
                <Iconify icon="material-symbols:cancel-outline" width="48px" />
            </IconButton>
        </Box>

        <Stepper activeStep={activeStep} sx={{mb: 5}} >
          <Step key={1}>
            <StepLabel>Description</StepLabel>
          </Step>
          <Step key={2}>
            <StepLabel>Behaviour</StepLabel>
          </Step>
          <Step key={3}>
            <StepLabel>Confirm</StepLabel>
          </Step>
          
      </Stepper>

      { activeStep === 0 &&
      <Box  sx={{ml: 1, pb:1, display: 'flex', justifyContent: 'center'}}  >
        <Stack spacing={3} width='70%' >
          <Stack direction='row' spacing={1} width='100%'>
            <TextField required id="snippetName" sx={{width: '100%'}}
                label="Name"
                value={snippetInfo.snippetName}
                onChange={(e) => {handleChange('snippetName', e.target.value); validateSingleControlInStep(activeStep, 'templateName');}}
                error={validationVariables['0'].errorTemplateName}
                helperText={validationVariables['0'].errorTemplateName && validationVariables['0'].msgTemplateName}
              />

            <TextField required id="snippetDescription" sx={{width: '100%'}}
                label="Description"
                value={snippetInfo.description}
                onChange={(e) => {handleChange('description', e.target.value); validateSingleControlInStep(activeStep, 'description');}}
                error={validationVariables['0'].errorTemplateDescription}
                helperText={validationVariables['0'].errorTemplateDescription && validationVariables['0'].msgTemplateDescription}
              />
          </Stack>

          <Stack spacing={1}>
            <Typography>Select a template</Typography>
            <Grid2 container alignItems="center" spacing={1} sx={{ height: 400, overflowY: 'scroll' }} >
              {props.templateGalleryInfo.map((item) => (
                <Grid2 xs={6} key={item.templateId} display='flex' justifyContent='center' >
                  <Box sx={{width: 400, height:300, position:'relative', border: `4px solid ${grey[200]}`,
                            borderRadius: '20px', padding: '10px', cursor:'pointer'}}
                            onMouseEnter={() => setShowTemplateInfoForId(item.templateId)}
                            onMouseLeave={() => setShowTemplateInfoForId(0)}
                            onClick={() => setCurrentTemplateId(item.templateId)}
                    >
                    <img style={{objectFit: 'contain'}}
                      src={`${item.previewURL}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${item.previewURL}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.templateName}
                      loading="lazy"
                    />
                    {item.templateId === snippetInfo.templateId &&
                      <SelectedIcon />
                    }
                    {showTemplateInfoForId !== item.templateId &&
                    <Box sx={{position: 'absolute', top: '4px', width: 'calc(100% + 10px)', height: '42px',
                              marginLeft: '-14px', marginTop: '-10px', 
                              border: `4px solid ${grey[200]}`, borderRadius: '20px 20px 0px 0px',
                              padding: '5px',
                              backgroundColor: grey[900], opacity:.5, color: grey[100]}}>{item.templateName}</Box>
                    }
                    {showTemplateInfoForId === item.templateId &&
                    <Box sx={{position: 'absolute', top: '4px', width: 'calc(100% + 10px)', height: '84px',
                              marginLeft: '-14px', marginTop: '-10px', 
                              border: `4px solid ${grey[200]}`, borderRadius: '20px 20px 0px 0px',
                              padding: '5px',
                              backgroundColor: grey[900], opacity:.9, color: grey[100]}}>
                                <Stack spacing={1}>
                                  <Typography variant='h4'>{item.templateName}</Typography>
                                  <Stack direction='row' spacing={2}>
                                    <Typography>{item.description}</Typography>
                                    <Stack direction='row' spacing={1}>
                                      <Iconify icon="bi:calendar" />
                                      <Typography>{new Date(item.created).toDateString()}</Typography>
                                    </Stack>
                                  </Stack>

                                </Stack>
                        </Box>
                    }

                  </Box>
                </Grid2>
              ))}
            </Grid2>
          </Stack>
        </Stack>
        </Box>
      
      }

{ activeStep === 1 &&
      <Box  sx={{ml: 1, pb:1, display: 'flex', justifyContent: 'center'}}  >
          <Stack spacing={2} width='70%'>
            <HrefSelector href={snippetInfo.href} fields={availableFieldsForHref}
                        onChange={(link) => handleChange('href', link)}
            />
            <Stack direction='row' spacing={1} width='100%'>
              <Typography variant='h6'>Filters</Typography>
              <ConditionsManager sx={{marginTop: 50}} availableConditions={availableFilters}
                                      onConditionChange={handleFilterChange}
                                      // clearFilter={clearFilter}
                                      initialConditions={snippetInfo.filters}
                                      urlParameters
                                      showResetButton
                        />

            </Stack>

            <Stack direction='row' spacing={1} width='100%'>
              <Typography variant='h6'>Order by</Typography>
              <OrderByManager availableFields={availableFieldsForOrderBy}
                              initialConditions={snippetInfo.orderBy}
                              onChange={handleOrderByChange}
              />
            </Stack>
          </Stack>
        </Box>
}

{
            activeStep === 2 &&
            <Box  sx={{ml: 1, pb:1, display: 'flex', justifyContent: 'center'}}  >
              <Stack>
              {!showSuccessMsg && !showErrorMsg && <Typography>Confirm</Typography> }

               {showLoader && <CircularProgress size={64} /> }
                {showSuccessMsg && <Alert severity="success" sx={{ml:'15%', mr:'15%'}}
                                      action={
                                        <IconButton color="inherit" onClick={() => copyHtmlCodeToClipboard(successHtmlMsg)} >
                                          <Iconify icon='heroicons-outline:clipboard-copy' width={42} />
                                        </IconButton>
                                      }
                                    >
                                <AlertTitle>Success</AlertTitle>
                                <Typography sx={{fontFamily: 'monospace'}} >{successHtmlMsg}</Typography>
                              </Alert>}
                {showErrorMsg && <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  Something went wrong while saving your snippet
                </Alert>}
              </Stack>
            </Box>
        }



        <Box display="flex" justifyContent="flex-end" sx={{m:1}} >
          {(!showSuccessMsg && !showErrorMsg) && activeStep > 0 && <Button variant="contained" onClick={moveToPreviousStep} sx={{mr:1}}>Previous</Button>} 
          {(!showSuccessMsg && !showErrorMsg) && activeStep < 2 && <Button variant="contained" onClick={moveToNextStep}>Next</Button>}
          {(!showSuccessMsg && !showErrorMsg) && activeStep === 2 && <LoadingButton variant="contained" 
                onClick={submitData} 
                loading={submitLoading} >Submit</LoadingButton>}
          {(showSuccessMsg || showErrorMsg) && <Button variant="contained" onClick={props.onClose}>Close</Button>}
      </Box>


      </Paper>
  
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarClipboardOpen}
        autoHideDuration={1500}
        onClose={handleCloseSnackBarClipboard}
        message="Copied to clipboard"
        action={SnackBarAction}
      />

      </>
  }
  