
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {Helmet} from "react-helmet";

import {TextField, Container, Box} from '@mui/material'
import { useNavigate } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";
import axios from 'axios'
import CssBaseline from '@mui/material/CssBaseline';
import { InputTextPassword } from "../commonComponents/inputFields";

import { object as yupObject, string, } from 'yup';
import { getErrorListFromYupError, getErrorMessage } from '../components/helperErrors';

import { Icon as Iconify } from '@iconify/react';
import { ccColorDarkTeal, ccColorLightTeal, ccColorSecondaryAzure, ccColorSecondaryBronze, ccColorSecondaryPurple, ccColorTeal } from "../components/helpersStyles";
import { CcActionButton } from "../commonComponents/styledTexts";



const ActivateEmail = () => {
    let params = useParams();

  const [loginError, setLoginError] = React.useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = React.useState('');
  const [requestInProgress, setRequestInProgress] = React.useState(true);
  const [success, setSuccess] = React.useState(false);
  const [passwordUpdated, setPasswordUpdated] = React.useState(false);
  const [codesValid, setCodesValid] = React.useState(false);
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")

  const [activeErrorList, setActiveErrorList] = React.useState([]);


const navigate = useNavigate();

useEffect(() => {
    checkResetCode(params.code1, params.code2)
}, [])

  
    async function checkResetCode(code1, code2) {
        setRequestInProgress(true)
      try {
        let url = `${process.env.REACT_APP_LION_API_URL}/Accounts/checkEmailActivationCode?code1=${code1}&code2=${code2}`;
        let response = await axios.get(url);

        let data = response.data;
        if (data.success) {
            setSuccess(true);
            setCodesValid(true);
        }
        else {
            setLoginError(true)
    
        }
              
      } catch (error) {
        setLoginErrorMsg("Ops something went wrong. Please try again later");
        setLoginError(true)
      }
      finally {
        setRequestInProgress(false)
      }
    }

    async function sendChangePasswordRequest(code1, code2, newPassword) {
      setRequestInProgress(true)
    try {
      let url = `${process.env.REACT_APP_LION_API_URL}/Accounts/changePassword`;
      let response = await axios.post(url, {code1: code1, code2: code2, password: newPassword});

      let data = response.data;
      if (data.success) {
          setPasswordUpdated(true);
      }
      else {
          setLoginErrorMsg(data.errorMessage);
          setLoginError(true)
  
      }
            
    } catch (error) {
      setLoginErrorMsg("Ops something went wrong. Please try again later");
      setLoginError(true)
    }
    finally {
      setRequestInProgress(false)
    }
  }


    const handleSubmit = (event) => {
      event.preventDefault();

      const objToValidate = createUserFormDataObject()
      const isValidForm = validateUserForm(objToValidate);
      if (!isValidForm) return;
  
      setLoginError(false)
      const data = new FormData(event.currentTarget);
      sendChangePasswordRequest(params.code1, params.code2, data.get("pswd"));

    };

    const createUserFormDataObject = () => {
      return {password: password, confirmPassword: confirmPassword}
    }

    const userFormValidationSchema = yupObject({
      password: string().required("Required field").min(8, "Must be at least 8 characters long"),
      confirmPassword: string().test(
        'validatePassword',
        '',
        (value, context) => {
          if (value === '' || value !== context.parent.password)
            return context.createError({path: 'confirmPassword', message: "Passwords don't match"});
          else return true;
        }
      )
    });

    const validateUserForm = (formData) => {
      let result = false;
      try {
        userFormValidationSchema.validateSync(formData, {abortEarly: false});
        setActiveErrorList([])
        result = true;
      }
      catch (e) {
        const errorList = getErrorListFromYupError(e)
        //console.log(errorList)
        setActiveErrorList(errorList)
        result = false;
      }

      return result;
    }

    const handleConfirmPasswordChange = (newName) => {
      const objToValidate = createUserFormDataObject()
      objToValidate.confirmPassword = newName
      //if (activeErrorList.length > 0) validateUserForm(objToValidate)
      validateUserForm(objToValidate)

        setConfirmPassword(newName)
    }
    const handlePasswordChange = (newName) => {
      const objToValidate = createUserFormDataObject()
      objToValidate.password = newName
      if (activeErrorList.length > 0) validateUserForm(objToValidate)

        setPassword(newName)
    }


    return (
      <div style={{height: '100vh', backgroundSize: 'cover', backgroundImage: `url("/assets//backgroundDashboardCircles.png")`}}>
          <div className='flex flex-row items-center w-full gap-20 font-poppins bg-gradient-to-r from-ccColorDarkTeal to-ccColorTeal'>
            <img src='/assets/logoWhite.png' className=' h-20 w-auto ml-8' alt='logo'/>
         </div>
         {requestInProgress &&<div className="flex flex-col justify-center items-center mt-24">
             <CircularProgress size={64} sx={{color: ccColorDarkTeal}} />
             </div>}

         {codesValid && !requestInProgress &&
            <div className="flex flex-col justify-center items-center mt-10">
              <Helmet>
                {/* Adding GA to the free trial page */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16685922544"></script>
                <script type="application/ld+json">{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-16685922544'); `}</script>
              </Helmet>

                <div class="w-96 flex items-center justify-center p-4 mb-4 text-green-800 border border-green-300 rounded-lg bg-green-50">
                    <div className="flex flex-col">
                        <div className=" mb-2 font-semibold">Email address successfully verified.</div>
                    </div>
                </div>
                <div class="w-96 flex items-center justify-center p-4 mb-4 border border-gray-200 rounded-lg">
                    <div className="flex flex-col">
                        <div className=" mb-2">You're ready to get started.</div>
                        <button className="bg-ccColorSecondaryPink p-2 rounded text-white font-semibold"
                        onClick={() => navigate('/login')}
                        >Login</button>
                    </div>
                </div>


            </div>
         }
         {
            !codesValid && !requestInProgress && <div className="mt-24 flex flex-col justify-center items-center text-ccColorDarkTeal">
                            <div className="font-bold">Link expired</div>
                            <div>The link you have used is expired or corrupted</div>
                            <div className="mt-4"><Link to={`/forgotPassword`} className=" text-ccColorSecondaryPink text-sm font-semibold">Generate a new link</Link>
                            </div>
                </div>
         }

      </div>
    );

  };

  export default ActivateEmail;