import { useNavigate } from 'react-router-dom';
import {SettingsContext} from '../components/userSettingsContext';
import { useState, useEffect, useContext } from "react"

import TimerPreEditor from "../preEditors/timerPreEditor"
import {CcStepper2, StepperNavButtons} from "../commonComponents/stepper"
import TemplateEditor from '../components/templateEditor';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LionCombo} from  '../commonComponents/inputFields'
import axios from 'axios';

import { FormControlLabel, RadioGroup, Radio, Slider,  } from '@mui/material';
import {InfoToolTip, LionLoaderGradient} from '../commonComponents/generalComponents'
import {SnippetCodeViewer} from '../commonComponents/snippetGeneric'
import {InputText, FileSelector, Toggle} from '../commonComponents/inputFields'
import {TryParseIntDefault} from "../components/helpers";


import { getErrorListFromYupError, getErrorMessage, snippetDataTimerValidationSchema } from '../components/helperErrors'


export function TimerElementManager(props) {
  /*
  props:
  templateId: (must be provided for new )
  snippetInfo: (must be provided for edits )

  onChange: returns all the snippet info
  */

  const {settings, setSettings} = useContext(SettingsContext);

  const [timeZones, setTimeZones] = useState(undefined);
  const [languages, setLanguages] = useState([]);
  const [timerSettings, setTimerSettings] = useState({ "timerType": "countdown", "hrefAfterExpiry": ""}) //"labels": {"defaultLanguage": "en", "customLanguageMapping":[]} LABELS NOT USED ANYMORE
  const [snippetName, setSnippetName] = useState('')
  const [snippetDesc, setSnippetDesc] = useState('')
  const [snippetHref, setSnippetHref] = useState('')
  const [selectedTimeZoneId, setSelectedTimeZoneId] = useState("Europe/London");
  const [selectedTimerDate, setSelectedTimerDate] = useState(dayjs().toJSON());
  const [useAfterImage, setUseAfterImage] = useState(false);
  const [templateId, setTemplateId] = useState(undefined);

  const MAX_SECONDS = 70;

  useEffect(() => {
    getTimezones()
    getLanguages()
  }, [])

  useEffect(() => {
    if (props.templateId === undefined && props.snippetInfo === undefined && settings === undefined) return;

    if (props.templateId) setTemplateId(props.templateId)
    if (props.snippetInfo) { //we are in edit
      setSnippetName(props.snippetInfo.snippetName)
      setSnippetDesc(props.snippetInfo.snippetDesc)
      setSelectedTimerDate(props.snippetInfo.timerDate)
      setSelectedTimeZoneId(props.snippetInfo.timerDateTimeZone)
      setSnippetHref(props.snippetInfo.href)
      setTimerSettings(JSON.parse(props.snippetInfo.timerSettings))
      setTemplateId(props.snippetInfo.templateId)
    }
    else {//new timer, we need to take some default values from the settings
        if (settings.accountSettings) {
          let timerSettingsChanged = false;
          const defaultSettings = settings.accountSettings.timers;
          const newSettings = {...timerSettings}
          if (defaultSettings.inputDateFormat && defaultSettings.inputDateFormat !== '') {
            newSettings.inputDateFormat = defaultSettings.inputDateFormat
            timerSettingsChanged = true;
          }
          if (defaultSettings.timeZone && defaultSettings.timeZone !== '') {
            setSelectedTimeZoneId(defaultSettings.timeZone)
            if (props.onChange) props.onChange(prepareDataToPost('timerDateTimeZone',defaultSettings.timeZone))
          }
          setTimerSettings(newSettings);
          if (timerSettingsChanged && props.onChange) props.onChange(prepareDataToPost(undefined,undefined,newSettings))
        }
    }

  }, [props.templateId, props.snippetInfo, settings])

  const getTimezones = async () => {
    try {  
      const url = `${process.env.REACT_APP_LION_API_URL}/Settings/timezones`;
      const response = await axios.get(url);
      const data = response.data;

      const result = data.map(d => {return {id: d.zoneId, name: d.zoneName}});
      setTimeZones(result);

    } catch (error) {
      //console.log(error);
    }
  }
  const getLanguages = async () => {
    try {  
      const url = `${process.env.REACT_APP_LION_API_URL}/Settings/languages`;
      const response = await axios.get(url);
      const data = response.data;

      setLanguages(data);

    } catch (error) {
      //console.log(error);
    }
  }

  const prepareDataToPost = (updatePropName, updatePropValue, newTimerSettings) => {
    const result = {snippetId: 0, snippetName: '', snippetDesc: '', 
    labels: [{labelId: 0, labelName: ''}], href: '',
    snippetTypeId: 12,
    filters: [], orderBy: [],
    dataConnectionId: 0, templateId: 0, dataConnectionInfo: {}};
    result.templateId = templateId;
    result.snippetName = snippetName;
    result.snippetDesc = snippetDesc;
    result.href = snippetHref;
    result.timerDate = selectedTimerDate;
    result.timerDateTimeZone = selectedTimeZoneId;
    result.timerSettings = newTimerSettings ? JSON.stringify(newTimerSettings) : JSON.stringify(timerSettings);

    if (updatePropName) {
      result[updatePropName] = updatePropValue;
      //console.log(result)
  }
    return result;
  }

  const handleTimerSettingsChange = (newSettings) => {
    setTimerSettings(newSettings)
  }
  const handleTimerTypeChange = (newType) => {
    const newSettings = {...timerSettings, timerType: newType}

    setTimerSettings(newSettings);
    if (props.onChange) props.onChange(prepareDataToPost(undefined,undefined,newSettings))
  }
  const handleHrefAfterChange = (href) => {
    const newSettings = {...timerSettings, hrefAfterExpiry: href}

    setTimerSettings(newSettings);
    if (props.onChange) props.onChange(prepareDataToPost(undefined,undefined,newSettings))
  }
  const handleImageAfterChange = (dataURL) => {
    let imgB64 = ''

    if (dataURL && dataURL !== '') {
      const dataParts = dataURL.split('base64,')
      imgB64 = dataParts[1]
    }
    const newSettings = {...timerSettings, imgAfterExpiry: imgB64}
    //console.log(newSettings)
    setTimerSettings(newSettings);
    if (props.onChange) props.onChange(prepareDataToPost(undefined,undefined,newSettings))
  }
  const handleImageMPPChange = (dataURL) => {
    let imgB64 = ''

    if (dataURL && dataURL !== '') {
      const dataParts = dataURL.split('base64,')
      imgB64 = dataParts[1]
    }
    const newSettings = {...timerSettings, imgMPP: imgB64}
    setTimerSettings(newSettings);
    if (props.onChange) props.onChange(prepareDataToPost(undefined,undefined,newSettings))
  }

  const handleTimerDateChange = (newDate) => {
    newDate.$d.setSeconds(0); //we don't allow to set seconds, for safety we always set them to 0
    //console.log(newDate)
    //console.log(newDate.toJSON())
    setSelectedTimerDate(newDate.toJSON())
    if (props.onChange) props.onChange(prepareDataToPost('timerDate', newDate))
  }
  const handleTimezoneChange = (timezone) => {
    setSelectedTimeZoneId(timezone.id)
    if (props.onChange) props.onChange(prepareDataToPost('timerDateTimeZone', timezone.id))
  }
  const handleSnippetNameChange = (newValue) => {
    setSnippetName(newValue)
    if (props.onChange) props.onChange(prepareDataToPost('snippetName', newValue))
  }
  const handleSnippetDescChange = (newValue) => {
    setSnippetDesc(newValue)
    if (props.onChange) props.onChange(prepareDataToPost('snippetDesc', newValue))
  }
  const handleSnippetHrefChange = (newValue) => {
    
    setSnippetHref(newValue)
    if (props.onChange) props.onChange(prepareDataToPost('href', newValue))
  }
  const handleNumSecondsChange = (newValue) => {
    let newSeconds = TryParseIntDefault(newValue,5)
    if (newSeconds > MAX_SECONDS) newSeconds = MAX_SECONDS;
    
    const newSettings = {...timerSettings, numSeconds: newSeconds}
    console.log(newSettings)
    setTimerSettings(newSettings);
    if (props.onChange) props.onChange(prepareDataToPost(undefined,undefined,newSettings))

  }
  const handleInputDateFormatChange = (newValue) => {
    const newSettings = {...timerSettings, inputDateFormat: newValue}
    //console.log(newSettings)
    setTimerSettings(newSettings);
    if (props.onChange) props.onChange(prepareDataToPost(undefined,undefined,newSettings))

  }
  const handleIsTagMultiLanguageChange = (newValue) => {
    const newSettings = {...timerSettings, isMultiLanguage: newValue}
    //console.log(newSettings)
    setTimerSettings(newSettings);
    if (props.onChange) props.onChange(prepareDataToPost(undefined,undefined,newSettings))

  }

  return <div>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
  <div className='flex flex-col gap-4 justify-center items-center'>
      <div className=" w-1/2 mt-10 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
    <div className="sm:col-span-3">
      <InputText
              displayName="Code name"
              value={snippetName}
              onChange={(val) => {handleSnippetNameChange(val);}}
              error={getErrorMessage(props.validationErrorsList, 'snippetName')}
      />
    </div>

    <div className="sm:col-span-3">
      <InputText
                displayName="Description"
                value={snippetDesc}
                onChange={(val) => {handleSnippetDescChange(val);}}
                error={getErrorMessage(props.validationErrorsList, 'snippetDesc')}
      />
    </div>
    <div className="sm:col-span-6">
        <RadioGroup
          row
          aria-labelledby="radio-timer-type"
          value={timerSettings.timerType}
          name="radio-timer-type"
          onChange={(e) => handleTimerTypeChange(e.target.value)}
          >
          <FormControlLabel value="countdown" control={<Radio />} label="Countdown" />
          <FormControlLabel value="countup" control={<Radio />} label="Countup" />
          <FormControlLabel value="customDateDown" control={<Radio />} label="Personalised countdown" />
          <FormControlLabel value="customDateUp" control={<Radio />} label="Personalised countup" />
      </RadioGroup>
  </div>

    <div className="sm:col-span-3">
      <label htmlFor="timerDate" className="block text-sm font-medium leading-6 text-gray-900">
        Expiry date
      </label>
      <div className="mt-1">
      <DateTimePicker
            value={dayjs(selectedTimerDate)}
            ampm={false}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            onChange={handleTimerDateChange}
          />
      </div>
    </div>

    <div className="sm:col-span-3">
      <label htmlFor="snippetDescription" className="block text-sm font-medium leading-6 text-gray-900">
        Time zone
      </label>
      <div className="mt-1">
        <LionCombo items={timeZones}
          onChange={handleTimezoneChange}
          selectedItemId={selectedTimeZoneId}
          zIndex={100}
         />
      </div>
    </div>
    {timerSettings.timerType.startsWith('custom') &&
        <div className="sm:col-span-6">
          <InputText
                displayName="Merged date format"
                //value={timerSettings.inputDateFormat ? timerSettings.inputDateFormat : settings.accountSettings.timers.inputDateFormat}
                value={timerSettings.inputDateFormat}
                onChange={(val) => {handleInputDateFormatChange(val);}}
                error={getErrorMessage(props.validationErrorsList, 'timerSettingsInputDateFormat')}
          />
      </div>
  }

    <div className="sm:col-span-6">
      <InputText
                displayName="Href"
                value={snippetHref}
                onChange={(val) => {handleSnippetHrefChange(val);}}
                error={getErrorMessage(props.validationErrorsList, 'href')}
      />
    </div>
    <div className="sm:col-span-3">
      <div className='flex flex-row gap-2 items-center'>
        <label htmlFor="numSecondsSlider" className="block text-sm font-medium leading-6 text-gray-900">
          Num seconds
        </label>
        <InfoToolTip 
        placement='right'
        headLine='Num seconds'
        message="Set how long the timer animation should tick for"
        />
      </div>
      <div className="mt-1 flex flex-row gap-4 items-center">
        <Slider min={25} max={MAX_SECONDS}
            value={timerSettings.numSeconds ? timerSettings.numSeconds : 25}
            onChange={(e) => handleNumSecondsChange(e.target.value)}
        />
        <input
          type="text"
          name="numSeconds"
          id="numSeconds"
          autoComplete="numSeconds"
          value={timerSettings.numSeconds ? timerSettings.numSeconds : 25}
          onChange={(e) => handleNumSecondsChange(e.target.value)}
          className="block w-16 rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
    </div>

  
    <div className="sm:col-span-3">
      <label htmlFor="multiLanguage" className=" mb-3 block text-sm font-medium leading-6 text-gray-900">
          Multi language
      </label>
        <Toggle
        checked={timerSettings.isMultiLanguage}
          onChange={handleIsTagMultiLanguageChange}
        />
    </div>
  

    { (timerSettings.timerType === 'countdown' || timerSettings.timerType === 'customDateDown') && <>
      <div className="sm:col-span-6">
        <div className='flex flex-col'>
          <div className='flex flex-row gap-2 items-center'>
            <div>After expiry custom settings</div>
            <InfoToolTip placement='right' 
            headLine='After expiry custom settings'
            message="An after image is an alternative image that can be displayed once the timer element has expired. We recommend uploading an 'after' image that we'll automatically display on expiry. This image should contain a relevant message to encourage your recipients to click through or an 1xwidth image to collapse the element. You can also direct the element to a different landing page."
            />
          </div>
          <InputText
              displayName="Href"
              value={timerSettings.hrefAfterExpiry}
              onChange={(val) => {handleHrefAfterChange(val);}}
              error={getErrorMessage(props.validationErrorsList, 'timerSettingsHrefAfterExpiry')}
          />
          <div className='mt-4'>
            <FileSelector 
              onChange={handleImageAfterChange}
              base64={timerSettings.imgAfterExpiry}
            />
          </div>

        </div>
        
    </div>
    </>
    }

<div className="sm:col-span-6">
  <div className='flex flex-row gap-2 items-center'>
    <label htmlFor="snippetHref" className="block text-sm font-medium leading-6 text-gray-900">
        Mail Privacy Protection
    </label>
    <InfoToolTip
    placement='right'
    headLine='MPP'
    message="Due to Apple's Mail Privacy Protection, images may be 'pre-fetched'. To ensure your recipients have the optimal experience, we recommend uploading an image to be shown instead of the timer for Mail Privacy impacted opens. "
    />
  </div>
  <div className="sm:col-span-6">
    <FileSelector 
      onChange={handleImageMPPChange}
      base64={timerSettings.imgMPP}
      />
  </div>

</div>
</div>

{/* WE DON'T SHOW THIS ANYMORE
templateHasTimeLabels && <div className='flex flex-col justify-start w-1/2'>
  <TimerLanguageManager 
  allLanguages={languages} 
  settings={timerSettings}
  onChange={handleTimerSettingsChange}
  />
          </div>*/}
</div>

  </LocalizationProvider>
  </div>
}

export default function TimerWorkflow(props) {
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();
    const [preEditorProps, setPreEditorProps] = useState({});
    const [templateId, setTemplateId] = useState(null);
    const [templateHasTimeLabels, setTemplateHasTimeLabels] = useState(false); // we check if the selected template contains time labels (by checking its templateJson)
    const [templateJson, setTemplateJson] = useState({}); // we keep the template josn only to see if it contains time labels
    const [snippetData, setSnippetData] = useState({});


    const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [activeErrorList, setActiveErrorList] = useState([]);
        

    const getSnippetInfo = async (snippetId) => {
      /*
      try {  
        const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/${snippetId}`;
        const response = await axios.get(url);
        const data = response.data;


      const newTemplate = {...selectedTemplate}
      newTemplate.templateId = data.templateId;
      setSelectedTemplate(newTemplate);
      setSnippetName(data.snippetName)
      setSnippetDesc(data.description)
      setSelectedTimerDate(data.timerDate)
      setSelectedTimeZoneId(data.timerDateTimezoneIANA);
      setTimerSettings(JSON.parse(data.timerSetting));

      } catch (error) {
        console.log(error);
      }
      */
    }
    const templateContainsTimeLabels = (templateJson) => {
      let result = false;
      const blocks = templateJson.model.children[0].children;

      blocks.forEach(block => {
        if (block.className === "Text" && block.attrs.id.startsWith('timerLabel')) result = true;
      })
      console.log(result)
      return result;
    }

    const submitDataSnippet = async () => {
      try {
        //console.log(snippetData)
        snippetDataTimerValidationSchema.validateSync(snippetData, {abortEarly: false});
        setActiveErrorList([])
      }
      catch (e) {
        //console.log(JSON.parse(JSON.stringify(e)))
        const errorList = getErrorListFromYupError(e)
        setActiveErrorList(errorList)
        return false;
      }


      setShowLoader(true);
      setSuccessHtmlMsg('');
    
      try {
          let url = `${process.env.REACT_APP_LION_API_URL}/Snippets/create/12`;
          //if (params.snippetId !== null) url = `${process.env.REACT_APP_LION_API_URL}/Snippets/update/12/${params.snippetId}`;
          
          const response = await axios.post(url, snippetData);
    
          setSuccessHtmlMsg(response.data);
          setShowSuccessMsg(true);
      } catch (error) {
        setShowSuccessMsg(false);
        setShowErrorMsg(true);
        console.log(error);
      }
      setShowLoader(false);
      setSubmitted(true)

      return true;
    }
  const handleTimerElementChange = (snippetInfo) => {
    //console.log(snippetInfo)
    setSnippetData(snippetInfo)

    if (activeErrorList.length > 0) {
      try {
        snippetDataTimerValidationSchema.validateSync(snippetInfo, {abortEarly: false});
        setActiveErrorList([])
      }
      catch (e) {
        const errorList = getErrorListFromYupError(e)
        setActiveErrorList(errorList)
        return;
      }  
    }
  }
      
    const handleNavChange = async(navInfo) => {
      if (navInfo.actionType === 'Submit') {
            const valid = await submitDataSnippet()
            if (valid) setCurrentStep(navInfo.nextIndex);
        }
      else setCurrentStep(navInfo.nextIndex);
      }
    
    const handleSaveExitClicked = () => {
        navigate("/ph/timers")
    }
    const handleNextClicked = (tId, tJson) => {
        setTemplateId(tId)
        setTemplateJson(tJson)
        setTemplateHasTimeLabels(templateContainsTimeLabels(tJson));
        setCurrentStep(2);
    }
    const handleClose = () => {
        navigate("/ph/timers")
    }
  
    const handlePreEditorChange = (propsDic) => {
        //console.log('pre editor change')
        //console.log(propsDic)
        setPreEditorProps(propsDic)
    }

    return <div>
        <CcStepper2
            steps={[{ name: 'Configure Template', href: '#' },
                    { name: 'Finalize Template', href: '#' },
                    { name: 'Configure Element', href: '#' },
                    { name: 'Get Element', href: '#' },
                ]}
            currentStepIndex={currentStep}
        />
        <div className="border border-2 border-gray-200 rounded-md p-2">
            {currentStep === 0 && 
                    <TimerPreEditor
                        templateProps={preEditorProps}
                        onChange={handlePreEditorChange}
                    />}

            {currentStep === 1 &&
                    <TemplateEditor id={templateId}
                    embeddedInWizard
                    onPreviousClicked={() => setCurrentStep(0)}
                    onSaveExitClicked={handleSaveExitClicked}
                    onNextClicked={handleNextClicked}
                    buildFromDictionary={preEditorProps}
                    //closingCall={() => {setAddEditMode(false); setTemplateIdToEdit(null)}}
                    snippetTypeId={12}

                    />
            }
            {currentStep === 2 && <TimerElementManager
                                    onChange={handleTimerElementChange}
                                    templateId={templateId}
                                    templateHasTimeLabels={templateHasTimeLabels}
                                    validationErrorsList={activeErrorList}
              />}

            {currentStep === 3 && <div className='flex justify-center'>
                {!showLoader && !showSuccessMsg && !showErrorMsg && <div>Confirm</div> }
                {showLoader && <LionLoaderGradient /> }
                {showSuccessMsg && <SnippetCodeViewer numSnippets={1} html={successHtmlMsg} />}      
            </div>}

            {currentStep !== 1 &&
                    <StepperNavButtons totalSteps={4}
                    currentStepNum={currentStep}
                    onChange={handleNavChange}
                    showClose={submitted}
                    onClose={handleClose}
                />
            }
        </div>

    </div>
}