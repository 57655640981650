import { useState, useEffect, useContext  } from 'react';
import { useNavigate } from 'react-router-dom';

import {SettingsContext} from '../components/userSettingsContext';
import { DataGridPro, GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid-pro';
import {TransformDataForGrid}  from "../components/helpers";
import { Button,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import {LionLoaderGradient} from "../commonComponents/generalComponents";
import {LionListbox} from "../commonComponents/inputFields";
import { CcActionButton } from '../commonComponents/styledTexts';

import axios from 'axios';
import { Icon as Iconify } from '@iconify/react';


export default function PageImpersonate() {
    /*
    props:

    NO props 
    */
    const {settings, setSettings} = useContext(SettingsContext);
    const apiRef = useGridApiRef();
    const [users, setUsers] = useState([]);
    const [impUUID, setImpUUID] = useState(null)

    const navigate = useNavigate();

  
    useEffect(() => {
        getUsers()
        const info = localStorage.getItem('CCimpersonate');
        if (info !== null) setImpUUID(JSON.parse(info))
    }, [])

    const getUsers = async () => {
        try {  
          const url = `${process.env.REACT_APP_LION_API_URL}/User/getAll`;
          const response = await axios.get(url);
          const data = response.data;
        setUsers(TransformDataForGrid(data, 'userId'));

        } catch (error) {
          console.log(error);
        }
      }

    const handleImpersonate = (row) => {
        const impInfo = {uuId: row.uuId, userName: row.userName}
        localStorage.setItem("CCimpersonate", JSON.stringify(impInfo) );
        setImpUUID(impInfo)
        axios.defaults.headers.common['CCimpersonate'] = `${row.uuId}`;
        navigate("/")

    }
    const handleStopImpersonating = () => {
        localStorage.removeItem("CCimpersonate");
        delete axios.defaults.headers.common['CCimpersonate']
        setImpUUID(null)
        navigate("/")
    }

    const columns = [
    { field: 'uuId', headerName: 'User Id', width: 320 },
    { field: 'userName', headerName: 'Business Name', width: 240 },
    { field: 'createdDate', headerName: 'Created', width: 200 },
    { field: 'email', headerName: 'Email', width: 240 },
    { field: 'stripeCustomerId', headerName: 'Stripe customerId', width: 200 },
    {
        field: 'actions',
        type: 'actions',
        width: 300,
        getActions: (params) => [
        <GridActionsCellItem icon={<CcActionButton ><div>Impersonate</div></CcActionButton>} onClick={() => { handleImpersonate(params.row);}} label="Impersonate" />,
        ]
    }
    
    ];
      
    const getImpersonatingUsername = () => {
        //const impUUID = localStorage.getItem('CCimpersonate');
        if (impUUID === null) return undefined
        return impUUID.userName
        //const impUserInfo = users.find(u => u.uuId === impUUID);
        //if (impUserInfo) return impUserInfo.userName;
        //else return undefined
    }    
      
    return <div className='flex flex-col gap-2'>
                <div className='flex flex-row items-center gap-x-2 text-xl text-ccColorTeal'>
                    <div>Currently impersonating:</div>
                    <div className='font-bold'>{getImpersonatingUsername() ? getImpersonatingUsername() : "No one"}</div>
                    {getImpersonatingUsername() && <div>
                        <button type="button" className={`focus:outline-none text-white bg-ccColorSecondaryPink hover:bg-ccColorSecondaryPurple font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900`}
                            onClick={handleStopImpersonating}>
                            <div className='flex flex-row gap-2 items-center font-poppins'>
                                <div><Iconify icon="bi:sign-stop" width={22} /></div>
                                <div>Stop impersonating</div>
                            </div>
                        </button>                        
                        </div>}
                </div>
                <DataGridPro rows={users} columns={columns}
                            apiRef={apiRef}
                            hideFooter
                />
</div>
}