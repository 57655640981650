import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

export function jsonDateTimeToDateOnlyPretty(strDate) {
    const d = dayjs(strDate);

    return d.format('D MMM YYYY')
}

export const CCCountDown = (props) => {

    //var countDownDate = new Date("2024-09-21").getTime();
    const [remainingTime, setRemainingTime] = useState('');
    const [isExpired, setIsExpired] = useState(false);

    useEffect(() => {
      console.log(props.date)
      if (props.date === undefined || props.date === null) return;

        var x = setInterval(function() {

            // Get today's date and time
            var now = new Date().getTime();
          
            // Find the distance between now and the count down date
            const countDownDate = new Date(props.date).getTime()
            var distance = countDownDate - now;
          
            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
          
            // Display the result in the element with id="demo"
            //document.getElementById("demo").innerHTML = days + "d " + hours + "h "
            //+ minutes + "m " + seconds + "s ";
            setRemainingTime(`${days}d ${hours}h ${minutes}m ${seconds}s`)
            // If the count down is finished, write some text
            if (distance < 0) {
              setRemainingTime('')
              setIsExpired(true)
              clearInterval(x);
            }
          }, 1000);
          
          return () => {
            console.log('entrato cleaning')
            clearInterval(x);
          }
    }, [props.date])
    return <>
    {remainingTime.length > 0 && <div className='w-fit flex flex-row items-center gap-x-2 px-2 py-1 text-sm font-semibold border rounded-xl bg-ccColorYellow text-ccColorDarkTeal'>
        <div>{remainingTime}</div>
      </div>
    }
    {isExpired && <div>Trial expired</div>

    }
    </>  
}