import { useState, useEffect, useCallback } from 'react';
import {Box, Button, FormControl, MenuItem,
    Paper, Select,
    Stack, Typography} from '@mui/material';


export function OrderByManager(props) {
    // props.availableFields = [{fieldName: '', dataType: 'unknown'}]
    const [availableFields, setAvailableFields] = useState([]);
    const [orderByFieldName, setOrderByFieldName] = useState('null');
    const [orderByDirection, setOrderByDirection] = useState('ASC');
    const [orderByFieldDataType, setOrderByFieldDataType] = useState('unknown');
    const [showDataTypeSelect, setShowDataTypeSelect] = useState(false);


useEffect(() => {
    if (props.availableFields !== undefined) setAvailableFields(props.availableFields);
    if (props.initialConditions !== undefined && props.initialConditions !== null) {
        //console.log(props.initialConditions)
        const orderByCondition = props.initialConditions[0];
        if (orderByCondition === undefined) return;
        setOrderByFieldName(orderByCondition.orderByField);
        setOrderByDirection(orderByCondition.orderByDirection);
        if (orderByCondition.orderByFieldDataType !== undefined) setOrderByFieldDataType(orderByCondition.orderByFieldDataType)

        //console.log(props.availableFields)
        const dataType = props.availableFields.find(f => f.fieldName === orderByCondition.orderByField).dataType;
        setShowDataTypeSelect(dataType === 'unknown');
        }
}, [props.availableFields, props.initialConditions])


const handleOrderByFieldNameChange = (fieldName) => {
    setOrderByFieldName(fieldName)
    if (fieldName === 'null') {
        // it means the "Not Selected" option has been selected
        setShowDataTypeSelect(false);        
    }
    else {
        const dataType = availableFields.find(f => f.fieldName === fieldName).dataType;
        console.log(dataType)
        setShowDataTypeSelect(dataType === 'unknown');
    }

    if (props.onChange !== undefined) props.onChange(prepareReturnObject('orderByField', fieldName));        
    
}

const prepareReturnObject = (propName, propValue) => {
    // some state variables could not be up to date, so we pass the fresh value in propName and propValue
    if (propName === 'orderByField' && propValue === 'null') return [];

    const results = [];
    const result = {orderByField: orderByFieldName, 'orderByDirection': orderByDirection,
                    'orderByFieldDataType': orderByFieldDataType}
    if (propName !== undefined) {
        result[propName] = propValue;
    }
    // no multiple orderBy fields supported for now, so we return an array with 1 item
    results.push(result);
    return results
}

    return <>
    <Stack direction='row' spacing={1}>
        <FormControl size='small'>
            <Select
                  id="orderByField"
                value={orderByFieldName}
                  onChange={(e) => handleOrderByFieldNameChange(e.target.value)}
                >
                <MenuItem value='null'>Not Specified</MenuItem>
                {availableFields.map(f => <MenuItem value={f.fieldName}>{f.fieldName}</MenuItem>)}
            </Select>
          </FormControl>
        <FormControl size='small'>
            <Select disabled={orderByFieldName === 'null'}
                    value={orderByDirection}
                 onChange={(e) => {setOrderByDirection(e.target.value); if (props.onChange !== undefined) props.onChange(prepareReturnObject('orderByDirection', e.target.value));} }
            >
                <MenuItem value='ASC' >Ascending</MenuItem>
                <MenuItem value='DESC' >Descending</MenuItem>
            </Select>
        </FormControl>

        {showDataTypeSelect && orderByFieldName !== 'null' &&
            <FormControl size='small'>
            <Select 
                value={orderByFieldDataType}
                onChange={(e) => {setOrderByFieldDataType(e.target.value);  if (props.onChange !== undefined) props.onChange(prepareReturnObject('orderByFieldDataType', e.target.value));
                }}
            >
                <MenuItem value='unknown' >Not selected</MenuItem>
                <MenuItem value='text' >Text</MenuItem>
                <MenuItem value='number' >Number</MenuItem>
                <MenuItem value='datetime' >Date time</MenuItem>
            </Select>
        </FormControl>            
        }
    </Stack>
    </>
}

