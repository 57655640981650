import axios from "axios";
import { useEffect } from "react";

export default function PageAdminUpdatePaymentConfirm() {

    const sessionId = new URLSearchParams(window.location.search).get(
        "session_id"
      );

      useEffect(() => {
        console.log(sessionId)
        if (sessionId) getCheckoutResult(sessionId);
      }, [])

      const getCheckoutResult = async (csId) => {
        try {  
            const url = `${process.env.REACT_APP_LION_API_URL}/Stripe/CheckoutResult/${csId}`;
            const response = await axios.get(url);
            
    
        } catch (error) {
            console.log(error);
        }
    }

    return <div className="mx-[10%]">
    <div class="flex items-center p-4 mb-4 text-base text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
        <div className="flex gap-x-2">
            <span class="font-semibold">Success</span> 
            <div>Your payment details have been saved correctly</div>
        </div>
    </div>
    </div>
}