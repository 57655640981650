import { createSlice } from '@reduxjs/toolkit';
// import { findLastKey } from 'lodash';
// utils
// import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  loaded: false,
  feedCustomColumnsPretty: {},
  supportedCultures: [],
  labels: [],
  labelsTemplates: [],
};

const slice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    // Test
    setAsLoaded(state, action) {
      state.loaded = action.payload;
    },
    setFeedCustomColumnsPretty(state, action) {
      state.feedCustomColumnsPretty = action.payload;
    },
    setSupportedCultures(state, action) {
      state.supportedCultures = action.payload;
    },
    setLabels(state, action) {
      state.labels = action.payload;
    },
    setLabelsTemplates(state, action) {
      state.labelsTemplates = action.payload;
    }

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setAsLoaded,
    setFeedCustomColumnsPretty,
    setSupportedCultures,
    setLabels,
    setLabelsTemplates,
} = slice.actions;

// ----------------------------------------------------------------------

