import { useState } from 'react';
import { Link } from "react-router-dom";

import DatafeedGrid from "../components/datafeedGrid";
import DatafeedWizard from '../components/datafeedWizard';
import { Typography, IconButton, Tooltip } from '@mui/material';
import { Icon as Iconify } from '@iconify/react';
import { CcTitle, CcCreateNewButton } from '../commonComponents/styledTexts';


export default function PageContentSourcesNew() {
  
    const [addEditMode, setAddEditMode] = useState(false);
    const [connectionIdToEdit, setConnectionIdToEdit] = useState(null);
  
    const createDataConnection = () => {
      setAddEditMode(true);
    }
    const editDataConnection = (id) => {
      setAddEditMode(true);
      setConnectionIdToEdit(id);
    }
  
    return <div>
      {!addEditMode && (
      <>
          <CcTitle>Generator Content Connections</CcTitle>
          <div className="mt-4 flex flex-row gap-x-4 items-center">
            <CcCreateNewButton onClick={createDataConnection} >Create new content connection</CcCreateNewButton>
            <div className='-mt-2'>
              <Link to={`/ph/generator`} className=" text-ccColorDarkTeal hover:text-ccColorSecondaryPink text-sm font-semibold">Create Generator element</Link>
            </div>

          </div>

          <div style={{height: 'calc(100% - 86px)'}}>
            <DatafeedGrid editCall={(id) => editDataConnection(id)} 
                onCreateFirstDataConnectionClicked={createDataConnection}
            />
          </div>
      </>    )      }

        {addEditMode && <>
        <DatafeedWizard id={connectionIdToEdit} closingCall={() => {setAddEditMode(false); setConnectionIdToEdit(null)}} />
        </>}
    </div>
}