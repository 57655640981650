import { useState, useEffect, useCallback } from 'react';

import {Alert, Box, Button, FormControl, IconButton, ListSubheader, MenuItem, 
    Popover, Select, Stack, TextField, ToggleButton, ToggleButtonGroup, Tooltip,
     Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import { grey, cyan, green, red } from '@mui/material/colors';

import {
    DndContext, 
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
  } from '@dnd-kit/core';
  import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    useSortable,
  } from '@dnd-kit/sortable';
  
import {CSS} from '@dnd-kit/utilities';

  
  
import { Icon as Iconify } from '@iconify/react';
import { RandomId}  from './helpers';
import { lionIcons } from './helpersIcons';
import { ccColorDarkTeal, ccColorLightTeal, ccColorSecondaryAzure, ccColorTeal } from './helpersStyles';


class MyPointerSensor extends PointerSensor {
    static activators = [
      {
        eventName: 'onPointerDown',
        handler: ({nativeEvent: event}) => {
          if (
            !event.isPrimary ||
            event.button !== 0 ||
            isInteractiveElement(event.target)
          ) {
            return false;
          }
  
          return true;
        },
      },
    ];
  }
  function isInteractiveElement(element) {
    const interactiveElements = [
      'button',
      'svg',
      'input',
      'textarea',
      'select',
      'option',
    ];
  
    if (interactiveElements.includes(element.tagName.toLowerCase())) {
      return true;
    }
  
    return false;
  }

   /*
    condition: {conditionName: '', conditionSymbol: '', dataType: ''
                , operator: '', value: '', 
                type: 'can be either *condition* or *logicalConjunction*'
                , valueOrigin: '', valueDefault: ''}
   */
export function ConditionBuilder(props) {
    const [condition, setCondition] = useState({})
    const [availableConditions, setAvailableConditions] = useState(undefined)
    const [currentConditionSymbol, setCurrentConditionSymbol] = useState('')
    const [currentOperators, setCurrentOperators] = useState([])
    const [currentOperator, setCurrentOperator] = useState(undefined)
    const [currentValue, setCurrentValue] = useState(undefined)
    const [currentValueOrigin, setCurrentValueOrigin] = useState('direct') // can be: direct, url 
    const [currentValueDefault, setCurrentValueDefault] = useState(undefined)

    useEffect(() => {
        setCondition(props.condition);
        setAvailableConditions(props.availableConditions)
        setCurrentConditionSymbol(props.condition.conditionSymbol)
        setCurrentOperator(props.condition.operator)
        setCurrentValue(props.condition.value)
        setCurrentValueOrigin(props.condition.valueOrigin === undefined ? 'direct' : props.condition.valueOrigin)
        setCurrentValueDefault(props.condition.valueDefault)
        const conditionProps = getConditionProps(props.condition.conditionSymbol);
        setCurrentOperators(conditionProps.availableOperators)

        setCurrentOperator(props.condition.operator)
        //if (currentOperator === props.condition.operator) setCurrentOperator(conditionProps.availableOperators[0].operatorSymbol)
    }, [props.availableConditions])

    const handleConditionSymbolChange = (symbol) => {
        setCurrentConditionSymbol(symbol);
        const conditionProps = getConditionProps(symbol);

        const newCondition = {...condition, conditionSymbol: symbol,
                                 conditionName: conditionProps.conditionName, 
                                 dataType: conditionProps.dataType,
                                operator: conditionProps.availableOperators[0].operatorSymbol}
        setCondition(newCondition);
        setCurrentOperators(conditionProps.availableOperators);
        setCurrentOperator(conditionProps.availableOperators[0].operatorSymbol);

        if (props.onConditionChange !== undefined) props.onConditionChange(newCondition);
    }
    const getConditionProps = (symbol) => {
        let result = {};
        if (props.availableConditions === undefined) return result;
        
        for (let i=0; i<props.availableConditions.length; i+=1) {
            if (props.availableConditions[i].conditionSymbol === symbol) {
                result = props.availableConditions[i];
                break;
            }
        }
        return result;
    }
    const handleConditionOperatorChange = (op) => {
        setCurrentOperator(op);

        const newCondition = {...condition, operator: op }
        setCondition(newCondition);

        if (props.onConditionChange !== undefined) props.onConditionChange(newCondition);
    }
    const handleConditionValueChange = (val) => {
        setCurrentValue(val);

        const newCondition = {...condition, value: val }
        setCondition(newCondition);

        if (props.onConditionChange !== undefined) props.onConditionChange(newCondition);
    }
    const handleConditionValueOriginChange = (val) => {
        setCurrentValueOrigin(val);

        const newCondition = {...condition, valueOrigin: val }
        setCondition(newCondition);
        if (props.onConditionChange !== undefined) props.onConditionChange(newCondition);
    }
    const handleConditionValueDefaultChange = (val) => {
        setCurrentValueDefault(val);

        const newCondition = {...condition, valueDefault: val }
        setCondition(newCondition);

        if (props.onConditionChange !== undefined) props.onConditionChange(newCondition);
    }
    const handleConditionDelete = () => {
        if (props.onConditionDelete !== undefined) props.onConditionDelete(condition);

    }

    return <>
        <Stack direction='row' spacing={1} sx={{display: 'flex', alignItems: 'center'}}>
        {condition !== undefined && availableConditions !== undefined 
              && <> <FormControl size='small'>
                    <Select
                        value={currentConditionSymbol}
                        onChange={(e) => handleConditionSymbolChange(e.target.value)}
                        >
                        {availableConditions.map(c => <MenuItem key={c.conditionName} value={c.conditionSymbol}>{c.conditionName}</MenuItem>)}
                    </Select>  
                </FormControl>
                <FormControl size='small'>
                    <Select
                        value={currentOperator}
                        onChange={(e) => handleConditionOperatorChange(e.target.value)}
                        >
                        {currentOperators !== undefined && currentOperators.map(op => {if (op.groupName !== undefined) return <ListSubheader>{op.groupName}</ListSubheader>
                        return <MenuItem key={op.operatorSymbol} value={op.operatorSymbol}>{op.operatorName}</MenuItem>})}
                    </Select>  
                </FormControl>  
                <TextField sx={{minWidth: '160px'}}
                    label={currentValueOrigin === 'direct' ? 'Value' : 'Parameter name'}
                      value={currentValue}
                      onChange={(e) => handleConditionValueChange(e.target.value)}
                      size="small"
                    />
                    {props.urlParameters && <>
                                                {currentValueOrigin === 'url' && <TextField sx={{width: '120px'}}
                                                                                        label='Default value'
                                                                                        value={currentValueDefault}
                                                                                        onChange={(e) => handleConditionValueDefaultChange(e.target.value)}
                                                                                        size="small"
                                                                                        />
                                                }
                                                <ToggleButtonGroup size='small'
                                                    color="primary"
                                                    value={currentValueOrigin}
                                                    exclusive
                                                    onChange={(e, v) => handleConditionValueOriginChange(v)}
                                                    >
                                                    <ToggleButton value="direct"><Tooltip title='Direct value' placement='top' ><Iconify icon={lionIcons.Icon_Databind_fixed} /></Tooltip></ToggleButton>
                                                    <ToggleButton value="url"><Tooltip title='Value from url parameter' placement='top' ><Iconify icon={lionIcons.Icon_Databind_url} /></Tooltip></ToggleButton>
                                                </ToggleButtonGroup>
                                                </>
                    }

                    {props.showDelBtn &&
                <IconButton
                        onClick={handleConditionDelete}
                    >
                    <Iconify icon="mdi:minus-circle-outline" width="24px" />
                </IconButton>
                    }
            </>
        }
        </Stack>
    </>
}

   /* PROPS:
    availableConditions: [{conditionName: '', conditionSymbol: '', dataType: ''
                availableOperators:[when not provided, the operators are inferred from the dataType]}]

   */
export function ConditionsManager(props) {
    /*
    conditions is an array of condition objects used in the <ConditionBuilder> component
    filter is the global object that will be exported. it is an array made of conditions obj and logicalConjunctions objects
    filter contains "conditions" and "conjunctions" (while conditions contains only "conditions")
    */
    const [availableConditions, setAvailableConditions] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [currentConditionLabel, setCurrentConditionLabel] = useState(1); // the label is a number
    const [filter, setFilter] = useState([]);


    const getInitialCondition = (aConditions) => {
        const conditions = availableConditions.length === 0 ? aConditions : availableConditions;
        const result = { id: RandomId(10),
                            conditionName: conditions[0].conditionName, conditionSymbol: conditions[0].conditionSymbol,
                            dataType: conditions[0].dataType, operator: conditions[0].availableOperators[0].operatorSymbol, value: '',
                            type: 'condition',
                            label: currentConditionLabel.toString()
                        }
        setCurrentConditionLabel(currentConditionLabel + 1);
        return result;
    }

    useEffect(() => {
        //console.log(props.initialConditions)
        if (props.initialConditions !== undefined && props.initialConditions !== null && props.initialConditions.length > 0) {
            const conditionsOnly = []
            props.initialConditions.forEach(c => {if (c.type !== 'conjunction') conditionsOnly.push(c)});

            setConditions(conditionsOnly)
            setCurrentConditionLabel(conditionsOnly.length +1)

            setFilter(props.initialConditions)
        }
    }, [props.initialConditions])

useEffect(() => {
    // order conditions alphabetically and set the available operators
    //console.log(props.availableConditions)
    const sortedConditions = props.availableConditions.sort((a,b) => {
        if ( a.conditionName.toLowerCase() < b.conditionName.toLowerCase() ){
            return -1;
          }
          if ( a.conditionName.toLowerCase() > b.conditionName.toLowerCase() ){
            return 1;
          }
          return 0;
    })
    const results = sortedConditions.map(item => {let operators = item.availableOperators
                                        if (item.availableOperators === undefined) {
                                            switch (item.dataType) {
                                                case 'text':
                                                    operators = [{operatorName: 'equal to', operatorSymbol: '=='},
                                                            {operatorName: 'contains (case sensitive)', operatorSymbol: 'contains(case-sensitive)'},
                                                            {operatorName: 'contains (not case sensitive)', operatorSymbol: 'contains'},]
                                                    break;
                                                case 'longtext':
                                                        operators = [{operatorName: 'contains (case sensitive)', operatorSymbol: 'contains(case-sensitive)'},
                                                                {operatorName: 'contains (not case sensitive)', operatorSymbol: 'contains'},]
                                                    break;
                                                case 'number':
                                                    operators = [{operatorName: 'equal to', operatorSymbol: '=='},    
                                                    {operatorName: 'greater than', operatorSymbol: '>'},
                                                    {operatorName: 'equal or greater than', operatorSymbol: '>='},
                                                    {operatorName: 'lower than', operatorSymbol: '<'},
                                                    {operatorName: 'equal or lower than', operatorSymbol: '<='}]
                                                    break;
                                                case 'datetime':
                                                    operators = [{operatorName: 'equal to', operatorSymbol: '=='},    
                                                    {operatorName: 'greater than', operatorSymbol: '>'},
                                                    {operatorName: 'equal or greater than', operatorSymbol: '>='},
                                                    {operatorName: 'lower than', operatorSymbol: '<'},
                                                    {operatorName: 'equal or lower than', operatorSymbol: '<='}]
                                                    break;
                                                case 'unknown':
                                                    operators = [{groupName: 'text'},
                                                    {operatorName: 'equal to', operatorSymbol: '==(text)'}, 
                                                    {operatorName: 'contains (case sensitive)', operatorSymbol: 'contains(case-sensitive)'},
                                                    {operatorName: 'contains (not case sensitive)', operatorSymbol: 'contains'},                                                    // numbers 
                                                    {groupName: 'number'},
                                                    {operatorName: 'equal to', operatorSymbol: '==(number)'},
                                                    {operatorName: 'greater than', operatorSymbol: '>(number)'},
                                                    {operatorName: 'equal or greater than', operatorSymbol: '>=(number)'},
                                                    {operatorName: 'lower than', operatorSymbol: '<(number)'},
                                                    {operatorName: 'equal or lower than', operatorSymbol: '<=(number)'},
                                                    // datetime 
                                                    {groupName: 'datetime'},
                                                    {operatorName: 'equal to', operatorSymbol: '==(datetime)'},    
                                                    {operatorName: 'greater than', operatorSymbol: '>(datetime)'},
                                                    {operatorName: 'equal or greater than', operatorSymbol: '>=(datetime)'},
                                                    {operatorName: 'lower than', operatorSymbol: '<(datetime)'},
                                                    {operatorName: 'equal or lower than', operatorSymbol: '<=(datetime)'}]
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                        return {...item, availableOperators: operators}
                                    })
    //console.log(results)
    setAvailableConditions(results);
    // we set the first condition and first filter with some initial values
    /*
    if (conditions.length === 0) {
        const initialCondition = getInitialCondition(results);
        setConditions([initialCondition])
        setFilter([initialCondition])
    }
    */
}, [props.availableConditions])

useEffect(() => {

    // when filter changes we need to popup the event
    //console.log(filter)
    //console.log(props.initialConditions)
    const filterHasChanged = JSON.stringify(filter) !== JSON.stringify(props.initialConditions);

    if (props.onConditionChange !== undefined && filterHasChanged) props.onConditionChange(filter)
    
}, [filter])

useEffect(() => {
    if (props.clearFilter !== undefined && props.clearFilter) {
        setFilter([])
        setConditions([])
        setCurrentConditionLabel(1)
    }
    
}, [props.clearFilter])

const handleConditionChange = (cond) => {
    // find cond in the conditions array and update it
    const newConditions = conditions.map(obj => obj.id === cond.id ? cond : obj);
    setConditions(newConditions);
    
    // find cond in the filter array and update it
    const newFilter = filter.map(obj => obj.id === cond.id ? cond : obj);
    //console.log(newFilter)
    
    setFilter(newFilter);
}
const handleConditionDelete = (cond) => {
    // find cond in the conditions array and delete it
    let index = conditions.findIndex(c => c.id === cond.id);
    if (index !== -1) {
        const newConditions = [...conditions]
        newConditions.splice(index,1);
        setConditions(newConditions)
    }

    // find cond in the filter array and delete it
    index = filter.findIndex(c => c.id === cond.id);
    if (index !== -1) {
        const newFilter = [...filter]
        newFilter.splice(index,1);
        // reset labels in newFilter
        let labelIndex = 1;
        for (let i=0; i<newFilter.length; i+=1) {
            if (newFilter[i].type === 'condition') {
                newFilter[i].label = labelIndex.toString();
                labelIndex +=1;
            }
        }
        setFilter(newFilter)
        setCurrentConditionLabel(currentConditionLabel - 1);
    }
}
const handleFilterChange = (f) => {
    
    //console.log('filter change')
    // if (props.initialConditions !== undefined) return;
    // console.log(f)
    setFilter(f);
}

const addCondition = () => {
    const newCond = getInitialCondition();
    const newConditions = [...conditions, newCond];
    const newFilter = [...filter, newCond];
    setConditions(newConditions)
    setFilter(newFilter)
}

const addConjunction = (symbol) => {
    const newConjunction = { id: RandomId(10),
        conjunctionName: symbol, conjunctionSymbol: symbol,
        dataType: '', operator: '', value: '',
        type: 'conjunction',
        label: symbol
    }
    const newFilter = [...filter, newConjunction];
    setFilter(newFilter)
}


    return <>
        <Stack spacing={2} >
            <FilterViewer items={filter} 
            onFilterChange={handleFilterChange}
             />
            <Stack direction='row' spacing={1}>
                    <Button variant="outlined" sx={{borderColor: ccColorTeal, color: ccColorTeal, ":hover":{backgroundColor: ccColorLightTeal, borderColor: ccColorDarkTeal}}}
                        onClick={addCondition}
                        startIcon={<Iconify icon="material-symbols:add-circle-outline" width="32px" />}
                    >
                        Condition
                    </Button>
                    <Button variant="outlined" sx={{borderColor: ccColorTeal, color: ccColorTeal, ":hover":{backgroundColor: ccColorLightTeal, borderColor: ccColorDarkTeal}}}
                        onClick={() => addConjunction('AND')}
                        startIcon={<Iconify icon="material-symbols:add-circle-outline" width="32px" />}
                    >
                        AND
                    </Button>
                    <Button variant="outlined" sx={{borderColor: ccColorTeal, color: ccColorTeal, ":hover":{backgroundColor: ccColorLightTeal, borderColor: ccColorDarkTeal}}}
                        onClick={() => addConjunction('OR')}
                        startIcon={<Iconify icon="material-symbols:add-circle-outline" width="32px" />}
                    >
                        OR
                    </Button>
                    <Button variant="outlined" sx={{borderColor: ccColorTeal, color: ccColorTeal, ":hover":{backgroundColor: ccColorLightTeal, borderColor: ccColorDarkTeal}}}
                        onClick={() => addConjunction('NOT')}
                        startIcon={<Iconify icon="material-symbols:add-circle-outline" width="32px" />}
                    >
                        NOT
                    </Button>
                    <Button variant="outlined" sx={{borderColor: ccColorTeal, color: ccColorTeal, ":hover":{backgroundColor: ccColorLightTeal, borderColor: ccColorDarkTeal}}}
                        onClick={() => addConjunction('(')}
                        startIcon={<Iconify icon="material-symbols:add-circle-outline" width="32px" />}
                    >
                        (
                    </Button>
                    <Button variant="outlined" sx={{borderColor: ccColorTeal, color: ccColorTeal, ":hover":{backgroundColor: ccColorLightTeal, borderColor: ccColorDarkTeal}}}
                        onClick={() => addConjunction(')')}
                        startIcon={<Iconify icon="material-symbols:add-circle-outline" width="32px" />}
                    >
                        )
                    </Button>
                                {props.showResetButton &&
                                <Button variant="contained" sx={{borderColor: ccColorTeal, backgroundColor: ccColorTeal, ":hover":{backgroundColor: ccColorLightTeal, borderColor: ccColorDarkTeal}}}
                                onClick={() => {setFilter([]); setConditions([]); setCurrentConditionLabel(1)}}
                            >
                        Reset
                    </Button>


                    }
            </Stack>
            {conditions.map((cond, index) => 
                            <Stack key={cond.id} direction='row' spacing={1} sx={{display: 'flex', alignItems: 'center'}} >
                                <NumberedBox number={index +1} />
                                <ConditionBuilder availableConditions={availableConditions}
                                                            condition={cond} 
                                                            onConditionChange={handleConditionChange}
                                                            onConditionDelete={handleConditionDelete}
                                                            showDelBtn={index > 0}
                                                            urlParameters={props.urlParameters}
                                                            />
                            </Stack>
                            )
            }
            
        </Stack>
    </>
}

export function FilterTextViewer(props) {

    const getOperatorPrettyname = (operator) => {
        switch (operator) {
            case "==":
            case "==(text)":
            case "==(number)":
            case "==(datetime)":
                return "equal to"
            case "<":
            case "<(number)":
            case "<(datetime)":
                return "lower than"
            case ">":
            case ">(number)":
            case ">(datetime)":
                return "greater than"
            case "<=":
            case "<=(number)":
            case "<=(datetime)":
                return "equal to or lower than"
            case ">=":
            case ">=(number)":
            case ">=(datetime)":
                return "equal to or geater than"
            case "contains(case-sensitive)":
                return "contains"
            default:
                return operator;
        }
    }
    const getSummaryLabel = (items) => {
        const result = []
        items.forEach(i => {
            if (i.type === 'condition') result.push(<><span style={{fontWeight: 'bold'}}>{i.conditionName} </span> 
                                                            <span style={{fontStyle: 'italic'}}>{getOperatorPrettyname(i.operator)} </span>
                                                    {i.value} </>);
            if (i.type === 'conjunction') result.push(<span style={{fontWeight: 'bold'}}> {i.label} </span>);
        })
        return result;
    }

    return <Box>
        {getSummaryLabel(props.items)}
    </Box>
}

export function FilterViewer(props) {
    const [items, setItems] = useState([]);
    const sensors = useSensors(
      useSensor(MyPointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );
    const handleDragEnd = (event) => {
        const {active, over} = event;
        
        if (active.id !== over.id) {
          setItems((items) => {
            const oldIndex = items.indexOf(active.id);
            const newIndex = items.indexOf(over.id);
            return arrayMove(items, oldIndex, newIndex);
          });
        }
      }
    
    
    const [listItems, setListItems] = useState(undefined);
    const [isSyntaxValid, setIsSyntaxValid] = useState(true)
    const defaultSyntaxErrorMsg = 'Check your syntax';
    const [syntaxErrorMsg, setSyntaxErrorMsg] = useState(defaultSyntaxErrorMsg)

    useEffect(() => {
        setListItems(props.items)
        const itemIds = props.items.map(i => i.id);
        setItems(itemIds);
    }, [props.items]);

    useEffect(() => {
        if (listItems === undefined) return;
        if (listItems.length === 0) return;
        // when items are re-ordered we want to re-order the listItems too
        const result = [];
        items.forEach(id => {
            const matchedItem = listItems.find(item => item.id === id);
            result.push(matchedItem)
        })
        setListItems(result);
        // we need to check if the order has been really changed before poppiong up the event
        let listItemsHash = '';
        let resultHash = '';
        listItems.forEach(item => {listItemsHash += item.id});
        result.forEach(item => {resultHash += item.id});
        if (props.onFilterChange !== undefined && (listItemsHash !== resultHash)) props.onFilterChange(result);

    }, [items])

    useEffect(() => {
        if (listItems !== undefined) setIsSyntaxValid(validateSyntax());
    }, [listItems])

    const getLabelFromId = (id) => {
        if (listItems === undefined) return '';
        const matchedItem = listItems.find(i => i.id === id);
        if (matchedItem === undefined) return '';
        return matchedItem.label;
    }
    const getTypeFromId = (id) => {
        // type can be either *operator* or *conjunction*
        if (listItems === undefined) return '';
        const matchedItem = listItems.find(i => i.id === id);
        if (matchedItem === undefined) return '';
        return matchedItem.type === undefined ? 'condition' : matchedItem.type;
    }

    const validateSyntax = () => {
        setSyntaxErrorMsg(defaultSyntaxErrorMsg);
        let strToEval = '';
        let containsNOT = false;
        let indexNOT = -1;
        listItems.forEach((item, index) => {
            if (item.type === 'condition') strToEval += ' true'
            else {
                switch(item.conjunctionSymbol) {
                    case 'AND':
                        strToEval += ' && ';
                    break;
                    case 'OR':
                        strToEval += ' || ';
                    break;
                    case 'NOT':
                        strToEval += ' ! ';
                        containsNOT = true;
                        indexNOT = index;
                    break;
                    case '(':
                        strToEval += ' ( ';
                    break;
                    case ')':
                        strToEval += ' ) ';
                    break;
                    default:
                        break;
                }
            }
        });
        try {
            // if the eval doesn't produce an exception then the syntax is valid
            const res = eval(strToEval);

            // MOD: we want to allow the NOT operator only immediately before and open bracket
            if (containsNOT) {
                const itemAfterNOT = listItems[indexNOT +1];
                if (itemAfterNOT.conjunctionSymbol !== "(") {
                    setSyntaxErrorMsg('NOT operator only supported before an open bracket')
                    return false;
                }
            }
            return true;
        }
        catch (e) {
            return false;
        }
    }
    const handleOnDeleteConjunction = (id) => {
        const newItems = [...items];
        const index = items.indexOf(id);
        if (index !== -1) {
            newItems.splice(index,1);
            setItems(newItems)
        }
    }

    return <>
        <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext 
        items={items}
        //  strategy={verticalListSortingStrategy}
      >
        <Stack spacing={.5}>
            <Grid2 container spacing={1}>
                {items.map((id) => <Grid2 xs={1} key={id}><FilterItemBox 
                                                            onDeleteConjunction={handleOnDeleteConjunction}
                                                            id={id} number={getLabelFromId(id)} type={getTypeFromId(id)} /></Grid2>)}

            </Grid2>
            {isSyntaxValid && items.length > 0 && <Stack spacing={1} direction='row' sx={{display: 'flex', alignItems: 'center', color: green[500]}}><Iconify icon='mdi:tick-circle' /><Typography>Syntax is correct</Typography></Stack>}
            {!isSyntaxValid && items.length > 0 && <Stack spacing={1} direction='row' sx={{display: 'flex', alignItems: 'center', color: red[500]}}><Iconify icon='material-symbols:error-outline' /><Typography>{syntaxErrorMsg}</Typography></Stack>}
        </Stack>
      </SortableContext>
    </DndContext>


        {/*
        listItems !== undefined && <Stack direction='row' spacing={1} >
            
            {listItems.map((i, index) => {if (i.type === 'condition') return <NumberedBox key={i.id} number={index +1} />
        
                             return <NumberedBox key={i.id} number={index +1} />})}
            
            </Stack>
*/}
    </>
}

function FilterItemBox(props) {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({id: props.id});
    
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    
    const handleDeleteConjunction = (id) => {
        if (props.onDeleteConjunction !== undefined) props.onDeleteConjunction(id)

    }
    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {props.type === 'condition' ? <NumberedBox number={props.number} /> : <ConjunctionBox number={props.number} id={props.id} onDelete={handleDeleteConjunction} />}
      </div>
    );
  }

export function NumberedBox(props) {

    return <Box sx={{borderRadius:1, border: `1px solid ${ccColorTeal}`, minWidth:40, minHeight:40
                     , display:'flex', justifyContent:'center', alignItems:'center',
                      backgroundColor: ccColorTeal, color:'white',
                      cursor:'pointer'  }}>
        <Typography><strong>{props.number}</strong></Typography>
    </Box>
}
export function ConjunctionBox(props) {
const [showDelBtn, setShowDelBtn] = useState(false);

const handleDeleteConjunction = (id) => {
    if (props.onDelete !== undefined) props.onDelete(id)
}


    return  <Box sx={{borderRadius:1, border: `1px solid ${grey[500]}`, minWidth:40, minHeight:40
                     , display:'flex', justifyContent:'center', alignItems:'center',
                      backgroundColor: 'white', color:ccColorSecondaryAzure, position:'relative', zIndex:10,
                      cursor:'pointer' }}
                onMouseEnter={(e) => setShowDelBtn(true)}
                onMouseLeave={() => {setShowDelBtn(false)}}                                      
                      >
        <Typography><strong>{props.number}</strong></Typography>
        {showDelBtn && <Box style={{position: 'absolute', top:-28, left:20}}>
            <IconButton
                        onClick={() => handleDeleteConjunction(props.id)}
                        >
                <Iconify icon="mdi:alpha-x-circle-outline" width="24px" color={cyan[500]} />
            </IconButton>
            </Box>}
    </Box>
}
