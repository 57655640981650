import { useState, useEffect, useRef  } from 'react';
import {Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton} from '@mui/material';

import { CcActionButton } from '../commonComponents/styledTexts';
import { InputText, MultipleFileSelector } from '../commonComponents/inputFields';

import { ccColorDarkTeal } from '../components/helpersStyles';
import { InfoToolTipNoMaxWidth } from '../commonComponents/generalComponents';

import axios from 'axios';
import { Icon as Iconify } from '@iconify/react';
import opentype from 'opentype.js'
import { languagesBlocksMapping } from '../components/helperFonts';

const extractFontInfo = (font) => {
  if (font === undefined) return {hasVariations: false, instances: [], valid: false}

  const result = {valid: true}
  //console.log(font);
  result.names = JSON.parse(JSON.stringify(font.tables.name));
  if (result.names.designer === undefined) result.names.designer = {en: "Not found"}
  if (result.names.manufacturer === undefined) result.names.manufacturer = {en: "Not found"}
  result.glyphs = font.glyphs
  
if (font.tables.fvar && font.tables.fvar.axes) {
    if (font.tables.fvar.axes.length > 0) {
      result.hasVariations = true;
      if (font.tables.fvar.instances) result.instances = JSON.parse(JSON.stringify(font.tables.fvar.instances));
      //if (font.tables.fvar.instances) result.instances = font.tables.fvar.instances.map(i => i.name.en)
    }
  }
  //console.log(result)
  return result;
}

const getFamilyNameFromNamesTable = (namesTable) => {
  if (namesTable.preferredFamily) 
    return namesTable.preferredFamily.en;
  else
    return namesTable.fontFamily.en;

}


const GlyphViewer = (props) => {
  const canvasRef = useRef(null)
  const canvasWidth = 40 //120;
  const canvasHeight = 56 //170;
  const fontSize = 28 //86
  
    /*
    props:
      glyph: the glyph object as defined in the opentype.js lib
    
    */

    useEffect(() => {
      if (props.glyph) {
        //console.log(props.glyph)
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        context.fillStyle = '#ccc'
        context.fillRect(0, 0, context.canvas.width, context.canvas.height)
  

        
        const path = props.glyph.getPath(0, 0, fontSize)

        const bb = path.getBoundingBox()
        //console.log(bb)
        const width = bb.x2 - bb.x1;
        const height = bb.y2 - bb.y1;
        const offsetX = (canvasWidth - width) / 2
        const offsetY = (canvasHeight - height) / 2
        //console.log(offsetX)
        //console.log(offsetY)

        //props.glyph.draw(context, offsetX, canvasHeight - offsetY, fontSize)
        //props.glyph.draw(context, offsetX, 120, fontSize)
        props.glyph.draw(context, offsetX, 40, fontSize)
        
      }

    }, [props.glyph])

  return <div>
          <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight}></canvas>
          {/*<div style={{width: canvasWidth}} className='text-sm text-center'>{props.glyph.name}</div>*/}
  </div>
}

const CustomStyleLabel = (props) => {
  /*
  props:
  style: {weight: , italic: true}
  */

  return <div className='flex flex-row items-center gap-x-2 p-2 text-sm font-semibold border rounded-xl bg-ccColorYellow text-ccColorDarkTeal'>
    <div>Weight: {props.style.weight}</div>
    {props.style.italic && <Iconify icon="solar:text-italic-circle-bold" width={20} />}
  </div>
}

const FontStylesMatrix = (props) => {
  const initialStyles = {100: {upright: false, italic: false},
    200: {upright: false, italic: false},
    300: {upright: false, italic: false},
    400: {upright: false, italic: false},
    500: {upright: false, italic: false},
    600: {upright: false, italic: false},
    700: {upright: false, italic: false},
    800: {upright: false, italic: false},
    900: {upright: false, italic: false},
  }

  const [availableStyles, setAvailableStyles] = useState(initialStyles)
  const [customStyles, setCustomStyles] = useState([])

  useEffect(() => {
    //console.log(props.styles)
    const newStyles = {...initialStyles}
    const newCustomStyles = [];

    props.styles.forEach(s => {
      const currentStyleWeight = s.weight;
      const currentStyleItalic = s.italics;
      if (newStyles[currentStyleWeight] === undefined) {
        //this can happen if a font has a custom weight (i.e. different than the 100..900 css ones)
        newCustomStyles.push({weight: currentStyleWeight, italic: currentStyleItalic})
        return;
      }

      if (currentStyleItalic) newStyles[currentStyleWeight].italic = true;
      else newStyles[currentStyleWeight].upright = true;
    })
    //console.log(newCustomStyles)
    setCustomStyles(newCustomStyles)
    setAvailableStyles(newStyles)
  }, [props.styles])

  const getStyleIcon = (weight, italic) => {
    let isAvailable = false;
    if (italic) isAvailable = availableStyles[weight].italic
    else isAvailable = availableStyles[weight].upright

    return isAvailable ? <Iconify icon="fluent:presence-available-10-regular" width={24} className='text-green-600' />
    :
    <Iconify icon="meteocons:not-available" width={40} color='green' />
  }

return <div className='flex flex-col'>
    <div className='flex flex-row'>
      <div className='font-thin w-24 text-center border-l border-t border-b'>Thin</div>
      <div className='font-extralight w-24 text-center border-l border-t border-b'>Extra Light</div>
      <div className='font-light w-24 text-center border-l border-t border-b'>Light</div>
      <div className='font-normal w-24 text-center border-l border-t border-b'>Normal</div>
      <div className='font-medium w-24 text-center border-l border-t border-b'>Medium</div>
      <div className='font-semibold w-24 text-center border-l border-t border-b'>Semi Bold</div>
      <div className='font-bold w-24 text-center border-l border-t border-b'>Bold</div>
      <div className='font-extrabold w-24 text-center border-l border-t border-b'>Extra Bold</div>
      <div className='font-black w-24 text-center border'>Black</div>
    </div>
    <div className='flex flex-row'>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Upright</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Italic</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Upright</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Italic</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Upright</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Italic</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Upright</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Italic</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Upright</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Italic</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Upright</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Italic</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Upright</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Italic</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Upright</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Italic</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b'>Upright</div>
      <div className='h-6 text-xs w-12 text-center content-center border-l border-b border-r'>Italic</div>
    </div>
    <div className='flex flex-row'>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(100, false)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(100, true)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(200, false)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(200, true)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(300, false)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(300, true)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(400, false)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(400, true)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(500, false)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(500, true)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(600, false)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(600, true)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(700, false)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(700, true)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(800, false)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(800, true)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b'>{getStyleIcon(900, false)}</div>
      <div className='w-12 flex items-center content-center justify-center border-l border-b border-r'>{getStyleIcon(900, true)}</div>
    </div>
    {customStyles.length > 0 && <div className='mt-4'>
      <div className='text-ccColorDarkTeal'>Found custom styles:</div>
        <div className='flex flex-row gap-2'>
          {customStyles.map(s => <CustomStyleLabel style={s} />)}
        </div>
      </div>}
  </div>
}

const SupportedLanguagesViewer = (props) => {
  const [supportedLanguages, setSupportedLanguages] = useState([]); //array of strings

  useEffect(() => {
    const languages = checkScriptsSupport(props.glyphs)
    setSupportedLanguages(languages)
  }, [props.glyphs])

  const checkScriptsSupport = (glyphs) => {
    const result = [];
    const allSymbols = []
    Object.keys(glyphs).forEach(k => {if(glyphs[k].unicode) allSymbols.push(glyphs[k].unicode)})
    //allSymbols.sort((a,b) => a-b);
    //console.log(allSymbols)
    
  
    Object.keys(languagesBlocksMapping).forEach(k => {
      let supported = true;
      languagesBlocksMapping[k].forEach(b => {
        const blockParts = b.split('-');
        //const blockStart = parseInt(blockParts[0], 16);
        //const blockEnd = blockParts[1] ? parseInt(blockParts[1], 16) : parseInt(blockParts[0], 16);
        const blockStart = parseInt(blockParts[0]);
        const blockEnd = blockParts[1] ? parseInt(blockParts[1]) : parseInt(blockParts[0]);
  
        //console.log(blockStart)
        //console.log(blockEnd)
  
        for (let i = blockStart; i<=blockEnd; i+=1) {
          const foundIndex = allSymbols.findIndex(s => s === i)
          if (foundIndex === -1) {
            supported = false;
            break;
          }
        }
        //console.log(supported)
  
      })
      if (supported) result.push(k);
    })
    //console.log(result)
    return result.sort();
  }
  
return <div className='flex flex-row items-center gap-x-1'>
 <div className='text-ccColorDarkTeal'>{supportedLanguages.length} languages supported</div>
 <InfoToolTipNoMaxWidth 
      placement='right'
      headLine='Supported Languages'
      message={<div className='w-[500px]'>
        <div className='max-h-48 overflow-auto border rounded px-1'>
          {supportedLanguages.map(l => <div className='font-semibold text-lg'>{l}</div>)}
        </div>
        <div className='mt-2'>Languages are not specified by the author of the font. We derive supported languages by analysing the common characters/glyphs of the top 100 spoken languages to see whether or not they are present in the font</div>

      </div>}
  />
</div>
}

const FontCardInfo = (props) => {
  const canvasRef = useRef(null)
  const [showEditName, setShowEditName] = useState(false)
  const [editName, setEditName] = useState(false)
  const [newName, setNewName] = useState(false)
  const [currentName, setCurrentName] = useState('')
  const [dialogInfoOpen, setDialogInfoOpen] = useState(false);
  const [fontInfo, setFontInfo] = useState({hasVariations: false, instances: [], glyphs: {lenght:0, glyphs:{}}}); //convenient obj to store parsed font info

  useEffect(() => {
    if (props.fontInfo === undefined) return;

    setCurrentName(props.fontInfo.prettyName)
    const buffer = base64ToArrayBuffer(props.fontInfo.previewFontB64)
    const f = opentype.parse(buffer);
    //console.log(f)

    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    context.fillStyle = '#fff'
    context.fillRect(0, 0, context.canvas.width, context.canvas.height)
    const path = f.getPath("Aa", 0, 104, 90)
    path.fill = '#000'
    path.draw(context)

    setFontInfo(extractFontInfo(f));
  }, [props.fontInfo])

  const base64ToArrayBuffer = (base64) => {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const updateFontName = async(fontId, fontName) => {
    const dataToPost = {fontId: fontId, fontName: fontName}
      try {
        const url = `${process.env.REACT_APP_LION_API_URL}/User/UpdateFontName`;

        const response = await axios.post(url, dataToPost);
        if (response.data === true) {
        }
        else {
        }

      } catch (error) {
      }
      finally {

      }

  }

  const saveNewName = (fontId) => {

    setCurrentName(newName)
    updateFontName(fontId, newName)

    setEditName(false)
    setShowEditName(false)
  }

  return <div style={{boxShadow: '0px 0px 8px 2px rgba(0,0,0,0.5)'}} className=' rounded-md p-2'>
    <div className='grid grid-cols-12'>
      <div className='col-span-3'>
        <div onMouseEnter={() => setShowEditName(true)} onMouseLeave={() => setShowEditName(false)}>
          {!showEditName && !editName && <div className=' h-10 text-ccColorDarkTeal text-2xl px-[9px] pt-1'>{currentName}</div>}
          {showEditName && !editName && <div className='h-10 flex flex-row mr-3 items-center border rounded-md px-2'>
                          <div className=' text-ccColorDarkTeal text-2xl'>{currentName}</div>
                          <div className='grow'></div>
                          <div onClick={() => {setEditName(true); setNewName(currentName)}} className='hover:bg-slate-200 p-2 rounded-3xl cursor-pointer'><Iconify icon="lucide:edit" width={20} /></div>
            </div>}         
          {editName && <div className='h-10 flex flex-row mr-3 items-center px-2'>
                        
                        <div className='grow'>
                          <InputText value={newName} onChange={setNewName}  />
                        </div>
                        <div onClick={() => saveNewName(props.fontInfo.fontId)} className='hover:bg-slate-200 p-2 rounded-3xl cursor-pointer'><Iconify icon="charm:circle-tick" width={20} /></div>
          </div>}         
        </div>
        {props.fontInfo.familyName !== currentName && <div className='px-[9px] text-gray-400 text-xs'>Original name: <span>{props.fontInfo.familyName}</span></div>}
        <canvas ref={canvasRef} width={180} height={132} className='mx-[9px]'></canvas>
        <div className='mx-[9px]'><SupportedLanguagesViewer glyphs={fontInfo.glyphs.glyphs} /></div>
      </div>
      <div className='col-span-8'>
        <FontStylesMatrix styles={JSON.parse(props.fontInfo.styles)} />
      </div>
      <div className='col-span-1'>
        <div className='flex flex-col h-full items-center'>
          <div class="w-14 h-full text-gray-600 border-gray-200 hover:bg-gray-300 hover:text-white focus:ring-4 focus:outline-none font-medium border rounded-t-md inline-flex items-center justify-center cursor-pointer">
          <MultipleFileSelector
                  text="Upload font files"
                  iconButton={<Iconify icon="solar:upload-linear" width={20} />}
                  onChange={(files) => {if (props.onUploadFiles) props.onUploadFiles(files, props.fontInfo.fontId)}}
                />
          </div>
          <button onClick={() => setDialogInfoOpen(true)} type="button" class="w-14 h-full text-gray-600 border-gray-200 hover:bg-gray-300 hover:text-white focus:ring-4 focus:outline-none font-medium border-r border-l border-b rounded-b-md inline-flex items-center justify-center ">
            <Iconify icon="simple-line-icons:magnifier-add" width={20} />
          </button>

        </div>
      </div>
    </div>

    <Dialog onClose={() => setDialogInfoOpen(false)} open={dialogInfoOpen}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-font"
                maxWidth="lg" fullWidth
        >
            <DialogTitle id="dialog-title"><span className='text-ccColorDarkTeal'>Font details</span></DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    <div className='text-ccColorDarkTeal'>       
                        <div>
                          <div className='font-bold text-3xl'>{fontInfo.names ? getFamilyNameFromNamesTable(fontInfo.names) : ''}</div>
                          <div className='font-bold text-sm'>{fontInfo.names ? fontInfo.names.version.en : ''}</div>
                          <div className='font-bold text-sm'>{fontInfo.names ? fontInfo.names.copyright.en : ''}</div>
                          <div className='mt-2'>Designed by {(fontInfo.names && fontInfo.names.designerURL) ? <a className='font-bold hover:text-ccColorLightTeal' target='_blank' href={fontInfo.names.designerURL.en} rel="noreferrer">{fontInfo.names ? fontInfo.names.designer.en : ''}</a> : <>{fontInfo.names ? fontInfo.names.designer.en : ''}</>}</div>
                          <div>Manufacturer {(fontInfo.names && fontInfo.names.manufacturerURL) ? <a className='font-bold hover:text-ccColorLightTeal' target='_blank' href={fontInfo.names.manufacturerURL.en} rel="noreferrer">{fontInfo.names ? fontInfo.names.manufacturer.en : ''}</a> : <>{fontInfo.names ? fontInfo.names.manufacturer.en : ''}</>}</div>
                          <div>License {(fontInfo.names && fontInfo.names.licenseURL) ? <a className='font-bold hover:text-ccColorLightTeal' target='_blank' href={fontInfo.names.licenseURL.en} rel="noreferrer">{fontInfo.names ? fontInfo.names.license.en : ''}</a> : <>{fontInfo.names ? fontInfo.names.license.en : ''}</>}</div>
                        </div>
                        

                      {<>
                        <div className='mt-4'>{fontInfo.glyphs.length} glyphs found</div>
                        <div className='h-[370px] overflow-auto'>
                          <div className='grid grid-cols-12 gap-y-6'>
                            {Object.keys(fontInfo.glyphs.glyphs).map(k => <GlyphViewer glyph={fontInfo.glyphs.glyphs[k]} />)

                            }
                          </div>
                        </div>
                      </>}
                      
                    </div>

                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setDialogInfoOpen(false)}>Close</Button>
                </DialogActions>

        </Dialog>

  </div>
}

export default function PageAdminFonts() {
    /*
    props:

    NO props 
    */
    const [fonts, setFonts] = useState([]); //fonts in user account
    const [filesInfo, setFilesInfo] = useState([]); //files to be uploaded
    const [filesFamilyName, setFilesFamilyName] = useState([]); //when multiple files are uploaded, it contains the name of the family
    const [currentFontId, setCurrentFontId] = useState(0); //if 0 it means we are uploading files for a new font. if >0  it is used to update the files of an existing font
    const [selectedFileIndex, setSelectedFileIndex] = useState(0);
    const [fontInfo, setFontInfo] = useState({hasVariations: false, instances: []}); //convenient obj to store parsed font info
    const [showLoader, setShowLoader] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
      getFonts();
    }, [])

    const getFonts = async() => {
        setShowLoader(true)
        try {
          const url = `${process.env.REACT_APP_LION_API_URL}/User/Fonts`;
  
          const response = await axios.get(url);

          setFonts(response.data)

        } catch (error) {
          setShowError(true)
        }
        finally {
          setShowLoader(false);

        }

    }
  const uploadFont = async(dataToPost, fontId) => {
      //console.log(dataToPost)
        setShowLoader(true)
        try {
          let url = `${process.env.REACT_APP_LION_API_URL}/User/UploadFont`;
          if (fontId) url = `${process.env.REACT_APP_LION_API_URL}/User/UploadFont/${fontId}`;

          const response = await axios.post(url, dataToPost);
          getFonts()
          if (response.data === true) {
            setShowSuccess(true)
          }
          else {
              setShowError(true)
          }

        } catch (error) {
          setShowError(true)
        }
        finally {
          setShowLoader(false);

        }

    }

    const handleFontLoaded = (files, fontId) => {
      //files has this format [{fName: '', data: arrayBuffer}]
      //we want to add a field with the parsed font
      if (fontId) setCurrentFontId(fontId)
      else setCurrentFontId(0)

      files.forEach(f => {
        const d = f.dataB64.split("base64,")[1];
        f.dataB64 = d;
      })
      //console.log(files)

      if (files && files.length > 0) {
          setDialogOpen(true);
          setSelectedFileIndex(0)
          try {
            const font = opentype.parse(files[0].data);
            setFontInfo(extractFontInfo(font))  
          }
          catch (e) {}
          const filesWithParsedFont = files.map(f => {try {
                                          const parsedFont = opentype.parse(f.data)
                                          const fontExctractedInfo = extractFontInfo(parsedFont)
                                          return {...f, validFile: true, font: parsedFont, hasVariations: fontExctractedInfo.hasVariations}}
                                        catch (e) {
                                          return {...f, validFile: false }
                                        }
                                        })
          
          let familyName = guessFamilyName(filesWithParsedFont)
          if (fontId) {
            const currentFontName = fonts.find(f => f.fontId === fontId);
            familyName = currentFontName.familyName;
          }

          setFilesFamilyName(familyName)  

          for (let i=0; i<filesWithParsedFont.length; i+=1) {
            if (filesWithParsedFont[i].validFile) {
              filesWithParsedFont[i].sameFamily = getFamilyNameFromNamesTable(filesWithParsedFont[i].font.names) === familyName
            }
          }
          //console.log(filesWithParsedFont)
          setFilesInfo(filesWithParsedFont)
          //uploadFont(fontB64)
        }
    }
    
    
    const guessFamilyName = (filesInfo) => {
      const names = [] //array of {name: name, occurencies: 1}
      filesInfo.forEach(f => {
        if (!f.validFile) return;
        if (f.hasVariations) return;

        const currentFName = getFamilyNameFromNamesTable(f.font.names)

        const matchedName = names.find(n => n.name === currentFName);
        if (matchedName === undefined) names.push({name: currentFName, occurencies: 1})
        else matchedName.occurencies += 1;
    })
      if (names.length === 0) return ''
      else {
       const maxElement = names.reduce((max, name) => max.occurencies > name.occurencies ? max : name);
       //console.log(names)
       return maxElement.name
      }
    } 

    const handleDialogClose = (action) => {
      if (action === 'delete') {
        const dataToPost = prepareDataToPost();
        currentFontId > 0 ? uploadFont(dataToPost, currentFontId) : uploadFont(dataToPost)
      }
      
      //setDialogData({});
      
      setDialogOpen(false);
    }
    const prepareDataToPost = () => {
      /*
      we need to prrepare an object like:
      {name: name,
      styles: [{weight: 100, italics: false}]
      data: [fontArr base64] 
      }
      */
     const results = {name: filesFamilyName, styles: [], data: []}
      filesInfo.forEach(f => {
        if (f.validFile && (f.hasVariations === undefined || f.hasVariations === false) && f.sameFamily) {
          //we only loop through valid files
          const weight = f.font.tables.os2.usWeightClass 
          const fsSelection = f.font.tables.os2.fsSelection 
          const italics = fsSelection & 1 //the first bit of fsSelection is the italics flag
          results.styles.push({weight: weight, italics: Boolean(italics)})
          results.data.push(f.dataB64)
        }
      })
      return results;
    }

  const handleFontSelected = (index) => {
    setSelectedFileIndex(index)
    //const font = opentype.parse(filesInfo[index].data);
    setFontInfo(extractFontInfo(filesInfo[index].font))

    console.log(index)
  }

  const getNumValidAndSupportedFiles = () => {
    let result = 0;
    filesInfo.forEach(f => {
      if (f.validFile && (f.hasVariations === undefined || f.hasVariations === false) && f.sameFamily) result += 1;
    })
    return result
  }
  const handleUpdateFontFiles = (files, fontId) => {
    handleFontLoaded(files, fontId)
  }

    return <div>
            <div className='text-ccColorDarkTeal text-lg font-semibold'>Brand Fonts</div>
          <div className='mb-2 text-ccColorDarkTeal'>Upload your own brand fonts. Files must be TrueTypeFont files and you must have the correct licence to use any fonts you upload. You can select multiple fonts from the same font family in one upload.
            <a className='text-ccColorSecondaryPink' href={`${process.env.REACT_APP_KNOWLEDGEBASE_URL}/article/26-adding-custom-fonts`} target="_blank" rel="noopener noreferrer"> Learn more about custom fonts.</a>
          </div>
            {!dialogOpen &&
                <MultipleFileSelector
                  text="Upload font files"
                  onChange={handleFontLoaded}
                />
            }
        <div className='h-4'></div>
        {fonts.length === 0 && <div>
            No fonts defined
        </div>}
        <div className='flex flex-col gap-y-6'>
          {fonts.map(f => <FontCardInfo fontInfo={f} onUploadFiles={handleUpdateFontFiles} />  )

          }
        </div>

        <Dialog onClose={() => handleDialogClose('abort')} open={dialogOpen}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-font"
                maxWidth="lg" fullWidth
        >
            <DialogTitle id="dialog-title"><span className='text-ccColorDarkTeal'>Files details</span></DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    <div className='text-ccColorDarkTeal'>
                      <div className='grid grid-cols-12'>
                        <div className='col-span-4 mr-4'>
                          <div className="flex flex-col gap-y-2 p-2 shadow-md rounded-xl bg-clip-border overflow-auto h-[180px]">
                            {filesInfo.map((f, index) => {
                              return selectedFileIndex === index ?  <div className='font-semibold text-sm rounded cursor-pointer p-2 bg-ccColorLightTeal  hover:bg-ccColorTeal hover:text-white'>
                                  <div className='flex flex-row'>
                                    <div>{f.fName}</div>
                                    <div className='flex grow'></div>
                                    {!f.validFile && <div className='border p-1 rounded text-gray-400'>Not valid</div>}
                                    {f.hasVariations && <div className='border p-1 rounded text-gray-400'>Not supported</div>}
                                    {f.validFile && !f.hasVariations && !f.sameFamily && <div className='border p-1 rounded text-gray-400'>Different family</div>}
                                  </div>                              
                              </div>
                              :
                              <div onClick={() => handleFontSelected(index)} className='font-semibold text-sm rounded cursor-pointer p-2  hover:bg-ccColorTeal hover:text-white'>
                                  <div className='flex flex-row'>
                                    <div>{f.fName}</div>
                                    <div className='flex grow'></div>
                                    {!f.validFile && <div className='border p-1 rounded text-gray-400'>Not valid</div>}
                                    {f.hasVariations && <div className='border p-1 rounded text-gray-400'>Not supported</div>}
                                    {f.validFile && !f.hasVariations && !f.sameFamily && <div className='border p-1 rounded text-gray-400'>Different family</div>}
                                  </div>
                                </div>
                            })

                            }
                          </div>
                        </div>
                        <div className='col-span-8'>
                          {fontInfo.valid && !fontInfo.hasVariations && filesInfo[selectedFileIndex].sameFamily && <div>
                            <div className='font-bold text-3xl'>{fontInfo.names ? fontInfo.names.fullName.en : ''}</div>
                            <div className='font-bold text-sm'>{fontInfo.names ? fontInfo.names.version.en : ''}</div>
                            <div className='font-bold text-sm'>{fontInfo.names ? fontInfo.names.copyright.en : ''}</div>
                            <div className='mt-2'>Designed by {(fontInfo.names && fontInfo.names.designerURL) ? <a className='font-bold hover:text-ccColorLightTeal' target='_blank' href={fontInfo.names.designerURL.en} rel="noreferrer">{fontInfo.names ? fontInfo.names.designer.en : ''}</a> : <>{fontInfo.names ? fontInfo.names.designer.en : ''}</>}</div>
                            <div>Manufacturer <a className='font-bold hover:text-ccColorLightTeal' target='_blank' href={fontInfo.names ? fontInfo.names.manufacturerURL.en : ''} rel="noreferrer">{fontInfo.names ? fontInfo.names.manufacturer.en : ''}</a></div>
                            <div>License <a className='font-bold hover:text-ccColorLightTeal' target='_blank' href={fontInfo.names ? fontInfo.names.licenseURL.en : ''} rel="noreferrer">{fontInfo.names ? fontInfo.names.license.en : ''}</a></div>
                          </div>
                          }
                          {!fontInfo.valid && <div class="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800" role="alert">
                                          <div>
                                              File not recognised
                                          </div>
                                      </div>
                          }
                          {fontInfo.hasVariations && <div class="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800" role="alert">
                                          <div>
                                              <span className='font-bold'>Variable fonts</span> are not supported. Please use static font files
                                          </div>
                                      </div>
                          }
                          {fontInfo.valid && !fontInfo.hasVariations && !filesInfo[selectedFileIndex].sameFamily && <div class="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800" role="alert">
                                          <div className='flex flex-col gap-y-2'>
                                              <div>This font belongs to the <span className='font-bold'>{getFamilyNameFromNamesTable(fontInfo.names)}</span> family.</div>
                                              <div>The rest  of the fonts belong to the <span className='font-bold'>{filesFamilyName}</span> family</div>
                                              <div>This font will be discarded, you can still use it in a different family</div>
                                          </div>
                                      </div>
                          }

                        </div>
                      </div>
                      {fontInfo.hasVariations && false &&
                        <div className='mt-2'>
                          <div className='font-semibold'>This is a Variable Font</div>
                          <div>{fontInfo.instances ? fontInfo.instances.length : 0} instances found</div>
                          <div className='flex flex-row gap-x-2'>{fontInfo.instances.map(i => <div
                                                                  class="items-center px-4 py-2 text-xs font-semibold text-ccColorDarkTeal bg-ccColorLightTeal rounded-lg select-none whitespace-nowrap">
                                                                  <span class="">{i.name.en}</span>
                                                                </div>)}
                          </div>
                        </div>
                      }
                      <div className='mt-4'>
                        <SupportedLanguagesViewer glyphs={fontInfo.glyphs ? fontInfo.glyphs.glyphs : []} />
                      </div>

                      {fontInfo.glyphs && !fontInfo.hasVariations && filesInfo[selectedFileIndex].sameFamily && <>
                        <div className='mt-2'>{fontInfo.glyphs.length} glyphs found</div>
                        <div className='h-[370px] overflow-auto'>
                          <div className='grid grid-cols-8 gap-y-6'>
                            {Object.keys(fontInfo.glyphs.glyphs).map(k => <GlyphViewer glyph={fontInfo.glyphs.glyphs[k]} />)

                            }
                          </div>
                        </div>
                      </>
                      }
                    </div>

                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => handleDialogClose('abort')}>Cancel</Button>
                <Button onClick={() => handleDialogClose('delete')} autoFocus disabled={getNumValidAndSupportedFiles() === 0}>
                    Confirm
                </Button>
                </DialogActions>

        </Dialog>

    </div>
}