import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {GeneratorElementManager} from "../workflows/generatorWorkflow"
import {LionLoaderGradient} from '../commonComponents/generalComponents'
import {SnippetCodeViewer} from '../commonComponents/snippetGeneric'
import { GetFilterDefFromMetadata}  from '../components/helpers';
import { getErrorListFromYupError, getErrorMessage, snippetDataValidationSchemaGenerator } from '../components/helperErrors'


export default function PageGeneratorFromTemplate() {
    const initialState = {snippetId: 0, snippetName: '', snippetDesc: '', 
                   //labels: [{labelId: 0, labelName: ''}], labels needs to be an empty object
                    href: '',
                   snippetTypeId: 1,
                   filters: [], orderBy: [],
                   dataConnectionId: 0, templateId: 0, dataConnectionInfo: {}};
    //const [snippetInfo, setSnippetInfo] = useState(initialState);
    const [availableFilters, setAvailableFilters] = useState([]);
    const [availableFieldsForOrderBy, setAvailableFieldsForOrderBy] = useState([]);
    const [availableFieldsForHref, setAvailableFieldsForHref] = useState([]);
    const [templateDataConnectionMetadata, setTemplateDataConnectionMetadata] = useState({}); // dictionary {templateId: {dataTypeInMetadata: true, filters: filters}}
    const [templateMetadata, setTemplateMetadata] = useState({});

    const [workflowNumSnippetsToCreate, setWorkflowNumSnippetsToCreate] = useState(1);


    const [snippetData, setSnippetData] = useState(initialState);

    const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [activeErrorList, setActiveErrorList] = useState([]);

    let params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getDataConnectionInfo(params.dataConnectionId)
    }, [params.dataConnectionId])
    useEffect(() => {
        getTemplateMetadata(params.templateId)
    }, [params.templateId])

    const handleClose = () => {
        navigate('/ph/generator')
    }
    const handleElementChange = (snippetInfo) => {
        //console.log(snippetInfo)
        setSnippetData(snippetInfo)
        try {
            snippetDataValidationSchemaGenerator.validateSync(snippetInfo, {abortEarly: false});
            setActiveErrorList([])
          }
          catch (e) {
            const errorList = getErrorListFromYupError(e)
            setActiveErrorList(errorList)
          }
      
    }

    const submitDataSnippet = async () => {
        try {
            snippetDataValidationSchemaGenerator.validateSync(snippetData, {abortEarly: false});
            setActiveErrorList([])
          }
          catch (e) {
            const errorList = getErrorListFromYupError(e)
            setActiveErrorList(errorList)
            console.log(errorList)
            return false;
          }
      
    setShowLoader(true);
    setSuccessHtmlMsg('');
    
    try {
        let url = `${process.env.REACT_APP_LION_API_URL}/Snippets/create/1`;
        const dataToPost = {...snippetData, fadeInFunction: JSON.stringify(snippetData.fadeInFunction)}

        const response = await axios.post(url, dataToPost);
    
        setSuccessHtmlMsg(response.data);
        setShowSuccessMsg(true);
    } catch (error) {
        setShowSuccessMsg(false);
        setShowErrorMsg(true);
        console.log(error);
    }
    setShowLoader(false);
    setSubmitted(true)
    }

    const getDataConnectionInfo = async (dataConnectionId) => {
    try {
            const url = `${process.env.REACT_APP_LION_API_URL}/Feeds/info/${dataConnectionId}`;
            const response = await axios.get(url);
        
            const dataTypeInMetadata = response.data.feedType === 'CATALOG';
            const filters = GetFilterDefFromMetadata(JSON.parse(response.data.metadata), dataTypeInMetadata);
            setAvailableFilters(filters);
            setAvailableFieldsForOrderBy(filters.map(f => ({fieldName: f.conditionName, dataType: f.dataType})))
            setAvailableFieldsForHref(filters.map(f => (f.conditionName)))
    
            const newTemplateDataConnectionMetadata = {...templateDataConnectionMetadata, [dataConnectionId]:{'dataTypeInMetadata': dataTypeInMetadata, 'filters': filters }}
            setTemplateDataConnectionMetadata(newTemplateDataConnectionMetadata);
            // console.log(newTemplateDataConnectionMetadata)
        
    } catch (error) {
        console.log(error);
    }
    }

    const getTemplateMetadata = async (templateId) => {    
    try {
            const url = `${process.env.REACT_APP_LION_API_URL}/Templates/info/${templateId}`;
            const response = await axios.get(url);
        
            setTemplateMetadata(response.data);
        
    } catch (error) {
        console.log(error);
    }
    }
             
    return <div>
        {!submitted && !showLoader &&
                <GeneratorElementManager
                        templateId={params.templateId}
                        dataConnectionId={params.dataConnectionId}
                        snippetInfo={snippetData}
                        availableFilters={availableFilters}
                        availableFieldsForOrderBy={availableFieldsForOrderBy}
                        availableFieldsForHref={availableFieldsForHref}
                        templateMetadata={templateMetadata}
                        onChange={handleElementChange}
                        onNumSnippetsChange={(num) => setWorkflowNumSnippetsToCreate(num)}
                        validationErrorsList={activeErrorList}
                        />
        }
        <div className='flex justify-center mt-20 mb-5'>
            {showLoader && <LionLoaderGradient /> }
            {showSuccessMsg && <SnippetCodeViewer numSnippets={workflowNumSnippetsToCreate} html={successHtmlMsg} />}      
        </div>        

        <div className='flex flex-col gap-4 justify-center items-center'>
            {!submitted && !showLoader &&  <div className="w-1/2 flex flex-row-reverse">
                <button type="button" onClick={submitDataSnippet} className="text-white bg-ccColorDarkTeal hover:bg-ccColorTeal  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Submit</button>
                </div>
            }
            {submitted &&  <div className="w-1/2 flex flex-row-reverse">
                <button type="button" onClick={handleClose} className="text-white bg-ccColorDarkTeal hover:bg-ccColorTeal font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Close</button>
                </div>
            }
        </div>
    </div>
}
