import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { PersAnimationsElementManager } from "../workflows/persAnimationsWorkflow";
import {LionLoaderGradient} from '../commonComponents/generalComponents'
import {SnippetCodeViewer} from '../commonComponents/snippetGeneric'

import { getErrorListFromYupError, getErrorMessage, snippetDataValidationSchemaBase } from '../components/helperErrors'

export default function PagePersAnimationsFromTemplate() {
    const [snippetData, setSnippetData] = useState({});

    const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [activeErrorList, setActiveErrorList] = useState([]);
    
    let params = useParams();
    const navigate = useNavigate();

    const handleClose = () => {
        navigate('/ph/persAnimation')
    }
    const handleElementChange = (snippetInfo) => {
        setSnippetData(snippetInfo)
        if (activeErrorList.length > 0) {
            try {
                snippetDataValidationSchemaBase.validateSync(snippetInfo, {abortEarly: false});
                setActiveErrorList([])
              }
              catch (e) {
                const errorList = getErrorListFromYupError(e)
                setActiveErrorList(errorList)
              }    
        }
      }
      
    const submitDataSnippet = async () => {
        try {
            snippetDataValidationSchemaBase.validateSync(snippetData, {abortEarly: false});
            setActiveErrorList([])
          }
          catch (e) {
            const errorList = getErrorListFromYupError(e)
            setActiveErrorList(errorList)
            console.log(errorList)
            return;
          }

    setShowLoader(true);
    setSuccessHtmlMsg('');
    
    try {
        let url = `${process.env.REACT_APP_LION_API_URL}/Snippets/create/14`;
        
        const response = await axios.post(url, snippetData);
    
        setSuccessHtmlMsg(response.data);
        setShowSuccessMsg(true);
    } catch (error) {
        setShowSuccessMsg(false);
        setShowErrorMsg(true);
        console.log(error);
    }
    setShowLoader(false);
    setSubmitted(true)
    }

    return <div>
        {!submitted && !showLoader &&
        <PersAnimationsElementManager
            onChange={handleElementChange}
            templateId={params.templateId}
            validationErrorsList={activeErrorList}
        />
        }
        <div className='flex justify-center mt-20 mb-5'>
            {showLoader && <LionLoaderGradient /> }
            {showSuccessMsg && <SnippetCodeViewer numSnippets={1} html={successHtmlMsg} />}      
        </div>        

        <div className='flex flex-col gap-4 justify-center items-center'>
            {!submitted && !showLoader &&  <div className="w-1/2 flex flex-row-reverse">
                <button type="button" onClick={submitDataSnippet} className="text-white bg-gradient-to-br from-blue-500 to-blue-800 hover:bg-gradient-to-br hover:from-blue-600 hover:to-blue-900  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Submit</button>
                </div>
            }
            {submitted &&  <div className="w-1/2 flex flex-row-reverse">
                <button type="button" onClick={handleClose} className="text-white bg-gradient-to-br from-blue-500 to-blue-800 hover:bg-gradient-to-br hover:from-blue-600 hover:to-blue-900  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Close</button>
                </div>
            }
        </div>
    </div>
}
