import axios from 'axios'

export const getAccessTokenKb = async () => {
    try {
        const url = `${process.env.REACT_APP_LION_API_URL}/User/accessToKb`

        const response = await axios.get(url);
        const token = response.data;

        return token;

    } catch (error) {
    }  

    }
