import * as React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { signOut } from 'aws-amplify/auth';
import { useAuth } from "../Login/AuthProvider";

import { Menu, Transition } from '@headlessui/react'

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Dialog, DialogTitle } from '@mui/material';

import {SettingsContext} from '../components/userSettingsContext';

import { Icon } from '@iconify/react';

import { CCCountDown } from '../commonComponents/helperDateTime';
import { CcActionButton } from '../commonComponents/styledTexts';

import dayjs from 'dayjs';

import { getAccessTokenKb } from '../components/helperHelpScout';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  height: 80,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function TrialEndedDialog(props) {
  const { onClose, selectedValue, open } = props;


  const navigate = useNavigate();

  const handleClose = () => {
    //onClose(selectedValue);
  };


  return (
    <Dialog onClose={handleClose} open={open}>
      <div className='p-6 text-ccColorDarkTeal'>
        <div className='font-semibold text-xl'>Your free trial has now ended.</div>
        <div className='mt-4'>We hope you enjoyed your trial and had enough time to look around. Upgrade to a paid plan to continue using Code Chemistry.</div>
        <CcActionButton className="mt-4" onClick={() => navigate("/admin/subscription-center")} >Upgrade now</CcActionButton>
        <div className="mt-6">Not sure what plan you need? <a className='text-ccColorSecondaryPink' href='https://codechemistry.io/custom-plan-enquiry/' target='_blank' rel='noreferrer'>Contact us</a> and we can help find the right fit.</div>
      </div>
    </Dialog>
  );
}

export default function PageLayout(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [impersonatingUsername, setImpersonatingUsername] = React.useState(undefined);

  const {settings, setSettings} = React.useContext(SettingsContext);

  React.useEffect(() => {
    setImpersonatingUsername(getImpersonatingUserName())
  }, [props.selectedPage])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const linkStyle = {
    textDecoration: "none",
    color: 'inherit'
  };

  const navigate = useNavigate();

  const { setToken } = useAuth();
  const handleLogout = async() => {
    await signOut();
    setToken();
    navigate("/login", { replace: true });

  }
//        <Toolbar className=' bg-gradient-to-r from-lionGradient2From via-lionGradient2Via to-lionGradient2To'>

const getImpersonatingUserName = () => {
  const info = localStorage.getItem('CCimpersonate');
  if (info !== null) return JSON.parse(info).userName
  else return undefined

}

const blockContent = () => {
  const pageUrl = window.location.href
  if (pageUrl.includes('admin/subscription-center')) return false;

  const now = new dayjs()
  const trialEnd = new dayjs(settings.trialEndDate)
  const diff = now.diff(trialEnd, 'second')
  return diff > 0

}
const handleOpenKnowledgeBase = async () => {
  //              <a href={`${process.env.REACT_APP_KNOWLEDGEBASE_URL}`} style={linkStyle} target='_blank' rel='noreferrer'>

  const token = await getAccessTokenKb()

  const redirectUrl = `${process.env.REACT_APP_KNOWLEDGEBASE_URL}/authcallback?token=${token}&returnTo=/`

  window.open(redirectUrl, "_blank");
}

  return (<Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar className=' font-poppins bg-gradient-to-r from-ccColorDarkTeal to-ccColorTeal'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <div className='flex flex-row items-center w-full gap-20'>
            <a href='https://codechemistry.io/' target='_blank' rel='noreferrer'><img src='/assets/logoWhite.png' className=' h-20 w-auto' alt='logo'/></a>
            <div className='flex flex-row gap-1 text-gray-50'>
              <Link type="button" to="/" class="flex items-end  h-[52px] mr-6 pb-[1px] hover:text-ccColorSecondaryPink hover:pb-0 hover:border-b hover:border-ccColorSecondaryPink">Home</Link>
              <Link type="button" to="/elements" class="flex items-end  h-[52px] mr-6 pb-[1px] hover:text-ccColorSecondaryPink hover:pb-0 hover:border-b hover:border-ccColorSecondaryPink">Elements</Link>
              <Link type="button" to="/contentsources" class="flex items-end h-[52px] mr-6 pb-[1px] hover:text-ccColorSecondaryPink hover:pb-0 hover:border-b hover:border-ccColorSecondaryPink">Content Connections</Link>
              <Link type="button" to="/reports" class="flex items-end h-[52px] mr-6 pb-[1px] hover:pb-0 hover:text-ccColorSecondaryPink hover:border-b hover:border-ccColorSecondaryPink">Reports</Link>
            </div>
            <div className='grow'>
            </div>

            <div className="text-right">
              {settings.canImpersonate && 
                  <IconButton sx={{mr: 2}}
                  onClick={() => navigate("/impersonate")}>
                    <Icon icon="solar:incognito-outline" width={32} color='white' />
                    <div className='text-xs ml-2 text-white'>{impersonatingUsername}</div>
                  </IconButton>
              }
              <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex w-10 h-10 justify-center items-center rounded-full bg-black/20 px-4 py-2 text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                {settings.initials}
              </Menu.Button>
            </div>
        <Transition
          //as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div className="px-1 py-1 ">
            <Menu.Item>
                {({ active }) => (
              <Link to={`/admin/users`} style={linkStyle} ><span className={`${
                                                        active ? 'bg-lionGradient2From text-white' : 'text-gray-900'
                                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                  >Users</span></Link>
              )}
              </Menu.Item>
              {/*
              <Menu.Item>
                {({ active }) => (
              <Link to={`/admin/colors`} style={linkStyle} ><span className={`${
                                                        active ? 'bg-lionGradient2From text-white' : 'text-gray-900'
                                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                  >Brand Colours</span></Link>
              )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
              <Link to={`/admin/fonts`} style={linkStyle} ><span className={`${
                                                        active ? 'bg-lionGradient2From text-white' : 'text-gray-900'
                                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                  >Brand Fonts</span></Link>
              )}
              </Menu.Item>
              
              */}
              <Menu.Item>
                {({ active }) => (
              <Link to={`/admin/settings`} style={linkStyle} ><span className={`${
                                                        active ? 'bg-lionGradient2From text-white' : 'text-gray-900'
                                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                  >Brand Configuration</span></Link>
              )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
              <Link to={`/admin/archive`} style={linkStyle} ><span className={`${
                                                        active ? 'bg-lionGradient2From text-white' : 'text-gray-900'
                                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                  >Archive</span></Link>
              )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
              <Link to={`/admin/subscription-center`} style={linkStyle} ><span className={`${
                                                        active ? 'bg-lionGradient2From text-white' : 'text-gray-900'
                                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                  >Subscriptions</span></Link>
              )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
              <a onClick={handleOpenKnowledgeBase} style={linkStyle} target='_blank' rel='noreferrer' ><span className={`${
                                                        active ? 'bg-lionGradient2From text-white cursor-pointer' : 'text-gray-900'
                                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer`}
                                                  >Help  Hub</span></a>
              )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
              <Link to={`/logout`} style={linkStyle} ><span className={`${
                                                        active ? 'bg-lionGradient2From text-white' : 'text-gray-900'
                                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                  >Logout</span></Link>
              )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
              </Menu>
              </div>


                  
         </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Link to={`/`} style={linkStyle}>
              <ListItemButton selected={props.selectedPage === 'home'}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Icon icon="mdi-light:home" width={24} />
                </ListItemIcon>
                <ListItemText primary={'Home'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
              </Link>
            </ListItem>
            {/*
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Link to={`/templates`} style={linkStyle}>
              <ListItemButton selected={props.selectedPage === 'templates'}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Icon icon="carbon:template" width={24} />
                </ListItemIcon>
                <ListItemText primary={'Templates'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
              </Link>
            </ListItem>
            */}
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Link to={`/elements`} style={linkStyle}>
              <ListItemButton selected={props.selectedPage === 'elements'}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Icon icon="mdi:code-block-html" width={24} />
                </ListItemIcon>
                <ListItemText primary={'Elements'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Link to={`/contentsources`} style={linkStyle}>
              <ListItemButton selected={props.selectedPage === 'contentsources'}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Icon icon="eos-icons:big-data-outlined" width={24} />
                </ListItemIcon>
                <ListItemText primary={'Content Connections'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Link to={`/reports`} style={linkStyle}>
              <ListItemButton selected={props.selectedPage === 'reports'}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Icon icon="oui:stats" width={24} />
                </ListItemIcon>
                <ListItemText primary={'Reports'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton selected={props.selectedPage === 'helphub'}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={handleOpenKnowledgeBase}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Icon icon="tabler:help" width={24} />
                </ListItemIcon>
                <ListItemText primary={'Help Hub'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
        
        </List>
        {(open  && settings.trialEndDate) && <div className="px-[22px] flex flex-col justify-center">
                        <div>Your free trial ends in:</div>
                          <CCCountDown  date={settings.trialEndDate} />
                          <Link to={`/admin/subscription-center`} className='mt-2 text-sm font-semibold text-ccColorSecondaryPink' >UPGRADE</Link>
                  </div>
              
        }
        {(!open && settings.trialEndDate)  && <div style={{height: 'calc(100vh - 370px)'}} className='flex flex-col justify-end'> <div className="py-1 ml-2 flex flex-col justify-end rounded bg-ccColorDarkTeal w-12 text-sm font-semibold text-white">
                          <div className='flex justify-center'>trial</div>
                          <div className='flex justify-center'>mode</div>
                        </div>
                        <Link to={`/admin/subscription-center`} className='flex justify-center mt-2 text-xs font-semibold text-ccColorSecondaryPink' >UPGRADE</Link>
            </div>
        }
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {blockContent() ? <div className='mt-48'><TrialEndedDialog open /></div>
        :
          <>{props.children}</>
        }
      </Box>
    </Box>
  );
}