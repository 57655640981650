import { useState } from 'react';



// @mui
import { Container, Typography, IconButton, Tooltip } from '@mui/material';
// components
// import { useSettingsContext } from '../components/settings';

// locales
// import { useLocales } from '../locales';

import TemplateGrid from '../components/templatesGrid';
import TemplateEditor from '../components/templateEditor';
import { useTranslation } from 'react-i18next';

import { Icon as Iconify } from '@iconify/react';
import { LabelSelector } from '../components/labelManager';


// ----------------------------------------------------------------------

export default function PageTemplates() {
  // const { themeStretch } = useSettingsContext();
  // const { translate } = useLocales();

  const [addEditMode, setAddEditMode] = useState(false);
  const [templateIdToEdit, setTemplateIdToEdit] = useState(null);
  const [labelFilters, setLabelFilters] = useState([]);

  const [snippetTypeId, setSnippetTypeId] = useState(undefined); //!!! undefined is good in production
  // use snippetTypeId=12 for timers

  const { t, i18n } = useTranslation();

  const createTemplate = () => {
    setAddEditMode(true);
  }
  const editTemplate = (id) => {
    setAddEditMode(true);
    setTemplateIdToEdit(id);
  }
  const handleLabelsFilter = (labels) => {
    setLabelFilters(labels);
  }

  return (
    <>

      <Container maxWidth='xl' >

      {!addEditMode && (
      <>
            <Typography variant="h3" component="h1" paragraph>
              {t('pageDataTemplates_title')}
            </Typography>
            <div className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2 items-center'>
                <Tooltip title="Create new template" placement='right' >
                  <IconButton variant="contained" color='primary' onClick={createTemplate}>
                    <Iconify icon="material-symbols:add-circle" width="48px" />
                  </IconButton>
              </Tooltip>
                <LabelSelector
                  onChange={handleLabelsFilter} 
                  product='template' />
              </div>
              <TemplateGrid editCall={(id) => editTemplate(id)} 
                            labelFilters={labelFilters}
              />
            </div>
      </>    )      }

        {addEditMode && <>
        <TemplateEditor id={templateIdToEdit} 
        closingCall={() => {setAddEditMode(false); setTemplateIdToEdit(null)}}
        snippetTypeId={snippetTypeId}
        />
        </>}

      </Container>
    </>
  );
}
