
import { useState, useEffect, forwardRef } from 'react';

import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,
        FormControl, FormControlLabel, IconButton, Radio, RadioGroup,
        Slide, Snackbar, Stack,
         TextField, Typography} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

//import Iconify from '../components/iconify';
import { Icon as Iconify } from '@iconify/react';

import { lionIcons } from './helpersIcons';
import {CcActionButton}  from '../commonComponents/styledTexts';
import { color } from 'highcharts';
import { ccColorDarkTeal, ccColorSecondaryPink } from './helpersStyles';


export default function SnippetHtmlViewer(props) {

    const [indexes, setIndexes] = useState([]);
    const [htmlImg, setHtmlImg] = useState('');
    const [htmlHref, setHtmlHref] = useState('');
    const [snackBarClipboardOpen, setSnackBarClipboardOpen] = useState(false);

    useEffect(() => {
        if (props.numSnippets !== undefined) {
            const inds = [];
            for (let i = 0; i < props.numSnippets; i+=1) {
                inds.push(i+1);
            }
            setIndexes(inds)
        }
    }, []);

    useEffect(() => {
        if (props.html === undefined || props.html === null || props.html === '') return;
        let regex = "src=\"(.*?)\""
        let matches = props.html.match(regex)
        if (matches === null) return;
 
        // console.log(matches[1])
        setHtmlImg(matches[1]);

        regex = "href=\"(.*?)\""
        matches = props.html.match(regex)
        // console.log(matches[1])
        setHtmlHref(matches[1]);

    }, [props.html]);

    const copyHtmlCodeToClipboard = (code) => {
        setSnackBarClipboardOpen(true);
        navigator.clipboard.writeText(code);
        }

    const copyAllToClipboard = () => {
        console.log('entrato')
        let result = ''
        indexes.forEach(i => {
            const snippetNum = `element=${i}`;
            const imgSrc = htmlImg.replace('element=1', snippetNum);
            const href = htmlHref.replace('element=1', snippetNum);

            result += `Image  ${i}\n`;
            result += `${imgSrc}\n`;
            result += `URL  ${i}\n`;
            result += `${href}\n\n`;
        })

        setSnackBarClipboardOpen(true);
        navigator.clipboard.writeText(result);
        }
    
    const handleCloseSnackBarClipboard = () => {
        setSnackBarClipboardOpen(false);
        }

    const SnackBarAction = (
        <>
            <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackBarClipboard}
            >
            <Iconify icon={lionIcons.Icon_Actions_Cancel} />
            </IconButton>
        </>
        );
                    
// sx={{fontFamily: 'monospace'}}
    return <Stack spacing={1} display='flex' alignItems='center' >
        <Box sx={{ maxHeight: 350, overflowY: 'scroll'}} >
    {indexes.map(i => {
        const snippetNum = `element=${i}`;
        const imgSrc = htmlImg.replace('element=1', snippetNum);
        const href = htmlHref.replace('element=1', snippetNum);

        return <Stack spacing={0} key={i}><Stack spacing={1} direction='row' display='flex' alignItems='center'>
                  <Typography sx={{width:70}} display='flex' justifyContent='flex-end'>Image {i}</Typography>
                  <TextField sx={{minWidth: 500}} size='small'
                  value={imgSrc} 
                  />
                 <IconButton sx={{color: ccColorDarkTeal}} onClick={() => copyHtmlCodeToClipboard(imgSrc)} >
                     <Iconify icon='heroicons-outline:clipboard-copy' width={42} />
                 </IconButton>
                </Stack>
                <Stack key={i} spacing={1} direction='row' display='flex' alignItems='center'>
                  <Typography sx={{width:70}} display='flex' justifyContent='flex-end'>URL {i}</Typography>
                  <TextField sx={{minWidth: 500}} size='small'
                  value={href} 
                  />
                 <IconButton sx={{color: ccColorDarkTeal}} onClick={() => copyHtmlCodeToClipboard(href)} >
                     <Iconify icon='heroicons-outline:clipboard-copy' width={42} />
                 </IconButton>
                </Stack>
                </Stack>
    })}
    </Box>
    <Button sx={{width:200, color: ccColorSecondaryPink}} startIcon={<Iconify icon='heroicons-outline:clipboard-copy' width={42} />}
            onClick={copyAllToClipboard} >
        Copy all</Button>
    <div className='text-ccColorDarkTeal'>
      <div className='mt-6'>Not sure how to add an Element to your HTML? Learn how
          <a href={`${process.env.REACT_APP_KNOWLEDGEBASE_URL}/article/27-adding-an-element-to-your-html`} target="_blank" rel="noopener noreferrer" className=" text-ccColorSecondaryPink"> here.</a>
      </div>
    
    </div>
<Snackbar
   anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
   open={snackBarClipboardOpen}
   autoHideDuration={1500}
   onClose={handleCloseSnackBarClipboard}
   message="Copied to clipboard"
   action={SnackBarAction}
 />


    </Stack>
}

const Transition = forwardRef((props, ref) => 
  (<Slide direction="up" ref={ref} {...props} />)
);

export function HtmlViewerDialog(props) {
    const [open, setOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    // const [showClose, setShowClose] = useState(false)
    const [voteResetStats, setVoteResetStats] = useState(0)
    const [fetchingVoteTemplateInfo, setFetchingVoteTemplateInfo] = useState(false)
    const [voteNumQuestionsChanged, setVoteNumQuestionsChanged] = useState(false)
  
  
    const handleClickOpen = () => {
      setOpen(true);
      if (props.onClick) props.onClick();
    };
  

    const handleClose = (submit) => {
      setOpen(false);
    };
    const handleCloseAndClearState = () => {
      setShowLoader(false);
      setShowSuccess(false);
      setErrorMsg('');
      setVoteResetStats(0);
  
      setOpen(false);
    }
  
  
    return (<div>
        <Iconify icon='heroicons-outline:clipboard-copy'
                onClick={handleClickOpen} />
        <Dialog
        maxWidth='lg'
        
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseAndClearState}
          aria-describedby="alert-dialog-slide-description"
        >
          {!fetchingVoteTemplateInfo && <DialogTitle><div className='text-ccColorDarkTeal'>{"Element HTML"}</div></DialogTitle>}
  
          <DialogContent>
            <SnippetHtmlViewer numSnippets={1}
                            html={props.html} />
          </DialogContent>

          <DialogActions>
            <CcActionButton onClick={(e) => handleClose(false)}>Cancel</CcActionButton>
            <CcActionButton onClick={() => handleClose(true)}>OK</CcActionButton>
          </DialogActions>
        </Dialog>
      </div>
    
      );
  }
  