import { Stack, Typography, Paper, FormControl, TextField, Button, Alert } from '@mui/material';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';


import LoadingButton from '@mui/lab/LoadingButton';
import { React, useState, useCallback } from 'react';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Icon as Iconify } from '@iconify/react';

// import AuthVerifyCodeForm from '../sections/auth/AuthVerifyCodeForm';
import  LionInputCode  from '../components/LionInputCode';

import axios from 'axios';



export default function PageActivateLogin() {

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [activationCode, setActivationCode] = useState("");
  const [validCode, setValidCode] = useState(false);
  const [validationRequested, setvalidationRequested] = useState(false);
  const [callingServer, setcallingServer] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const formatActivationCode = (code) => {
    setvalidationRequested(false);
    setActivationCode(code);
    if (code.length === 6) setSubmitDisabled(false);
    else setSubmitDisabled(true);
  }

  const handlePasswordChange = (code, isConfirm) => {
    if (isConfirm) {
      setConfirmPassword(code);
      if (code === password) setSubmitDisabled(false);
      else setSubmitDisabled(true);  
    }
    else {
      setPassword(code);
      if (confirmPassword === code) setSubmitDisabled(false);
      else setSubmitDisabled(true);  
    }

  }

const submitCode = async () => {


    try {
      setcallingServer(true);
      const url = `${process.env.REACT_APP_LION_API_URL}/Accounts/ActivationCode/${activationCode}`;
      console.log(url);
      const response = await axios.get(url);
      setcallingServer(false);

      setvalidationRequested(true);
      setValidCode(response.data.isActivationCodeValid);

      if (response.data.isActivationCodeValid) {
        setSubmitDisabled(true);
        setcallingServer(false);
        setActiveStep(1);
      }

      console.log(response.data);

    } catch (error) {
      console.log(error);
    }
  };

  const activateLogin = async () => {

    try {
      setcallingServer(true);
      const url = `${process.env.REACT_APP_LION_API_URL}/Accounts/CreateLogin`;
      console.log(url);
      const payload = {'ActivationCode': activationCode, 'Password': password};
      const response = await axios.post(url, payload);
      setcallingServer(false);


      if (response.data.isActivationCodeValid) setActiveStep(2);

      console.log(response.data);

    } catch (error) {
      console.log(error);
    }
  };

const getCode = (code) => {
  formatActivationCode(code);
}



    return (
      <>
        <Paper style={{width: '40%', marginLeft: '30%', marginRight: '30%', marginTop:'200px', height:'400px'}} elevation={6}>
          
        <Stepper activeStep={activeStep}>
        <Step key={'code'}>
              <StepLabel>Enter activation code</StepLabel>
            </Step>

            <Step key={'password'}>
              <StepLabel>Set your password</StepLabel>
            </Step>
 
            <Step key={'complete'}>
              <StepLabel>Login activated</StepLabel>
            </Step>
      </Stepper>


          <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>

        

            {activeStep === 0 &&
            <>
              <Typography variant="h3">Activate your login</Typography>

              <LionInputCode ids={['code1', 'code2', 'code3', 'code4', 'code5', 'code6']} returnCode={getCode} />

     {/* <TextField value={activationCode} onChange={(event)=> formatActivationCode(event.target.value)} style={{width: '70%', marginLeft: '15%', marginRight: '15%' }} id="code" label="Enter activation code" variant="outlined" /> */}
          {/* onChange={formatActivationCode} */}

              <LoadingButton disabled={submitDisabled} loading={callingServer}
                     style={{width: '70%', marginLeft: '15%', marginRight: '15%' }} 
                     variant='contained' 
                     onClick={submitCode}>Activate</LoadingButton>
                     </>
            }

            {validationRequested && !validCode &&
              <>
              <Alert severity="error"
              style={{width: '70%', marginLeft: '15%', marginRight: '15%' }}
              >Invalid Authorization Code</Alert>
              </>
            }


            {activeStep === 1 &&
            <>
              <Typography variant="h3">Create your password</Typography>

              <FormControl style={{width: '70%', marginLeft: '15%', marginRight: '15%' }}>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(event) => handlePasswordChange(event.target.value, false)}
                  endAdornment={
                    <InputAdornment tabIndex="-1" position="end">
                    <IconButton tabIndex="-1" onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify tabIndex="-1" icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                  }
                  label="Password"
                />  

              </FormControl>

              <FormControl style={{width: '70%', marginLeft: '15%', marginRight: '15%' }}>

                <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-confirm-password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(event) => handlePasswordChange(event.target.value, true)}
                  endAdornment={
                    <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                      <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                  }
                  label="Confirm Password"
                />  
              </FormControl>

              <LoadingButton disabled={submitDisabled} loading={callingServer}
                     style={{width: '70%', marginLeft: '15%', marginRight: '15%' }} 
                     variant='contained' 
                     onClick={activateLogin}>Activate login</LoadingButton>
            </>
            }

          {activeStep === 2 &&
            <>
              <Typography variant="h3">Login activated</Typography>
              <Alert severity="success"
              style={{width: '70%', marginLeft: '15%', marginRight: '15%' }}
              >Congratulations your login is active<br />
              Go to the <a href='login'>login</a> page</Alert>

            </>
            }

          </Stack>
        </Paper>


      </>
    );
  }
