import AuthProvider from "./Login/AuthProvider";
import Routes from "./Routes/index";
import { Amplify } from 'aws-amplify';

import axios from "axios";


import logo from './logo.svg';
import './App.css';
import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('170bfcefa2cda03184879c5e2b5cbeabTz05Njk5MCxFPTE3NTYzODU5ODIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==');


Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: '77d645chsf3gkb5ie960qmf5ru',
      userPoolId: 'eu-west-1_XR0xAuokj',
      loginWith: { // Optional
        oauth: {
          domain: 'abcdefghij1234567890-29051e27.auth.us-east-1.amazoncognito.com',
          scopes: ['openid email phone profile aws.cognito.signin.user.admin '],
          redirectSignIn: ['http://localhost:3000/','https://example.com/'],
          redirectSignOut: ['http://localhost:3000/','https://example.com/'],
          responseType: 'code',
        },
        username: 'true',
        email: 'false', // Optional
        phone: 'false', // Optional
      }
    }
  }
});

 axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
 if (localStorage.getItem("CCimpersonate") !== null) axios.defaults.headers.common["CCimpersonate"] = JSON.parse(localStorage.getItem("CCimpersonate")).uuId;

 axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status === 401) window.location.href = '/sessionexpired';
  
  return Promise.reject(error);
});


function App() {
  return (
    <AuthProvider>
    <Routes />
  </AuthProvider>
  )

  /*
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
  */
}

export default App;
