'use client'

import { useState, useEffect, useRef } from 'react';
import {Box, Button, FormControl, Menu, MenuItem,
    Stack, TextField, Typography} from '@mui/material';


export function HrefSelector(props) {
    // props.href - the actual href value
    // props.fields - all the available fields to select from


    const [anchorEl, setAnchorEl] = useState(null);
    const [href, setHref] = useState('');
    const [allFields, setAllFields] = useState([]);
    const open = Boolean(anchorEl);

    const inputRef = useRef();
    const [selectionStart, setSelectionStart] = useState();


useEffect(() => {
    if (props.href !== undefined && props.href !== null) setHref(props.href)
    if (props.fields !== undefined) {
        const sortedFields = props.fields.sort((a,b) => {
            if ( a.toLowerCase() < b.toLowerCase() ){
                return -1;
              }
              if ( a.toLowerCase() > b.toLowerCase() ){
                return 1;
              }
              return 0;
        })
    
         setAllFields(sortedFields)
    }
}, [props.fields, props.href])

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (fieldName) => {
        // setHref(href.concat('${').concat(fieldName).concat('}'))
        const fieldNameCode = ''.concat('${').concat(fieldName).concat('}');
        const newHref = href.slice(0, selectionStart) + fieldNameCode + href.slice(selectionStart);
        setHref(newHref)
        if (props.onChange !== undefined) props.onChange(newHref)
      setAnchorEl(null);
    };
  
    const updateSelectionStart = () => {
        setSelectionStart(inputRef.current.selectionStart);
    }

    return <Box sx={{width: '100%'}}>
            <Stack direction='row' sx={{width: '100%'}}>
                        <TextField required id="snippetHref" sx={{width: '80%'}}
                        label="Href"
                        value={href}
                        onChange={(e) => {setHref(e.target.value);  if (props.onChange !== undefined) props.onChange(e.target.value)}}
                        onSelect={updateSelectionStart}
                        inputRef={inputRef}
                        error={props.error}
                        helperText={props.helperText}
                    />

                    <Button color='secondary' onClick={handleClick} >Insert field</Button>
            </Stack>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {allFields.map(f => <MenuItem key={f} onClick={() => handleClose(f)}>{f}</MenuItem>)}
            </Menu>
    </Box>

}