import axios from 'axios';

import { useEffect, useState, useRef } from "react"
import {LionColorPicker, LionColorAndBorderPicker, LionLinearGradientPicker} from '../components/lionColorPicker'
import {AnimationSettingsEdit, ArcSimpleEdit, InputText, InputNumber, FontSelector, ImageUploader, Toggle, LionListbox} from './inputFields'
import Konva from 'konva';
import {RandomId}  from '../components/helpers';
import {ParseSvg, EditSvg, DeleteSegment, ChangeEndPointSvg, GetSegmentEndXY}  from '../components/helpersSvg';
import Collapsible from 'react-collapsible';
import { Icon as Iconify } from '@iconify/react';
import DatafeedGrid from '../components/datafeedGrid'
import {Tooltip } from '@mui/material';



// TemplateGallery is used in saas pre-defined templates
export function TemplateGallery(props) {
  /*
  props:
  productTypeId: show templates of a specific type
  */
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        getTemplates();
    }, [props.productTypeId])

    const getTemplates = async () => {
        try {  
          const url = `${process.env.REACT_APP_LION_API_URL}/Templates/saas/templateGallery/${props.productTypeId}`;
          const response = await axios.get(url);
          const data = response.data;

          // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Templates/saas/templateGallery/${props.productTypeId}/`;
          // const response = await fetch(url, { method: "GET", });    
          // const data = await response.json();

          if (props.selectedTemplateId === undefined) data[0].selected = true; // pre-select the first template
          else {
            data.find(t => t.templateId === props.selectedTemplateId).selected = true
          }

        for (let i=0; i<data.length; i++) {
          data[i].metadata = JSON.parse(data[i].metadata)
          // data[i].modelJson = JSON.parse(data[i].modelJson)
        }
        if (props.onChange !== undefined) {
           props.onChange(data[0])
           if (props.selectedTemplateId === undefined) props.onChange(data[0]);
           else {
            props.onChange(data.find(t => t.templateId === props.selectedTemplateId))
           }
           }
        setTemplates(data);

        } catch (error) {
          console.log(error);
        }
      }

    const handleTemplateChange = (templateId) => {
      // @ts-ignore
      const newTemplates = templates.map(t => {return {...t, selected: templateId === t.templateId}});
      setTemplates(newTemplates);
      const activeTemplate = newTemplates.find(t => t.templateId == templateId);

      if (props.onChange !== undefined) props.onChange(activeTemplate)
    }

    return <>
    
<div className="grid grid-cols-2 md:grid-cols-3 gap-4">
    {
        templates.map(t => t.selected ? (<div key={t.templateId} className="w-[400px] h-[350px] flex justify-center bg-blue-200 hover:bg-blue-400 p-2 rounded-lg shadow-lg cursor-pointer" onClick={() => handleTemplateChange(t.templateId)}>
            <img
              src={`/images/${t.thumbnailURL}`}
              alt="Template"
              style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}}
              //width={props.itemWidth ? props.itemWidth : 500}
              //height={props.itemHeight ? props.itemHeight : 150}
            />    
            </div>) : (<div key={t.templateId} className="w-[400px] h-[350px] flex justify-center bg-slate-200 hover:bg-blue-400 p-2 rounded-lg shadow-lg cursor-pointer" onClick={() => handleTemplateChange(t.templateId)}>
            <img
              src={`/images/${t.thumbnailURL}`}
              alt="Template"
              style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}}
              //width={props.itemWidth ? props.itemWidth : 500}
              //height={props.itemHeight ? props.itemHeight : 150}
            />       
            </div>)
        )

    }
</div>


    </>
}

export const mergeValuesInModel = (model, props, bindings) => {
  let result = model;

  props.forEach(p => {
    if (p.propType === 'fontSelector') {
      result = result.replaceAll(`<${p.propName}_fontFamily>`, p.defaultValue.fontName)
      result = result.replaceAll(`<${p.propName}_fontSize>`, p.defaultValue.size)
      result = result.replaceAll(`<${p.propName}_fontStyle>`, p.defaultValue.style)
      result = result.replaceAll(`<${p.propName}_textDecoration>`, p.defaultValue.textDecoration)
      result = result.replaceAll(`<${p.propName}_align>`, p.defaultValue.align)
    }
    else if (p.propType === 'linearGradient') {
      result = result.replaceAll(`<${p.propName}_colorStops>`, JSON.stringify(p.defaultValue.colorStops))
    }
    else if (p.propType === 'borderColor') {
      result = result.replaceAll(`<${p.propName}_color>`, p.defaultValue.color)
      result = result.replaceAll(`<${p.propName}_width>`, p.defaultValue.width)
    }
    else if (p.propType === 'animation') {
      result = result.replaceAll(`<${p.propName}_duration>`, p.defaultValue.duration)
      result = result.replaceAll(`<${p.propName}_pauseBetweenLoops>`, p.defaultValue.pauseBetweenLoops)
      result = result.replaceAll(`<${p.propName}_isLoop>`, JSON.stringify(p.defaultValue.isLoop))
    }
    else result = result.replaceAll(`<${p.propName}>`, p.defaultValue)
  });
  //console.log(result);
  if (bindings !== undefined && bindings.length > 0) {
    bindings.forEach(b => {
      if (b.databindValue !== '') result = result.replaceAll(`<${b.mergeCode}>`, b.databindValue)
      // we only replace if there is actually a binding defined
    })
  }

  return JSON.parse(result);
}

export function TemplateGalleryCustomizer(props) {
  const DEFAULT_SAMPLE_DATA = {title: 'product title', price: 'prod price',
  image_link: 'https://images.katespade.com/is/image/KateSpade/WF52390_040?$s7fullsize$'}

  /*
  props
  databindMode: it displays controls to select a dataconnection and the bindings
                if not active, it display controls to customize the template

  onDataConnectionChange: returns a dataConnectionInfo (available when in databindMode)
  dataConnectionInfo: 
  */

  const [templateMetadata, setTemplateMetadata] = useState({inputProps: [], dataBinds:[]})
  const [templateModel, setTemplateModel] = useState({})
  const [previewImageData, setPreviewImageData] = useState(false);
  const [previewUrlParamsValues, setPreviewUrlParamsValues] = useState([]);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [useBackgroundImage, setUseBackgroundImage] = useState(false);
  const [canvasUploadedFiles, setCanvasUploadedFiles] = useState({});
  const [canvasWidthStr, setCanvasWidthStr] = useState("500");
  const [canvasHeightStr, setCanvasHeightStr] = useState("150");
  const [svgEditBlocks, setSvgEditBlocks] = useState([]); //these are used only in the "ArcSimpleEdit" component
  
  const canvasRef = useRef(null);
  const [arcEditNodesTimeout, setArcEditNodesTimeout] = useState(undefined);

  // state for databindMode
  const [dataConnectionInfo, setDataConnectionInfo] = useState(undefined);
  const [dataConnectionInfoLoaded, setDataConnectionInfoLoaded] = useState(false);
  const [sampleData, setSampleData] = useState(undefined);
  //

const getInputPropValue = (propName) => {
 const match = templateMetadata.inputProps.find(p => p.propName === propName);
 if (match === undefined) return undefined;

 return match.defaultValue;
}
const getCanvasWidth = () => {
  //return parseInt(getInputPropValue("canvasWidth"))
  return getInputPropValue("canvasWidth")
}


const initCanvas = () => {
  const tr = new Konva.Transformer();
  const trNoResize = new Konva.Transformer({resizeEnabled: false});
  const layer = new Konva.Layer();

  const stage = new Konva.Stage({
    container: 'canvas',   // id of container <div>
    width: parseInt(canvasWidthStr),
    height: parseInt(canvasHeightStr)
  });

  // then create layer
  // stage.container().style.backgroundColor = '#ccc';

    
  layer.add(tr);
  layer.add(trNoResize);
  stage.add(layer);

  return stage;
};

  useEffect(() => {
    if (props.canvasUploadedFiles !== undefined && Object.keys(props.canvasUploadedFiles).length > 0) {
      setCanvasUploadedFiles(props.canvasUploadedFiles)
      setUseBackgroundImage(true)
    }
  }, [])

useEffect(() => {

  if (props.dataConnectionInfo !== undefined) {
    if (props.databindMode) getDataConnectionInfoAndSampleData(props.dataConnectionInfo.id);  
    else {
      //legacy: the "id" is called differently in some old code. We have "id" and "feedId"
      // we need to use "feedId" here instead of "id"
      const dInfo = {...props.dataConnectionInfo, feedId: props.dataConnectionInfo.id} 
      //if we are not in databindMode we don't need sample data,  we just need the dataConnectionId
      setDataConnectionInfo(dInfo); 
      setDataConnectionInfoLoaded(true);
    }
  }
}, [props.dataConnectionInfo])


  const getTemplatePreview = async (templateMetadata, templateModel, urlParams, filters) => {
    setPreviewLoading(true);
    setCanvasWidthStr(getCanvasWidth()); //we need to set the html canvas size at every redraw

    try {
      // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Templates/preview`;
      const url = `${process.env.REACT_APP_LION_API_URL}/Templates/preview`;

      const requestObj = prepareModelToPost(templateMetadata, templateModel, urlParams, filters)
      //console.log(requestObj)

      const response = await axios.post(url, requestObj);

      // const response = await fetch(url, { method: "POST", body: JSON.stringify(requestObj) });    
      // response.data = await response.json();

      setPreviewLoading(false);
      // setPreviewImageData(`data:image/jpg;base64,${response.data.imageBase64}`);
      
      // img.src = `data:image/jpg;base64,${response.data.imageBase64}`
      
      const canvasWidth = requestObj.canvas.width;
      let canvasHeight = requestObj.canvas.height;
      if (props.productTypeId === 3) {
        canvasHeight = response.data.height;
        templateMetadata.inputProps.find(ip => ip.propName === "canvasHeight").defaultValue = canvasHeight;
      }

      canvasRef.current.container().style.backgroundSize = `${canvasWidth}px ${canvasHeight}px`;      
      canvasRef.current.container().style.backgroundImage = `url(data:image/jpg;base64,${response.data.imageBase64})`;
  
      canvasRef.current.width(canvasWidth);
      canvasRef.current.height(canvasHeight);
      canvasRef.current.container().style.width = `${canvasWidth}px`;
      canvasRef.current.container().style.height = `${canvasHeight}px`;

    } catch (error) {
      setPreviewLoading(false);
      //console.log(error);
    }
  }
const prepareModelToPost = (templateMetadata, templateModel, urlParams, filters) => {
    // object to post: {
    //                    model: {this is the Konva obj}, 
    //                   canvas: {the object canvasProps which is necessary because Konva doesn't handle the background well}
    //                    dataConnectionId, etc...
    //                    urlParameters: array with test values
    //                    previewItems: object  with rules on to filter products to preview 
    //                    fadeInFunction: optional fade id effect
    //                    animation: optional animation global settings
    //                    previewSampleData: if the template has data-bindings and the bindings are not defined yet THEN we need to provide some default bidnings
    //                                        for the preview we also pass any url parameter test value inserted by users, etc...
    //                    iconProgressBarFitSvgToCanvas: true when productTypeId=9 (for iconProgressBar)
    // }

    // if urlParams have been passed as a parameter we use those
    // otherwise we use the ones from the state (previewUrlParamsValues)
    const params = urlParams === undefined ? previewUrlParamsValues : urlParams;
    // const filterParams = filters === undefined ? previewItemsFilters : filters;
    const filterParams = null;
    const previewModel = mergeValuesInModel(templateModel, templateMetadata.inputProps, templateMetadata.dataBinds)

    // check if we need to send previewSampleData               
    if (templateMetadata.dataBinds !== undefined && templateMetadata.dataBinds.length > 0) {
      let allAssigned = true;
      templateMetadata.dataBinds.forEach(b => {if (b.databindValue === '') allAssigned = false});
      if (!allAssigned) {
        // when using preview sample data, we need to remove all the bindings
        // and replace them with fixed text
        const blocks = previewModel.model.children[0].children;
        for (let i=0; i<blocks.length; i+=1) {
          if (blocks[i].attrs.dataBindType === 'databind') {
            const databindValue = blocks[i].attrs.dataBindValue.replace('<','').replace('>','').replace('databind_','');
            blocks[i].attrs = {...blocks[i].attrs, dataBindType: 'none', text: DEFAULT_SAMPLE_DATA[databindValue]}
          }
        }
        //console.log(previewModel.model.children[0].children)
      }
    }
    const result = {model: previewModel.model,
                        canvas: previewModel.canvas,
                        dataConnectionId: dataConnectionInfo === undefined ? null : dataConnectionInfo.feedId ?? dataConnectionInfo.id,
                        previewItems: filterParams,
                        productTypeId: props.productTypeId,
                        "fadeInFunction": null,
                        urlParameters: params,
                        animation: previewModel.animation,
                        iconProgressBarFitSvgToCanvas: props.productTypeId === 3 ? true : false,
                      };
    return result;
}
const getUrlParametersFromModel = (templateModel, defaultValues) => {
  const results = [];
  // we have 1 konva stage and 1 konva layer on top of the blocks;
  const blocks = templateModel.children[0].children;

  blocks.forEach(b => {
    if (b.attrs.dataBindType !== undefined && b.attrs.dataBindType === 'url') {
      const paramName = b.attrs.text.replace('param(', '').slice(0, -1)
      let paramValue = paramName;
      if (defaultValues !== undefined) {
        const match = defaultValues.find(v => v.name === paramName);
        if (match !== undefined) paramValue = match.value;
      }
      results.push({name: paramName, value: paramValue});
    }
  })

  return results;
}
const changePreviewUrlParamValue = (index, newValue) => {
  const newParams = [...previewUrlParamsValues];
  const param = newParams[index];
  const newParam = {...param, value: newValue};

  const result = newParams.splice(index, 1, newParam)

  setPreviewUrlParamsValues(newParams);
}
const handleRefreshPreview = () => {
  getTemplatePreview(templateMetadata, templateModel, previewUrlParamsValues, null);

}
const handleModelChange = (propName, propValue) => {
  //console.log(propValue)
  const newInputProps = [...templateMetadata.inputProps];
  const propIndex = newInputProps.findIndex(p => p.propName === propName);

  const prop = newInputProps[propIndex];
  const newParam = {...prop, defaultValue: propValue};

  newInputProps.splice(propIndex, 1, newParam)
  const newTemplateMetadata = {...templateMetadata, inputProps: newInputProps}
  setTemplateMetadata(newTemplateMetadata);
  if (props.onInputPropsChange !== undefined) {
  props.onInputPropsChange(newInputProps)
  }
  //if (propName === "canvasWidth") setCanvasWidth(propValue)
}
const handleModelMultiChange = (propNames, propValues) => {
  const newInputProps = [...templateMetadata.inputProps];
  for (let i =0; i<propNames.length; i+=1) {
    const propName = propNames[i];
    const propValue = propValues[i];

    const propIndex = newInputProps.findIndex(p => p.propName === propName);

    const prop = newInputProps[propIndex];
    const newParam = {...prop, defaultValue: propValue};
  
    newInputProps.splice(propIndex, 1, newParam)
    }

  const newTemplateMetadata = {...templateMetadata, inputProps: newInputProps}
  setTemplateMetadata(newTemplateMetadata);

  if (props.onInputPropsChange !== undefined) {
  props.onInputPropsChange(newInputProps)
  }
  return newTemplateMetadata;
}

  useEffect(() => {
    if (props.templateMetadata === undefined || props.templateJSON === undefined) return;
    if (props.databindMode && !dataConnectionInfoLoaded) return;

    canvasRef.current = initCanvas();

    setTemplateMetadata(props.templateMetadata);
    const templateJson = mergeValuesInModel(props.templateJSON, props.templateMetadata.inputProps);
    setTemplateModel(props.templateJSON);
    getTemplatePreview(props.templateMetadata, props.templateJSON, getUrlParametersFromModel(templateJson.model, props.defaultParamValues), null);
    setPreviewUrlParamsValues(getUrlParametersFromModel(templateJson.model, props.defaultParamValues))

    //setDataConnectionInfoLoaded(false)
  }, [props.templateJSON, dataConnectionInfoLoaded])

const handleFileChange = (fileInfo) => {
  // this can only be 1 file for the canvas background
  const filenames = fileInfo.fileMappings;
  const fileSizes = fileInfo.fileSizes;

  const fn = Object.keys(filenames);
  //we want to resize the canvas to fit the uploaded backgroud
  canvasRef.current.width(fileSizes[fn[0]].width);
  canvasRef.current.height(fileSizes[fn[0]].height);

  setCanvasUploadedFiles(filenames);
  const newTemplateMetadata = handleModelMultiChange(["canvasImageUrl", "canvasDatabindType", "canvasWidth", "canvasHeight"], ['"' + filenames[fn[0]] + '"', '"none"', fileSizes[fn[0]].width, fileSizes[fn[0]].height])
  getTemplatePreview(newTemplateMetadata, templateModel, previewUrlParamsValues, null);
  if (props.onCanvasUploadedFileChange !== undefined) props.onCanvasUploadedFileChange(filenames);
}
const handleUseBackgroundImageChange = (use) => {
  if (!use) {
    const newTemplateMetadata = handleModelMultiChange(["canvasImageUrl", "canvasDatabindType"], [null, null])
    getTemplatePreview(newTemplateMetadata, templateModel, previewUrlParamsValues, null);
  }
  else {
    const fn = Object.keys(canvasUploadedFiles);
    if (fn.length > 0) {
      const newTemplateMetadata = handleModelMultiChange(["canvasImageUrl", "canvasDatabindType"], ['"' + canvasUploadedFiles[fn[0]] + '"', '"none"'])
      getTemplatePreview(newTemplateMetadata, templateModel, previewUrlParamsValues, null);  
    }
  
  }

  setUseBackgroundImage(use);
}

const handleLinearGradientChange = (gradientProps) => {
  const colorsStops = [];
  const delta = 1 / (gradientProps.colors.length - 1);
 
  gradientProps.colors.forEach((c, index) => {colorsStops.push(index * delta); colorsStops.push(c)});
  const newGradient = getInputPropValue("mainBlockLinearGradient")
  newGradient.colorStops = colorsStops;
  handleModelChange("mainBlockLinearGradient", newGradient)
}

const handleArcChange = (propName, svgPath) => {

  const match = previewUrlParamsValues.find(p => p.name === "text"); // "text" is the merge tag in the template
  const nodes = canvasRef.current.find(n => (n.attrs.id !== undefined && n.attrs.id.length >=  20));

  if (nodes.length === 0) handleAddTextPathBlock("mainBlock", match.value, true);
  else {
    for (let i = 0; i<nodes.length; i += 1) {
      nodes[i].setAttrs({data: svgPath});
    }
  }
  handleModelChange(propName, svgPath)

    // we want to delete these nodes after an interval
    if (arcEditNodesTimeout !== undefined) {
       clearTimeout(arcEditNodesTimeout);
    }
    setArcEditNodesTimeout(setTimeout(() => {
      const nodesToDelete = canvasRef.current.find(n => (n.attrs.id !== undefined && n.attrs.id.length >=  20));
      nodesToDelete.forEach(n => n.destroy());
    }, 1500))
}

  const renderCanvasInputs = () => {
    const inputs = templateMetadata.inputProps.filter(p => p.visible).filter(p => (p.propName === 'canvasWidth' || p.propName === 'canvasHeight' || p.propName === 'canvasBackgroundColor'));

    return <div className="flex flex-col gap-4 border border-gray-200 rounded shadow-md p-2">
        {props.allowBakcgroundImage && <Toggle displayName="Use background image"
                onChange={handleUseBackgroundImageChange}
                checked={useBackgroundImage} />
                }
        {!useBackgroundImage &&
                      <div className="flex flex-row gap-x-8">
                      {
                        inputs.map((p, index) =><div key={index}>
                        {p.propType === 'number' && <div className=" max-w-[200px]"> 
                                    <InputNumber displayName={p.propPrettyName} 
                                                value={p.defaultValue}
                                                disabled={p.propName === "canvasHeight"}
                                                onChange={(num) => {handleModelChange(p.propName, num)}}/>
                                  </div>
                        }
                        {p.propType === 'color' && <div>  <label className="block text-sm font-medium leading-6 text-gray-900">
                                              {p.propPrettyName}
                                          </label>
                                          <LionColorPicker color={p.defaultValue}
                                              onChange={(color) => {handleModelChange(p.propName, color)}}
                                              />
                                        </div>
                        }

                        </div>)
                      }
                    </div>
        }
        {useBackgroundImage && <ImageUploader onChange={handleFileChange}
                                            uploadedFiles={canvasUploadedFiles} />

        }
    </div>
  }

  const renderInputs = () => {
    const inputs = templateMetadata.inputProps.filter(p => p.visible && p.groupName === undefined).filter(p => (p.propName !== 'canvasWidth' && p.propName !== 'canvasHeight' && p.propName !== 'canvasBackgroundColor'));
    return <>
          {inputs.map((p, index) =><div key={index}>
        {p.propType === 'number' && <div className=" max-w-[200px]"> 
                                        <InputNumber displayName={p.propPrettyName} 
                                                    value={p.defaultValue}
                                                    onChange={(num) => {handleModelChange(p.propName, num)}}/>
                                      </div>}
        {p.propType === 'text' && <InputText displayName={p.propPrettyName} value={p.defaultValue} />}
        {p.propType === 'color' && <div>  <label className="block text-sm font-medium leading-6 text-gray-900">
                                              {p.propPrettyName}
                                          </label>
                                          <LionColorPicker color={p.defaultValue}
                                              onChange={(color) => {handleModelChange(p.propName, color)}}
                                              />
                                        </div>}
        {p.propType === 'fontSelector' && <div>  <label className="block text-sm font-medium leading-6 text-gray-900">
                                              {p.propPrettyName}
                                          </label>
                                           <FontSelector currentFont={p.defaultValue}
                                           onChange={(f) => handleModelChange(p.propName, f)}
                                            />
                                           </div>}
        {p.propType === 'linearGradient' && <div>
                                              <LionLinearGradientPicker saas
                                                    onChange={handleLinearGradientChange}
                                                    direction={p.defaultValue.direction}
                                                    colorsStops={p.defaultValue.colorStops}
                                                    />
          </div>}
        {p.propType === 'svg' && <div>
                              <ArcSimpleEdit data={p.defaultValue}
                                            onChange={(svgPath) => {handleArcChange(p.propName, svgPath)}}
                                            blocks={svgEditBlocks}
                                  />
          </div>}
          {p.propType === 'borderColor' && <div>  <label className="block text-sm font-medium leading-6 text-gray-900">
                                              {p.propPrettyName}
                                          </label>
                                          <LionColorAndBorderPicker data={p.defaultValue}
                                              onChange={(color) => {handleModelChange(p.propName, color)}}
                                              />
                                        </div>}

      </div>)}

    </>
  }

  const renderRatingInputs = () => {
    const inputs = templateMetadata.inputProps.filter(p => p.visible).filter(p => (p.groupName === 'rating'));
    if (inputs.length === 0) return <></>
    return <div className="flex flex-col gap-4 border border-gray-200 rounded shadow-md p-2">
          <div>Rating</div>
                      <div className="flex flex-row gap-x-8">
                      {
                        inputs.map((p, index) =><div key={index}>
                        {p.propType === 'number' && <div className=" max-w-[200px]"> 
                                    <InputNumber displayName={p.propPrettyName} 
                                                value={p.defaultValue}
                                                onChange={(num) => {handleModelChange(p.propName, num)}}/>
                                  </div>
                        }
                        {p.propType === 'color' && <div>  <label className="block text-sm font-medium leading-6 text-gray-900">
                                              {p.propPrettyName}
                                          </label>
                                          <LionColorPicker color={p.defaultValue}
                                              onChange={(color) => {handleModelChange(p.propName, color)}}
                                              />
                                        </div>
                        }

                        </div>)
                      }
                    </div>
    </div>
  }

  const renderAnimationInputs = () => {
    const inputs = templateMetadata.inputProps.filter(p => p.visible).filter(p => (p.propType === 'animation'));
    if (inputs.length !== 1) return <></>
    const settings = inputs[0];

    return <div className="flex flex-col gap-4 border border-gray-200 rounded shadow-md p-2">
         <AnimationSettingsEdit displayName={settings.propPrettyName}
         settings={settings.defaultValue}
         onChange={(animationSettings) => {handleModelChange(settings.propName, animationSettings)}}
         />
    </div>
  }

  const handleAddTextBlock = (blockName, blockText) => {
    const fontInfo = getInputPropValue(`${blockName}Font`)
    const text =  new Konva.Text({
      id: RandomId(20),
      x: getInputPropValue(`${blockName}X`),
      y: getInputPropValue(`${blockName}Y`),
      width: getInputPropValue(`${blockName}Width`),
      height: getInputPropValue(`${blockName}Height`),
      lineHeight: 1.1,
      text: blockText !== undefined ? blockText : 'Lorem ipsum, Hello world Text, ciao mondo, hola mundo',
      fontSize: fontInfo.size,
      fontFamily: fontInfo.fontName,
      fontStyle: fontInfo.style,
      textDecoration: fontInfo.textDecoration,
      align: fontInfo.align,
      fill: '#000',
      fillMode: 'solid',
      gradientType: 'linear',
      strokeWidth: 0,
      draggable: true,
      dataBindType: 'none',
    });

  /*
    text = new Konva.Text({
      id: RandomId(20),
      x: tMetadata.attrs.x,
      y: tMetadata.attrs.y,
      width: tMetadata.attrs.width,
      height: tMetadata.attrs.height,
      lineHeight: tMetadata.attrs.lineHeight,
      text: tMetadata.attrs.text,
      fontSize: tMetadata.attrs.fontSize,
      fontFamily: tMetadata.attrs.fontFamily,
      fontStyle: tMetadata.attrs.fontStyle,
      textDecoration: tMetadata.attrs.textDecoration,
      align: tMetadata.attrs.align,
      fill: tMetadata.attrs.fill,
      fillMode: tMetadata.attrs.fillMode,
      fillPriority: tMetadata.attrs.fillPriority,
      gradientType: tMetadata.attrs.gradientType,
      gradientDirection: tMetadata.attrs.gradientDirection,
      fillLinearGradientColorStops: tMetadata.attrs.fillLinearGradientColorStops,
      fillLinearGradientStartPointX: tMetadata.attrs.fillLinearGradientStartPointX,
      fillLinearGradientStartPointY: tMetadata.attrs.fillLinearGradientStartPointY,
      fillLinearGradientEndPointX: tMetadata.attrs.fillLinearGradientEndPointX,
      fillLinearGradientEndPointY: tMetadata.attrs.fillLinearGradientEndPointY,
      fillRadialGradientColorStops: tMetadata.attrs.fillRadialGradientColorStops,
      fillRadialGradientStartPointX: tMetadata.attrs.fillRadialGradientStartPointX,
      fillRadialGradientStartPointY: tMetadata.attrs.fillRadialGradientStartPointY,
      fillRadialGradientEndPointX: tMetadata.attrs.fillRadialGradientEndPointX,
      fillRadialGradientEndPointY: tMetadata.attrs.fillRadialGradientEndPointY,
      fillRadialGradientEndRadius: tMetadata.attrs.fillRadialGradientEndRadius,
      strokeWidth: tMetadata.attrs.strokeWidth,
      stroke: tMetadata.attrs.stroke,
      draggable: true,
      dataBindType: tMetadata.attrs.dataBindType,
      dataBindValue: tMetadata.attrs.dataBindValue,
      textTransforms: tMetadata.attrs.textTransforms,
      conditionalProperties: tMetadata.attrs.conditionalProperties,
      animation: tMetadata.attrs.animation,
    });
  */
  
    const tr = canvasRef.current.getLayers()[0].find('Transformer')[0];
  
    text.on('click', (e) => {
      // deleteCustomNodes();
      // addDeleteBlockButton(text.attrs.id, text.attrs.x ,text.attrs.y, text.attrs.width, text.attrs.height);
      e.cancelBubble = true;

      // tr.enabledAnchors(['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right']);
      // tr.nodes([e.target]);
    });
    text.on('dragmove', (e) => {

      // synchDeleteBlockButtonPosition(e.target);
    });
    text.on('mouseover', (e) => {
      document.body.style.cursor = 'pointer';
    });
    text.on('mouseout', (e) => {
      document.body.style.cursor = 'default';
    });
    text.on('dragend', () => {
      document.body.style.cursor = 'default';
      const newTemplateMetadata = handleModelMultiChange(["mainBlockX", "mainBlockY"], [text.x(), text.y()])
      getTemplatePreview(newTemplateMetadata, templateModel, previewUrlParamsValues, null);
      text.destroy();
      tr.nodes([]);
    
    })
  
    text.on('transform', () => {      
      // with enabled anchors we can only change scaleX
      // so we don't need to reset height
      // just width
      const newWidth = Math.round(text.width() * text.scaleX());
      const newHeight = Math.round(text.height() * text.scaleY());
      const newX = Math.round(text.x())
      const newY = Math.round(text.y())

      text.setAttrs({
        width: newWidth,
        height: newHeight,
        x: newX,
        y: newY,
        scaleX: 1,
        scaleY: 1,
      });
    });

    text.on('transformend', () => {      
      // with enabled anchors we can only change scaleX
      // so we don't need to reset height
      // just width
      const newWidth = Math.round(text.width() * text.scaleX());
      const newHeight = Math.round(text.height() * text.scaleY());
      const newX = Math.round(text.x())
      const newY = Math.round(text.y())

      text.setAttrs({
        width: newWidth,
        height: newHeight,
        x: newX,
        y: newY,
        scaleX: 1,
        scaleY: 1,
      });

      const newTemplateMetadata = handleModelMultiChange(["mainBlockX", "mainBlockY", "mainBlockWidth", "mainBlockHeight"], [newX, newY, newWidth, newHeight])
      getTemplatePreview(newTemplateMetadata, templateModel, previewUrlParamsValues, null);

      text.destroy();
      tr.nodes([]);
    });
  
    tr.enabledAnchors(['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right']);
    tr.nodes([text]);

    canvasRef.current.getLayers()[0].add(text);
    return text;
  }
  const handleAddTextPathBlock = (blockName, blockText, noDrag) => {
    const fontInfo = getInputPropValue(`${blockName}Font`)
    const text = new Konva.TextPath({
      id: RandomId(20),
      x: getInputPropValue(`${blockName}X`),
      y: getInputPropValue(`${blockName}Y`),
      fill: '#000',
      fillMode: 'solid',
      strokeWidth: 0,
      text: blockText !== undefined ? blockText : 'Lorem ipsum, Hello world Text, ciao mondo, hola mundo',
      fontSize: fontInfo.size,
      fontFamily: fontInfo.fontName,
      fontStyle: fontInfo.style,
      textDecoration: fontInfo.textDecoration,
      align: fontInfo.align,

      data: getInputPropValue(`${blockName}SVGPath`),
      draggable: true,
      dataBindType: 'none',
    });
    
    const tr = canvasRef.current.getLayers()[0].find('Transformer')[1];
  
    text.on('click', (e) => {
      // deleteCustomNodes();
      // synchPathEditPosition(text)
      e.cancelBubble = true;
      // tr.resizeEnabled = false;
      tr.nodes([e.target]);
    });
    text.on('dragmove', (e) => {
    });
    text.on('mouseover', (e) => {
      document.body.style.cursor = 'pointer';
    });
    text.on('mouseout', (e) => {
      document.body.style.cursor = 'default';
    });
  
    text.on('dragstart', () => {
      // transformstart and transformend are use to manage conditionalProperties changes
      text.setAttrs({originalX: text.x(), originalY: text.y()})
    })
    text.on('dragend', () => {
      /*
      document.body.style.cursor = 'default';
      const newTemplateMetadata = handleModelMultiChange(["mainBlockX", "mainBlockY"], [text.x(), text.y()])
      getTemplatePreview(newTemplateMetadata, templateModel, previewUrlParamsValues, null);
      text.destroy();
      tr.nodes([]);
*/
    })
    
  
    text.on('transform', () => {
      // with enabled anchors we can only change scaleX
      // so we don't need to reset height
      // just width
      text.setAttrs({
        width: Math.round(text.width() * text.scaleX()),
        height: Math.round(text.height() * text.scaleY()),
        x: Math.round(text.x()),
        y: Math.round(text.y()),
        scaleX: 1,
        scaleY: 1,
      });
    });
  
    const editBlock = handleAddTextPathEdit(text, noDrag);

    canvasRef.current.getLayers()[0].add(text);
    return [text, editBlock]
  }

  const handleAddTextPathEdit = (mainObj, noDrag) => {
    const text = new Konva.Path({
      id: `${mainObj.attrs.id}_path`,
      x: mainObj.attrs.x,
      y: mainObj.attrs.y,
      fill: 'transparent',
      stroke: '#000',
      strokeWidth: 1,
      // data: 'M10,10 C0,0 10,150 100,100 S300,150 5.0.300',
      data: mainObj.attrs.data,
      align: 'left',
      draggable: noDrag === undefined ? true : !noDrag,
      dataBindType: 'none',
    });
  
    const tr = canvasRef.current.getLayers()[0].find('Transformer')[0];
  
    if (noDrag !== undefined || !noDrag) {
      text.on('click', (e) => {
        e.cancelBubble = true;
      });
      text.on('dragmove', (e) => {
        synchPathEditPosition(text)
      });
      text.on('dragend', () => {
        document.body.style.cursor = 'default';
        const newTemplateMetadata = handleModelMultiChange(["mainBlockX", "mainBlockY"], [text.x(), text.y()])
  
        getTemplatePreview(newTemplateMetadata, templateModel, previewUrlParamsValues, null);
        text.destroy();
        tr.nodes([]);
  
        const nodesToDelete = canvasRef.current.find(n => (n.attrs.id !== undefined && n.attrs.id.length >=  20));
        nodesToDelete.forEach(n => n.destroy());
  
      })
      text.on('mouseover', (e) => {
        document.body.style.cursor = 'pointer';
      });
      text.on('mouseout', (e) => {
        document.body.style.cursor = 'default';
      });
    
      text.on('transform', () => {
      });
  
    }
  
    canvasRef.current.getLayers()[0].add(text);
  
    // we add circles for every editable points
    const segments = ParseSvg(mainObj.attrs.data);
    let currentX = 0;
    let currentY = 0;
    segments.forEach((s, index) => {
      if (index === 0) {
        const circle = new Konva.Circle({
          id: `${mainObj.attrs.id}_segmentEditPos_${index}`,
          x: text.attrs.x,
          y: text.attrs.y,
          radius: 6,
          fill: '#999',
          draggable: false,
        });
        currentX = text.attrs.x
        currentY = text.attrs.y
        canvasRef.current.getLayers()[0].add(circle);  
      }
      else {
        const circle = new Konva.Circle({
          id: `${mainObj.attrs.id}_segmentEditPos_${index}`,
          x: currentX + parseInt(s.endX, 10),
          y: currentY + parseInt(s.endY, 10),
          radius: 6,
          fill: '#999',
          draggable: false,
        });
      
        currentX += parseInt(s.endX, 10);
        currentY += parseInt(s.endY, 10);
        canvasRef.current.getLayers()[0].add(circle);  
  
      }
    })
    return text;
  }
  const handleAddHighlightRectangle = (index, rectId, rectX, rectY, rectWidth, rectHeight) => {
    const rect =  new Konva.Rect({
      id: rectId,
      x: rectX,
      y: rectY,
      width: rectWidth,
      height: rectHeight,
      // fill: 'red',
      stroke: '#2233aa',
      shadowBlur: 10,
      cornerRadius: 10,
    });
  
    const circle = new Konva.Circle({
      id: rectId + '_circle',
      x: rectX + 25,
      y: rectY + 25,
      radius: 21,
      fill: '#ffffffaa',
      stroke: '#2233aa',
      strokeWidth: 2,
    });

    const text =  new Konva.Text({
      id: rectId + '_text',
      x: rectX + 1,
      y: rectY + 12,
      width: 48,
      height: 48,
      lineHeight: 1,
      fontSize: 28,
      fontFamily: 'Calibri',
      text: index + 1,
      align: 'center',
      fill: '#000',
      fillMode: 'solid',
      strokeWidth: 0,
    });


    const tr = canvasRef.current.getLayers()[0].find('Transformer')[0];
  
  
    //tr.enabledAnchors(['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right']);
    //tr.nodes([rect]);

    canvasRef.current.getLayers()[0].add(rect);
    canvasRef.current.getLayers()[0].add(circle);
    canvasRef.current.getLayers()[0].add(text);
    return rect;
  }

  const synchPathEditPosition = (konvaObj) => {
    let id = '';
    let idBase = ''; // handy var to store the first 20 chars of the id
    let objToSync = {};
    // kanvaObj can be the text block or the path-only block
    if (konvaObj.attrs.id.includes('_path')) {
      // path-only block -> we need to synch the text block
      id = konvaObj.attrs.id.replace('_path', '');
      idBase = id;
      objToSync = canvasRef.current.getLayers()[0].find(`#${id}`)[0];
    }
    else {
      idBase = id;
      id = `${konvaObj.attrs.id}_path`;
      objToSync = canvasRef.current.getLayers()[0].find(`#${id}`)[0];
    }
  
    const deltaX = konvaObj.attrs.x - objToSync.attrs.x;
    const deltaY = konvaObj.attrs.y - objToSync.attrs.y;
  
    objToSync.setAttrs({
      x: konvaObj.attrs.x,
      y: konvaObj.attrs.y
    });
  
    // we want to update the position of all the "editing circles"
    // const circles = canvasRef.current.getLayers()[0].find(node => node.attrs.id.startsWith(id) && node.attrs.id.includes('_circle'))[0];
    const circles = canvasRef.current.getLayers()[0].find(node => {if (node.attrs === undefined || node.attrs.id === undefined) return false;
                                                                     return node.attrs.id.startsWith(idBase) && (node.attrs.id.includes('_segmentEditPos'))});
    circles.forEach(c => {
      c.setAttrs({
        x: c.attrs.x + deltaX,
        y: c.attrs.y + deltaY
      });  
    })
  }
  
  const handleChangeTextPosition = () => {
    // we use the same text entered by the user
    const match = previewUrlParamsValues.find(p => p.name === "text"); // "text" is the merge tag in the template

    //we need to check if this is a normal text or a text-on-path. There is a special inputProp for this
    const isTextOnPath = getInputPropValue("containsTextOnPath");
    let svgBlocks = [];
    if (isTextOnPath) svgBlocks = handleAddTextPathBlock("mainBlock", match.value);
    else handleAddTextBlock("mainBlock", match.value);

    setSvgEditBlocks(svgBlocks)
  }


  // START REGION: databindMode
  const handleDataConnectionChange = (dataConInfo) => {
    getDataConnectionInfoAndSampleData(dataConInfo.id);
    if (props.onDataConnectionChange !== undefined) props.onDataConnectionChange(dataConInfo);
  }
  const getDataConnectionInfoAndSampleData = async (dataConnectionId) => {
    try {
      let url = `${process.env.REACT_APP_LION_API_URL}/Feeds/info/${dataConnectionId}`;
      let response = await axios.get(url);
      let data = response.data;

      // let url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Feeds/info/${dataConnectionId}`;
      // let response = await fetch(url, { method: "GET", });
      // let data = await response.json();

      setDataConnectionInfo(data)
      setDataConnectionInfoLoaded(true);

      url = `${process.env.REACT_APP_LION_API_URL}/Feeds/sample/${dataConnectionId}`;
      // response = await fetch(url, { method: "GET", });
      // data = await response.json();

      response = await axios.get(url);
      data = response.data;
  
      setSampleData(data);
      // console.log(response.data);  

    } catch (error) {
      console.log(error);
    }
  }
  const handleBindingChange = (newBindings) => {
    // first we check that all bindinds have been assigned (i.e. databindValue is not an empty string)
    let allAssigned = true;
    newBindings.forEach(b => {if (b.databindValue === '') allAssigned = false});

    if (allAssigned) {
      const newTemplateMetadata = {...templateMetadata, dataBinds: newBindings};
      setTemplateMetadata(newTemplateMetadata);
    }

  }
  const handleHighlightChange = (highlight, bindingName, index) => {
    if (highlight) {
      const binding = templateMetadata.dataBinds.find(b => b.name === bindingName);
      handleAddHighlightRectangle(index, `highlightRect_${index}`, binding.x, binding.y, binding.width, binding.height)
    }
    else {
      //we need to remove the highlight rect
      const nodesToDelete = canvasRef.current.find(n => (n.attrs.id !== undefined && n.attrs.id.startsWith(`highlightRect_${index}`)));
      nodesToDelete.forEach(n => n.destroy());

    }
  }
  // END REGION: databindMode


return <div className=" flex flex-row gap-2">
  <div className="basis-7/12">
    {props.databindMode && <><TemplateGalleryDataBinder
                              dataConnectionInfo={dataConnectionInfo}
                              onChange={handleDataConnectionChange}
                            />
                              <TemplateDataMapping 
                                dataConnectionInfo={dataConnectionInfo}
                                sampleData={sampleData}
                                templateBindings={templateMetadata.dataBinds}
                                onChange={handleBindingChange}
                                onHighlightChange={handleHighlightChange}
                                 />
                            </>
    }

    {(props.databindMode === undefined || props.databindMode === false) && <div className=" grid grid-cols-1 gap-x-2 gap-y-3" >
    {renderCanvasInputs()}
    {renderInputs()}
    {renderRatingInputs()}
    {renderAnimationInputs()}

    {previewUrlParamsValues.find(p => p.name === "text") !== undefined &&
    <button type="button" className="text-white bg-gradient-to-br from-blue-500 to-blue-800 hover:bg-gradient-to-br hover:from-blue-600 hover:to-blue-900  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 w-40"
            onClick={handleChangeTextPosition}
            >Change text position</button>
    }
  </div>

    }
  </div>
  <div className="basis-5/12">
    <div className="flex justify-center"><button type="button" className="text-white bg-gradient-to-br from-blue-500 to-blue-800 hover:bg-gradient-to-br hover:from-blue-600 hover:to-blue-900  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                                  onClick={handleRefreshPreview}
                                                  >Refresh</button></div>
    <div style={{position: 'relative'}} >
        <div className={previewLoading ? "blur-sm" : "blur-0"} style={{width: '500px', height: '400px', overflow: 'scroll', outlineWidth: 2, outlineStyle: 'solid', outlineColor: 'black' }} >
            {/*<img id="canvasImage" style={{maxWidth: 'none'}} src={previewImageData} alt='Preview' hidden /> 
            <canvas id="canvas" width={canvasWidthStr} ></canvas> */}
            <div id="canvas" ref={canvasRef} style={{width: `${canvasWidthStr}px`, height: `${canvasHeightStr}px`, backgroundColor: '#fff'}}  />

          </div>
          {previewLoading &&  <div role="status" style={{position: 'absolute', top: 150, left:220}} className="flex justify-center items-center blur-0" >
        <svg aria-hidden="true" className="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
        <span className="sr-only">Loading...</span>
      </div>
  }

    </div> 


    {previewUrlParamsValues.map((p, index) => <div key={p.name} >
    <InputText displayName={p.name}
          onChange={(val) => changePreviewUrlParamValue(index, val)}
          value={p.value}
          />
    </div>)}


  </div>
</div>
}

function TemplateDataMapping(props) {
  /*
  props:
  dataConnectionInfo:
  sampleData:
  templateBindings:

  onChange: returns a new "templateBindings" object
  onHighlightChange: returns the bidningName and index of the item to highlight and a true/false value to highlight or remove highlighting
  */

  const [columns, setColumns] = useState([]); //[{id: '', name: ''}]
  const [templateBindings, setTemplateBindings] = useState([]);
  const [firstHighligthed, setFirstHighligthed] = useState(false);

  useEffect(() => {
    if (props.dataConnectionInfo !== undefined) {
      const metadata = JSON.parse(props.dataConnectionInfo.metadata)
      const cols = metadata.visibleColumns.map(c => {return {id: c, name:c}})
      cols.unshift({id: '', name:'Select a field'})
      setColumns(cols)
    }
    else setColumns([])


    if (props.templateBindings !== undefined) {
      setTemplateBindings(props.templateBindings)
      
      //we want to start with the first binding already highligthed
      if (props.onHighlightChange !== undefined && props.templateBindings.length > 0 && props.templateBindings[0].databindValue === '') {
         props.onHighlightChange(true, props.templateBindings[0].name, 0);
         setFirstHighligthed(true);
      }
      //if (props.templateBindings.length > 0) highlightBinding(true, 0, props.templateBindings[0].name)
    }
    else setTemplateBindings([])
  }, [props.dataConnectionInfo, props.templateBindings])

  const handleMappingChange = (bindingName, bindingValue) => {
    const newBindings = [...templateBindings]
    const currentBindingIndex = newBindings.findIndex(b => b.name === bindingName);
    const currentBinding = newBindings[currentBindingIndex];
    currentBinding.databindValue = bindingValue.id;
    setTemplateBindings(newBindings);
    if (props.onChange !== undefined) props.onChange(newBindings)
    highlightBinding(false, currentBindingIndex, '')
    setFirstHighligthed(false);
  }
  const highlightBinding = (highlight, index, bindingName) => {
    setFirstHighligthed(false)

    //if (props.onHighlightChange !== undefined) props.onHighlightChange(false, '', 0);
    if (highlight) {
      document.getElementById(`mapNum${index}`).style.border = '2px solid #2233aa'
      document.getElementById(`mapPrettyName${index}`).style.border = '2px solid #2233aa'
      document.getElementById(`mapPrettyName${index}`).style.borderRadius = '6px'
    
    }
    else {
      document.getElementById(`mapNum${index}`).style.border = '';
      document.getElementById(`mapPrettyName${index}`).style.border = '';

    }
    if (props.onHighlightChange !== undefined) props.onHighlightChange(highlight, bindingName, index);
  }

  return <div>
          <div>Map the template fields to your data</div>
          {templateBindings.map((b, index) => <div key={index} className="flex flex-row gap-2 items-center" onMouseEnter={() => highlightBinding(true && b.databindValue === '', index, b.name)} onMouseLeave={() => highlightBinding(false, index, b.name)}>
                    <div id={`mapNum${index}`} className={(firstHighligthed && index === 0 && b.databindValue === '') ? `flex justify-center items-center rounded-full border w-8 h-8 border-2 border-[#2233aa]` : `flex justify-center items-center rounded-full border w-8 h-8`} >{index + 1}</div>
                    {b.databindValue === '' && <div className="col-start-2 col-end-2">
                                                  <Tooltip title="Not mapped" placement='top' > 
                                                        <div className="flex justify-center items-center rounded-full border w-8 h-8 border-orange-200 text-orange-400 hover:text-orange-700 hover:bg-orange-200" ><Iconify icon="octicon:alert-24"  /></div>
                                                      </Tooltip></div>
                    }
                    {b.databindValue !== '' && <div className="col-start-2 col-end-2">
                                                  <Tooltip title="Mapped" placement='top' > 
                                                        <div className="flex justify-center items-center rounded-full border w-8 h-8 border-green-200 text-green-400 hover:text-green-700 hover:bg-green-200" ><Iconify icon="material-symbols:check"  /></div>
                                                      </Tooltip></div>
                    }
                    <div id={`mapPrettyName${index}`} className={(firstHighligthed && index === 0 && b.databindValue === '') ? `w-48 border-2 border-[#2233aa] rounded-md` : `w-48`}>{b.prettyName}</div>
                    <div>
                    <LionListbox 
                      selectedItemId={b.databindValue}
                      onChange={(val) => handleMappingChange(b.name, val)}
                      items={columns}
                      zIndex={100 - index}
                     noDataJSX={<div className="border border-orange-200 text-orange-400 rounded-md p-1">No columns available, select a data connection first</div>} />
                     </div>
                  </div>)
            }
  </div>
}

export function TemplateGalleryDataBinder(props) {
  const [dataConnectionListOpen, setDataConnectionListOpen] = useState(false);
  const [dataConnectionName, setDataConnectionName] = useState("");
  const [dataConnectionId, setDataConnectionId] = useState(undefined);

  /*
    props:
    dataConnectionInfo: 

    onChange: returns a dataConectionInfo
  */

  useEffect(() => {
    if (props.dataConnectionInfo !== undefined) {
      setDataConnectionId(props.dataConnectionInfo.feedId);
      setDataConnectionName(props.dataConnectionInfo.feedname)
    }
  }, [props.dataConnectionInfo])

  const renderTitle = () => {
    if (dataConnectionId === undefined) { 
      return <button
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm border-orange-200 border font-semibold text-orange-400 shadow-sm hover:text-orange-700 hover:bg-orange-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
              >
                  <Iconify icon="octicon:alert-24"  />
                Select a data connection
              </button>}
    else return <button
                    type="button"
                    className="inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm border-green-200 border font-semibold text-green-400 shadow-sm hover:text-green-700 hover:bg-green-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                      <Iconify icon="system-uicons:checkbox-checked" width={20}  />
                    {dataConnectionName}
                  </button>       
  }

  const handleDataConnectionIdChange = (dataConnectionInfo) => {
    setDataConnectionId(dataConnectionInfo.id);
    setDataConnectionName(dataConnectionInfo.feedname);
    setDataConnectionListOpen(false);
    if (props.onChange !== undefined) props.onChange(dataConnectionInfo);
  }

  return <div className=" flex flex-row gap-2">
          <div className="basis-6/12">
            <div className=" border-2 border-gray-200 rounded-md p-2">
            <Collapsible trigger={renderTitle()} open={dataConnectionListOpen}
            onOpening={() => setDataConnectionListOpen(true)}
            onClosing={() => setDataConnectionListOpen(false)}
             >
              <div className=" pt-4">
              <DatafeedGrid mini
                // selectedDataconnectionId={props.selectedDataconnectionId}
                onDatafeedSelected={(datafeedInfo) => handleDataConnectionIdChange(datafeedInfo)}
               />
              </div>
            </Collapsible>
            </div>
          </div>
          <div className="basis-6/12">
          </div>
        </div>
}

export function TemplateGalleryViewer(props) {
  /*
  props:
  productTypeId: filter templates
  selectedTemplateId: 
  onChange:
  */
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        getTemplates(props.selectedTemplateId);
    }, [props.productTypeId])

    useEffect(() => {
        const newTemplates = [...templates]
        newTemplates.forEach(t => {t.selected = (t.templateId === props.selectedTemplateId)})
        setTemplates(newTemplates);
        //props.onChange(newTemplates.find(t => t.templateId === props.selectedTemplateId))
    }, [props.selectedTemplateId])

    const getTemplates = async (preselectId) => {
        try {  
          let url = `${process.env.REACT_APP_LION_API_URL}/Templates/previewImages/${props.productTypeId === undefined ? '' : props.productTypeId}`;
          if (props.dataConId) url = `${process.env.REACT_APP_LION_API_URL}/Templates/previewImages/${props.productTypeId === undefined ? '' : props.productTypeId}?dataConId=${props.dataConId}`;
          const response = await axios.get(url);
          const data = response.data;
    
          // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Templates/previewImages/${props.productTypeId === undefined ? '' : props.productTypeId}`;
          // const response = await fetch(url, { method: "GET", });    
          // const data = await response.json();

        if (preselectId === undefined) {
          data[0].selected = true;
          props.onChange(data[0]);
      } // pre-select the first template
        else {
          data.find(t => t.templateId === preselectId).selected = true
        }
        
/*
        if (props.onChange !== undefined) {
           if (props.selectedTemplateId === undefined){console.log('entrato no id'); props.onChange(data[0]);}
           else {
            console.log('entrato id');
            props.onChange(data.find(t => t.templateId === props.selectedTemplateId))
           }
        }
        */
        setTemplates(data);

        } catch (error) {
          console.log(error);
        }
      }

    const handleTemplateChange = (templateId) => {
      // @ts-ignore
      const newTemplates = templates.map(t => {return {...t, selected: templateId === t.templateId}});
      setTemplates(newTemplates);
      const activeTemplate = newTemplates.find(t => t.templateId == templateId);

      if (props.onChange !== undefined) props.onChange(activeTemplate)
    }

    return <>
{/*
<div className="grid grid-cols-4 gap-x-2 gap-y-5 h-80 overflow-auto">
    {
        templates.map(t => t.selected ? (<div className='flex flex-col gap-0'>
                <div className='flex flex-row justify-center'>{t.templateName}</div>
                <div key={t.templateId} className="relative w-full h-[280px] flex justify-center bg-blue-200 hover:bg-blue-400 p-2 rounded-lg shadow-lg cursor-pointer" onClick={() => handleTemplateChange(t.templateId)}>
            <img
              src={`${t.previewURL}`}
              alt="Template"
              style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}}
              //width={props.itemWidth ? props.itemWidth : 500}
              //height={props.itemHeight ? props.itemHeight : 150}
            />  
            </div></div>) : (<div className='flex flex-col gap-0'>
              <div className='flex flex-row justify-center'>{t.templateName}</div>
              <div key={t.templateId} className="relative w-full h-[280px] flex justify-center bg-slate-200 hover:bg-blue-400 p-2 rounded-lg shadow-lg cursor-pointer" onClick={() => handleTemplateChange(t.templateId)}>
            <img
              src={`${t.previewURL}`}
              alt="Template"
              style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}}
              //width={props.itemWidth ? props.itemWidth : 500}
              //height={props.itemHeight ? props.itemHeight : 150}
            />       
            </div></div>)
        )

    }
</div>
*/}

<div className='grid grid-cols-4 gap-x-2 gap-y-5 h-[480px] overflow-auto'>
                {templates.map(t => t.selected ? (<div className='flex flex-col gap-0'>
                            <div className='flex flex-row justify-center'>{t.templateName}</div>

                            <div className=' relative w-full h-[280px] flex flex-row items-center justify-center bg-ccColorLightTeal hover:bg-ccColorTeal p-2 rounded-md cursor-pointer' onClick={() => handleTemplateChange(t.templateId)}>
                            <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={t.previewURL} />
                            </div>

                        </div>)
                        :
                        (<div className='flex flex-col gap-0'>
                          <div className='flex flex-row justify-center'>{t.templateName}</div>

                          <div className=' relative w-full h-[280px] flex flex-row items-center justify-center bg-gray-200 hover:bg-ccColorTeal p-2 rounded-md cursor-pointer' onClick={() => handleTemplateChange(t.templateId)}>
                          <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={t.previewURL} />
                          </div>




                      </div>)
                )}
            </div>

    </>
}
