import { useState, useEffect, useRef  } from 'react';
import {Alert, AlertTitle} from '@mui/material';

import { CcActionButton } from '../commonComponents/styledTexts';
import { InputText, MultipleFileSelector } from '../commonComponents/inputFields';

import { ccColorDarkTeal } from '../components/helpersStyles';
import { InfoToolTipNoMaxWidth } from '../commonComponents/generalComponents';

import axios from 'axios';
import { Icon as Iconify } from '@iconify/react';
import { LionColorPicker } from '../components/lionColorPicker';
import { FontSelectorNew } from '../components/templateEditor';

const QuickBlock = (props) => {
    const handleClick = () => {
        if (props.onClick) props.onClick(props.name)
    }

    return <div className=' relative h-16 border border-slate-400 rounded p-2 flex justify-center items-center cursor-pointer hover:bg-ccColorTeal hover:text-white'
            onClick={handleClick} >
        {props.name}
        {!props.isCustom &&
            <img className=' absolute -top-0 -left-0' width={22} height={22} src='/assets/logoBadge.png' />
        }
        {props.hasMetadata &&
            <Iconify style={{left: 'calc(100% - 18px)'}} className='absolute top-[44px] text-ccColorSecondaryGreen' icon="teenyicons:tick-circle-solid" />
        }
    </div>
}

const QuickBlockSettingManager = (props) => {
    //props:
    // block :{title: "", metadata: {}}
    const defaultTextColor = '#000000'
    const defaultTextFont = {fontName: 'Arial',
        fontId: -1,
         size: 30, 
         style: '400',
         align: 'left',
         textDecoration: '',
         isAutoSize: false,
         }
    const defaultMetadata = {textColor: defaultTextColor, textFont: defaultTextFont}

    const [blockMetadata, setBlockMetadata] = useState(defaultMetadata)

    const mergeDefaultValues = (metadata) => {
        if (!metadata.textColor) metadata.textColor = defaultTextColor;
        if (!metadata.textFont) metadata.textFont = defaultTextFont;

        return metadata
    }

    useEffect(() => {
        console.log(props.block)
        if (props.block && props.block.metadata) setBlockMetadata(mergeDefaultValues(props.block.metadata))
        else setBlockMetadata(defaultMetadata)
    }, [props.block])

    const handlePropertyChange = (propName, propValue) => {
        const newMetadata = {...blockMetadata}
        newMetadata[propName] = propValue
        setBlockMetadata(newMetadata)
        if (props.onChange) props.onChange(props.block.colName, newMetadata)
    }
const handleUpdateFont = (fontId, fontName, size, style, tAlign, tDecoration, isAutoSize) => {

    const newTextFontSettings = {fontName: fontName,
        fontId: fontId,
         size: size, 
         style: style,
         align: tAlign,
         textDecoration: tDecoration,
         isAutoSize: isAutoSize,
         }
        const newMetadata = {...blockMetadata, textFont: newTextFontSettings}
        setBlockMetadata(newMetadata)
        if (props.onChange) props.onChange(props.block.colName, newMetadata)
} 

    return <div className='border border-slate-400 rounded p-4 text-ccColorDarkTeal'>
            {(props.block === null || props.block === undefined ? <div className='flex justify-center text-slate-400'>Select a block</div> 
            : <>
                <div className='font-semibold text-xl text-center'>Settings for {props.block.colName}</div>
                <div className='mt-4 font-semibold text-sm'>Text colour</div>
                <LionColorPicker color={blockMetadata.textColor}
                    onChange={(color) => {handlePropertyChange('textColor', color)}}
                    />

                <div className='mt-4 font-semibold text-sm'>Font</div>
                <div className='mt-0'>
                    <FontSelectorNew returnCurrentFont={handleUpdateFont}
                    currentFont={blockMetadata.textFont}
                    noPopup
                     />
                    
                </div>
            </>)

            }
    </div>
}

export default function PageAdminQuickBlocks() {
    /*
    props:

    NO props 
    */
    const [blocksSettings, setBlocksSettings] = useState([]); //fonts in user account
    const [selectedBlock, setSelectedBlock] = useState(); //fonts in user account
    const [showLoader, setShowLoader] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        getQuickBlocksSettings();
    }, [])

    const getQuickBlocksSettings = async() => {
        setShowLoader(true)
        try {
          const url = `${process.env.REACT_APP_LION_API_URL}/User/QuickBlocksSettings`;
  
          const response = await axios.get(url);
        const settings = response.data.map(s => {
                                if (s.metadata) return {...s, metadata: JSON.parse(s.metadata)}
                                else return s;
        })
          setBlocksSettings(settings)

        } catch (error) {
          setShowError(true)
        }
        finally {
          setShowLoader(false);

        }

    }

    const handleBlockClicked = (blockName) => {
        const currentBlock = blocksSettings.find(b => b.colName === blockName)
        setSelectedBlock(currentBlock)
    }
    const handleBlockSettingsChange = (blockName, metadata) => {
        const newBlocks = [...blocksSettings];
        const index = newBlocks.findIndex(b => b.colName === blockName)
        const newBlock = newBlocks[index]
        newBlock.metadata = metadata
        setSelectedBlock(newBlock)

        newBlocks.splice(index, 1, newBlock)
        console.log(newBlocks)
        setBlocksSettings(newBlocks)
    }

    const handleUpdateQuickBlocksSettings = async() => {
        setShowLoader(true)
        try {
          const url = `${process.env.REACT_APP_LION_API_URL}/User/UpdateQuickBlocksSettings`;
  
          const settingsToPost = blocksSettings.map(b => {return {...b, metadata: JSON.stringify(b.metadata)}})
          const response = await axios.post(url, {settings: settingsToPost});
          if (response.data === true) {
            setShowSuccess(true)
          }
          else {
              setShowError(true)
          }

        } catch (error) {
          setShowError(true)
        }
        finally {
          setShowLoader(false);

        }
    }

    const handleGoToHome = () => {
        //navigate("/")
        setShowLoader(false)
        setShowSuccess(false)
        setShowError(false)
    }

    return <div>
            <div className='text-ccColorDarkTeal text-lg font-semibold'>Font Formatting</div>
          <div className='mb-2 text-ccColorDarkTeal'>Add formatting to your content blocks. Formatting set here will be applied to blocks in the Generator Design lab. Click on a Content block to get started.
          </div>
        <div className='h-4'></div>
        {(!showSuccess && !showError) && <>
        <div className='grid grid-cols-2 gap-x-12 h-[320px]'>
            <div className='grid grid-cols-3 gap-4 place-content-start'>
                {blocksSettings.map(b => <QuickBlock name={b.colName} isCustom={b.isCustom} hasMetadata={b.metadata} onClick={handleBlockClicked} />)}
            </div>
            <QuickBlockSettingManager block={selectedBlock}
            onChange={handleBlockSettingsChange}
            />
        </div>
        <div className='mt-4 flex flex-row-reverse'>
                <button type="button" onClick={handleUpdateQuickBlocksSettings} className="text-white bg-ccColorDarkTeal hover:bg-gradient-to-br hover:bg-ccColorTeal font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Update</button>
            </div>
        </>}
            {showSuccess && <div className=" container max-w-md mx-auto flex flex-col justify-center mb-8">
                            <Alert severity="success">
                            <AlertTitle>Success</AlertTitle>
                            <div className='flex flex-col gap-2'>
                                <div>Settings have been updated</div>
                            </div>
                            </Alert>                      
                        </div>
            }
            {showError && <div className=" container max-w-md mx-auto flex flex-col justify-center mb-8">
                            <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            There was an error updating the settings. Please try again later
                            </Alert>                      
                        </div>
        }
        {(showSuccess || showError) && <div className='flex flex-row-reverse'>
                            <button type="button" class="text-white bg-ccColorDarkTeal hover:bg-ccColorTeal font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                onClick={handleGoToHome}
                            >Done</button>
                            </div>
        }

    </div>
}