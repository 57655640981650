import axios from 'axios';

import PropTypes, { object } from 'prop-types';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { GridApi, DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';

import {IconButton, Paper, Box, Stack, Button, Radio, RadioGroup, FormControlLabel, 
  Chip, Select, MenuItem, Autocomplete, Popover, CircularProgress, InputLabel, FormHelperText
, List, ListItem, ListItemButton, ListItemText, ListItemIcon, Input, Typography, Slider, Tooltip } from '@mui/material';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';

import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';

import Switch from '@mui/material/Switch';

import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2

import { useState, useEffect, useRef, useContext } from 'react';
//import { useSelector, useDispatch } from 'react-redux'
import {SettingsContext} from '../components/userSettingsContext';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { JsonViewer } from '@textea/json-viewer'


import { Icon as Iconify } from '@iconify/react';

import {ccColorDarkTeal, ccColorTeal, lionObjectsColors}  from './helpersStyles';
import {TransformDataForGridArrayOfRows, GuessDataType}  from './helpers';
import {OneToOneParametersSelector} from './oneToOneParametersSelector'

// the combos in the grid header are dynamic and their state is managed with redux
import {store} from '../redux/store'
import dataConnections, {setColumsSettings} from '../redux/slices/dataConnections' // columnsSettings[colName] = {dataType: '', isKey: bool, visible: bool, textLength: 'string', datetimeInputFormat: 'string' }

//-------------------------
import {InputText, InputURL, LionListbox} from '../commonComponents/inputFields';
import {CcStepper2} from "../commonComponents/stepper"
import { CcActionButton, CcSecondaryActionButtonOutlined, CcSubSectionTitle } from '../commonComponents/styledTexts';
import { InfoToolTip } from '../commonComponents/generalComponents';
import { lionIcons } from './helpersIcons';
import { object as yupObject, string, number, date, InferType } from 'yup';
import { getErrorListFromYupError, getErrorMessage } from './helperErrors';

const ColumnSettingsManager = (props) => {
  const [columnsSettings, setColumnsSettings] = useState({});
  const [calculatingDatatypes, setCalculatingDatatypes] = useState(false);
  const [sampleData, setSampleData] = useState({})

  useEffect(() => {
    //const colSettings = store.getState().dataConnections.columsSettings;
    //console.log(colSettings)
    //setColumnsSettings(colSettings)
    setColumnsSettings(props.colSettings)
  }, [props.colSettings])

  useEffect(() => {
    setSampleData(props.sampleData)
  }, [props.sampleData])

  const handleColVisibilityChange = (colName, isSynch) => {
    const colSettings = {...columnsSettings[colName]};
    colSettings.visible = isSynch
    //console.log(colSettings)
    const newSettings = {...columnsSettings, [colName]: colSettings};
    setColumnsSettings(newSettings)

    store.dispatch(setColumsSettings(newSettings));
    if (props.onColumnVisibilityChange) props.onColumnVisibilityChange(colName, isSynch)
  }
  const handleVisibilityChangeForAll = (areVisible) => {
    const colSettings = {...columnsSettings};
    const keys = Object.keys(colSettings);

    keys.forEach(k => {
      colSettings[k] = {...colSettings[k], visible: areVisible}
    })
    //console.log(colSettings)
    setColumnsSettings(colSettings)

    store.dispatch(setColumsSettings(colSettings));
    if (props.onColumnSettingsChange) props.onColumnSettingsChange(colSettings, areVisible);
  }
  const handleColDatatypeChange = (colName, newType) => {
    const colSettings = {...columnsSettings[colName]};
    colSettings.dataType = newType
    //console.log(colSettings)
    const newSettings = {...columnsSettings, [colName]: colSettings};
    setColumnsSettings(newSettings)

    store.dispatch(setColumsSettings(newSettings));
    if (props.onColumnSettingsChange) props.onColumnSettingsChange(newSettings)
  }


const handleCalculateDataTypes = () => {

  setCalculatingDatatypes(true);
  const MAX_NUM_SAMPLES = 50;
  const numRowsInData = sampleData.rows.length;

  const colSettings = {...columnsSettings};

  for (let i = 0; i < sampleData.headers.length; i +=1) {
    const currentColName = sampleData.headers[i];
    //preparing an array with the sample rows
    const sampleRows = [];
    for (let rowIndex = 0; rowIndex < Math.min(MAX_NUM_SAMPLES, numRowsInData); rowIndex += 1) {
      sampleRows.push(sampleData.rows[rowIndex][i]);      
    }
    //console.log(currentColName)
    const datatype = GuessDataType(sampleRows);
    colSettings[currentColName] = {...colSettings[currentColName], dataType: datatype};
  }

  setColumnsSettings(colSettings)

  store.dispatch(setColumsSettings(colSettings));
  if (props.onColumnSettingsChange) props.onColumnSettingsChange(colSettings);
  setCalculatingDatatypes(false)
}

  return <div style={{height: props.height}} className='flex flex-col gap-2 border rounded shadow p-1'>
      <div className='flex flex-row gap-1 items-center'>
      <Tooltip title="Auto-assign data types" placement='top'>
        <LoadingButton loading={calculatingDatatypes} variant="outlined"
                  onClick={handleCalculateDataTypes}
                  sx={{color: '#357e86', borderColor: '#357e86', ":hover": {color: '#fff', backgroundColor: '#357e86'}}}
        >
          <Iconify icon="gg:assign" width={22} />
        </LoadingButton>
      </Tooltip>
        <CcSecondaryActionButtonOutlined onClick={()  => {handleVisibilityChangeForAll(true)}}>Select all</CcSecondaryActionButtonOutlined>
        <CcSecondaryActionButtonOutlined variant='outlined' onClick={()  => {handleVisibilityChangeForAll(false)}}>De-select all</CcSecondaryActionButtonOutlined>
      </div>
      <div className='flex flex-row gap-1 items-center'>
        <div className='w-32'>Field</div>
        <div className='w-[110px]'>Data type</div>
        <div>Synch</div>
      </div>
      <div style={{overflow: 'auto'}}>
        {Object.keys(columnsSettings).map(k => <div className='flex flex-row gap-1 items-center'>
            <Typography noWrap sx={{width: 128}}><span className=' font-bold'>{k}</span></Typography>
            <div>
              <FormControl sx={{ width: 110 }} size="small" variant="standard">
                  <Select
                      labelId={`inputLabel2_${k}`}
                      id={`select2_${k}`}
                      value={columnsSettings[k].dataType}
                      label="Data Type"
                      onChange={(e) => handleColDatatypeChange(k, e.target.value)}
                  >
                      <MenuItem value="text">Text</MenuItem>
                      <MenuItem value="number">Number</MenuItem>
                      <MenuItem value="datetime">Date Time</MenuItem>
                      <MenuItem value="longtext">Long Text</MenuItem>
                  </Select>
              </FormControl>
            </div>
            <div className='flex flex-row gap-x-1 items-center'>
              <Switch
                checked={columnsSettings[k].visible}
                onChange={(e) => handleColVisibilityChange(k, e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{'& .MuiSwitch-switchBase.Mui-checked': {color: '#357e86'},
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {backgroundColor:'#9bcccd'}}}
              />
            </div>
          </div>)
        }
      </div>
  </div>
}

const JsonEntryPointPicker = (props) => {
  const [dataIsArray, setDataIsArray] = useState(false);
  const [dataHasOnly1Prop, setDataHasOnly1Prop] = useState({has1PropOnly: false,  propIsArray: false});
  const [foundArrays, setFoundArrays] = useState([]);
  const [jsonEntryPath, setJsonEntryPath] = useState("");
  const [jsonPathType, setJsonPathType] = useState("NO_PATH");

  useEffect(() => {
    // 1 pre-select values depending on how the props.jsonData is made
    // possible scenarios:
    // 1. props.jsonData IS an ARRAY -> we use the feed as is
    // 2. props.jsonData IS not an array but has 1 only prop of type array -> we use that prop as our array
    // 3. props.jsonData IS not an array and doesn't have any prop that is an array -> this is a feed  with 1 item only, warn user and use feed as is
    // 4. props.jsonData IS not an array and has both array and non-array props -> user needs to decide what to do

    if (props.jsonData === null) return;
    // auto-decisions part
    setDataIsArray(Array.isArray(props.jsonData));
    const jsonDataProps = Object.keys(props.jsonData);
    let selectedPath = '';

    const possibleArraysToPick = [];
    if (props.jsonData !== undefined) {
      const arr = findArrays(props.jsonData, '');
      console.log(arr)
      const possibleEntryPaths = arr.map(path => path.substring(1))
      setFoundArrays(possibleEntryPaths);
      // setJsonEntryPath(possibleEntryPaths[0])  
    }  


    if (jsonDataProps.length === 1) {
      const isArray = Array.isArray(props.jsonData[jsonDataProps[0]]);
      setDataHasOnly1Prop({has1PropOnly: true,  propIsArray: isArray});
      selectedPath = jsonDataProps[0];
      if (isArray) setJsonEntryPath(jsonDataProps[0]);
    }

    
    // possible auto-decisions overwrite when a selecion has been provided
    if (props.currentPathSelection !== undefined && props.currentPathSelection !== null) {
      setDataIsArray(props.currentPathSelection.isArray)
      setJsonEntryPath(props.currentPathSelection.path);
      selectedPath = props.currentPathSelection.path;
      if (props.currentPathSelection.path !== '') setJsonPathType('PATH');
      else setJsonPathType('NO_PATH');
    }

    if (props.onEntryPointChanged !== undefined)
       props.onEntryPointChanged({isArray: Array.isArray(props.jsonData), path: selectedPath})

  }, [props.jsonData])

  const findArrays = (obj, currentPath) => {
    const result = [];
    if (obj === undefined || obj === null) return result; // MOD: this line has been added and not fully tested yet
    
    const objProps = Object.keys(obj);
    objProps.forEach(o => {      
      const cPath = `${currentPath}.${o}`
      if (Array.isArray(obj[o])) result.push(cPath);
      else if (typeof(obj[o]) === "object") result.push(...findArrays(obj[o], cPath))
      
    })
    // console.log(result)
    return result;
  }

  const handleJsonPathTypeChange = (pathType) => {
    setJsonPathType(pathType);
    let entryPoint = jsonEntryPath;
    if (pathType === 'PATH' && jsonEntryPath === ''){
      entryPoint = foundArrays[0];
      setJsonEntryPath(entryPoint);
    }
    // console.log(pathType)
    // console.log(entryPoint)
    if (props.onEntryPointChanged !== undefined) {
      if (pathType === 'NO_PATH') props.onEntryPointChanged({isArray: false, path: ''})
      else props.onEntryPointChanged({isArray: false, path: entryPoint})
    }
  }

  const handleJsonEntryPathChange = (newPath) => {
    setJsonEntryPath(newPath);
    if (props.onEntryPointChanged !== undefined)
      props.onEntryPointChanged({isArray: false, path: newPath})
  }


  return <>
    {dataIsArray && <Stack spacing={1}>
                      <Alert sx={{width: '80%'}} severity="success">The web reource contains a list of items</Alert>
      </Stack>
   }

{!dataIsArray && dataHasOnly1Prop.has1PropOnly &&
            dataHasOnly1Prop.propIsArray && <Stack spacing={1}>
                      <Alert sx={{width: '80%'}} severity="success">The web reource contains a list of items</Alert>
                      <Stack direction='row' sx={{display: 'flex', alignItems: 'center'}} spacing={1} >
                        <Typography>Entry point:</Typography>
                        <TextField
                                size="small"
                                value={jsonEntryPath}
                                disabled
                              />

                      </Stack>
      </Stack>
   }

{!dataIsArray && !dataHasOnly1Prop.has1PropOnly &&
              foundArrays.length === 0 && <Stack spacing={1}>
                <Alert severity="warning" sx={{width: '80%'}}>
                  <AlertTitle>Feed doesn't contain a list of items</AlertTitle>
                  This feed is for <strong>1 item only</strong><br/>
                  You will only be able to generate 1 image with this feed
                </Alert>
      </Stack>
   }


  {!dataIsArray && !dataHasOnly1Prop.has1PropOnly && 
              foundArrays.length > 0 && <Stack spacing={1}>
                      <Alert sx={{width: '80%', mb:2}} severity="info">The web reource is not an array</Alert>
                      <RadioGroup
                                row
                                name="radio-json-path"
                                value={jsonPathType}
                                onChange={(e) => handleJsonPathTypeChange(e.target.value)}
                              >
                                <FormControlLabel value="NO_PATH" control={<Radio />} label={<Chip sx={{backgroundColor: lionObjectsColors.Color_WebResource, color:'#fff'}} label="Use web resource as is"  />} />
                                <FormControlLabel value="PATH" control={<Radio />} label={<Chip sx={{backgroundColor: lionObjectsColors.Color_WebScrape, color:'#fff'}} label="Select a path"  />} />
                      </RadioGroup>

                      {jsonPathType === "NO_PATH" &&
                        <Typography>The feed  contains data for 1 image</Typography>
                      }
                      {jsonPathType === "PATH" &&
                          <Stack direction='row' sx={{display: 'flex', alignItems: 'center'}} spacing={1} >
                          <Typography>Entry point:</Typography>
                          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <Select
                              labelId="demo-select-entry-point"
                              id="demo-select-entry-point"
                              value={jsonEntryPath}
                              onChange={(e) => handleJsonEntryPathChange(e.target.value)}
                            >
                              {foundArrays.map(ep => <MenuItem key={ep}
                                                       value={ep}>{ep}</MenuItem>)}
                            </Select>
                          </FormControl>
                        </Stack>
                      }
      </Stack>
   }
  </>
}

const ColumnsAdvancedSettingsEditor = (props) => {
      const [colNamesAndTypes, setColNamesAndTypes] = useState([]);
      const [selectedColName, setSelectedColName] = useState("");
      const [columnInGrid, setColumnInGrid] = useState([]);
      const [colSettings, setColSettings] = useState({});
      const [availableLength, setAvailableLength] = useState(0);

      const datetimeInputOptions = [
        { label: 'Auto detect', id: 'auto' },
        { label: 'dd/MM/yyyy HH:mm:ss', id: 'dd/MM/yyyy HH:mm:ss' },
        { label: 'MM/dd/yyyy HH:mm:ss', id: 'MM/dd/yyyy HH:mm:ss' },
        { label: 'yyyy-MM-dd HH:mm:ss', id: 'yyyy-MM-dd HH:mm:ss' },
        { label: 'dd/MM/yyyy', id: 'dd/MM/yyyy' },
        { label: 'MM/dd/yyyy', id: 'MM/dd/yyyy' },
        { label: 'yyyy-MM-dd', id: 'yyyy-MM-dd' },
      ];

      
      useEffect(() => {
        const settings = store.getState().dataConnections.columsSettings;
        setColSettings(settings);
        const propNames = Object.keys(settings);
        const visibleColumnsNamesAndTypes = [];
        propNames.forEach((colName) => {
          if (settings[colName].visible) visibleColumnsNamesAndTypes.push({name: colName, type:settings[colName].dataType});
        })
        setColNamesAndTypes(visibleColumnsNamesAndTypes);
        if (selectedColName === "" && propNames.length > 0) setSelectedColName(propNames[0]);
      },[])

      const iconsDatatypes = {'text': 'ic:baseline-text-fields',
                              'number': 'tabler:numbers',
                              'datetime': 'material-symbols:date-range-outline',
                              'longtext': 'material-symbols:text-snippet-outline',}

      const handleChangeSelectedColumn = (event, colName) => {
          setSelectedColName(colName);
      }
      const handleDatatypeChanged = (event) => {
          const selectedColProps = colSettings[selectedColName];
          setColSettings({...colSettings, [selectedColName]: {...selectedColProps, dataType: event.target.value}});
      }

      // when selectedColName changes we want to change the columns in the grid
      useEffect(() => {
        const columns = [];

        const currentCol =   {
          field: selectedColName,
          // headerName: element,
          width: 400,
          editable: false,
          sortable: false,
        };

      columns.push(currentCol);
      setColumnInGrid(columns);

      }, [selectedColName])
  
useEffect(() => {    
  const GetAvailableLength = () => {
      const totalVachar = 50000;
      let occupiedSpace = 0;

      const colNames = Object.keys(colSettings);
      
      colNames.forEach((colName) => {
                  if (colSettings[colName].visible 
                        && colSettings[colName].dataType === 'text') occupiedSpace += colSettings[colName].textLength});  

      return totalVachar - occupiedSpace;
    }


      setAvailableLength(GetAvailableLength());
    }, [colSettings]);



    const handleTextLengthChange = (event) => {
      const selectedColProps = colSettings[selectedColName];
      setColSettings({...colSettings, [selectedColName]: {...selectedColProps, textLength: event.target.value}});

    }
    const handleDateInputFormatChange = (value) => {
      const selectedColProps = colSettings[selectedColName];
      setColSettings({...colSettings, [selectedColName]: {...selectedColProps, datetimeInputFormat: value}});

    }
     
    const ConfirmChanges = () => {
      //store.dispatch(setColumsSettings({...colSettings}));

      props.returnOnClose(true);
    }


      return <>
      <Stack sx={{width: '100%'}}>
      <Grid2 container spacing={2} sx={{width: '100%'}}>
      <Grid2 xs={3}>
        <Box sx={{overflow: 'auto', height: '400px'}}>
        <List >
          {colNamesAndTypes.map(c => <ListItem key={c.name} disablePadding>
                  <ListItemButton selected={c.name === selectedColName}
                                  onClick={(event) => handleChangeSelectedColumn(event, c.name)}>
                    <ListItemIcon>
                    <Iconify icon={iconsDatatypes[c.type]} />
                    </ListItemIcon>
                            <ListItemText primary={c.name} />
                          </ListItemButton>
                </ListItem>)}
        </List>
        </Box>
      </Grid2>
      <Grid2 xs={4}>
      <DataGrid hideFooter
                  rowHeight={32}
                  rows={props.datasourceRows}
                  columns={columnInGrid}
                  disableColumnMenu
                  disableColumnFilter
                  headerHeight={80}
                />
      </Grid2>
      <Grid2 xs={5}>
        <Stack spacing={2}>
          <FormControl sx={{ m: 1, minWidth: 150 }} size="small" >
          <InputLabel id="label-datatypeColumn">Data type</InputLabel>
            <Select labelId="label-datatypeColumn" label='Data type'
                  onChange={(event) => handleDatatypeChanged(event)}
                  value={colSettings[selectedColName] !== undefined ? colSettings[selectedColName].dataType : ''}
            >
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="datetime">Date Time</MenuItem>
                <MenuItem value="longtext">Long Text</MenuItem>
            </Select>
          </FormControl>
          {colSettings[selectedColName] !== undefined && colSettings[selectedColName].dataType === 'text' &&
          <>
          <Box >
          <Typography id="input-slider" gutterBottom>
            Text column max length
          </Typography>
          <Grid2 container spacing={2} alignItems="center">
            <Grid2 item xs={6} >
              <Slider min={1} max={colSettings[selectedColName].textLength + availableLength}
              value={colSettings[selectedColName].textLength}
                onChange={handleTextLengthChange}
                aria-labelledby="input-slider"
              />
            </Grid2>
            <Grid2 item xs={2}>
              <TextField
                size="small"
                value={colSettings[selectedColName].textLength}
                // onChange={handleInputChange}
                inputProps={{
                  step: 10,
                  min: 1,
                  max: {availableLength},
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
              />
            </Grid2>
          </Grid2>
          <Stack direction='row' spacing={1} sx={{display: 'flex', alignItems: 'center'}}>
            <Typography>Total available length:</Typography>
            <TextField sx={{width: '100px'}}
                  size="small"
                  value={availableLength}
                  disabled
                />
          </Stack>
        </Box>        
      </>
          }

{colSettings[selectedColName] !== undefined && 
  colSettings[selectedColName].dataType === 'datetime' &&
          <>
          <Box >
          <Stack direction='row' spacing={1} sx={{display: 'flex', alignItems: 'center'}}>
            <Typography>Input Format:</Typography>
            <Autocomplete
              disablePortal
              freeSolo
              id="combo-datetime-inputFormat"
              options={datetimeInputOptions}
              getOptionLabel={(option) => {
                                          if (option == null) return '';
                                          if (Object.prototype.hasOwnProperty.call(option, 'label')) return option.label;
                                          return option.toString();
                                        }}
              sx={{ width: 300 }}
              onChange={(e, value) => handleDateInputFormatChange(value)}
              onInputChange={(e, value) => handleDateInputFormatChange(value)}
              value={colSettings[selectedColName].datetimeInputFormat === undefined ? datetimeInputOptions[0] : colSettings[selectedColName].datetimeInputFormat}
              renderInput={(params) => <TextField {...params} label="Date format" />}
            />
          </Stack>
        </Box>        
      </>
          }



        </Stack>
      </Grid2>
      </Grid2>
      <Box display="flex" justifyContent="flex-end" sx={{m:1}} >
            <Button variant='outlined' color='secondary'  sx={{mr:1}} onClick={() => props.returnOnClose(false)}>Cancel</Button>
            <Button variant='outlined' color='secondary'  sx={{mr:1}} onClick={ConfirmChanges}>Confirm settings</Button>
      </Box>

  </Stack>
      </>
}



const PrimaryKeySelect = (props) => {
  const [selectedKey, setSelectedKey] = useState("");

  const handleKeyChanged = (event) => {
    setSelectedKey(event.target.value);
    props.returnKeyColumn(event.target.value);
  }

  useEffect(()=>{
    setSelectedKey(props.selectedCol);
  }, [props.selectedCol])


  return <div className='flex flex-row gap-1 items-center'>
      <FormControl sx={{ m: 1, minWidth: 150 }} size="small" disabled={props.disabled} >
      <InputLabel id="label-keyColumn">Key column</InputLabel>
        <Select labelId="label-keyColumn" label='Key column'
              onChange={(event) => handleKeyChanged(event)}
              value={selectedKey}
              error={selectedKey === ""}
        >
          {props.columns.map((col) => <MenuItem key={col} value={col}>{col}</MenuItem>)}
        </Select>
        {selectedKey === "" && <FormHelperText error>Select a column</FormHelperText>}
      </FormControl>
      <InfoToolTip 
        placement='right'
        headLine='Key column'
        message="Select the column that includes the unique identifier for the data. This may be an individual product SKU or the title of a blog article."
        />

  </div>
}

const PrettyNamesMapper = (props) => {
  const [prettyNames, setPrettyNames] = useState(props.prettyNames);
  const feedColumns = props.columns;
//console.log(prettyNames)
  const [mapping, setMapping] = useState({}); // mapping[prettyName] = columnInFeed
  const [isAddMode, setIsAddMode] = useState(false)
  const [newPrettyName, setNewPrettyName] = useState('')
  const [showAlreadyExistsError, setShowAlreadyExistsError] = useState(false)

  useEffect(() => {
    //console.log(props.colMappings);
    // if the colMappings prop is passed we assign it to an internal state
    if (props.colMappings !== undefined) setMapping(props.colMappings);
  }, [])


  const handleMappingChange = (event, name) => {
    const newMappings = {...mapping, [name]: event.target.value};
    setMapping(newMappings);

    props.returnColMappings(newMappings);

    //console.log(newMappings);
  }

  const columnAlreadyUsedInMapping = (colName) => {
    const keys = Object.keys(mapping);
    if (keys === undefined || keys === null) return false;
    let found = false;
    keys.forEach(k => {
      // console.log(mapping[k]);
      if (mapping[k] !== undefined && mapping[k] === colName) found = true
    })
    return found;
  }

  const PrettyNameLabel = (props) => {

    if (props.prettyNameDef === undefined) return <></>
    else if (props.prettyNameDef.isCustom === false) return <div className='w-[200px]'>
        <div className='border border-ccColorLightTeal rounded-md w-full py-2 text-center text-ccColorTeal'>{props.prettyNameDef.colName}</div>
      </div>
  else
  return <div className='w-[200px] border border-ccColorLightTeal rounded-md flex flex-row items-center'>
  <div className='flex grow py-2 px-1 justify-center text-ccColorTeal'>{props.prettyNameDef.colName}</div>
  <IconButton onClick={() => handleDeletePrettyName(props.prettyNameDef.colName)}><Iconify icon={lionIcons.Icon_Actions_Delete} /></IconButton>
</div>

  }
  const handleStartAddMode = () => {
    setIsAddMode(true);
    setNewPrettyName('')
  }
  const handlePrettyNameInputKeyDown = (e, submit) => {
    if ((e.key === 'Enter' && showAlreadyExistsError === false) || submit ) {
      const alreadyExists = checkIfPrettyNameAlreadyExists(newPrettyName)
      if (alreadyExists) {
        setShowAlreadyExistsError(true)
        return
      }
      const newPName = {colName: newPrettyName, isCustom: true};
      setPrettyNames([...prettyNames, newPName])
      setIsAddMode(false);
      if (props.onAdded) props.onAdded([...prettyNames, newPName])
    }


  }
  const handlePrettyNameChange = (newName) => {
    //otherwise we just  check if the current name already exists

    setNewPrettyName(newName)

    const alreadyExists = checkIfPrettyNameAlreadyExists(newName)
    if (alreadyExists) {
      setShowAlreadyExistsError(true)
    }
    else setShowAlreadyExistsError(false)
  }

  const handleDeletePrettyName = (name) => {
    //delete prettyname
    let newPrettynames = [...prettyNames]
    const index = newPrettynames.findIndex(pn => pn.colName === name);
    if (index !== -1) newPrettynames.splice(index, 1);
    setPrettyNames(newPrettynames)
    setIsAddMode(false);
    if (props.onDeleted) props.onDeleted(newPrettynames)

    //potentially we need to remove a mapping here
    const mappingNames = Object.keys(mapping);
    if (mappingNames.includes(name)) {
      const newMappings = {...mapping};
      delete newMappings[name];

      //console.log(newMappings)
      setMapping(newMappings);
  
      props.returnColMappings(newMappings);  
    }

  }

  const checkIfPrettyNameAlreadyExists = (name) => {
    const index = prettyNames.findIndex(pn => pn.colName === name);
    if (index !== -1) return true
    else return false 
  }
  
  return <div className=' h-[400px] overflow-auto'>
          <div className='flex flex-col items-center'>
        {prettyNames.map(pName => <div className='flex flex-row items-center gap-x-1'>
            <PrettyNameLabel prettyNameDef={pName} />
              <Iconify icon="material-symbols:compare-arrows" width={42} height={42} />
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select sx={{width: 200}}
                        onChange={(event) => handleMappingChange(event, pName.colName)}
                        value={mapping[pName.colName] === undefined ? 'NotMapped' : mapping[pName.colName]}
              >
                <MenuItem value='NotMapped'>Not Mapped</MenuItem>
                {feedColumns.filter(colName => (colName === mapping[pName.colName] || !columnAlreadyUsedInMapping(colName))).map(colName => {
                  // if (colName === 'id') console.log(columnAlreadyUsedInMapping(colName));
                  return <MenuItem value={colName}>{colName}</MenuItem>
                }
                )}
              </Select>
              </FormControl>
            </div>
          )}
        {!isAddMode &&
          <div className='flex flex-row items-center gap-x-1'>
            <div className='w-[200px]'>
              <button className='border border-ccColorLightTeal rounded-md w-full py-2 text-center text-ccColorTeal hover:text-white hover:bg-ccColorTeal'
                      onClick={handleStartAddMode}>Add new</button>
            </div>
            <div className='flex w-[58px]'></div>
            <div className='flex w-[200px]'></div>
          </div>
        }
        {isAddMode &&
          <div className='flex flex-row items-center gap-x-1'>
            <div className='w-[200px] flex flex-row items-center'>
              <InputText
                  value={newPrettyName}
                  onChange={(val) => {handlePrettyNameChange(val)}}
                  onKeyDown={handlePrettyNameInputKeyDown}
                  error={showAlreadyExistsError === true ? "This name already exists" : undefined}
               />
               <IconButton onClick={(e) => handlePrettyNameInputKeyDown(e, true)}>
                 <Iconify icon={lionIcons.Icon_Actions_TickOutlined} className=' text-ccColorTeal' width={22} height={22} />
               </IconButton>
            </div>
            <Iconify icon="material-symbols:compare-arrows" className=' text-gray-400' width={42} height={42} />
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select sx={{width: 200}}
                      disabled
                        value={'NotMapped'}
              >
                <MenuItem value='NotMapped'>Not Mapped</MenuItem>
              </Select>
              </FormControl>

          </div>
        }

          </div>
  </div>



/* OLD LAYOUT
return <>
<Grid2 container spacing={2} >
 {prettyNames.map(name => <>
    <Grid2 xs={5} sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
    <Chip label={name} color="primary" />
    </Grid2>
    <Grid2 xs={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Iconify icon="material-symbols:compare-arrows" width={42} height={42} />
    </Grid2>
    <Grid2 xs={5} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <Select 
                onChange={(event) => handleMappingChange(event, name)}
                value={mapping[name] === undefined ? 'NotMapped' : mapping[name]}
      >
        <MenuItem value='NotMapped'>Not Mapped</MenuItem>
        {feedColumns.filter(colName => (colName === mapping[name] || !columnAlreadyUsedInMapping(colName))).map(colName => {
          // if (colName === 'id') console.log(columnAlreadyUsedInMapping(colName));
          return <MenuItem value={colName}>{colName}</MenuItem>
        }
        )}
      </Select>
      </FormControl>
    </Grid2>  
    </>
  )}
</Grid2>  
  </>
*/
}

export default function DatafeedWizard(props) {

  const apiRef = useGridApiRef();

  const initialState = {feedname: '', description: '', feedType: 'WEB_RESOURCE'
                        , endpointURL: '', verb: 'GET', feedFormat: 'CSV', csvSeparator: { label: ', (comma)', id: ',' }
                        , mapping: {forURL: '', data: []}
                      };

  const step0ValidationSchema = yupObject({
    feedname: string().required("Required field"),
    description: string().required("Required field"),
  });
  const step1ValidationSchema = yupObject({
    endpointURL: string().required("Required field").url("Must be a valid url"),
  });
  const [activeErrorList, setActiveErrorList] = useState([]);

  const initialValidationVars = {
    '1': {errorEndpointURL: false, errorCsvSeparator: false,
    msgEndpointURL: 'Endpoint URL must not be empty', msgCsvSeparator: 'Separator must not be empty' }
 
    }

    const {settings, setSettings} = useContext(SettingsContext);

    const [activeStep, setActiveStep] = useState(0);
    const [numSteps, setNumSteps] = useState(5);
    const [allStepsDef, setAllStepsDef] = useState([]);
    const [connectionData, setConnectionData] = useState(initialState);
    const [showMovePrevious, setShowMovePrevious] = useState(false);
    const [showMoveNext, setShowMoveNext] = useState(true);
    const [showSubmit, setShowSubmit] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [showTestConnectionSuccessMsg, setShowTestConnectionSuccessMsg] = useState(false);
    const [showTestConnectionErrorMsg, setShowTestConnectionErrorMsg] = useState(false);
    const [previewGridColumns, setPreviewGridColumns] = useState([]);
    const [previewGridRows, setPreviewGridRows] = useState([]); // used in the catalog grid, it's been re-used to preview csv files
    const [previewError, setPreviewError] = useState("");
    const [userSettingsColumnPrettyNames, setUserSettingsColumnPrettyNames] = useState([]);
    const [columnsToSync, setColumnsToSync] = useState([]);
    const [colPrettyNamesMappings, setColPrettyNamesMappings] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [oneToOneParams, setOneToOneParams] = useState([]);
    const [showAdvancedColSettings, setShowAdvancedColSettings] = useState(false);
    const [advancedColSettingsUsed, setAdvancedColSettingsUsed] = useState(false);
    const [validationVariables, setValidationVariables] = useState(initialValidationVars);
    const [webResourceJsonData, setWebResourceJsonData] = useState(null);
    const [webResourceJsonEntryPoint, setWebResourceJsonEntryPoint] = useState(null);

    const[columnsSettingsFromStore, setColumnsSettingsFromStore] = useState({}) // contains the same object from the store


    const CloseAdvancedColSettings = (advancedSettingsUsed) => {
      setShowAdvancedColSettings(false);
      setAdvancedColSettingsUsed(advancedSettingsUsed);
    }

    const returnColumnsPrettyNamesMappings = (mapping) => {
      console.log(mapping)
      setColPrettyNamesMappings(mapping)
    }

    const handlePrettyNamesChanged = (newPrettyNames) => {
      const newSettings = {...settings, feedCustomColumnsPretty: newPrettyNames}
      console.log(newSettings)
      setSettings(newSettings)
      setUserSettingsColumnPrettyNames(newPrettyNames);
      //const colNames = newSettings.feedCustomColumnsPretty.map(cc => cc.colName);
      updateFeedColumnsPretty({columns: newSettings.feedCustomColumnsPretty})
    }
    
    const returnKeyColumn = (colName) => {
      let settings = store.getState().dataConnections.columsSettings;
      // we need to loop through all the keys and set the "isKey" field to false
      // then we set the  new key 
      const propNames = Object.keys(settings);
      propNames.forEach(name => {
        const currentColumn = settings[name];
        const updatedColumn = {...currentColumn, isKey: (name === colName)};
        settings = {...settings, [name]: updatedColumn}
      });
      
      store.dispatch(setColumsSettings(settings));
      setColumnsSettingsFromStore(settings)

        console.log(settings);
    }

    const getKeyColumn = () => {
      const settings = store.getState().dataConnections.columsSettings;
      const colNames = Object.keys(settings);
      let result = "";
      colNames.forEach(name => {
        const currentColumn = settings[name];
        if (currentColumn.isKey) result = name;
      });
      return result;
    }
  

        useEffect(() => {
      // Prepare data to pass to the PrettyNamesMapper component via props
      setUserSettingsColumnPrettyNames(settings.feedCustomColumnsPretty);
    }, []);


    const updateStepsDef = (feedType, feedFormat) => {
      const result = [];
      if (feedType === 'CATALOG') {
        result.push({key: 'description', label:'Description'})
        result.push({key: 'stepConnectionDetails', label:'Connection Info'})
        result.push({key: 'stepMapping', label:'Mapping'})
        result.push({key: 'stepMappingNames', label:'Names Mapping'})
        result.push({key: 'stepFinal', label:'Final'})
      }
      else if (feedType === 'WEB_RESOURCE') {
        result.push({key: 'description', label:'Description'})
        result.push({key: 'stepConnectionDetails', label:'Connection Info'})
        if (feedFormat === 'JSON') result.push({key: 'stepMapping', label:'Entry Point'})
        result.push({key: 'stepFinal', label:'Final'})
      }
      else {
        result.push({key: 'description', label:'Description'})
        result.push({key: 'stepConnectionDetails', label:'Connection Info'})
        result.push({key: 'stepMapping', label:'Mapping'})
        result.push({key: 'stepMappingNames', label:'Names Mapping'})
        result.push({key: 'stepFinal', label:'Final'})
      }
      setAllStepsDef(result);
      setNumSteps(result.length);
    }

    const elementRef = useRef();

    /*
    useEffect(() => {
        let found = false;
        const config = { attributes: false, characterData: false, childList: true, subtree: true, attributeOldValue: false, characterDataOldValue: false };
        const observer = new MutationObserver(mutations => {
            found = false;
            for (let j=0; j<mutations.length; j+=1) {
              if (found) break;
              const mutation = mutations[j];
              const newNodes = mutation.addedNodes;
              for (let i=0; i<newNodes.length; i+=1) {
                const node= newNodes[i];
                if (node.querySelector === undefined) break;                  
                const selectDataTypes = node.querySelector('[id^="select_datatype_"]');
                if (selectDataTypes !== null) {
                  found = true;
                  UIRefeshSelects();
                  refreshVisibleColumns();
                  break;
                }
              }

            }
        });
        observer.observe(elementRef.current, config);
    }, []);
  */

    const UIRefeshSelects = () => {
      const settings = store.getState().dataConnections.columsSettings;
      console.log('entrato')
      console.log(settings)
      Object.keys(settings).forEach((key) => {
        const select = document.getElementById(`select_datatype_${key}`);
        //if (select !== null) console.log(select.parentElement.getElementsByTagName('input')[0].value)
        //if (select !== null) select.parentElement.getElementsByTagName('input')[0].value = settings[key].dataType;
        if (select !== null) select.value = settings[key].dataType; 
      })
    }


    // load data for edit mode -----
    const getDataConnectionInfo = async (dataConnectionId) => {
      try {
        const url = `${process.env.REACT_APP_LION_API_URL}/Feeds/info/${dataConnectionId}`;
        const response = await axios.get(url);
        const data = response.data;
  
        console.log(data)
        // we need to format cetain data before inserting it into the state
        if (data.csvSeparator !== undefined) {
          let separatorOption = "";
          separatorOption = csvSeparatorOptions.find(s => s.id === data.csvSeparator);
          if (separatorOption !== "") data.csvSeparator = separatorOption;
        }
        if (data.jsonEntryPoint !== undefined) {
          setWebResourceJsonEntryPoint(data.jsonEntryPoint)
        }

        if (data.oneToOneParameters !== '') {
          setOneToOneParams(checkIfOneToOneParametersAreUsed(data.oneToOneParameters, data.endpointURL))
          //setOneToOneParams(JSON.parse(data.oneToOneParameters))

        }


        if (data.feedType === 'CATALOG') {
          const metadata = JSON.parse(data.metadata);
          setAdvancedColSettingsUsed(metadata.advancedSettingsUsed);
  
          // pretty names mapping
          setColPrettyNamesMappings(metadata.colPrettyNames);
  
          // columns datatype and visibility
          store.dispatch(setColumsSettings(metadata.columns));
          setColumnsSettingsFromStore(metadata.columns)
          const visibleColumns = getVisibleColumnsNames(metadata.columns);
          setColumnsToSync(visibleColumns);  
        }


        setConnectionData({...data, mapping: {forURL: '', data: []} });
        updateStepsDef(data.feedType, data.feedFormat)
      } catch (error) {
        console.log(error);
      }
    }
    const checkIfOneToOneParametersAreUsed = (parameters, endpoint) => {
      // add an "inUse" flag to each param
      // checks if param is used in the endpoint
      // TO DO: check also in the body and in the headers

      if (parameters === undefined) return undefined;
      if (parameters === null) return null;
      const result = parameters.map(p => {
        if (p.paramName === '') return {...p, inUse: true} //avoid error when we don't have a param name yet
        const pName = "${" + p.paramName + "}"
        if (endpoint === undefined) return {...p, inUse: connectionData.endpointURL.includes(pName)}
        else return {...p, inUse: endpoint.includes(pName)}
      });
      //console.log(result)
      return result;
    }

    // get a feed preview -----
    const getDataConnectionPreview = async (endpointURL, verb, feedType, feedFormat, csvSeparator) => {
      setPreviewError("");
      const safeUrl = encodeURIComponent(endpointURL);
      const dataToPost = {oneToOne: oneToOneParams}

      try {
        const url = `${process.env.REACT_APP_LION_API_URL}/Feeds/preview?endpointURL=${safeUrl}&verb=${verb}&feedType=${feedType}&feedFormat=${feedFormat}&csvSeparator=${csvSeparator}`;
        const response = await axios.post(url, dataToPost );
        const data = response.data;

        //const url = `api/Feeds/preview?endpointURL=${safeUrl}&verb=${verb}&feedType=${feedType}&feedFormat=${feedFormat}&csvSeparator=${csvSeparator}`;
        //const response = await fetch(url, { method: "POST", body: JSON.stringify(dataToPost) });    
        //const data = await response.json();

        if (data.errorFromRemoteServer !== "") {
            setPreviewError(data.errorFromRemoteServer);
            return;
        }

        const newMappings = {forURL: endpointURL, 'data': data};
        setConnectionData({...connectionData, mapping: newMappings});

        if (connectionData.feedType === 'CATALOG') {
          setPreviewGridRows(TransformDataForGridArrayOfRows(data.rows, data.headers));

          let colSettings = {};
          // IF NEW
          // we need to set the columnsSettings array with default values
          if (props.id == null) {
            const colSettingDefaults = {dataType: 'text', visible:true};
            for (let i=0; i< data.headers.length; i+=1) {
              const colName = data.headers[i];
              colSettings = {...colSettings, [colName]: colSettingDefaults}
            }
            store.dispatch(setColumsSettings(colSettings));  
            setColumnsSettingsFromStore(colSettings)
            const visibleColumns = getVisibleColumnsNames(colSettings);
            setColumnsToSync(visibleColumns);
            } 

            setPreviewGridColumns(GetGridColumnsCustomHeaders());

        }

        setWebResourceJsonData(JSON.parse(data.jsonData));
  
      } catch (error) {
        console.log(error);
      }
    }

    const updateFeedColumnsPretty = async (colNames) => {
      //setPreviewError("");
      //const dataToPost = {oneToOne: oneToOneParams}
      try {
        const url = `${process.env.REACT_APP_LION_API_URL}/User/UpdateFeedColumnsPretty`;
        const response = await axios.post(url, colNames);
        const data = response.data;

        //console.log(response.data)
  
      } catch (error) {
        console.log(error);
      }
    }

    useEffect(() => {
            if (props.id == null) {setConnectionData(initialState); updateStepsDef(initialState.feedType, initialState.feedFormat)}
            else getDataConnectionInfo(props.id);
    }, [props.id]);

    const handleChange = (propName, propValue) => {
      const newConnectionData = {...connectionData, [propName]: propValue}
      setConnectionData(newConnectionData);
      validateStepOnChange(activeStep, newConnectionData)
    }

    // we get a feed preview when needed
    useEffect(() => {
      if (activeStep === 2 && connectionData.mapping.forURL !== connectionData.endpointURL) {
        getDataConnectionPreview(connectionData.endpointURL, connectionData.verb, connectionData.feedType, connectionData.feedFormat, connectionData.csvSeparator !== undefined ? connectionData.csvSeparator.id : undefined);
        return;
      }
      if (activeStep === 2 && oneToOneParams && oneToOneParams.length > 0) {
        // if there are 1-2-1 params we always refresh (could be improved)
        getDataConnectionPreview(connectionData.endpointURL, connectionData.verb, connectionData.feedType, connectionData.feedFormat, connectionData.csvSeparator !== undefined ? connectionData.csvSeparator.id : undefined);
      }
    }, [activeStep])

    // we update the status of the nav buttons every time activeStep changes
    useEffect(() => {
      // SHOW PREVIOUS
      if (activeStep > 0) setShowMovePrevious(true);
      else setShowMovePrevious(false);

      // SHOW SUBMIT
      if (connectionData.feedType === 'CATALOG' && activeStep === numSteps -2) setShowSubmit(true);
      else if (connectionData.feedType === 'WEB_RESOURCE' && connectionData.feedFormat === 'JSON'
                 && activeStep === numSteps -2) setShowSubmit(true);
      else if (connectionData.feedType === 'WEB_RESOURCE' && connectionData.feedFormat === 'CSV'
                 && activeStep === numSteps -2) setShowSubmit(true);
      else setShowSubmit(false);

      // SHOW NEXT
      if (connectionData.feedType === 'CATALOG' && activeStep === numSteps - 2) setShowMoveNext(false);
      else if (connectionData.feedType === 'WEB_RESOURCE' && connectionData.feedFormat === 'JSON'
               && activeStep === numSteps -2) setShowMoveNext(false);
      else if (connectionData.feedType === 'WEB_RESOURCE' && connectionData.feedFormat === 'CSV'
               && activeStep === numSteps -2) setShowMoveNext(false);
      else setShowMoveNext(true);

    }, [activeStep]) 

    const validateStep = (numStep, cData) => {
      let isValid = true;
      const errorVarStep1 = initialValidationVars['1'];
      if (cData === undefined) cData = connectionData;

      switch(numStep) {
          case 0:
            try {
              step0ValidationSchema.validateSync(cData, {abortEarly: false});
              setActiveErrorList([])
            }
            catch (e) {
              const errorList = getErrorListFromYupError(e)
              setActiveErrorList(errorList)
              isValid = false;
            }
            break;
          case 1:
            try {
              step1ValidationSchema.validateSync(cData, {abortEarly: false});
              setActiveErrorList([])
            }
            catch (e) {
              const errorList = getErrorListFromYupError(e)
              setActiveErrorList(errorList)
              isValid = false;
            }

            if (cData.endpointURL === '') errorVarStep1.errorEndpointURL = true;
            else errorVarStep1.errorEndpointURL = false;

            if (cData.feedFormat === 'CSV') {
              if (cData.csvSeparator == null) errorVarStep1.errorCsvSeparator = true;
              else errorVarStep1.errorCsvSeparator = false;  
            }
            if (cData.endpointURL === '' || (cData.csvSeparator == null && cData.feedFormat === 'CSV')) isValid = false;
            setValidationVariables({...validationVariables, '1': errorVarStep1});
            break;
          case 2:
            const key = getKeyColumn();
            if (key === undefined || key === '') isValid = false;
            break;
          default:
          break;
        }
        return isValid;
    }
    const validateStepOnChange = (numStep, cData) => {

      if (activeErrorList.length === 0) return;
      validateStep(numStep, cData);
    }

    const validateSingleControlInStep = (numStep, controlName, controlCustomData) => {
      const errorVarStep0 = validationVariables['0'];
      const errorVarStep1 = validationVariables['1'];

      switch(numStep) {
          case 0:
            switch(controlName) {
              case 'feedname':
                if (connectionData.feedname === '') errorVarStep0.errorDataConnectionName = true;
                else errorVarStep0.errorDataConnectionName = false;    
              break;
              case 'description':
                if (connectionData.description === '') errorVarStep0.errorDataConnectionDescription = true;
                else errorVarStep0.errorDataConnectionDescription = false;    
              break;
            default:
              break;
            }

            setValidationVariables({...validationVariables, '0': errorVarStep0});
            break;
            case 1:
              switch(controlName) {
                case 'endpointURL':
                  if (connectionData.endpointURL === '') errorVarStep1.errorEndpointURL = true;
                  else errorVarStep1.errorEndpointURL = false;    
                break;
                case 'csvSeparator':
                  console.log(connectionData.csvSeparator);
                  if (controlCustomData == null) errorVarStep1.errorCsvSeparator = true;
                  else errorVarStep1.errorCsvSeparator = false;    
                break;
              default:
                break;
              }
              setValidationVariables({...validationVariables, '1': errorVarStep1});
              break;
            default:
            break;
        }
    }


    const moveToNextStep = () => {
      const isValidStep = validateStep(activeStep);
      if (isValidStep) {
        // special cases first, otherwise we increment by 1
        if (activeStep === 1 && connectionData.feedType === 'WEB_RESOURCE') {
          if (connectionData.feedFormat === 'CSV') {setActiveStep(activeStep + 3); return;}          
        }
        if (activeStep === 2 && connectionData.feedType === 'WEB_RESOURCE') {
          if (connectionData.feedFormat === 'JSON') {setActiveStep(activeStep + 2); return;}
        }

        setActiveStep(activeStep + 1);
      }
    }
    const moveToPreviousStep = () => {
      // special cases first, otherwise we increment by 1
      if (activeStep === 4 && connectionData.feedType === 'WEB_RESOURCE') {
        if (connectionData.feedFormat === 'CSV') {setActiveStep(activeStep - 3); return;}          
      }
      if (activeStep === 4 && connectionData.feedType === 'WEB_RESOURCE') {
        if (connectionData.feedFormat === 'JSON') {setActiveStep(activeStep - 2); return;}
        return;
      }
      
      setActiveStep(activeStep - 1);
    }

    const submitData = async () => {
      setShowLoader(true);
      setSubmitLoading(true);

      const columnsSettings = store.getState().dataConnections.columsSettings;
       
      //console.log(columnsSettings)


      // preparing object to POST
      // we need to re-set all the dateInputFormat values to only save ids in the backend
      const colNames = Object.keys(columnsSettings);
      let colSettingsToPost = {...columnsSettings};
      colNames.forEach(colName => {
        let currentCol = columnsSettings[colName];
        if (columnsSettings[colName].dataType === 'datetime') {
          //currentCol = {...currentCol, datetimeInputFormat: currentCol.datetimeInputFormat.id === undefined ? currentCol.datetimeInputFormat : currentCol.datetimeInputFormat.id};
          // THE LINE ABOVE is used when user can select the datetime input format from the UI. We have removed this feature for now, so we always set the input format to be 'auto'
          currentCol = {...currentCol, datetimeInputFormat: 'auto'};
          colSettingsToPost = {...colSettingsToPost, [colName]: currentCol}
        }

        // // we don't have anymore the advanced settings in the UI so we want to assign a length = 300 for every varchar
        if (columnsSettings[colName].dataType === 'text') {
          if (currentCol.textLength === undefined || currentCol.textLength === 0) currentCol = {...currentCol, textLength: 300};
          //console.log(currentCol)
          colSettingsToPost = {...colSettingsToPost, [colName]: currentCol}
        }
      });
      const dataToPost = {feedName: connectionData.feedname, 
                    description: connectionData.description,
                    feedType: connectionData.feedType,
                    endpointURL: connectionData.endpointURL,
                    verb: connectionData.verb,
                    feedFormat: connectionData.feedFormat,
                    csvSeparator: connectionData.csvSeparator === undefined ? null: connectionData.csvSeparator.id,
                    oneToOneParameters: JSON.stringify(oneToOneParams),
                    jsonEntryPoint: webResourceJsonEntryPoint,
                    metadata: JSON.stringify({colPrettyNames: colPrettyNamesMappings, columns: colSettingsToPost, advancedSettingsUsed: advancedColSettingsUsed})
                  }
                  console.log(dataToPost)
      try {
        let url = `${process.env.REACT_APP_LION_API_URL}/Feeds/create`;
        if (props.id !== null && props.id !== undefined) url = `${process.env.REACT_APP_LION_API_URL}/Feeds/update/${props.id}`;
        const response = await axios.post(url, dataToPost);
        const data = response.data;

        //let url = `api/Feeds/create`;
        //if (props.id !== null) url = `api/Feeds/update/${props.id}`;

        //const response = await fetch(url, { method: "POST", body: JSON.stringify(dataToPost) });    
        //const data = await response.json();

        if (props.id === null) {
            // new data connection
            if (props.onDataConnectionCreated !== undefined) {
              props.onDataConnectionCreated({dataConnectionId: data});
            }
        }


        setShowSuccessMsg(true);
      } catch (error) {
        setShowSuccessMsg(false);
        setShowErrorMsg(true);
        console.log(error);
      }
    setShowLoader(false);
    setSubmitLoading(false);


    }

    const handleColumnDataTypeChange = (colName, newValue) => {
      const settings = store.getState().dataConnections.columsSettings;
      const currentColumn = settings[colName];
      const updatedColumn = {...currentColumn, dataType: newValue};
      store.dispatch(setColumsSettings({...settings, [colName]: updatedColumn}));
    } 

    const GetGridColumnsCustomHeaders = (newSettings) => {
      const columns = [];
      const colNames = [];
      const colSettings = newSettings ?? store.getState().dataConnections.columsSettings;
      Object.keys(colSettings).forEach(k => {if (colSettings[k].visible) colNames.push(k)})

      //console.log(colSettings)
      colNames.forEach(element => {
          const currentCol =   {
              field: element,
              headerName: element,
              
              width: 150,
              editable: false,
              sortable: false,
            };
  
          columns.push(currentCol);
      });
      return columns;
  }

  const GetGridColumnsPreviewWebResource = (colNames) => {
    const columns = [];
    colNames.forEach(element => {
        const currentCol =   {
            field: element,
            headerName: element,
            width: 150,
            editable: false,
            sortable: false,
          };

        columns.push(currentCol);
    });
    return columns;
}

  
    // end load data for edit mode -----

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickSynchColumnsButton = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseSynchColumns = () => {
      setAnchorEl(null);
    };
    const openSynchColumns = Boolean(anchorEl);

    const handleColVisibilityChange = (e) => {
      const settings = store.getState().dataConnections.columsSettings;
      const colSettings = {...settings[e.target.value]};
      colSettings.visible = e.target.checked;
      const newSettings = {...settings, [e.target.value]: colSettings};
      store.dispatch(setColumsSettings(newSettings));
      apiRef.current.setColumnVisibility(e.target.value, e.target.checked);

      const visibleColumns = getVisibleColumnsNames(newSettings);
      setColumnsToSync(visibleColumns);

    }
    const refreshVisibleColumns = () => {
      const settings = store.getState().dataConnections.columsSettings;
      const colNames = Object.keys(settings);

      colNames.forEach((colName) => {
        apiRef.current.setColumnVisibility(colName, settings[colName].visible);
      });
    }
    const handleColumnVisibilitySettingChange = (colName, value) => {
      //console.log(colName)
      //console.log(value)
      apiRef.current.setColumnVisibility(colName, value);

      console.log(previewGridColumns)

      const newColSettings = {...columnsSettingsFromStore[colName], visible: value}
      const newAllColSettings = {...columnsSettingsFromStore, [colName]: newColSettings}
      store.dispatch(setColumsSettings(newAllColSettings));

      setPreviewGridColumns(GetGridColumnsCustomHeaders(newAllColSettings));

      setColumnsSettingsFromStore(newAllColSettings)
    }
    const handleColumnSettingsChange = (colSettings, allVisible) => {
      console.log(colSettings)
      setColumnsSettingsFromStore(colSettings)
      setPreviewGridColumns(GetGridColumnsCustomHeaders(colSettings));

      if (allVisible !== undefined) {
        const keys = Object.keys(colSettings);
        keys.forEach(k => {
          apiRef.current.setColumnVisibility(k, allVisible);
        })
      }
    }

    const setAllColVisibility = (allVisible) => {
      const settings = store.getState().dataConnections.columsSettings;
      const newSettings = {};
      const colNames = Object.keys(settings);

      colNames.forEach((colName) => {
        const colSettings = {...settings[colName], visible:allVisible };
        newSettings[colName] = colSettings;
        apiRef.current.setColumnVisibility(colName, allVisible);
      });
      //store.dispatch(setColumsSettings(newSettings));
      setColumsSettings(newSettings);

      const visibleColumns = getVisibleColumnsNames(newSettings);
      setColumnsToSync(visibleColumns);

      handleCloseSynchColumns();
    }

  const getVisibleColumnsNames = (settings) => {
    const colNames = Object.keys(settings);
    const visibleColumns = colNames.filter((colName) => settings[colName].visible);
    return visibleColumns;
  } 

    const renderColsToSynch = () => {
      const settings = store.getState().dataConnections.columsSettings;
      const colNames = Object.keys(settings);
      const col1 = [];
      const col2 = [];
      colNames.forEach((col, index) => {
        if (index % 2 === 0) col1.push(col);
        else col2.push(col);
      })
      return (
        <Grid2 container spacing={1} >
          <Grid2 xs={6} >
            {col1.map((colName) => <FormGroup key={colName}>
                                      <FormControlLabel control={<Switch defaultChecked={settings[colName].visible} />} value={colName} label={colName} onChange={(e) => handleColVisibilityChange(e)} />
                                    </FormGroup>)}
                                    
          </Grid2>
          <Grid2 xs={6}  >
           {col2.map((colName) => (<FormGroup key={colName}>
                                      <FormControlLabel control={<Switch defaultChecked={settings[colName].visible} />} value={colName} label={colName} onChange={(e) => handleColVisibilityChange(e)} />
                                    </FormGroup>))}
          </Grid2>
        </Grid2>
      );
    };
    
    
    // const count = useSelector((state) => {console.log(state); return state.userSettings});

    const csvSeparatorOptions = [
      { label: ', (comma)', id: ',' },
      { label: '; (semi colon)', id: ';' },
      { label: 'Tab', id: 'ascii(9)' },
    ];
    // const csvSeparatorOptions = [',', ';'];q1\

    const OpenAdvancedSettings = () => {
      if (!advancedColSettingsUsed) SpreadAvailableVarchar();
      setShowAdvancedColSettings(true);

    }


    const SpreadAvailableVarchar = () => {
      const totalVachar = 50000;
      // 1 we find th avg value
      // 2 we spread the avg value

      const settings = store.getState().dataConnections.columsSettings;
      const colNames = Object.keys(settings);
      
      const visibleTextColumns = colNames.filter((colName) => (settings[colName].visible
                                                   && settings[colName].dataType === 'text'));

      const avgLength = Math.floor(totalVachar / visibleTextColumns.length);
      const newSettings = {};
      colNames.forEach((colName) => {
          const colSettings = {...settings[colName], textLength:avgLength };
          if (settings[colName].isKey) newSettings[colName] = {...colSettings, textLength:Math.min(100, avgLength)}
          else newSettings[colName] = colSettings;  
      });
      store.dispatch(setColumsSettings(newSettings));

      return newSettings
    }

    const handleJsonEntryPointChange = (selection) => {
      setWebResourceJsonEntryPoint(selection)
      console.log(selection)  
    }

   const testConnectionCSV = async () => {
      setPreviewError("");
      setShowLoader(true);
      setShowTestConnectionErrorMsg(false);
      setShowTestConnectionSuccessMsg(false);

      const safeUrl = encodeURIComponent(connectionData.endpointURL);
      try {
          const url = `${process.env.REACT_APP_LION_API_URL}/Feeds/preview?endpointURL=${safeUrl}&verb=${connectionData.verb}&feedType=${connectionData.feedType}&feedFormat=${connectionData.feedFormat}&csvSeparator=${connectionData.csvSeparator.id}`;
          //const response = await axios.get(url);
          const dataToPost = {oneToOne: undefined}
          const response = await axios.post(url,  dataToPost);
          const data = response.data;

          //const url = `api/Feeds/preview?endpointURL=${safeUrl}&verb=${connectionData.verb}&feedType=${connectionData.feedType}&feedFormat=${connectionData.feedFormat}&csvSeparator=${connectionData.csvSeparator.id}`;
  
          //const response = await fetch(url, { method: "GET", });    
          //const data = await response.json();
  

          //console.log(data.errorFromRemoteServer)
          if (data.errorFromRemoteServer !== "") {
            setShowTestConnectionErrorMsg(true)
            setPreviewError(data.errorFromRemoteServer);
          }
          else {
            setShowTestConnectionSuccessMsg(true)
            const previewHeaders = GetGridColumnsPreviewWebResource(data.headers);
            console.log(previewHeaders)
            if (previewHeaders.length === 1) {
              var occurenciesTab = (previewHeaders[0].headerName.match(/\t/g) || []).length;
              var occurenciesComma = (previewHeaders[0].headerName.match(/,/g) || []).length;
              var occurenciesSemicolon = (previewHeaders[0].headerName.match(/;/g) || []).length;
              if (occurenciesTab > 1  || occurenciesComma > 1  || occurenciesSemicolon > 1) {
                setShowTestConnectionErrorMsg(true)
                setPreviewError("Failed to read data correctly. If this is a CSV file please check  that the separator is correct");
              }
            }
            else  {
              setPreviewGridColumns(GetGridColumnsPreviewWebResource(data.headers));
              setPreviewGridRows(TransformDataForGridArrayOfRows(data.rows, data.headers));
            }
          
          }

        }
        catch (e) {
          // todo
        }
        finally {setShowLoader(false)}
        

    }

    const handleOneToOneParamChange = (params) => {
      setOneToOneParams(checkIfOneToOneParametersAreUsed(params))
    }


    return <>
    <Paper ref={elementRef} elevation={4} sx={{pb:1}}>
        <Box display="flex" justifyContent="flex-end" p={0.5} >
                <IconButton variant="contained" sx={{'&.MuiIconButton-root': {color: ccColorTeal}}} onClick={props.closingCall} >
                    <Iconify icon="material-symbols:cancel-outline" width="48px" />
                </IconButton>
        </Box>
        {/*
        <Stepper activeStep={activeStep} sx={{mb: 5}} >
          {allStepsDef.map(step => <Step key={step.key}>
                                    <StepLabel>{step.label}</StepLabel>
                                  </Step>)
          }
        </Stepper>
        */}
        <div className=' mx-2'>
          <CcStepper2
              steps={allStepsDef.map(step => {return {name: step.label, href: '#'} })}
              currentStepIndex={activeStep}
          />
        </div>


      { activeStep === 0 &&
      <Box  sx={{ml: 1, pb:1, display: 'flex', justifyContent: 'center'}}  >
        <Stack width="70%" spacing={2}>
            <InputText
                displayName="Name"
                value={connectionData.feedname}
                onChange={(val) => {handleChange('feedname', val);}}
                error={getErrorMessage(activeErrorList, 'feedname')}
            />
            <InputText
                displayName="Description"
                value={connectionData.description}
                onChange={(val) => {handleChange('description', val);}}
                error={getErrorMessage(activeErrorList, 'description')}
            />

            {/* Radio for feedType */}
            <div class=" w-fit p-1 flex flex-row gap-5 rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
            <div className='pt-1 flex flex-row gap-1 items-start cursor-pointer  hover:bg-slate-100 hover:rounded-xl' onClick={(e) => {handleChange('feedType', 'WEB_RESOURCE'); updateStepsDef('WEB_RESOURCE', connectionData.feedFormat)}}>                
                <div class="inline-flex items-center">
                    <label class="relative flex items-center p-3 -mt-[6px] rounded-full cursor-pointer" htmlFor="html_version">
                    <input name="description" type="radio" value="WEB_RESOURCE"
                        checked={connectionData.feedType === 'WEB_RESOURCE'}
                        class="before:content[''] peer relative h-5 w-5 appearance-none rounded-full border border-blue-200 text-blue-700 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-500 before:opacity-0 before:transition-opacity checked:border-blue-900 checked:before:bg-blue-700 hover:before:opacity-10"
                        id="html" />
                    <span
                        class="absolute text-blue-700 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 16 16" fill="currentColor">
                        <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                        </svg>
                    </span>
                    </label>
                </div>
                <div className='flex flex-col gap-2'>
                    <Chip sx={{width:150, backgroundColor: lionObjectsColors.Color_WebResource, color:'#fff'}} label="WEB RESOURCE" icon={<Iconify icon="grommet-icons:resources" style={{color: 'white'}} />} />
                    <div className=' max-w-72 text-sm'>Use this type for small size resources like API calls</div>
                </div>
            </div>

            <div className='pt-1 flex flex-row gap-1 items-start cursor-pointer hover:bg-slate-100 hover:rounded-xl' onClick={(e) => {handleChange('feedType', 'CATALOG'); updateStepsDef('CATALOG', connectionData.feedFormat)}}>                
                <div class="inline-flex items-center">
                    <label class="relative flex items-center p-3 -mt-[6px] rounded-full cursor-pointer" htmlFor="html_version">
                    <input name="description" type="radio" value="CATALOG"
                        checked={connectionData.feedType === 'CATALOG'}
                        class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-200 text-blue-700 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-500 before:opacity-0 before:transition-opacity checked:border-blue-900 checked:before:bg-blue-700 hover:before:opacity-10"
                        id="html2" />
                    <span
                        class="absolute text-blue-700 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 16 16" fill="currentColor">
                        <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                        </svg>
                    </span>
                    </label>
                </div>
                <div className='flex flex-col gap-2'>
                    <Chip sx={{width:100, backgroundColor: lionObjectsColors.Color_Catalog, color:'#fff'}} label="CATALOG" icon={<Iconify icon="fluent-mdl2:product-catalog" style={{color: 'white'}} />} />
                    <div className=' max-w-72 text-sm'>Use this type for large resources like product catalogs</div>
                </div>
            </div>

            </div>

        </Stack>
      </Box>
      }

      {activeStep === 1 &&
      <Box  sx={{ml: 1, pb:1, display: 'flex', justifyContent: 'center'}}  >
        <Stack width="70%" spacing={2} sx={{maxWidth: 1000}}>
          <Grid2 container spacing={1} >
            <Grid2 xs={2} >
            <label htmlFor="Method" className="block text-sm font-medium leading-6 text-gray-900">
              Method
            </label>
          <LionListbox items={[{id: 'GET', name: 'GET'}, {id: 'POST', name: 'POST'}]}
                      selectedItemId={connectionData.verb}
                      onChange={(item) => handleChange('verb', item.id)}
                      zIndex={1}
                      id="dataConnectionVerb"
                      />
            </Grid2>
            <Grid2 xs={10}>
              <label htmlFor="Endpoint" className="block text-sm font-medium leading-6 text-gray-900">
                Endpoint
              </label>
              {/*
              <InputText
                //displayName="Name"
                value={connectionData.endpointURL}
                onChange={(val) => {handleChange('endpointURL', val);  setPreviewGridRows([]); setShowTestConnectionErrorMsg(false); setShowTestConnectionSuccessMsg(false); setOneToOneParams(checkIfOneToOneParametersAreUsed(oneToOneParams, val))}}
                error={getErrorMessage(activeErrorList, 'endpointURL')}
                />
              */}
              <InputURL
                //displayName="Name"
                value={connectionData.endpointURL}
                onChange={(val) => {handleChange('endpointURL', val);  setPreviewGridRows([]); setShowTestConnectionErrorMsg(false); setShowTestConnectionSuccessMsg(false); setOneToOneParams(checkIfOneToOneParametersAreUsed(oneToOneParams, val))}}
                error={getErrorMessage(activeErrorList, 'endpointURL')}
                />
            </Grid2>

          </Grid2>

        <div>
          <label htmlFor="Format" className="block mt-2 text-sm font-medium leading-6 text-gray-900">
                Format
          </label>
          <RadioGroup
              row
              name="radio-buttons-feedType"
              value={connectionData.feedFormat}
              onChange={(e) => {handleChange('feedFormat', e.target.value); updateStepsDef(connectionData.feedType, e.target.value)}}
            >
              <FormControlLabel value="CSV" control={<Radio />} label={<Chip sx={{backgroundColor: lionObjectsColors.Color_FormatCSV, color:'#fff'}} label="CSV" icon={<Iconify icon="grommet-icons:document-csv" style={{color: 'white'}} />} />} />
              {connectionData.feedType === "CATALOG" &&
              <FormControlLabel value="XML" control={<Radio />} label={<Chip sx={{backgroundColor: lionObjectsColors.Color_FormatXML, color:'#fff'}} label="XML" icon={<Iconify icon="bi:filetype-xml" style={{color: 'white'}} />} />} />
              }
              {connectionData.feedType === "WEB_RESOURCE" &&
              <FormControlLabel value="JSON" control={<Radio />} label={<Chip sx={{backgroundColor: lionObjectsColors.Color_FormatJSON, color:'#fff'}} label="JSON" icon={<Iconify icon="lucide:file-json" style={{color: 'white'}} />} />} />
              }
          </RadioGroup>
        </div>
            {connectionData.feedFormat === 'CSV' &&      
              <Autocomplete
              disablePortal
              freeSolo
              id="combo-csv-separator"
              options={csvSeparatorOptions}
              getOptionLabel={(option) => {
                                          if (option == null) return '';
                                          if (Object.prototype.hasOwnProperty.call(option, 'label')) return option.label;
                                          return option.toString();
                                        }}
              sx={{ width: 300 }}
              onChange={(e, value) => {handleChange('csvSeparator', value); validateSingleControlInStep(activeStep, 'csvSeparator', value);}}
              onInputChange={(e, value, reason) => {console.log(reason); if (reason === 'input') {handleChange('csvSeparator', value); validateSingleControlInStep(activeStep, 'csvSeparator', value);}}}
              value={connectionData.csvSeparator}
              renderInput={(params) => <TextField {...params} label="Separator"
                                            error={validationVariables['1'].errorCsvSeparator}
                                            helperText={validationVariables['1'].errorCsvSeparator && validationVariables['1'].msgCsvSeparator}
                                             />}
          />    
            }

      {connectionData.feedType === "WEB_RESOURCE" &&
              <Stack>
                <Typography variant='h4'>Parameters</Typography>
                <OneToOneParametersSelector onChange={handleOneToOneParamChange} 
                initialParameters={oneToOneParams} />
              </Stack>
              }


              {connectionData.feedType === "WEB_RESOURCE" && connectionData.feedFormat === "CSV" &&
              <><Stack direction='row' spacing={2}>
                  <Button color='secondary' sx={{width: 140}}
                          onClick={testConnectionCSV}
                  >Test connection</Button>
                  {showTestConnectionSuccessMsg && <Alert severity="success">
                                  Connection succeeded
                                </Alert>}
                  {showTestConnectionErrorMsg && <Alert severity="error">
                    {previewError}
                  </Alert>}
              </Stack>
                {showLoader && <CircularProgress size={64} /> }
                {previewGridRows.length > 0 &&
                      <Box sx={{ height: 300, width: '100%', }}>
                        <DataGridPro      rows={previewGridRows}
                                          columns={previewGridColumns}
                                          disableColumnMenu
                                          disableColumnFilter
                                          hideFooter
                                          rowHeight={26}
                                          headerHeight={80}
                                        />
                        </Box>
                }

              </>
            }
        </Stack>
      </Box>
      }

      {activeStep === 2 && previewError.length > 0 &&
      <Box  sx={{ml: 1, pb:1, display: 'flex', justifyContent: 'center'}}  >
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
              {previewError}
          </Alert>
      </Box>}

      { activeStep === 2 && connectionData.feedType === "CATALOG" && !showAdvancedColSettings && previewError.length === 0 &&
       <Box  sx={{ml: 1, pb:1, display: 'flex', justifyContent: 'center'}}  >
        <Stack width="100%" spacing={2} >
          <Stack direction='row' spacing={2} alignItems='center'>
          <PrimaryKeySelect columns={columnsToSync} selectedCol={getKeyColumn()} returnKeyColumn={returnKeyColumn} disabled={props.id !== null} /> 

          </Stack>
          <div className='flex flex-row gap-x-3'>
            <div className='flex flex-col'>
              <CcSubSectionTitle>Content Type</CcSubSectionTitle>
              <ColumnSettingsManager height={400}
              colSettings={columnsSettingsFromStore}
              sampleData={connectionData.mapping.data}
                onColumnVisibilityChange={handleColumnVisibilitySettingChange}
                onColumnSettingsChange={handleColumnSettingsChange}
              />
            </div>
            <div className='flex flex-col'>
              <CcSubSectionTitle>Content Preview</CcSubSectionTitle>
              <Box sx={{ height: 400, maxWidth: '60vw', overflow: 'auto'}}>
                  <DataGridPro apiRef={apiRef}
                    rows={previewGridRows}
                    columns={previewGridColumns}
                    disableColumnMenu
                    disableColumnFilter
                    //columnHeaderHeight={90}
                  />
              </Box>       
            </div>     
          </div>
        </Stack>
        </Box>
      }


{ activeStep === 2 && connectionData.feedType === "WEB_RESOURCE" && !showAdvancedColSettings && previewError.length === 0 &&
       <Box  sx={{ml: 1, pb:1}}  >
        <Grid2 container>
          <Grid2 xs={6} >
            <Box sx={{overflow: 'auto', maxHeight: '500px'}} >
              <JsonViewer value={webResourceJsonData}
                          rootName={false}
                        enableClipboard={false}
                        groupArraysAfterLength={5}
              />
            </Box>
          </Grid2>
          <Grid2 xs={6}>
            <JsonEntryPointPicker jsonData={webResourceJsonData}
                                 onEntryPointChanged={handleJsonEntryPointChange}
                                 currentPathSelection={webResourceJsonEntryPoint}
                                  />
          </Grid2>
        </Grid2>
        </Box>
      }


        {
            activeStep === 3 &&
             <PrettyNamesMapper prettyNames={userSettingsColumnPrettyNames} 
                columns={columnsToSync}
                colMappings={colPrettyNamesMappings}
                onAdded={handlePrettyNamesChanged}
                onDeleted={handlePrettyNamesChanged}
                returnColMappings={returnColumnsPrettyNamesMappings} />
        }

        {
            activeStep === 4 &&
            <Box  sx={{ml: 1, pb:1, display: 'flex', justifyContent: 'center'}}  >
              <Stack>
               {showLoader && <CircularProgress size={64} /> }
                {showSuccessMsg && <Alert severity="success">
                                <AlertTitle>Success</AlertTitle>
                                Data connection saved
                              </Alert>}
                {showErrorMsg && <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  Something went wrong while saving your data connection
                </Alert>}
              </Stack>
            </Box>
        }

      <Box display="flex" justifyContent="flex-end" sx={{m:1}} >
          {(!showSuccessMsg && !showErrorMsg) && showMovePrevious && !showAdvancedColSettings && <CcActionButton onClick={moveToPreviousStep} className='mr-1' >Previous</CcActionButton>} 
          {(!showSuccessMsg && !showErrorMsg) && showMoveNext && !showAdvancedColSettings && <CcActionButton onClick={moveToNextStep}>Next</CcActionButton>}
          {(!showSuccessMsg && !showErrorMsg) && showSubmit && !showAdvancedColSettings && <CcActionButton onClick={() => {moveToNextStep(); submitData()}} loading={submitLoading} >Submit</CcActionButton>}
          {(showSuccessMsg || showErrorMsg) && <CcActionButton onClick={props.closingCall}>Close</CcActionButton>}
      </Box>

    </Paper>

    </>
}

DatafeedWizard.propTypes = {
  closingCall: PropTypes.func
};