
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { signIn, signOut, fetchAuthSession } from 'aws-amplify/auth';

import {TextField, Container, Box, Button} from '@mui/material'
import { useForm } from "react-hook-form";
import { Magic } from "magic-sdk";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert, CircularProgress } from "@mui/material";
import axios from 'axios'
import CssBaseline from '@mui/material/CssBaseline';


import { Icon as Iconify } from '@iconify/react';
import { InputText, InputTextPassword } from "../commonComponents/inputFields";
import { ccColorDarkTeal, ccColorLightTeal, ccColorSecondaryAzure, ccColorSecondaryBronze, ccColorSecondaryPurple, ccColorTeal } from "../components/helpersStyles";

import { getAccessTokenKb } from "../components/helperHelpScout";

/*export default*/ function LoginMagic() {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async ({ email }) => {
    const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY);
    await magic.user.logout();

    const didToken = await magic.auth.loginWithMagicLink({ email });
    console.log(didToken)
    const res = await fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + didToken,
      },
      body: JSON.stringify({ email }),
    });
    console.log(res)
    if (res.status === 200) {
      navigate("/accounts", { replace: true });
      //router.push("/dashboard")
    } else {
      // display an error
    }
  };

  return (
    
<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  {...register("email")}
                />
              </div>
            </div>


            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get a magic link
              </button>
            </div>
          </form>
{/*
          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{' '}
            <a href="#" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Start a 14 day free trial
            </a>
          </p>
          */
}
        </div>
      </div>

  );
}
const defaultTheme = createTheme();

const Login = () => {
  const [loginError, setLoginError] = React.useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = React.useState('');
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [showEmailNotValidated, setShowEmailNotValidated] = useState(false);
  const [emailValidationRequestInProgress, setEmailValidationRequestInProgress] = useState(false);
  const [emailValidationSent, setEmailValidationSent] = useState(false);
  const [loginName, setLoginName] = useState('');

    const { setToken } = useAuth();
    const navigate = useNavigate();
    let params = useParams();

  const isKnowledgeBaseLogin = params.dest === "kb"
  const kbReturnTo = new URLSearchParams(window.location.search).get('returnTo');

    async function signUserIn(username, password) {
      try {
        setRequestInProgress(true)
        await signOut();
        const { isSignedIn, nextStep } = await signIn({ username, password });
        //const user = await signIn(username, pswd);  
        console.log(nextStep); 
        setRequestInProgress(false);
        
        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};  
      
        const jwt = idToken.toString();
        //console.log(accessToken.toString()); 
        //console.log(idToken.toString()); 

        setToken(jwt);
        axios.defaults.headers.common['Authorization'] = `Bearer ${  jwt}`;

        updateLastLoginDate();
        
        /*
        axios.interceptors.request.use(function (config) {
          config.headers.Authorization =  "Bearer " + jwt;
           
          return config;
      });
  */
 /*
        axios.interceptors.response.use(function (response) {
          // Any status code that lie within the range of 2xx cause this function to trigger
          // Do something with response data
          return response;
        }, function (error) {
          // Any status codes that falls outside the range of 2xx cause this function to trigger
          // Do something with response error
          console.log("errore dall'interceptor")
          return Promise.reject(error);
        });
*/
        if (isKnowledgeBaseLogin) {
          //we need to issue a token and redirect to the kb
          const token = await getAccessTokenKb()

          const redirectUrl = `${process.env.REACT_APP_KNOWLEDGEBASE_URL}/authcallback?token=${token}&returnTo=${kbReturnTo}`
                  
          window.location.replace(redirectUrl)

        }
        else {
          //otherwise we proceed to the login page
          navigate("/", { replace: true });
        }
    
        /*
        currentSession().then(res=>{
          let accessToken = res.getIdToken();
          let jwt = accessToken.getJwtToken();
    
          setToken(jwt);
          axios.defaults.headers.common.Authorization = `Bearer ${  jwt}`;
    
          navigate("/", { replace: true });
    
        })
    */
        
      } catch (error) {
        setLoginError(true);
        setLoginErrorMsg(error)
        setRequestInProgress(false);

        // console.log('error signing in', error);
      }
    }

    const updateLastLoginDate = async () => {
      try {
          // const url = `api/Stats/${endpoint}`;
          const url = `${process.env.REACT_APP_LION_API_URL}/User/UpdateLastLoginDate`

          const response = await axios.get(url);
                  
      } catch (error) {
      }  

      }

  
    const isEmailValidated = async (loginName) => {
      try {
          const url = `${process.env.REACT_APP_LION_API_URL}/Accounts/checkEmailValidated?loginName=${encodeURIComponent(loginName)}`

          const response = await axios.get(url);

        return response.data.success;
                  
      } catch (error) {
        return true;
      }  

    }
    const resendValidationEmail = async () => {
      try {
          const url = `${process.env.REACT_APP_LION_API_URL}/Accounts/resendValidationEmail?loginName=${encodeURIComponent(loginName)}`

          const response = await axios.get(url);

        setEmailValidationRequestInProgress(false)
                  
      } catch (error) {
        return true;
      }  
      setEmailValidationSent(true);
    }

    const handleSubmit = async (event) => {
      setLoginError(false)
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      setLoginName(data.get('user'))

      const emailValidated = await isEmailValidated(data.get('user'))
      if (emailValidated) signUserIn(data.get('user'), data.get('password'));
      else setShowEmailNotValidated(true);
    };

    const handleEmailValidationResend = () => {
      setEmailValidationRequestInProgress(true)
      resendValidationEmail()
    }

    return (
      <div style={{height: '100vh', backgroundSize: 'cover', backgroundImage: `url("/assets//backgroundDashboardCircles.png")`}}>
          <div className='flex flex-row items-center w-full gap-20 font-poppins bg-gradient-to-r from-ccColorDarkTeal to-ccColorTeal'>
            <img src='/assets/logoWhite.png' className=' h-20 w-auto ml-8' alt='logo'/>
         </div>
         <div className="flex flex-col justify-center items-center">
          <div className="mt-12 w-12 h-12 rounded-full bg-ccColorDarkTeal flex justify-center items-center"><Iconify icon="ic:outline-lock" color="white" width={32} /></div>
          <div className="mt-2 text-2xl">Log in</div>
          {isKnowledgeBaseLogin &&
                <div className="mt-3 text-ccColorDarkTeal font-poppins text-xl">You will be redirected to the knowledge base</div>    
          }
          
          <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
            sx={{backgroundColor: 'white', "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: ccColorTeal
              },
              "&.Mui-focused fieldset": {
                borderColor: ccColorTeal
              }
            },
            "& label.Mui-focused": {
                  color: ccColorTeal
                },
          }}
                margin="normal"
                required
                fullWidth
                id="user"
                label="Username"
                name="user"
                autoComplete="user"
                autoFocus
              />
              <TextField
                          sx={{backgroundColor: 'white', "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              borderColor: ccColorTeal
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: ccColorTeal
                            }
                          },
                          "& label.Mui-focused": {
                                color: ccColorTeal
                              },
                        }}
              
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <button type="submit" className={`mt-6 w-full focus:outline-none text-white bg-ccColorSecondaryPink hover:bg-ccColorSecondaryPurple font-medium rounded-lg text-sm px-5 py-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900`}>
                <div className='flex flex-row gap-2 items-center justify-center font-poppins'>
                  <div className="font-bold">Log in</div>
                  {/*<Iconify icon="fa6-solid:rocket" width={20} />*/}
                </div>
              </button>
            </Box>
            {loginError && <Alert severity="error">{`Login error - ${loginErrorMsg}`}</Alert>}
            {requestInProgress && <CircularProgress sx={{color: ccColorDarkTeal}} />}
          </Box>
          {/*<Copyright sx={{ mt: 8, mb: 4 }} /> */}
          <div className="mt-6 flex flex-row justify-center gap-x-6">
            <Link to={`/stripe/newUser`} className=" text-ccColorDarkTeal text-sm font-semibold">Create an account</Link>
            <Link to={`/forgotPassword`} className=" text-ccColorDarkTeal text-sm font-semibold">Forgot password?</Link>
          </div>
        </Container>

        {showEmailNotValidated && <div className="mt-4 bg-white border rounded border-ccColorDarkTeal text-ccColorDarkTeal p-4 w-[600px]">
          {!emailValidationRequestInProgress && !emailValidationSent &&
          <>
          <div className="font-semibold text-lg text-center mb-2 font-poppins">Verify Your Email Address</div>
          <div>To access your account, please confirm your email by clicking the link we sent to your inbox when you signed up. The link is valid for 1 hour.</div>
          <div className="mt-2">Didn't receive the email or need a new one? Click Resend Email to get a fresh verification link.</div>
          <div className="flex justify-center"> 
            <button className={`mt-2 focus:outline-none text-white bg-ccColorSecondaryPurple font-medium rounded-lg text-sm px-5 py-2 mb-2`}
            onClick={handleEmailValidationResend}>
              <div className='flex flex-row gap-2 items-center justify-center font-poppins'>
                <div className="font-bold">Resend Email</div>
              </div>
            </button>
          </div>
          <Link to={`mailto:support@codechemistry.io`} className=" text-ccColorDarkTeal hover:text-ccColorSecondaryPink text-sm font-semibold">Need help? Contact support</Link>
          </>
          }
          {emailValidationRequestInProgress && <div className="flex justify-center">
            <CircularProgress sx={{color: ccColorDarkTeal}} />
          </div>
          }
          {emailValidationSent && <div className="flex justify-center">
            <div className="font-semibold">Email Sent</div>
          </div>
          }
        </div>
        }
         </div>

      </div>
    );

/*
    return (
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="user"
                label="Username"
                name="user"
                autoComplete="user"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </Box>
            {loginError && <Alert severity="error">{`Login error - ${loginErrorMsg}`}</Alert>}
            {requestInProgress && <CircularProgress />}
          </Box>
        </Container>
      </ThemeProvider>
    );
*/
  };

  export default Login;