import { useState, useEffect } from 'react';

import { IconButton, Snackbar, Stack, TextField, Tooltip, Typography } from "@mui/material";

import { Icon as Iconify } from '@iconify/react';
import { lionIcons } from './helpersIcons';


export function OneToOneParametersSelector(props) {

    const [allParameters, setAllParameters] = useState([{paramName: '', defaultValue: '', inUse: true}])
    const [snackBarClipboardOpen, setSnackBarClipboardOpen] = useState(false);

    useEffect(() => {
        if (props.initialParameters !== undefined && props.initialParameters !== null && props.initialParameters.length > 0) {
            setAllParameters(props.initialParameters)
        }
    }, [props.initialParameters])

    const handleAddParameter = () => {
        const newParameters = [...allParameters, {paramName: '', defaultValue: '', inUse: true}]

        setAllParameters(newParameters)
    }

    const handleDeleteParameter = (index) => {
        const newParameters = [...allParameters]
        newParameters.splice(index, 1);

        setAllParameters(newParameters)

        if (props.onChange !== undefined) props.onChange(newParameters)
    }

    const handleChangeParameter = (index, propName, propValue) => {
        const newParameters = [...allParameters]
        newParameters[index][propName] = propValue

        setAllParameters(newParameters)

        if (props.onChange !== undefined) props.onChange(newParameters)
    }

    const copyHtmlCodeToClipboard = (code) => {
        setSnackBarClipboardOpen(true);
        navigator.clipboard.writeText(code);
        }
    const handleCloseSnackBarClipboard = () => {
        setSnackBarClipboardOpen(false);
        }
    const SnackBarAction = (
        <>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackBarClipboard}
        >
            <Iconify icon={lionIcons.Icon_Actions_Cancel} />
        </IconButton>
        </>
    );
    
    
return <Stack spacing={1}>

        {allParameters.map((p, index) =>   <Stack key={index} direction='row' spacing={2} alignItems='center' justifyContent='flex-start'>
            <TextField size="small"
            value={p.paramName}
            label='Parameter name'
            onChange={(e) => handleChangeParameter(index, 'paramName', e.target.value)}
            />
            <TextField size="small"
            value={p.defaultValue}
            label='Default value'
            onChange={(e) => handleChangeParameter(index, 'defaultValue', e.target.value)}
            />

            <Stack key={index} direction='row' spacing={0} alignItems='center' justifyContent='flex-start'>
                <Tooltip title='Copy merge-code to clipboard'>
                    <IconButton color="inherit" onClick={() => copyHtmlCodeToClipboard(`\${${p.paramName}}`)} >
                        <Iconify icon='heroicons-outline:clipboard-copy' width={24} />
                    </IconButton>
                </Tooltip>

                <IconButton
                    onClick={handleAddParameter}
                    >
                    <Iconify icon={lionIcons.Icon_Actions_Add} width="24px" />
                </IconButton>
                {index > 0 &&       <IconButton
                                    onClick={() => handleDeleteParameter(index)}
                                    >
                                    <Iconify icon={lionIcons.Icon_Actions_Delete} width="24px" />
                                </IconButton>
                }

            </Stack>
            {!p.inUse && 
                <div class="flex flex-col gap-2 p-2 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800" role="alert">
                    <div className='flex gap-1 items-center'>
                        <Iconify icon="ph:warning" width={20} />
                        <div className=' font-semibold'>Parameter not used</div>
                    </div>
                    <span>Copy and paste this parameter in the endpoint to use it</span>
                    
                </div>
            }
        </Stack>)
}

<Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarClipboardOpen}
        autoHideDuration={1500}
        onClose={handleCloseSnackBarClipboard}
        message="Copied to clipboard"
        action={SnackBarAction}
      />

</Stack>
}
