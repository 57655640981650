import { ccColorDarkTeal } from "./helpersStyles"

const lionIcons = {
    Icon_bold: 'material-symbols:format-bold',
    Icon_italics: 'material-symbols:format-italic',
    Icon_underlined: 'material-symbols:format-underlined',
    Icon_TextAlignLeft: 'ph:text-align-left',
    Icon_TextAlignCenter: 'ph:text-align-center',
    Icon_TextAlignRight: 'ph:text-align-right',
    Icon_TextInsert:  'material-symbols:insert-text',
    Icon_TimerInsert:  'mdi:clock-outline',
    Icon_ImageInsert:  'material-symbols:image-outline',
    Icon_Shapes_Circle: 'material-symbols:circle-outline',
    Icon_Shapes_Ellipse: 'mdi:ellipse-outline',
    Icon_Shapes_Polygon: 'uil:polygon',
    Icon_Shapes_Triangle: 'mdi:triangle-outline',
    Icon_Shapes_Triangle_Points: 'tabler:vector-triangle',
    Icon_Shapes_Rectangle: 'material-symbols:rectangle-outline',
    Icon_Shapes_Square: 'material-symbols:square-outline',
    Icon_Shapes_Star: 'ic:baseline-star-outline',
    Icon_Shapes_Path: 'ph:path',
    Icon_Ratings: 'solar:stars-outline',
    Icon_Actions_Cancel: 'material-symbols:cancel-outline',
    Icon_Actions_Delete: 'material-symbols:delete-outline',
    Icon_Actions_Delete_CircleOutline: 'mdi:delete-circle-outline',
    Icon_Actions_Archive: 'clarity:archive-line',
    Icon_Actions_UnArchive: 'clarity:unarchive-line',
    Icon_Actions_Add: 'material-symbols:add-circle-outline',
    Icon_Actions_Minus: 'mdi:minus-circle-outline',
    Icon_Actions_Edit: 'material-symbols:edit',
    Icon_Actions_TickOutlined: 'mdi:tick-outline',
    Icon_Actions_MoveUp: 'material-symbols:move-up',
    Icon_Actions_MoveDown: 'material-symbols:move-down',
    Icon_Actions_MoveTop: 'gg:move-up',
    Icon_Actions_MoveBottom: 'gg:move-down',
    Icon_Databind_none: 'arcticons:url-checker',
    Icon_Databind_url: 'carbon:parameter',
    Icon_Databind_databound: 'file-icons:binder',
    Icon_Databind_imgLibrary: 'material-symbols:photo-library',
    Icon_Databind_fixed: 'lucide:locate-fixed',
    Icon_Databind_connected: 'material-symbols:power-plug-outline',
    Icon_Databind_disconnected: 'material-symbols:power-plug-off-outline',
    Icon_Label: 'quill:label'
}

const svgElementIcons = {
    PersonalisedAnimation: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24"><path fill={ccColorDarkTeal} d="M4 2a2 2 0 0 0-2 2v10h2V4h10V2zm4 4a2 2 0 0 0-2 2v10h2V8h10V6zm12 6v8h-8v-8zm0-2h-8a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2m-6 3v6l4-3z"/></svg>
}
export { lionIcons, svgElementIcons }
