import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import {LionLoaderGradient} from "../commonComponents/generalComponents"

const SessionExpired = () => {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    setToken();
    navigate("/profile", { replace: true });
  };

  
  setTimeout(() => {
    handleLogout();
  }, 2500);

  return <div className="flex flex-col w-full items-center mt-12 gap-2">
    <div>Session Expired</div>
    <div>Logging out...</div>
    <LionLoaderGradient />
    </div>;
};

export default SessionExpired;

