import TemplateGallery from "../../commonComponents/CC_templateGallery";
import SnippetsGrid from '../../components/snippetsGrid';
import { Link } from "react-router-dom";
import {linkStyle} from "../../components/helpersStyles";
//import { IconButton, Tooltip } from '@mui/material';
//import { Icon as Iconify } from '@iconify/react';
import { CcCreateNewSnippet, CcSubSectionTitle, CcTitle } from '../../commonComponents/styledTexts';
import { useEffect } from "react";

export default function ProductHomeOSTracker() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [])

    return <div>
            <CcTitle>
                App Match
            </CcTitle>
            <div className="mt-2 text-ccColorDarkTeal">Enhance relevance by matching the content you display to the operating system used to open the email. Ideal for displaying app download banners that match the user's device.</div>
            <Link to={`/workflow/13`} style={linkStyle}>
                <CcCreateNewSnippet className="mt-4" >Create new App Match</CcCreateNewSnippet>
            </Link>

            <CcSubSectionTitle className=" mt-6">Latest App Match Elements</CcSubSectionTitle>
            <SnippetsGrid productTypeId={13}
                 //editCall={(id, typeId) => editSnippet(id, typeId)}
             />

    </div>
}