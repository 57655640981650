import { useNavigate } from 'react-router-dom';

import {CcStepper2, StepperNavButtons} from '../commonComponents/stepper'
import {LionCombo, LionListbox} from  '../commonComponents/inputFields'
import {TemplateGalleryViewer} from '../commonComponents/templateGallery'
import {LionLoaderGradient} from '../commonComponents/generalComponents'
import { Icon as Iconify } from '@iconify/react';
import { IconButton } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useEffect, useState } from 'react';
import {SnippetCodeViewer} from '../commonComponents/snippetGeneric'

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';

import { useParams } from "react-router-dom";
import axios from 'axios';

import {TimerElementManager} from "../workflows/timerWorkflow";

import { getErrorListFromYupError, getErrorMessage, snippetDataTimerValidationSchema } from '../components/helperErrors'

// ----------------------------------------------------------------------

export const TimerLanguageManager = (props) => {
  const [settings, setSettings] = useState({"labels": {"defaultLanguage": "en", "customLanguageMapping":[{}]}})
  const [languages, setLanguages] = useState([{id: '', name: ''}]); //languages in the right format for the LionListbox
  const [defaultLanguage, setDefaultLanguage] = useState(undefined); //languages in the right format for the LionListbox
/*
props:
settings: the object the the language settings
allLanguages:

onChange
*/
  useEffect(() => {
    if (props.allLanguages !== undefined) {
      const langs = props.allLanguages.map(l => {return {id: l.languageCode, name: l.displayName}})
      setLanguages(langs)
    }

    if (props.settings !== undefined) {
      setSettings(props.settings);
      if (props.settings.labels !== undefined) setDefaultLanguage(props.settings.labels.defaultLanguage) 
    }
  }, [props.settings, props.allLanguages])

  const handleMappingChange = (code, mapping) => {
    const currentMatch = settings.labels.customLanguageMapping.find(lm => lm.code === code);
    let newMappings = [...settings.labels.customLanguageMapping];


    if (currentMatch === undefined) {
      newMappings.push({'code': code, 'mapping': mapping})
    }
    else {
      newMappings.find(m => m.code === code).mapping = mapping
    }
    const newLabels = {...settings.labels, customLanguageMapping: newMappings}
    const newSettings = {...settings, labels: newLabels};
    setSettings(newSettings)

    if (props.onChange !== undefined) props.onChange(newSettings);
  }
  const handleDefaultLanguageChange = (lang) => {
    const newLabels = {...settings.labels, defaultLanguage: lang.id}
    const newSettings = {...settings, labels: newLabels}

    setSettings(newSettings)
    setDefaultLanguage(lang.id)

    if (props.onChange !== undefined) props.onChange(newSettings);

  }

  return <div className='flex flex-col gap-4'>
           <div className="text-sm font-semibold leading-6 text-gray-900">
              Timer Label Settings</div>

          <div className='flex flex-col'>
            <div className="text-sm font-medium leading-6 text-gray-900">
              Default language</div>
            <LionListbox items={languages}
                  selectedItemId={defaultLanguage}
                  onChange={handleDefaultLanguageChange}
            />
          </div>
          <div className='flex flex-col'>
            <div className="text-sm font-medium leading-6 text-gray-900">
              Custom mapping (optional)</div>
          </div>
          {languages.map(l => {
            let currentMatch = undefined;
            if (settings.labels !== undefined) currentMatch = settings.labels.customLanguageMapping.find(lm => lm.code === l.id);
            const currentMapping = currentMatch === undefined ? '' : currentMatch.mapping;
            return <div key={l.id} className='flex flex-row items-center gap-2'>
            <div>{l.name}</div>
            <Iconify icon="fluent:arrow-fit-16-regular" width={26} />
            <input
                        type="text"
                        value={currentMapping}
                        onChange={(e) => handleMappingChange(l.id, e.target.value)}
                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />

          </div>

          })

          }
  </div>
}

export function TimerWizard_OLD(props) {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [selectedTemplate, setSelectedTemplate] = useState({templateId: undefined});
    const [timeZones, setTimeZones] = useState(undefined);
    const [languages, setLanguages] = useState([]);
    const [selectedTimeZoneId, setSelectedTimeZoneId] = useState("Europe/London");
    const [selectedTimerDate, setSelectedTimerDate] = useState(dayjs().toJSON());
    const [snippetName, setSnippetName] = useState('')
    const [snippetDesc, setSnippetDesc] = useState('')
    const [templateDicJson, setTemplateDicJson] = useState({}); //{templateId: {templateJson}} - we keep a dic with template json, it gets updated at step 1
    const [templateHasTimeLabels, setTemplateHasTimeLabels] = useState(false); // we check if the selected template contains time labels (by checking its templateJson)
    const [timerSettings, setTimerSettings] = useState({"labels": {"defaultLanguage": "en", "customLanguageMapping":[]}}) //{"labels": {"defaultLanguage": "en", "customLanguageMapping":{}}}


    const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);

    let params = useParams();

    useEffect(() => {
      getTimezones()
      getLanguages()
    }, [])

    useEffect(() => {
        console.log(params)
      if (params.snippetId !== undefined) {
        getSnippetInfo(params.snippetId)
      }
    }, [params])

    const getTimezones = async () => {
      try {  
        const url = `${process.env.REACT_APP_LION_API_URL}/Settings/timezones`;
        const response = await axios.get(url);
        const data = response.data;

        // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Settings/timezones`;
        // const response = await fetch(url, { method: "GET", });    
        // const data = await response.json();

        const result = data.map(d => {return {id: d.zoneId, name: d.zoneName}});
        setTimeZones(result);

      } catch (error) {
        console.log(error);
      }
    }
    const getLanguages = async () => {
      try {  
        const url = `${process.env.REACT_APP_LION_API_URL}/Settings/languages`;
        const response = await axios.get(url);
        const data = response.data;

        // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Settings/languages`;
        // const response = await fetch(url, { method: "GET", });    
        // const data = await response.json();

        setLanguages(data);

      } catch (error) {
        console.log(error);
      }
    }
    const getSnippetInfo = async (snippetId) => {
      try {  
        const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/${snippetId}`;
        const response = await axios.get(url);
        const data = response.data;

        // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Snippets/info/${snippetId}`;
      //const response = await fetch(url, { method: "GET", });    
      // const data = await response.json();

      const newTemplate = {...selectedTemplate}
      newTemplate.templateId = data.templateId;
      setSelectedTemplate(newTemplate);
      setSnippetName(data.snippetName)
      setSnippetDesc(data.description)
      setSelectedTimerDate(data.timerDate)
      setSelectedTimeZoneId(data.timerDateTimezoneIANA);
      setTimerSettings(JSON.parse(data.timerSetting));

      } catch (error) {
        console.log(error);
      }
    }
    const getTemplateJson = async (templateId) => {
      try {  
        const url = `${process.env.REACT_APP_LION_API_URL}/Templates/info/${templateId}`;
        const response = await axios.get(url);
        const data = response.data;

        // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Templates/info/${templateId}`;
        // const response = await fetch(url, { method: "GET", });    
        // const data = await response.json();

      const newTemplateDic = {...templateDicJson, [templateId]: data}
      setTemplateDicJson(newTemplateDic);

      setTemplateHasTimeLabels(templateContainsTimeLabels(data))

    } catch (error) {
        console.log(error);
      }
    }

    const handleNavChange = (navInfo) => {
      if (navInfo.actionType === 'Navigation'){
        setCurrentStepIndex(navInfo.nextIndex);
        if (navInfo.nextIndex === 1) {
          const currentTemplateIds = Object.keys(templateDicJson);
          if (!currentTemplateIds.includes(selectedTemplate.templateId.toString())) {
            getTemplateJson(selectedTemplate.templateId);
          }
          else setTemplateHasTimeLabels(templateContainsTimeLabels(templateDicJson[selectedTemplate.templateId]))
        }
      }
      else if (navInfo.actionType === 'Submit') {
        submitData();
      }
    }

    const handleSelectedTemplateChange = (tInfo) => {
      if (tInfo !== undefined) setSelectedTemplate(tInfo)
    }
    const handleTimerDateChange = (newDate) => {
      //console.log(newDate)
      //console.log(newDate.toJSON())
      setSelectedTimerDate(newDate.toJSON())
    }
    const handleTimezoneChange = (timezone) => {
      setSelectedTimeZoneId(timezone.id)
    }
    const submitData = async () => {
      setShowLoader(true);
      setSuccessHtmlMsg('');
    
      try {
          let url = `${process.env.REACT_APP_LION_API_URL}/Snippets/create/ca`;
          if (params.snippetId !== null) url = `${process.env.REACT_APP_LION_API_URL}/Snippets/update/12/${params.snippetId}`;
          // let url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Snippets/create/timer`;
          // if (props.snippetId !== undefined && props.snippetId !== null) url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Snippets/update/timer/${props.snippetId}`;
          // console.log(snippetInfo)
      
          const dataToPost = prepareDataToPost();
          const response = await axios.post(url, dataToPost);
          // const response = await fetch(url, { method: "POST", body: JSON.stringify(dataToPost) });    
          // response.data = await response.json();
    
          setSuccessHtmlMsg(response.data);
          setShowSuccessMsg(true);
      } catch (error) {
        setShowSuccessMsg(false);
        setShowErrorMsg(true);
        console.log(error);
      }
      setShowLoader(false);
      //setSubmitLoading(false);
    }
    const prepareDataToPost = () => {
      const result = {snippetId: 0, snippetName: '', description: '', 
      labels: [{labelId: 0, labelName: ''}], href: '',
      snippetTypeId: 12,
      filters: [], orderBy: [],
      dataConnectionId: 0, templateId: 0, dataConnectionInfo: {}};

      result.templateId = selectedTemplate.templateId;
      result.snippetName = snippetName;
      result.description = snippetDesc;
      result.timerDate = selectedTimerDate;
      result.timerDateTimeZone = selectedTimeZoneId;
      result.timerSettings = JSON.stringify(timerSettings);
      console.log(result)
      return result;
    }
    const templateContainsTimeLabels = (templateJson) => {
      let result = false;
      const blocks = templateJson.model.children[0].children;

      blocks.forEach(block => {
        if (block.className === "Text" && block.attrs.id.startsWith('timerLabel')) result = true;
      })
      return result;
    }
    const handleTimerSettingsChange = (newSettings) => {
      setTimerSettings(newSettings)
    }

   return (
    <>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className='border border-gray-300 rounded-md shadow-md p-1'>
      <div className='flex flex-row-reverse'>
        <IconButton variant="contained" sx={{color: '#d1d5db'}} >
          <Iconify icon="material-symbols-light:cancel-outline" width="44px" color='#d1d5db' />
        </IconButton>

      </div>
      <CcStepper2 steps={[{ name: 'Template selection' }, { name: 'Configure' }, { name: 'Finish' }]}
          currentStepIndex={currentStepIndex}
      />

        {currentStepIndex === 0 && <div>
          <TemplateGalleryViewer productTypeId={12}
          onChange={handleSelectedTemplateChange}
          selectedTemplateId={selectedTemplate.templateId}
          />
        </div>
        }

      {currentStepIndex === 1 && <div className='flex flex-col gap-4 justify-center items-center'>
        <div className=" w-1/2 mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label htmlFor="snippetName" className="block text-sm font-medium leading-6 text-gray-900">
                      Code name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="first-name"
                        id="snippetName"
                        autoComplete="given-name"
                        value={snippetName}
                        onChange={(e) => setSnippetName(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="snippetDescription" className="block text-sm font-medium leading-6 text-gray-900">
                      Description
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="last-name"
                        id="snippetDescription"
                        autoComplete="family-name"
                        value={snippetDesc}
                        onChange={(e) => setSnippetDesc(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="timerDate" className="block text-sm font-medium leading-6 text-gray-900">
                      Expiry date
                    </label>
                    <div className="mt-2">
                    <DateTimePicker
                          value={dayjs(selectedTimerDate)}
                          ampm={false}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          onChange={handleTimerDateChange}
                        />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="snippetDescription" className="block text-sm font-medium leading-6 text-gray-900">
                      Time zone
                    </label>
                    <div className="mt-2">
                      <LionCombo items={timeZones}
                      onChange={handleTimezoneChange}
                      selectedItemId={selectedTimeZoneId}
                       />
                    </div>
                  </div>

            </div>
          
          {templateHasTimeLabels && <div className='flex flex-col justify-start w-1/2'>
            <TimerLanguageManager 
              allLanguages={languages} 
              settings={timerSettings}
              onChange={handleTimerSettingsChange}
              />
            </div>}
        </div>
        
      }

      {currentStepIndex === 2 && <div className='flex justify-center'>
        {!showLoader && !showSuccessMsg && !showErrorMsg && <div>Confirm</div> }
        {showLoader && <LionLoaderGradient /> }
        {showSuccessMsg && <SnippetCodeViewer numSnippets={1} html={successHtmlMsg} />}

      
      </div>
      }

      <StepperNavButtons totalSteps={3}
      currentStepNum={currentStepIndex}
      onChange={handleNavChange}
      />
      </div>
    </LocalizationProvider>

    </>
  );
}

export default function TimerWizard(props) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState({templateId: undefined});
  const [templateDicJson, setTemplateDicJson] = useState({}); //{templateId: {templateJson}} - we keep a dic with template json, it gets updated at step 1
  const [templateHasTimeLabels, setTemplateHasTimeLabels] = useState(false); // we check if the selected template contains time labels (by checking its templateJson)
  const [timerSettings, setTimerSettings] = useState({"labels": {"defaultLanguage": "en", "customLanguageMapping":[]}}) //{"labels": {"defaultLanguage": "en", "customLanguageMapping":{}}}
  const [snippetData, setSnippetData] = useState(undefined)


  const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  let params = useParams();
  const navigate = useNavigate();

  const [activeErrorList, setActiveErrorList] = useState([]);


  useEffect(() => {
      console.log(params)
    if (params.snippetId !== undefined) {
      getSnippetInfo(params.snippetId)
    }
  }, [params])

  const getSnippetInfo = async (snippetId) => {
    try {  
      const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/${snippetId}`;
      const response = await axios.get(url);
      const data = response.data;

      // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Snippets/info/${snippetId}`;
    //const response = await fetch(url, { method: "GET", });    
    // const data = await response.json();

    const newTemplate = {...selectedTemplate}
    newTemplate.templateId = data.templateId;
    setSelectedTemplate(newTemplate);
    //setSnippetName(data.snippetName)
    //setSnippetDesc(data.description)
    //setSelectedTimerDate(data.timerDate)
    //setSelectedTimeZoneId(data.timerDateTimezoneIANA);
    //setTimerSettings(JSON.parse(data.timerSetting));
      setSnippetData(data);

    } catch (error) {
      console.log(error);
    }
  }
  const getTemplateJson = async (templateId) => {
    try {  
      const url = `${process.env.REACT_APP_LION_API_URL}/Templates/info/${templateId}`;
      const response = await axios.get(url);
      const data = response.data;

      // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Templates/info/${templateId}`;
      // const response = await fetch(url, { method: "GET", });    
      // const data = await response.json();

    const newTemplateDic = {...templateDicJson, [templateId]: data}
    setTemplateDicJson(newTemplateDic);

    setTemplateHasTimeLabels(templateContainsTimeLabels(data))

  } catch (error) {
      console.log(error);
    }
  }

  const handleNavChange = async(navInfo) => {
    if (navInfo.actionType === 'Navigation'){
      if (navInfo.nextIndex === 1) {
        const currentTemplateIds = Object.keys(templateDicJson);
        if (!currentTemplateIds.includes(selectedTemplate.templateId.toString())) {
          getTemplateJson(selectedTemplate.templateId);
        }
        else setTemplateHasTimeLabels(templateContainsTimeLabels(templateDicJson[selectedTemplate.templateId]))
      }
      setCurrentStepIndex(navInfo.nextIndex);
    }
    else if (navInfo.actionType === 'Submit') {
      const valid = await submitData();
      if (valid) setCurrentStepIndex(navInfo.nextIndex);
    }
  }

  const handleSelectedTemplateChange = (tInfo) => {
    if (tInfo !== undefined) setSelectedTemplate(tInfo)
    const newSnippetData = {...snippetData, templateId: tInfo.templateId}
    
    setSnippetData(newSnippetData)
  }
  const submitData = async () => {
    try {
      //console.log(snippetData)
      snippetDataTimerValidationSchema.validateSync(snippetData, {abortEarly: false});
      setActiveErrorList([])
    }
    catch (e) {
      //console.log(JSON.parse(JSON.stringify(e)))
      const errorList = getErrorListFromYupError(e)
      setActiveErrorList(errorList)
      return false;
    }

    setShowLoader(true);
    setSuccessHtmlMsg('');
  
    try {
        let url = `${process.env.REACT_APP_LION_API_URL}/Snippets/create/ca`;
        if (params.snippetId !== null) url = `${process.env.REACT_APP_LION_API_URL}/Snippets/update/12/${params.snippetId}`;
    
        const response = await axios.post(url, snippetData);
        // const response = await fetch(url, { method: "POST", body: JSON.stringify(dataToPost) });    
        // response.data = await response.json();
  
        setSuccessHtmlMsg(response.data);
        setShowSuccessMsg(true);
    } catch (error) {
      setShowSuccessMsg(false);
      setShowErrorMsg(true);
      console.log(error);
    }
    setShowLoader(false);
    setSubmitted(true);
    return true;
  }

  const templateContainsTimeLabels = (templateJson) => {
    let result = false;
    const blocks = templateJson.model.children[0].children;

    blocks.forEach(block => {
      if (block.className === "Text" && block.attrs.id.startsWith('timerLabel')) result = true;
    })
    return result;
  }

  const handleTimerElementChange = (snippetInfo) => {
    setSnippetData(snippetInfo)
    try {
      snippetDataTimerValidationSchema.validateSync(snippetInfo, {abortEarly: false});
      setActiveErrorList([])
    }
    catch (e) {
      const errorList = getErrorListFromYupError(e)
      setActiveErrorList(errorList)
      //return false;
    }

  }
  const handleClose = () => {
    navigate("/ph/timers")
  }

 return (
  <>
    <div className='border border-gray-300 rounded-md shadow-md p-1'>
    <div className='flex flex-row-reverse'>
      <IconButton variant="contained" sx={{color: '#d1d5db'}} >
        <Iconify icon="material-symbols-light:cancel-outline" width="44px" color='#d1d5db' />
      </IconButton>

    </div>
    <CcStepper2 steps={[{ name: 'Template selection' }, { name: 'Configure' }, { name: 'Finish' }]}
        currentStepIndex={currentStepIndex}
    />

      {currentStepIndex === 0 && <div style={{maxHeight: 'calc(100vh - 300px)'}} className='overflow-auto'>
        <TemplateGalleryViewer productTypeId={12}
        onChange={handleSelectedTemplateChange}
        selectedTemplateId={selectedTemplate.templateId}
        />
      </div>
      }

    {currentStepIndex === 1 && 
        <TimerElementManager
            onChange={handleTimerElementChange}
            snippetInfo={snippetData}
            templateHasTimeLabels={templateHasTimeLabels}
            validationErrorsList={activeErrorList}
        />
      
      
    }

    {currentStepIndex === 2 && <div className='flex justify-center'>
      {showLoader && <LionLoaderGradient /> }
      {showSuccessMsg && <SnippetCodeViewer numSnippets={1} html={successHtmlMsg} />}

    
    </div>
    }

    <StepperNavButtons totalSteps={3}
        currentStepNum={currentStepIndex}
        onChange={handleNavChange}
        showClose={submitted}
        onClose={handleClose}
    />
    </div>

  </>
);
}
