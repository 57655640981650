import { Stack } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Box } from '@mui/material';

import {store} from '../redux/store'
import {setAsLoaded} from '../redux/slices/userSettings'
import dayjs from 'dayjs';
import {ApplyDateJs} from "../components/date";
import {ApplyDateJsIT} from "../components/date-it-IT";


function TransformDataForGrid(data, idField) {
    const rows = [];

    data.forEach(element => {
        const currentRow = {};
        Object.keys(element).forEach(k => {
            if (k === idField) currentRow.id = element[k];
            else currentRow[k] = element[k];
        });
        rows.push(currentRow);
    });
    return rows;
}

function TransformDataForGridArrayOfRows(rowArray, colNamesArray) {
    const rows = [];
    const containsId = colNamesArray.includes("id")
    let fakeId = 1;
    rowArray.forEach(row => {
        const currentRow = {};
        if (!containsId) currentRow["id"] = fakeId++
        for (let i = 0; i < colNamesArray.length; i+=1) {
            currentRow[colNamesArray[i]] = row[i];
        }
        rows.push(currentRow);
    });
    return rows;
}


function DownloadUserSettings() {
    const settingsLoaded = store.getState().userSettings.loaded;
    if (!settingsLoaded) {
        getData();
        store.dispatch(setAsLoaded());
    }

    alert(store.getState().userSettings.loaded);
    return 'ciao';
}

const getData = async () => {
    try {
      // const url = `${process.env.REACT_APP_LION_API_URL}/User/Settings`;
      // const response = await axios.get(url);

      const url = `api/User/Settings`;
      const response = await fetch(url, { method: "GET", });    
      const data = await response.json();

    } catch (error) {
      console.log(error);
    }
  }

  function TryParseIntDefault(strToParse, defaultIfError) {

    try {
        const result = parseInt(strToParse, 10);
        if (Number.isNaN(result)) {return defaultIfError;}
        return result;
    }
    catch (err) {
        return defaultIfError;
    }
}
function TryParseFloatDefault(strToParse, defaultIfError) {

  try {
      const result = parseFloat(strToParse);
      if (Number.isNaN(result)) {return defaultIfError;}
      return result;
  }
  catch (err) {
      return defaultIfError;
  }
}


function GetFilterDefFromMetadata(metadata, dataTypesInMetadata)  {
    // if (dataConnectionInfo === undefined || dataConnectionInfo === null) return [];
    const results = [];
    const colNames = Object.keys(metadata.columns);
    for (let i=0; i<colNames.length; i+=1) {
      const currentColName = colNames[i];
      if (metadata.columns[currentColName].visible) {
        const currentCondition = {
          conditionName: currentColName,
          dataType: dataTypesInMetadata === true ? metadata.columns[currentColName].dataType : 'unknown', 
          conditionSymbol: currentColName
        }
        results.push(currentCondition);  
      }

    }

    return results;
  }


function RandomId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function GuessDataType(samples) {
  ApplyDateJs()
  //ApplyDateJsIT() //TODO: apply logic to parse at least in two cultures


  const TEXT_LENGTH_LIMIT = 300;

  let occurenciesTxt = 0;
  let occurenciesLongTxt = 0;
  let occurenciesNumber = 0;
  let occurenciesDatetime = 0;

  samples.forEach(sample => {
    try {
      if (sample === '') return;

      if (!isNaN(Number(sample))) {occurenciesNumber += 1; return};

      //if (dayjs(sample).isValid()) {occurenciesDatetime += 1; return;}
      if (sample.length > 1 && Date.parse(sample) !== null) {occurenciesDatetime += 1; return;}

      //if we are here it's text
      if (sample.length > TEXT_LENGTH_LIMIT) {occurenciesLongTxt += 1; return}
      else {occurenciesTxt += 1; return;}
    }
    catch (e) {}

  })

  if (occurenciesDatetime > occurenciesNumber && occurenciesDatetime > occurenciesTxt
      && occurenciesDatetime > occurenciesLongTxt) return 'datetime'
  
  if (occurenciesNumber > occurenciesDatetime && occurenciesNumber > occurenciesTxt
    && occurenciesNumber > occurenciesLongTxt) return 'number'
    
  //if we are here it's either a text or a longtext. If there is even 1 long text occurency we need to set it as long text
  if (occurenciesLongTxt > 0) return 'longtext'
  else return 'text';

}

async function CheckImageUrlExists(url) {
  let img = new Image();
  const imageLoadPromise = new Promise(resolve => {
    img.onload = resolve;
    img.onload = () => {img = null; resolve(true);}
    img.onerror = () => {img = null; resolve(false);}
    img.src = url;
});
  const result = await imageLoadPromise;
  return result;

}

export {TransformDataForGrid, TransformDataForGridArrayOfRows, DownloadUserSettings, TryParseIntDefault, TryParseFloatDefault, RandomId, GetFilterDefFromMetadata, GuessDataType, CheckImageUrlExists };