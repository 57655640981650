
// simplified methods to handle simple svg data path 

function GetSegments(svgPath) {
    // const result = svgPath.split(/^[A-Za-z]+$/);
    const results = [];
    let currentSegment = '';
    for (let i = 0; i < svgPath.length; i+=1) {
        const currentChar = svgPath[i];
        if (currentChar !== undefined) {
            if (currentChar.match(/[A-Za-z]/)) {
                if (currentSegment.length > 0) results.push(currentSegment.trim());
                // start new segment
                currentSegment = currentChar;
            }
            else currentSegment += currentChar;    
        }

    }
    results.push(currentSegment.trim());

    return results;
};

function ParseSvg(svgPath) {
    const results = [];
    const segments = GetSegments(svgPath);

    segments.forEach((s, index) => {
        const currentSegmentParts = s.split(' ');
        const currentSegment = {data: s, pos: index};
        currentSegment.typeCode = s[0];
        switch (s[0]) {
            case 'a':
                currentSegment.type = 'Arc';
                currentSegment.rx = currentSegmentParts[1];
                currentSegment.ry = currentSegmentParts[2];
                currentSegment.xRotation = currentSegmentParts[3];
                currentSegment.arcFlag = currentSegmentParts[4];
                currentSegment.sweepFlag = currentSegmentParts[5];
                currentSegment.endX = currentSegmentParts[6];
                currentSegment.endY = currentSegmentParts[7];
            break;
            case 'l':
                currentSegment.type = 'Line';
                currentSegment.endX = currentSegmentParts[1];
                currentSegment.endY = currentSegmentParts[2];
            break;
            default:
                break;
        }
        results.push(currentSegment);
    })
    return results;
}

function GetSegmentEndXY(svgPath, segmentPos) {
    const results = {x:0, y:0};
    if (segmentPos === 0) return results;

    const segments = ParseSvg(svgPath);
    for (let i = 1; i <= segmentPos; i += 1) {
        const currentSegment = segments[i];
        results.x += parseInt(currentSegment.endX, 10);
        results.y += parseInt(currentSegment.endY, 10);    

    }
    // console.log(results)
    return results;
}

function EditSvg(originalPath, segmentPos, propName, propValue) {
    
    const segments = ParseSvg(originalPath);
    let segmentToEdit = segments[segmentPos];

    if (propName === 'typeCode') {
        segmentToEdit = changeSegmentType(segmentToEdit, propValue);
        segments.splice(segmentPos, 1, segmentToEdit);
    }
    else segmentToEdit[propName] = propValue;

    return SegmentsToSvg(segments);
}

function ChangeEndPointSvg(originalPath, segmentPos, endX, endY) {
    
    const segments = ParseSvg(originalPath);
    const segmentToEdit = segments[segmentPos];

    segmentToEdit.endX = endX;
    segmentToEdit.endY = endY;

    return SegmentsToSvg(segments);
}


function changeSegmentType(originalSegment, newType) {
    // we change a segment type keeping its endX , endY coords
    // and applying default values for other props
    const result = {...originalSegment, typeCode: newType};
    

    switch(newType) {
        case 'l':
            result.type = 'Line'
            // no default values to use
            break;
        case 'a':
            {
                result.type = 'Arc'
                const k = Math.max(Math.abs(parseInt(result.endX,10)), Math.abs(parseInt(result.endY,10)));
                result.rx = k / 2;
                result.ry = k / 2;
                result.xRotation = 0;
                result.arcFlag = 0;
                result.sweepFlag = 1;    
            }
            break;
            default:
            break;
    }

    return result;
}

function DeleteSegment(originalPath, segmentPos) {
    
    const segments = ParseSvg(originalPath);

    segments.splice(segmentPos, 1);


    return SegmentsToSvg(segments);
}


function SegmentsToSvg(segments) {
    let result = '';

    segments.forEach(s => {
        switch (s.typeCode) {
            case 'M':
                result += 'M0 0 ';
                break;
            case 'a':
                result += `a ${s.rx} ${s.ry} ${s.xRotation} ${s.arcFlag} ${s.sweepFlag} ${s.endX} ${s.endY} `;
            break;
            case 'l':
                result += `l ${s.endX} ${s.endY} `;
            break;
            default:
                break;
        }
    })

    return result;
}


export { ParseSvg, GetSegments, EditSvg, DeleteSegment, ChangeEndPointSvg, GetSegmentEndXY, SegmentsToSvg };
